import FuseScrollbars from 'src/lib/@_fuse/core/FuseScrollbars';
import { styled } from '@mui/material/styles';
import clsx from 'clsx';
import * as PropTypes from 'prop-types';
import { forwardRef, useImperativeHandle, memo, useRef } from 'react';
import GlobalStyles from '@mui/material/GlobalStyles';
import FusePageSimpleHeader from './FusePageSimpleHeader';
import FusePageSimpleSidebar from './FusePageSimpleSidebar';

const Root = styled('div')(({ theme, ...props }) => ({
	display: 'flex',
	flexDirection: 'column',
	minWidth: 0,
	minHeight: '100%',
	position: 'relative',
	flex: '1 1 auto',
	width: '100%',
	height: 'auto',
	backgroundColor: theme.palette.background.default,

	'& .FusePageSimple-scroll-content': {
		height: '100%'
	},

	'& .FusePageSimple-wrapper': {
		display: 'flex',
		flexDirection: 'row',
		flex: '1 1 auto',
		zIndex: 2,
		// maxWidth: '100%',
		minWidth: 0,
		height: '100%',
		backgroundColor: theme.palette.background.default
	},

	'& .FusePageSimple-header': {
		// height: headerHeight,
		// minHeight: headerHeight,
		display: 'flex',
		flex: '0 1 auto',
		// backgroundColor: theme.palette.background.paper,
		// color: theme.palette.primary.main,
		backgroundSize: 'cover'
		// borderBottom: `1px solid ${theme.palette.divider}`,
	},

	'& .FusePageSimple-topBg': {
		position: 'absolute',
		left: 0,
		right: 0,
		top: 0,
		height: headerHeight,
		pointerEvents: 'none'
	},

	'& .FusePageSimple-contentWrapper': {
		display: 'flex',
		flexDirection: 'column',
		width: '100%',
		flex: '1 1 auto',
		overflow: 'hidden',
		//    WebkitOverflowScrolling: 'touch',
		zIndex: 9999
	},

	'& .FusePageSimple-toolbar': {
		height: toolbarHeight,
		minHeight: toolbarHeight,
		display: 'flex',
		alignItems: 'center'
	},

	'& .FusePageSimple-content': {
		display: 'flex',
		flex: '1 1 auto',
		minHeight: 0
	},

	'& .FusePageSimple-sidebarWrapper': {
		overflow: 'hidden',
		backgroundColor: 'transparent',
		position: 'absolute',
		'&.permanent': {
			[theme.breakpoints.up('lg')]: {
				position: 'relative',
				marginLeft: 0,
				marginRight: 0,
				transition: theme.transitions.create('margin', {
					easing: theme.transitions.easing.sharp,
					duration: theme.transitions.duration.leavingScreen
				}),
				'&.closed': {
					transition: theme.transitions.create('margin', {
						easing: theme.transitions.easing.easeOut,
						duration: theme.transitions.duration.enteringScreen
					}),

					'&.FusePageSimple-leftSidebar': {
						marginLeft: -props.leftsidebarwidth
					},
					'&.FusePageSimple-rightSidebar': {
						marginRight: -props.rightsidebarwidth
					}
				}
			}
		}
	},

	'& .FusePageSimple-sidebar': {
		position: 'absolute',
		backgroundColor: theme.palette.background.paper,
		color: theme.palette.text.primary,

		'&.permanent': {
			[theme.breakpoints.up('lg')]: {
				position: 'relative'
			}
		},
		maxWidth: '100%',
		height: '100%'
	},

	'& .FusePageSimple-leftSidebar': {
		width: props.leftsidebarwidth,

		[theme.breakpoints.up('lg')]: {
			borderRight: `1px solid ${theme.palette.divider}`,
			borderLeft: 0
		}
	},

	'& .FusePageSimple-rightSidebar': {
		width: props.rightsidebarwidth,

		[theme.breakpoints.up('lg')]: {
			borderLeft: `1px solid ${theme.palette.divider}`,
			borderRight: 0
		}
	},

	'& .FusePageSimple-sidebarHeader': {
		height: headerHeight,
		minHeight: headerHeight,
		backgroundColor: theme.palette.primary.dark,
		color: theme.palette.primary.contrastText
	},

	'& .FusePageSimple-sidebarHeaderInnerSidebar': {
		backgroundColor: 'transparent',
		color: 'inherit',
		height: 'auto',
		minHeight: 'auto'
	},

	'& .FusePageSimple-sidebarContent': {
		display: 'flex',
		flexDirection: 'column',
		minHeight: '100%'
	},

	'& .FusePageSimple-backdrop': {
		position: 'absolute'
	}
}));

const headerHeight = 120;
const toolbarHeight = 64;

const FusePageSimple = forwardRef((props, ref) => {
	// console.info("render::FusePageSimple");
	const leftSidebarRef = useRef(null);
	const rightSidebarRef = useRef(null);
	const rootRef = useRef(null);

	useImperativeHandle(ref, () => ({
		rootRef,
		toggleLeftSidebar: (val) => {
			leftSidebarRef.current.toggleSidebar(val);
		},
		toggleRightSidebar: (val) => {
			rightSidebarRef.current.toggleSidebar(val);
		}
	}));

	return (
		<>
			<GlobalStyles
				styles={(theme) => ({
					'#fuse-main': {
						height: props.scroll === 'content' && '100vh',
						overflow: props.scroll === 'body' && 'hidden'
					}
				})}
			/>
			<Root
				className={clsx('FusePageSimple-root', `FusePageSimple-scroll-${props.scroll}`, props.className)}
				ref={rootRef}
				leftsidebarwidth={props.leftSidebarWidth}
				rightsidebarwidth={props.rightSidebarWidth}
			>
				<div className="flex flex-auto flex-col z-10 h-full">
					{props.header && props.sidebarInner && (
						<FusePageSimpleHeader header={<div className="container">{props.header}</div>} />
					)}

					<div className="FusePageSimple-wrapper">
						{props.leftSidebarContent && (
							<FusePageSimpleSidebar
								position="left"
								header={props.leftSidebarHeader}
								content={props.leftSidebarContent}
								variant={props.leftSidebarVariant || 'permanent'}
								sidebarInner={props.sidebarInner}
								ref={leftSidebarRef}
								rootRef={rootRef}
								open={props.leftSidebarOpen}
								onClose={props.leftSidebarOnClose}
							/>
						)}

						<div
							className="FusePageSimple-contentWrapper"
							// enable={props.scroll === 'page'}
						>
							{props.header && !props.sidebarInner && <FusePageSimpleHeader header={props.header} />}
							{props.content && (
								<FuseScrollbars
									enable={props.scroll === 'content'}
									className={clsx('FusePageSimple-content container')}
								>
									{props.content}
								</FuseScrollbars>
							)}
						</div>

						{props.rightSidebarContent && (
							<FusePageSimpleSidebar
								position="right"
								header={props.rightSidebarHeader}
								content={props.rightSidebarContent}
								variant={props.rightSidebarVariant || 'permanent'}
								sidebarInner={props.sidebarInner}
								ref={rightSidebarRef}
								rootRef={rootRef}
								open={props.rightSidebarOpen}
								onClose={props.rightSidebarOnClose}
							/>
						)}
					</div>
				</div>
			</Root>
		</>
	);
});

FusePageSimple.propTypes = {
	leftSidebarHeader: PropTypes.node,
	leftSidebarContent: PropTypes.node,
	leftSidebarVariant: PropTypes.node,
	rightSidebarHeader: PropTypes.node,
	rightSidebarContent: PropTypes.node,
	rightSidebarVariant: PropTypes.node,
	header: PropTypes.node,
	content: PropTypes.node,
	contentToolbar: PropTypes.node,
	scroll: PropTypes.oneOf(['body', 'page', 'content']),
	leftSidebarOpen: PropTypes.bool,
	rightSidebarOpen: PropTypes.bool,
	leftSidebarWidth: PropTypes.number,
	rightSidebarWidth: PropTypes.number,
	rightSidebarOnClose: PropTypes.func,
	leftSidebarOnClose: PropTypes.func
};

FusePageSimple.defaultProps = {
	classes: {},
	scroll: 'page',
	sidebarInner: false,
	leftSidebarOpen: false,
	rightSidebarOpen: false,
	rightSidebarWidth: 240,
	leftSidebarWidth: 240
};

export default memo(styled(FusePageSimple)``);
