import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import FuseSvgIcon from 'src/lib/@fuse/core/FuseSvgIcon';
import { darken } from '@mui/material/styles';
import { alpha } from '@mui/system/colorManipulator';
import Tooltip from '@mui/material/Tooltip';
import clsx from 'clsx';
import Popover from '@mui/material/Popover/Popover';
import { useAuth, useAuthState } from 'src/app/context/useAuth';
import { useSettings } from 'src/app/context/useSettings';
import { useSessionStorage } from 'react-storage-complete';
import { Base64 } from 'js-base64';
import UserMenuVariant from 'src/app/components/UserMenu';

/**
 * The user menu.
 */
function UserMenu(props) {
	const { className, popoverProps, arrowIcon = 'heroicons-outline:chevron-up' } = props;

	const navigate = useNavigate();

	const { displayName, role, photoUrl, email } = useAuthState();
	const { signOut } = useAuth();
	const { removeStorageUser } = useSettings();

	const [settingsSession, _] = useSessionStorage(
		'settings',
		{},
		{
			prefix: 'config',
			shouldInitialize: true,
			emitterDisabled: false,
			encode: (t) => Base64.encode(JSON.stringify(t)),
			decode: (t) => JSON.parse(Base64.decode(t))
		}
	);

	const [userMenu, setUserMenu] = useState(null);
	const userMenuClick = (event) => {
		setUserMenu(event.currentTarget);
	};
	const userMenuClose = () => {
		setUserMenu(null);
	};

	if (!displayName) {
		return null;
	}

	return (
		<>
			<Button
				className={clsx(
					'user-menu flex justify-start shrink-0  min-h-56 h-56 rounded-lg p-8 space-x-12',
					className
				)}
				sx={{
					borderColor: (theme) => theme.palette.divider,
					'&:hover, &:focus': {
						backgroundColor: (theme) =>
							theme.palette.mode === 'dark'
								? alpha(theme.palette.divider, 0.1)
								: alpha(theme.palette.divider, 0.6)
					}
				}}
				onClick={userMenuClick}
				color="inherit"
			>
				{settingsSession?.photoUrl?.imagePreviewUrl ? (
					<Avatar
						sx={{
							background: (theme) => theme.palette.background.default,
							color: (theme) => theme.palette.text.secondary
						}}
						className="avatar w-40 h-40 rounded-lg"
						alt="user photo"
						src={settingsSession?.photoUrl.imagePreviewUrl}
						variant="rounded"
					/>
				) : (
					<Avatar
						sx={{
							background: (theme) => darken(theme.palette.background.default, 0.05),
							color: (theme) => theme.palette.text.secondary
						}}
						className="avatar md:mx-4"
					>
						{displayName?.[0]}
					</Avatar>
				)}
				<div className="flex flex-col flex-auto space-y-8">
					<Typography
						component="span"
						className="title flex font-semibold text-base capitalize truncate  tracking-tight leading-none"
					>
						{displayName}
					</Typography>
					<Typography
						className="subtitle flex text-md font-medium tracking-tighter leading-none"
						color="text.secondary"
					>
						{email}
					</Typography>
				</div>
				<div className="flex flex-shrink-0 items-center space-x-8">
					<Tooltip
						title={
							<>
								{role?.toString()}
								{(!role || (Array.isArray(role) && role.length === 0)) && 'Guest'}
							</>
						}
					>
						<FuseSvgIcon
							className="info-icon"
							size={20}
						>
							heroicons-outline:information-circle
						</FuseSvgIcon>
					</Tooltip>
					<FuseSvgIcon
						className="arrow"
						size={13}
					>
						{arrowIcon}
					</FuseSvgIcon>
				</div>
			</Button>

			<Popover
				open={Boolean(userMenu)}
				anchorEl={userMenu}
				onClose={userMenuClose}
				anchorOrigin={{
					vertical: 'top',
					horizontal: 'center'
				}}
				transformOrigin={{
					vertical: 'bottom',
					horizontal: 'center'
				}}
				classes={{
					paper: 'py-8 min-w-256'
				}}
				{...popoverProps}
			>
				{!role || role.length === 0 ? (
					<>
						<MenuItem
							component={Link}
							to="/sign-in"
							role="button"
						>
							<ListItemIcon className="min-w-36">
								<FuseSvgIcon>heroicons-outline:lock-closed</FuseSvgIcon>
							</ListItemIcon>
							<ListItemText primary="Sign In" />
						</MenuItem>
						<MenuItem
							component={Link}
							to="/sign-up"
							role="button"
						>
							<ListItemIcon className="min-w-36">
								<FuseSvgIcon>heroicons-outline:user-plus</FuseSvgIcon>
							</ListItemIcon>
							<ListItemText primary="Sign up" />
						</MenuItem>
					</>
				) : (
					<>
						<UserMenuVariant
							role={role}
							userMenuClose={userMenuClose}
						/>
						<MenuItem
							onClick={() => {
								signOut();
								removeStorageUser();
								// refreshPage();
								navigate({
									pathname: '/login'
								});
								userMenuClose();
							}}
						>
							<ListItemIcon className="min-w-36">
								<FuseSvgIcon>heroicons-outline:arrow-right-on-rectangle</FuseSvgIcon>
							</ListItemIcon>
							<ListItemText primary="Sign out" />
						</MenuItem>
					</>
				)}
			</Popover>
		</>
	);
}

export default UserMenu;
