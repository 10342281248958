/**
 * Global constant values.
 */

import { lazy } from 'react';

import cfLogoLt from '../assets/images/logofiles/chartleaf_lit_bg.svg';
import cfLogoDk from '../assets/images/logofiles/chartleaf_drk_bg.svg';
import cfIcon from '../assets/images/logofiles/chartLeaf_icon_drk.svg';

import ndLogoLt from '../assets/images/logofiles/nexusdata_lit_bg.svg';
import ndLogoDk from '../assets/images/logofiles/nexusdata_drk_bg.svg';

import ndIcon from '../assets/images/logofiles/nexusdata_icon.svg';

import hmIconLt from '../assets/images/logofiles/helpmatrix/helpmatrix-lg-lt.svg';
import hmIconDk from '../assets/images/logofiles/helpmatrix/helpmatrix_lg_transparent.svg';

import jjLogoLt from '../assets/images/logofiles/jetjaw_lit_bg.svg';
import jjLogoDk from '../assets/images/logofiles/jetjaw_drk_bg.svg';

import skyLogoLt from '../assets/images/logofiles/skydera_lit_bg.svg';
import skyLogoDk from '../assets/images/logofiles/skydera_drk_bg.svg';
import skIconLt from '../assets/images/logofiles/skydera_icon_lit.svg';
import skIconBk from '../assets/images/logofiles/skydera_icon_bk.svg';

import skynasLogoLt from '../assets/images/logofiles/skynas_lit_bg.svg';
import skynasLogoDk from '../assets/images/logofiles/skynas_drk_bg.svg';
import rnLogoLt from '../assets/images/logofiles/redis_lit_bg.svg';
import rnLogoDk from '../assets/images/logofiles/redis_drk_bg.svg';
import pdLogoLt from '../assets/images/logofiles/pingdera_lit_bg.svg';
import pdLogoDk from '../assets/images/logofiles/pingdera_drk_bg.svg';

import saLogoLt from '../assets/images/logofiles/synthagents/synthagents_lit_bg.svg';
import saLogoDk from '../assets/images/logofiles/synthagents/synthagents_drk_bg.svg';
import saIconLt from '../assets/images/logofiles/synthagents/synthagents_icon_lt.png';
import saIconDk from '../assets/images/logofiles/synthagents/synthagents_icon_drk.svg';
import { faCircleXmark } from '@fortawesome/pro-duotone-svg-icons';

// Logos from https://www.vectorlogo.zone
import BrandChartleafLogo from '../assets/images/logofiles/chartLeaf_icon_drk.svg';
import BrandNexusDataLogo from '../assets/images/logofiles/nexusdata_icon.svg';
import BrandGithubMark from '../assets/brands/github/github-mark.svg';
import BrandDockerHub from '../assets/brands/docker/docker-icon.svg';
import BrandStripeIcon from '../assets/brands/stripe/stripe-icon.svg';
import BrandAWSIcon from '../assets/brands/aws/amazon_aws-icon.svg';
import BrandTwitterIcon from '../assets/brands/twitter/twitter-official.svg';

import BrandFacebookIcon from '../assets/brands/facebook/facebook-icon.svg';
import BrandFacebookMessangerIcon from '../assets/brands/facebook/messanger/messenger-icon.svg';
import BrandSlackIcon from '../assets/brands/slack/slack-icon.svg';
import BrandTwilioIcon from '../assets/brands/twilio/twilio-icon.svg';
import BrandWhatsAppIcon from '../assets/brands/whatsapp/whatsapp-icon.svg';

/* eslint-disable import/prefer-default-export */
// Active app options:
// chartleaf, callLeaf, nexusData, pingdera,
// skydera, skynas, synthagents, jetjaw, redisNow,
// ipNow, snapnow, cloudBluff, cloudStation, helpmatrix
// buwee, leafLayer, logdera

// FIXME @LC: The process.env needs to be changed to import.meta.env
// export const { APP_ACTIVE = 'skydera' } = process.env;

// export const { APP_ACTIVE = 'skydera' } = import.meta.env;

export const { DEV, PROD } = import.meta.env;

// These colors can be used in place of black.
const otherColorsForBlack = {
	oil_black: '#0C0C0C',
	obsidian: '#0B1215',
	dark_slate_grey: '#0D1717',
	midnight_black: '#101720',
	charcoal_gray: '#171717',
	deep_navy_blue: '#011222',
	rich_black: '#020D19'
};

// const env_data = import.meta.env;

export const darkTheme = {
	footer: 'mainThemeDark',
	main: 'dark01',
	navbar: 'mainThemeDark',
	toolbar: 'mainThemeDark'
};
export const lightTheme = {
	footer: 'mainThemeLight',
	main: 'light0',
	navbar: 'mainThemeLight',
	toolbar: 'mainThemeLight'
};

export const APP_ACTIVE = 'synthagents';

const APP_STRIP_PUBLISH_KEY = {
	helpmatrix:
		'pk_test_51OHOUPFYR8upCG9fnNq8ss2bgyNiKt2uE9hUuKsw5sIrnsBNk6kiXU7D1yQ00aT73EweM3gSjh1bI94hhgpyEt2S00RHDkBSfT',
	synthagents:
		'pk_test_51QDw3hB9HpSGR4rvgUATYreVEYHIIpxxJBPLUz1GdbrXQW3fhKyh5RuaMZI15fg1tQdY4mX1anKNLKdwyjYRr0pz00ZIttlJBd'
};

const APP_LOGO = {
	buwee: { lt: '', dk: '', brandName: 'Buwee' },
	callLeaf: { lt: '', dk: '', brandName: 'CallLeaf' },
	chartleaf: {
		lt: cfLogoLt,
		dk: cfLogoDk,
		icon: cfIcon,
		brandName: 'Chartleaf Analytics',
		brandColorMajor: '#084577',
		brandColorMinor: '#FF5D03'
	},
	cloudBluff: { lt: '', dk: '', brandName: 'CloudBuff' },
	cloudStation: { lt: '', dk: '', brandName: 'CloudStation' },
	helpmatrix: { lt: hmIconLt, dk: hmIconDk, brandName: 'HelpMatrix' },
	ipNow: { lt: '', dk: '', brandName: 'IpNow' },
	jetjaw: { lt: jjLogoLt, dk: jjLogoDk, brandName: 'JetJaw' },
	leafLayer: { lt: '', dk: '', brandName: 'LeafLayer' },
	logdera: { lt: '', dk: '', brandName: 'Logdera' },
	nexusData: {
		lt: ndLogoLt,
		dk: ndLogoDk,
		iconLt: ndIcon,
		iconBk: ndIcon,
		brandName: 'NexusData',
		brandColorMajor: '#48D21C',
		brandColorMinor: '#8E13B3'
	},
	pingdera: { lt: pdLogoLt, dk: pdLogoDk, brandName: 'Pingdera' },
	redisNow: { lt: rnLogoLt, dk: rnLogoDk, brandName: 'RedisNow' },
	snapnow: { lt: '', dk: '', brandName: 'SnapNow' },
	skydera: {
		lt: skyLogoLt,
		dk: skyLogoDk,
		iconBk: skIconBk,
		iconLt: skIconLt,
		brandName: 'Skydera',
		brandColorMajor: '#FFFFFF',
		brandColorMinor: '#15BDF9'
	},
	skynas: { lt: skynasLogoLt, dk: skynasLogoDk, brandName: 'SkyNas' },
	synthagents: {
		lt: saLogoLt,
		dk: saLogoDk,
		iconLt: saIconLt,
		iconBk: saIconDk,
		brandName: 'SynthAgents',
		brandColorMajor: '#006987',
		brandColorMinor: '#4D4D4D'
	}
};

const APP_CONFIG = {
	buwee: { appType: 'simple', billing: 'simple', registration: 'simple' },
	callLeaf: { appType: 'simple', billing: 'simple', registration: 'simple' },
	chartleaf: { billing: 'extended', registration: 'simple' },
	cloudBluff: { billing: 'extended', registration: 'simple' },
	cloudStation: {
		appType: 'simple',
		billing: 'simple',
		registration: 'simple'
	},
	helpmatrix: {
		appType: 'simple',
		billing: 'simple',
		registration: 'skydera',
		preferences: 'simple',
		settingsAdmin: 'extended',
		settingsUser: 'simple'
	},
	ipNow: { appType: 'simple', billing: 'simple', registration: 'simple' },
	jetjaw: { appType: 'simple', billing: 'simple', registration: 'simple' },
	leafLayer: { appType: 'simple', billing: 'simple', registration: 'simple' },
	logdera: { appType: 'simple', billing: 'simple', registration: 'simple' },
	nexusData: {
		// account: 'extended',           // extended, simple
		// appType: 'complex',            // complex, simple
		// billing: 'simple',             // extended, simple
		// login: 'simple',               // extended, simple
		// preferences: 'extended',       // extended, simple
		// profile: 'extended',           // extended, simple
		// project: 'extended',           // extended, simple
		// registration: 'default',       // default, simple
		// security: 'extended',          // extended, simple
		// userApp: 'extended',           // extended, simple
	},
	pingdera: {
		appType: 'complex',
		account: 'simple',
		billing: 'simple'
	},
	redisNow: { appType: 'simple', billing: 'simple' },
	snapnow: { appType: 'simple', billing: 'simple' },
	skydera: {
		account: 'simple', // extended, simple
		appType: 'complex', // complex, simple
		billing: 'extended', // extended, simple
		twoFa: 'enabled', // enabled, disabled
		login: 'simple', // extended, simple
		preferences: 'extended', // extended, simple
		profile: 'extended', // extended, simple
		project: 'extended', // extended, simple
		registration: 'skydera', // default, simple
		settingsAdmin: 'skydera', // extended, simple
		settingsUser: 'skydera', // extended, simple
		security: 'extended', // extended, simple
		userApp: 'extended' // extended, simple
	},
	skynas: { billing: 'extended' },
	synthagents: {
		account: 'simple',
		appType: 'simple',
		billing: 'simple',
		twoFa: 'enabled', // Not implemented
		preferences: 'simple',
		registration: 'simple',
		registrationType: 'paid', // paid, free ** only for simple registration. **
		settingsAdmin: 'extended',
		settingsUser: 'extended',
		integrationsList: [
			'dockerHub',
			'chartleaf',
			'aws',
			'stripe',
			'twitter',
			'facebook',
			'slack',
			'twilio',
			'whatsapp',
			'facebookMessanger',
			'leafLayer',
			'leafCi',
			'nexusData',
			'github'
		]
	}
};

const APP_URIS = {
	buwee: 'https://www.buwee.com/',
	callLeaf: 'https://www.callLeaf.com/',
	chartleaf: 'https://www.chartleaf.com/',
	cloudBluff: 'https://www.cloudbluff.com/',
	cloudStation: 'https://www.cloudstation.co/',
	helpmatrix: 'https://www.helpmatrix.com',
	ipNow: 'https://www.ipnow.io',
	jetjaw: 'https://www.jetjaw.com',
	leafLayer: 'https://www.leaflayer.com/',
	logdera: 'https://www.logdera.com/',
	nexusData: 'https://www.nexusdata.com',
	pingdera: 'https://www.pingdera.com',
	redisNow: 'https://www.redisnow.com',
	snapNow: 'https://www.snapNow.io',
	skydera: 'https://www.skydera.com',
	skynas: 'https://www.skynas.com',
	synthagents: 'https://www.synthagents.com'
};

const APP_DOMAINS = {
	buwee: 'buwee.com',
	callLeaf: 'callleaf.com',
	chartleaf: 'chartleaf.com',
	cloudBluff: 'cloudbluff.com',
	cloudStation: 'cloudstation.co',
	helpmatrix: 'helpmatrix.com',
	ipNow: 'ipnow.io',
	jetjaw: 'jetjaw.com',
	leafLayer: 'leaflayer.com',
	logdera: 'logdera.com',
	nexusData: 'nexusdata.com',
	pingdera: 'pingdera.com',
	redisNow: 'redisnow.com',
	snapNow: 'snapnow.io',
	skydera: 'skydera.com',
	skynas: 'skynas.com',
	synthagents: 'synthagents.com'
};

const githubPath = 'https://github.com/apps/skydera/installations/new?state=123';

// Logos from https://www.vectorlogo.zone
const APP_INTEGRATION_LIST = [
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandStripeIcon,
		IntegrationsTitle: 'Stripe',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandStripeIcon,
			IntegrationsTitle: 'Stripe',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'stripe'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandTwitterIcon,
		IntegrationsTitle: 'Twitter',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandTwitterIcon,
			IntegrationsTitle: 'Twitter',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'twitter'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandWhatsAppIcon,
		IntegrationsTitle: 'WhatsApp',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandWhatsAppIcon,
			IntegrationsTitle: 'WhatsApp',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'whatsapp'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandSlackIcon,
		IntegrationsTitle: 'Slack',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandSlackIcon,
			IntegrationsTitle: 'Slack',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'slack'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandTwilioIcon,
		IntegrationsTitle: 'Twilio',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandTwilioIcon,
			IntegrationsTitle: 'Twilio',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'twilio'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandFacebookIcon,
		IntegrationsTitle: 'Facebook',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandFacebookIcon,
			IntegrationsTitle: 'Facebook',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'facebook'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandFacebookMessangerIcon,
		IntegrationsTitle: 'FB Messenger',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandFacebookMessangerIcon,
			IntegrationsTitle: 'Facebook Messenger',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'facebookMessanger'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandAWSIcon,
		IntegrationsTitle: 'AWS',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandAWSIcon,
			IntegrationsTitle: 'AWS',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'aws'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandChartleafLogo,
		IntegrationsTitle: 'Chartleaf',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandChartleafLogo,
			IntegrationsTitle: 'Chartleaf',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'chartleaf'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandChartleafLogo,
		IntegrationsTitle: 'LeafLayer',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandChartleafLogo,
			IntegrationsTitle: 'LeafLayer',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'leafLayer'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandChartleafLogo,
		IntegrationsTitle: 'LeafCi',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandChartleafLogo,
			IntegrationsTitle: 'LeafCi',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'leafCi'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandNexusDataLogo,
		IntegrationsTitle: 'NexusData',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandNexusDataLogo,
			IntegrationsTitle: 'NexusData',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'nexusData'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandGithubMark,
		IntegrationsTitle: 'GitHub',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandGithubMark,
			IntegrationsTitle: 'Github',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   Connect your <a href="https://hub.docker.com" target="_blank" className="font-semibold">GitHub</a> account to Skydera, to enable full\n' +
				'                          CI/CD for your project.\n',
			activateUrl: githubPath
		},
		id: 'github'
	},
	{
		icon: faCircleXmark,
		iconSize: '2xl',
		IntegrationsBrandIcon: BrandDockerHub,
		IntegrationsTitle: 'DockerHub',
		IntegrationsSubTitle: 'Send event analytics',
		modelData: {
			icon: faCircleXmark,
			iconSize: '2xl',
			IntegrationsBrandIcon: BrandDockerHub,
			IntegrationsTitle: 'DockerHub',
			IntegrationsSubTitle: 'Send event analytics',
			IntegrationsOverviewText:
				'   The <a href="https://hub.docker.com" target="_blank" className="font-semibold">DockerHub</a> integration\n' +
				'          allows you to automatic build/release applications on events from the\n' +
				'          DockerHub system.'
		},
		id: 'dockerHub'
	}
];

const APP_DOMAINS_WS = {
	skydera: 'wss://api-ws.skydera.com',
	synthagents: 'wss://ws.synthagents.ai'
};

const APP_ROOTS = {
	buwee: 'https://app.buwee.com/',
	callLeaf: 'https://app.callleaf.com/',
	chartleaf: 'https://app.chartleaf.com/',
	cloudBluff: 'https://app.cloudbluff.com/',
	cloudStation: 'https://app.cloudstation.co/',
	helpmatrix: 'https://app.helpmatrix.com/',
	ipNow: 'https://app.ipnow.io/',
	jetjaw: 'https://app.jetjaw.com/',
	leafLayer: 'https://app.leaflayer.com/',
	logdera: 'https://app.logdera.com/',
	nexusData: 'https://app.nexusdata.com/',
	pingdera: 'https://app.pingdera.com/',
	redisNow: 'https://app.redisnow.com/',
	snapNow: 'https://app.snapnow.io/',
	skydera: 'https://app.skydera.com/',
	skynas: 'https://app.skynas.com/',
	synthagents: 'https://app.synthagents.ai/'
};

const APP_PLANS_SET = {
	buwee: {},
	callLeaf: {},
	chartleaf: {},
	cloudBluff: {},
	cloudStation: {},
	helpmatrix: {},
	ipNow: {},
	jetjaw: {},
	leafLayer: {},
	logdera: {},
	nexusData: {
		products: {
			monthly: {
				free: 'monthly_free_v1',
				basic: 'monthly_basic_v1',
				plus: 'monthly_plus_v1',
				business: 'monthly_business_v1'
			},
			quarterly: {
				free: 'quarterly_free_v1',
				basic: 'quarterly_basic_v1',
				plus: 'quarterly_plus_v1',
				business: 'quarterly_business_v1'
			},
			yearly: {
				free: 'yearly_free_v1',
				basic: 'yearly_basic_v1',
				plus: 'yearly_plus_v1',
				business: 'yearly_business_v1'
			}
		},
		descriptions: {
			free: 'Free plan - Up to 3 Data Sources, User and Dashboards. Data refresh rate daily.',
			basic: 'Basic plan - Up to 10 Data Sources, User and Dashboards. Data refresh rate hourly.',
			plus: 'Plus plan - Up to 10 Data Sources, User and Dashboards. Data refresh rate hourly.',
			business: 'Business plan - Up to 50 Data Sources, User and Dashboards. Data refresh rate hourly.'
		},
		pricing: {
			monthly: { free: 0, basic: 59, plus: 119, business: 299 },
			quarterly: { free: 0, basic: 54, plus: 109, business: 275 },
			yearly: { free: 0, basic: 49, plus: 99, business: 248 },
			quarterlySavings: { free: 0, basic: 57, plus: 114, business: 287 },
			yearlySavings: { free: 0, basic: 120, plus: 243, business: 610 }
		},
		services: {
			free: {
				dataSources: 3,
				user: 3,
				dashboards: 3,
				dataRefreshInterval: 86400,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: false,
				queryBuilder: false,
				dataCalculation: false,
				sqlDatabaseIntegration: false,
				companyBranding: false,
				additionalSecurity: false
			},
			basic: {
				dataSources: 10,
				user: 10,
				dashboards: 10,
				dataRefreshInterval: 3600,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: true,
				queryBuilder: false,
				dataCalculation: false,
				sqlDatabaseIntegration: false,
				companyBranding: false,
				additionalSecurity: false
			},
			plus: {
				dataSources: 10,
				user: 10,
				dashboards: 10,
				dataRefreshInterval: 3600,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: true,
				queryBuilder: true,
				dataCalculation: true,
				sqlDatabaseIntegration: true,
				companyBranding: false,
				additionalSecurity: false
			},
			business: {
				dataSources: 50,
				user: 20,
				dashboards: 50,
				dataRefreshInterval: 3600,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: true,
				queryBuilder: true,
				dataCalculation: true,
				sqlDatabaseIntegration: true,
				companyBranding: true,
				additionalSecurity: true
			}
		},
		agency: {
			monthly: { free: 0, basic: 59, plus: 119, business: 299 },
			quarterly: { free: 0, basic: 54, plus: 109, business: 275 },
			yearly: { free: 0, basic: 49, plus: 99, business: 248 },
			quarterlySavings: { free: 0, basic: 57, plus: 114, business: 287 },
			yearlySavings: { free: 0, basic: 120, plus: 243, business: 610 }
		},
		agencyServices: {
			free: {
				dataSources: 3,
				user: 3,
				dashboards: 3,
				dataRefreshInterval: 86400,
				clientAccounts: 10,
				clientDataSources: 3,
				clientUsers: 3,
				clientDashboards: 3,
				clientAccountPackBuy: false,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: false,
				queryBuilder: false,
				dataCalculation: false,
				sqlDatabaseIntegration: false,
				companyBranding: false,
				additionalSecurity: false,
				agencyClientManagement: true,
				agencyClientPerformance: true,
				agencyClientGoalTracking: true,
				agencyAdvancedUserAndClientAccess: false,
				agencyCompanyBranding: false,
				agencyAdditionalSecurity: false
			},
			basic: {
				dataSources: 10,
				user: 10,
				dashboards: 10,
				dataRefreshInterval: 3600,
				clientAccounts: 10,
				clientDataSources: 10,
				clientUsers: 10,
				clientDashboards: 10,
				clientAccountPackBuy: true,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: true,
				queryBuilder: false,
				dataCalculation: false,
				sqlDatabaseIntegration: false,
				companyBranding: false,
				additionalSecurity: false,
				agencyClientManagement: true,
				agencyClientPerformance: true,
				agencyClientGoalTracking: true,
				agencyAdvancedUserAndClientAccess: true,
				agencyCompanyBranding: true,
				agencyAdditionalSecurity: true
			},
			plus: {
				dataSources: 10,
				user: 10,
				dashboards: 10,
				dataRefreshInterval: 3600,
				clientAccounts: 10,
				clientDataSources: 10,
				clientUsers: 10,
				clientDashboards: 10,
				clientAccountPackBuy: true,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: true,
				queryBuilder: true,
				dataCalculation: true,
				sqlDatabaseIntegration: true,
				companyBranding: false,
				additionalSecurity: false,
				agencyClientManagement: true,
				agencyClientPerformance: true,
				agencyClientGoalTracking: true,
				agencyAdvancedUserAndClientAccess: true,
				agencyCompanyBranding: true,
				agencyAdditionalSecurity: true
			},
			business: {
				dataSources: 50,
				user: 20,
				dashboards: 50,
				dataRefreshInterval: 3600,
				clientAccounts: 10,
				clientDataSources: 10,
				clientUsers: 10,
				clientDashboards: 10,
				clientAccountPackBuy: true,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: true,
				queryBuilder: true,
				dataCalculation: true,
				sqlDatabaseIntegration: true,
				companyBranding: true,
				additionalSecurity: true,
				agencyClientManagement: true,
				agencyClientPerformance: true,
				agencyClientGoalTracking: true,
				agencyAdvancedUserAndClientAccess: true,
				agencyCompanyBranding: true,
				agencyAdditionalSecurity: true
			}
		}
	},
	pingdera: {},
	redisNow: {},
	snapNow: {},
	skydera: {
		productId: 'container_management',
		products: {
			monthly: {
				free: 'monthly_free_v1',
				productive: 'monthly_productive_v1',
				enterprise: 'monthly_enterprise_v1'
			},
			quarterly: {
				free: 'quarterly_free_v1',
				productive: 'quarterly_productive_v1',
				enterprise: 'quarterly_enterprise_v1'
			},
			yearly: {
				free: 'yearly_free_v1',
				productive: 'yearly_productive_v1',
				enterprise: 'yearly_enterprise_v1'
			}
		},
		descriptions: {
			free: 'Free plan - 1 User, 1 Namespaces, Up to 100 deploys, Community Support',
			productive: 'productive plan - Up to 200 Users, 10 Clusters, Unlimited Namespaces, Email & Chat Support',
			enterprise:
				'Enterprise plan - Unlimited Users, Unlimited Clusters, Unlimited Namespaces, 24/7 Priority Support'
		},
		pricing: {
			monthly: { free: 0, productive: 25, enterprise: 59 },
			quarterly: { free: 0, productive: 22, enterprise: 52 },
			yearly: { free: 0, productive: 20, enterprise: 47 },
			quarterlySavings: { free: 0, productive: 3, enterprise: 7 },
			yearlySavings: { free: 0, productive: 5, enterprise: 16 }
		},
		services: {
			free: {
				user: 1,
				clusters: 1,
				namespaces: 1,
				applications: 100,
				deployments: 100,
				community_support: true,
				email_chat_support: false,
				'24_7_priority_support': false
			},
			productive: {
				user: 10,
				clusters: 10,
				namespaces: 10,
				applications: -1,
				deployments: 100,
				community_support: true,
				email_chat_support: true,
				'24_7_priority_support': false
			},
			enterprise: {
				user: 25,
				clusters: -1,
				namespaces: -1,
				applications: -1,
				deployments: 100,
				community_support: true,
				email_chat_support: true,
				'24_7_priority_support': true
			}
		}
	},
	skynas: {},
	synthagents: {
		productId: 'chat_bot_service',
		products: {
			monthly: {
				free: 'monthly_free_v1',
				basic: 'monthly_basic_v1',
				plus: 'monthly_plus_v1',
				business: 'monthly_business_v1'
			},
			quarterly: {
				free: 'quarterly_free_v1',
				basic: 'quarterly_basic_v1',
				plus: 'quarterly_plus_v1',
				business: 'quarterly_business_v1'
			},
			yearly: {
				free: 'yearly_free_v1',
				basic: 'yearly_basic_v1',
				plus: 'yearly_plus_v1',
				business: 'yearly_business_v1'
			}
		},
		descriptions: {
			free: 'Free plan - Up to 3 Data Sources, User and Dashboards. Data refresh rate daily.',
			basic: 'Basic plan - Up to 10 Data Sources, User and Dashboards. Data refresh rate hourly.',
			plus: 'Plus plan - Up to 10 Data Sources, User and Dashboards. Data refresh rate hourly.',
			business: 'Business plan - Up to 50 Data Sources, User and Dashboards. Data refresh rate hourly.'
		},
		pricing: {
			monthly: { free: 0, basic: 59, plus: 119, business: 299 },
			quarterly: { free: 0, basic: 54, plus: 109, business: 275 },
			yearly: { free: 0, basic: 49, plus: 99, business: 248 },
			quarterlySavings: { free: 0, basic: 57, plus: 114, business: 287 },
			yearlySavings: { free: 0, basic: 120, plus: 243, business: 610 }
		},
		services: {
			free: {
				dataSources: 3,
				user: 3,
				dashboards: 3,
				dataRefreshInterval: 86400,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: false,
				queryBuilder: false,
				dataCalculation: false,
				sqlDatabaseIntegration: false,
				companyBranding: false,
				additionalSecurity: false
			},
			basic: {
				dataSources: 10,
				user: 10,
				dashboards: 10,
				dataRefreshInterval: 3600,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: true,
				queryBuilder: false,
				dataCalculation: false,
				sqlDatabaseIntegration: false,
				companyBranding: false,
				additionalSecurity: false
			},
			plus: {
				dataSources: 10,
				user: 10,
				dashboards: 10,
				dataRefreshInterval: 3600,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: true,
				queryBuilder: true,
				dataCalculation: true,
				sqlDatabaseIntegration: true,
				companyBranding: false,
				additionalSecurity: false
			},
			business: {
				dataSources: 50,
				user: 20,
				dashboards: 50,
				dataRefreshInterval: 3600,
				oneClickIntegration: true,
				templates: true,
				library: true,
				designer: true,
				goals: true,
				alerts: true,
				dashboardCarousel: true,
				emailSnapshot: true,
				mobileApps: true,
				tvDisplay: true,
				slack: true,
				annotation: true,
				pushDataAPI: true,
				queryBuilder: true,
				dataCalculation: true,
				sqlDatabaseIntegration: true,
				companyBranding: true,
				additionalSecurity: true
			}
		}
	}
};

export const APP_API_ENDPOINTS = {
	buwee: {
		root: 'https://xx11dei1e8.execute-api.us-east-2.amazonaws.com/prod/'
	},
	callLeaf: '',
	chartleaf: {
		root: 'https://8v5qbcm7mb.execute-api.us-east-2.amazonaws.com/prod/',
		tracker: 'https://tracker.chartleaf.co/v1/'
	},
	cloudBluff: '',
	cloudStation: '',
	helpmatrix: {
		root: 'https://api.helpmatrix.com/v1/'
	},
	ipNow: '',
	jetjaw: '',
	leafLayer: {
		root: 'https://77r1t0cxhe.execute-api.us-east-2.amazonaws.com/prod/'
	},
	logdera: {
		root: 'https://s8tbutgj18.execute-api.us-east-2.amazonaws.com/prod/'
	},
	nexusData: {
		root: 'https://zzhvc1o6ke.execute-api.us-east-2.amazonaws.com/dev/'
	},
	pingdera: {
		root: 'https://iyqsattg62.execute-api.us-east-2.amazonaws.com/dev/'
	},
	redisNow: '',
	snapNow: '',
	skydera: {
		// root: 'https://127.0.0.1/api/',
		// srv: 'https://127.0.0.1/api/',
		// git: 'https://127.0.0.1/api/',
		// root: 'https://skydera-dev-api.cloudstart.co/',
		// srv: 'https://skydera-dev-api.cloudstart.co/',
		// git: 'https://skydera-dev-github-api.cloudstart.co/',
		root: 'https://api.skydera.com/',
		srv: 'https://api.skydera.com/',
		git: 'https://api-git.skydera.com/'
	},
	synthagents: {
		root: 'https://api.synthagents.ai/v1/'
	},
	skynas: ''
};

export const APP_CONFIG_CURRENT = APP_CONFIG[APP_ACTIVE];
export const APP_LOGO_CURRENT = APP_LOGO[APP_ACTIVE];
export const APP_DOMAIN = APP_DOMAINS[APP_ACTIVE];
export const APP_URI = APP_URIS[APP_ACTIVE];
export const APP_ROOT = APP_ROOTS[APP_ACTIVE];
export const APP_PLANS = APP_PLANS_SET[APP_ACTIVE];

export const BASE_PHOTO_URL = 'https://s3.amazonaws.com/chartleaf/';

export const BASE_PHOTO_URL_DEV = 'https://s3-us-west-2.amazonaws.com/dev-v10-admin.cloudstart.co/';

export const PHOTO_URL = BASE_PHOTO_URL;
export const NO_AVATAR_IMAGE = `${BASE_PHOTO_URL}photos/static/no-avatar.jpg`;

export const API_ROOT = APP_API_ENDPOINTS[APP_ACTIVE].root;

export const API_SRV = APP_API_ENDPOINTS[APP_ACTIVE].srv;

export const API_GIT = APP_API_ENDPOINTS[APP_ACTIVE].git;

export const API_ROOT_AUTH = `${API_ROOT}auth/`;

export const API_TRACKER = `${APP_API_ENDPOINTS.chartleaf.tracker}track/ce`;

export const API_TRACKER_IDENTIFY = `${APP_API_ENDPOINTS.chartleaf.tracker}track/identify`;

export const WS_API_URL = APP_DOMAINS_WS[APP_ACTIVE];

// export const WS_API_URL =
//   'wss://oqij9zza6c.execute-api.us-west-2.amazonaws.com/prod';

// export const WS_API_URL =
//   'wss://k0jjcljmqf.execute-api.us-east-2.amazonaws.com/dev';

// export const API_TRACKER = `${APP_API_ENDPOINTS.chartleaf}tracker/ce`;

// export const API_TRACKER_IDENTIFY = `${APP_API_ENDPOINTS.chartleaf}tracker/identify`;

export const UPDATE_INTERVAL = 300000;

const URL_MAPS = {
	main: {
		// Call Leaf

		CL_CAPABILITY_TOKEN_GET: `${API_ROOT}call/token`,
		CL_CALL_MAKE: `${API_ROOT}call/voice`,
		CL_APP_FETCH_CONTACT: `${API_ROOT}call/app/contact/info`,

		CL_AGENT_CAPABILITY_TOKEN_GET: `${API_ROOT}task/agents`,
		CL_AGENT_CONFERENCE_CALLBACK: `${API_ROOT}task/conference/callback`,
		CL_AGENT_RECORDING_CALLBACK: `${API_ROOT}task/recording/callback`,

		AUTH_URL: `${API_ROOT}auth/`,

		AUTH_PASSWORD_CHANGE: `${API_ROOT}auth/password/change`,
		AUTH_PASSWORD_LOST: `${API_ROOT}auth/password/lost`,
		AUTH_PASSWORD_RESET: `${API_ROOT}auth/password/reset`,

		AUTH_USER_LOCK_LIST: `${API_ROOT}auth/user/lock/list`,
		AUTH_USER_LOGIN: `${API_ROOT}auth/user/login`,
		AUTH_USER_LOGIN_TOKEN: `${API_ROOT}auth/user/token/login`,
		AUTH_USER_LOGIN_LOCK: `${API_ROOT}auth/user/lock/login`,

		AUTH_USER_INVITE_CHECK: `${API_ROOT}auth/user/check/invite`,
		AUTH_USER_INVITE_JOIN_PROJECT: `${API_ROOT}auth/user/invite/join`,

		AUTH_USER_SIGNUP: `${API_ROOT}auth/user/signup`,

		// FIXME @LC: This maybe should be changed to verify different things
		AUTH_USER_VERIFY_REGISTRATION: `${API_ROOT}auth/user/verify`,

		AUTH_USER_TWO_FA: `${API_ROOT}auth/user/two/fa`,
		AUTH_USER_TWO_STATUS: `${API_ROOT}auth/user/two/status`,
		AUTH_USER_TWO_RECOVER_CODE: `${API_ROOT}auth/user/two/recover/code`,
		AUTH_USER_TWO_VERIFY_CODE: `${API_ROOT}auth/user/two/verify`,
		// Could be update User.
		AUTH_USER_TWO_SETUP_COMPLETE: `${API_ROOT}auth/user/two/setup/complete`,

		ADMIN_SUPPORT_CASE_LIST: `${API_ROOT}admin/support/case/list`,
		ADMIN_SUPPORT_CASE_GET: `${API_ROOT}admin/support/case`,
		ADMIN_SUPPORT_CASE_CREATE: `${API_ROOT}admin/support/case/create`,
		ADMIN_SUPPORT_CASE_UPDATE: `${API_ROOT}admin/support/case/update`,

		ACCESS_TYPE: `${API_ROOT}access/type`,

		OVERVIEW_ACCOUNT: `${API_ROOT}overview/account`,

		ACCOUNT_IMAGE_UPLOAD: `${API_ROOT}account/image/upload/image`,
		ACCOUNT_IMAGE_UPLOAD_NO_AUTH: `${API_ROOT}account/unsecured/image/upload`,

		ACCOUNT_OWNER: `${API_ROOT}account/owner`,
		ACCOUNT_CLOSE: `${API_ROOT}account/close`,
		ACCOUNT_SETTINGS: `${API_ROOT}account/settings`,
		ACCOUNT_TOKEN: `${API_ROOT}account/token`,
		COMPANY_INFO: `${API_ROOT}account/company/info`,
		COMPANY_UPDATE: `${API_ROOT}account/company/update`,
		COMPANY_SUBSCRIPTION_STATUS: `${API_ROOT}account/company/subscription/status`,

		PRICING_LIST: `${API_ROOT}billing/pricing`,

		BILLING_SUB_CANCEL: `${API_ROOT}billing/subscriptions/cancel`,
		BILLING_SUB_CREATE: `${API_ROOT}billing/subscriptions/create`,
		BILLING_SUB_DELETE: `${API_ROOT}billing/subscription/del`,
		BILLING_SUB_EDIT: `${API_ROOT}billing/subscriptions/edit`,
		BILLING_SUB_GET: `${API_ROOT}billing/subscriptions`,
		BILLING_SUB_INFO: `${API_ROOT}billing/subscription/info`,
		BILLING_SUB_LIST: `${API_ROOT}billing/subscriptions`,
		BILLING_SUB_SETUP_INTENT: `${API_ROOT}billing/subscriptions/setup/intent`,

		BILLING_INV_GET: `${API_ROOT}billing/invoice`,
		BILLING_INV_LIST: `${API_ROOT}billing/invoice`,
		BILLING_INV_PRINT: `${API_ROOT}billing/invoice/print`,
		BILLING_INV_UPCOMING: `${API_ROOT}billing/invoice/upcoming`,

		BILLING_PLAN_GET: `${API_ROOT}billing/plans`,
		BILLING_PLANS_ALL: `${API_ROOT}billing/plans/all`,

		BILLING_CC_ADD: `${API_ROOT}billing/cc/add`,
		BILLING_CC_DELETE: `${API_ROOT}billing/cc/del`,
		BILLING_CC_DEFAULT_SET: `${API_ROOT}billing/cc/default/set`,
		BILLING_CC_UPDATE: `${API_ROOT}billing/cc/update`,
		BILLING_CC_GET: `${API_ROOT}billing/cc`,
		BILLING_CC_LIST: `${API_ROOT}billing/cc/list`,

		DATA_ASSET_GET: `${API_ROOT}data/asset/get`,
		DATA_ASSET_LIST: `${API_ROOT}data/asset/list`,
		DATA_ASSET_STATUS: `${API_ROOT}data/asset/status`,
		DATA_ASSET_UPDATE: `${API_ROOT}data/asset/update`,
		DATA_ASSET_DELETE: `${API_ROOT}data/asset/delete`,

		METRICS_GET: `${API_ROOT}metrics`,
		GRAPHS_GET: `${API_ROOT}graphs`,

		PROJECT_ACTIVE: `${API_ROOT}account/project/active`,
		PROJECT_API_KEY_CREATE: `${API_ROOT}account/project/apikey/create`,
		PROJECT_API_KEY_GET: `${API_ROOT}account/project/apikey/get`,
		PROJECT_API_KEY_LIST: `${API_ROOT}account/project/apikey/list`,
		PROJECT_API_KEY_UPDATE: `${API_ROOT}account/project/apikey/update`,
		PROJECT_API_KEY_DELETE: `${API_ROOT}account/project/apikey/delete`,
		PROJECT_INFO: `${API_ROOT}account/project/info`,
		PROJECT_CREATE: `${API_ROOT}account/project/create`,
		PROJECT_SETUP_INIT: `${API_ROOT}account/project/setup/init`,
		PROJECT_LIST: `${API_ROOT}account/project/list`,
		PROJECT_STATUS: `${API_ROOT}account/project/status`,
		PROJECT_UPDATE: `${API_ROOT}account/project/update`,

		SUPPORT_CASE_GET: `${API_ROOT}support/case`,
		SUPPORT_CASE_CREATE: `${API_ROOT}support/case/create`,
		SUPPORT_CASE_LIST: `${API_ROOT}support/case/list`,
		SUPPORT_CASE_UPDATE: `${API_ROOT}support/case/update`,
		SUPPORT_FEEDBACK_CREATE: `${API_ROOT}support/feedback/create`,

		USER_CREATE: `${API_ROOT}account/user/create`,
		USER_DELETE: `${API_ROOT}account/user/del`,
		USER_DETAIL: `${API_ROOT}account/user/detail`,
		USER_ENABLED: `${API_ROOT}account/user/enabled`,
		USER_INVITE: `${API_ROOT}account/user/invite`,
		USER_INVITED: `${API_ROOT}account/user/invited`,
		USER_LIST: `${API_ROOT}account/user/list`,
		USER_LIST_BASE: `${API_ROOT}account/user/list/base`,
		USER_LOGIN: `${API_ROOT}account/user/login`,

		USER_NAME_CHECK: `${API_ROOT}account/user/check/username`,

		USER_ABILITY: `${API_ROOT}account/user/ability`,
		USER_PREFERENCES_INFO: `${API_ROOT}account/user/pref`,
		USER_PROFILE: `${API_ROOT}account/user/profile`,
		USER_PROFILE_PICTURE: `${API_ROOT}account/image/upload/user/profile`,
		USER_SETTINGS: `${API_ROOT}account/user/settings`,
		USER_UPDATE: `${API_ROOT}account/user/update`,

		USER_SEARCH: `${API_ROOT}account/user/search`,
		USER_STATUS: `${API_ROOT}account/user/status`,
		USER_TWITTER: `${API_ROOT}account/user/twitter`,

		TRACKING_PIXEL: `${API_ROOT}tracking/pixel`
	},
	buwee: {
		SCRUM_BOARD: `${API_ROOT}scrum/board/`,
		SCRUM_BOARD_CREATE: `${API_ROOT}scrum/board/create/`,
		SCRUM_BOARD_DELETE: `${API_ROOT}scrum/board/delete/`,
		SCRUM_BOARD_RENAME: `${API_ROOT}scrum/board/rename/`,
		SCRUM_BOARD_LIST: `${API_ROOT}scrum/board/list/`,
		SCRUM_BOARD_SETTINGS_UPDATE: `${API_ROOT}scrum/board/settings/update/`,
		SCRUM_LIST_ORDER: `${API_ROOT}scrum/list/order/`,
		SCRUM_LIST_CREATE: `${API_ROOT}scrum/list/create/`,
		SCRUM_LIST_RENAME: `${API_ROOT}scrum/list/rename/`,
		SCRUM_LIST_REMOVE: `${API_ROOT}scrum/list/remove/`,
		SCRUM_CARD_ORDER: `${API_ROOT}scrum/card/order/`,
		SCRUM_CARD_CREATE: `${API_ROOT}scrum/card/create/`,
		SCRUM_CARD_UPDATE: `${API_ROOT}scrum/card/update/`,
		SCRUM_CARD_REMOVE: `${API_ROOT}scrum/card/remove/`,

		TODOS_LIST: `${API_ROOT}todos/list/`,
		TODOS_FILTERS_LIST: `${API_ROOT}todos/filters/list/`,
		TODOS_FOLDERS_LIST: `${API_ROOT}todos/folders/list/`,
		TODOS_LABELS_LIST: `${API_ROOT}todos/labels/list/`,

		TODOS_UPDATE: `${API_ROOT}todos/update/`,
		TODOS_CREATE: `${API_ROOT}todos/create/`,
		TODOS_DELETE: `${API_ROOT}todos/delete/`
	},
	helpmatrix: {
		BLOG_ASSET_GET: `${API_ROOT}blog/asset/get`
	},
	nexusData: {
		DASHBOARD_GET: `${API_ROOT}dashboard/`,
		DASHBOARD_CREATE: `${API_ROOT}dashboard/create/`,
		DASHBOARD_LIST: `${API_ROOT}dashboard/list/`,
		DASHBOARD_EDIT: `${API_ROOT}dashboard/edit/`,
		DASHBOARD_RENAME: `${API_ROOT}dashboard/rename/`,
		DASHBOARD_DELETE: `${API_ROOT}dashboard/delete/`,
		DASHBOARD_SETTINGS: `${API_ROOT}dashboard/settings/`,
		DASHBOARD_COPY: `${API_ROOT}dashboard/copy/`,
		DASHBOARD_SNAPSHOT: `${API_ROOT}dashboard/snapshot/`,

		WIDGET_LIST: `${API_ROOT}dashboard/widget/list/`,
		WIDGET_IDS: `${API_ROOT}dashboard/widget/ids/`,

		SCRUM_BOARD: `${API_ROOT}scrumboard-app/board/`,
		SCRUM_BOARD_CREATE: `${API_ROOT}scrumboard-app/board/create/`,
		SCRUM_BOARD_DELETE: `${API_ROOT}scrumboard-app/board/delete/`,
		SCRUM_BOARD_RENAME: `${API_ROOT}scrumboard-app/board/rename/`,
		SCRUM_BOARD_LIST: `${API_ROOT}scrumboard-app/board/list/`,
		SCRUM_BOARD_SETTINGS_UPDATE: `${API_ROOT}scrumboard-app/board/settings/update/`,
		SCRUM_LIST_ORDER: `${API_ROOT}scrumboard-app/list/order/`,
		SCRUM_LIST_CREATE: `${API_ROOT}scrumboard-app/list/create/`,
		SCRUM_LIST_RENAME: `${API_ROOT}scrumboard-app/list/rename/`,
		SCRUM_LIST_REMOVE: `${API_ROOT}scrumboard-app/list/remove/`,
		SCRUM_CARD_ORDER: `${API_ROOT}scrumboard-app/card/order/`,
		SCRUM_CARD_CREATE: `${API_ROOT}scrumboard-app/card/create/`,
		SCRUM_CARD_UPDATE: `${API_ROOT}scrumboard-app/card/update/`,
		SCRUM_CARD_REMOVE: `${API_ROOT}scrumboard-app/card/remove/`,

		TODOS_LIST: `${API_ROOT}todos/list/`,
		TODOS_FILTERS_LIST: `${API_ROOT}todos/filters/list/`,
		TODOS_FOLDERS_LIST: `${API_ROOT}todos/folders/list/`,
		TODOS_LABELS_LIST: `${API_ROOT}todos/labels/list/`,

		TODOS_UPDATE: `${API_ROOT}todos/update/`,
		TODOS_CREATE: `${API_ROOT}todos/create/`,
		TODOS_DELETE: `${API_ROOT}todos/delete/`,

		INTEGRATION_CREATE: `${API_ROOT}integration/create/`,
		INTEGRATION_TWITTER_AUTH_GET_URL: `${API_ROOT}integration/twitter/auth/url/`
	},
	chartleaf: {
		DASHBOARD_GET: `${API_ROOT}dashboard/`,
		DASHBOARD_CREATE: `${API_ROOT}dashboard/create/`,
		DASHBOARD_LIST: `${API_ROOT}dashboard/list/`,
		DASHBOARD_EDIT: `${API_ROOT}dashboard/edit/`,
		DASHBOARD_RENAME: `${API_ROOT}dashboard/rename/`,
		DASHBOARD_DELETE: `${API_ROOT}dashboard/delete/`,
		DASHBOARD_SETTINGS: `${API_ROOT}dashboard/settings/`,
		DASHBOARD_COPY: `${API_ROOT}dashboard/copy/`,
		DASHBOARD_SNAPSHOT: `${API_ROOT}dashboard/snapshot/`,

		WIDGET_LIST: `${API_ROOT}dashboard/widget/list/`,
		WIDGET_IDS: `${API_ROOT}dashboard/widget/ids/`,

		CAMPAIGN_CLICKS: `${API_ROOT}campaign/clicks/`,
		CAMPAIGN_LIST: `${API_ROOT}campaign/list/`,

		CHART_KPI: `${API_ROOT}chart/kpi/`,

		COHORT: `${API_ROOT}cohort/`,
		COHORT_CREATE: `${API_ROOT}cohort/create/`,
		COHORT_DELETE: `${API_ROOT}cohort/del/`,
		COHORT_EDIT: `${API_ROOT}cohort/edit/`,
		COHORT_LIST: `${API_ROOT}cohort/list/`,

		GEO_OVERVIEW: `${API_ROOT}geo/user/map/`,

		CONCURRENT_USERS: `${API_ROOT}concurrent/users/`,

		COMPANY_INFO: `${API_ROOT}account/company/info/`,

		EVENT_CHART: `${API_ROOT}event/chart/`,
		EVENT_INDEX: `${API_ROOT}event/index/`,
		EVENT_PARAM_INDEX: `${API_ROOT}event/param/`,
		EVENT_LIST: `${API_ROOT}event/list/`,
		EVENT_SALES: `${API_ROOT}event/sales/`,

		FINANCIAL: `${API_ROOT}financial/`,
		FINANCIAL_DETAIL: `${API_ROOT}financial/detail/`,
		FINANCIAL_KPI: `${API_ROOT}financial/kpi/`,
		FINANCIAL_MTD: `${API_ROOT}financial/mtd/`,
		FINANCIAL_TABLE: `${API_ROOT}financial/table/`,
		FINANCIAL_EVENTS_CHART: `${API_ROOT}financial/events/chart/`,

		FUNNEL: `${API_ROOT}funnel/`,
		FUNNEL_ADD_STEP: `${API_ROOT}funnel/add/step/`,
		FUNNEL_ANALYTICS: `${API_ROOT}funnel/analytics/`,
		FUNNEL_CHART: `${API_ROOT}funnel/chart/`,
		FUNNEL_CREATE: `${API_ROOT}funnel/create/`,
		FUNNEL_DELETE: `${API_ROOT}funnel/delete/`,
		FUNNEL_EDIT: `${API_ROOT}funnel/edit/`,
		FUNNEL_GET: `${API_ROOT}funnel/get/`,
		FUNNEL_LIST: `${API_ROOT}funnel/list/`,
		FUNNEL_STEPS: `${API_ROOT}funnel/steps/`,

		INVITE_LIST: `${API_ROOT}invite/list/`,

		PAGE_LIST: `${API_ROOT}page/list/`,
		PAGE_REAL_TIME: `${API_ROOT}page/rt/`,
		PAGE_SCROLL: `${API_ROOT}page/scroll/`,
		PAGE_TO_LIST: `${API_ROOT}page/to/list/`,
		PAGE_FROM_LIST: `${API_ROOT}page/from/list/`,
		RSM_JOB_STATUS: `${API_ROOT}rsm/job/status/`,
		RSM_GET_DATA: `${API_ROOT}rsm/get/`,
		RSM_ANALYTIC: `${API_ROOT}rsm/analytic/`,
		RSM_FINALIZE_ANALYTIC: `${API_ROOT}rsm/finalize/analytic/`,
		RSM_GET_RESULT_SET: `${API_ROOT}rsm/result/set/`,

		SEGMENT: `${API_ROOT}segment/`,
		SEGMENT_GET: `${API_ROOT}segment/get/`,
		SEGMENT_LIST: `${API_ROOT}segment/list/`,
		SEGMENT_EDIT: `${API_ROOT}segment/edit/`,

		VISITORS_ACTIVE_USER: `${API_ROOT}visitors/active/user/`,
		VISITORS_CONCURRENT: `${API_ROOT}visitors/concurrent/`,
		VISITORS_ENGAGEMENT: `${API_ROOT}visitors/engagement/`,
		VISITORS_GEO: `${API_ROOT}visitors/geo/`,
		VISITORS_GRAPH: `${API_ROOT}visitors/graph/`,
		VISITORS_HISTOGRAM: `${API_ROOT}visitors/now/histogram/`,
		VISITORS_TABLE: `${API_ROOT}visitors/table/`,
		VISITORS_TRAFFIC_TYPE: `${API_ROOT}visitors/traffic/type/`,
		VISITORS_VISIT: `${API_ROOT}visitors/visit/`,

		VISITORS_SEARCH: `${API_ROOT}visitors/search/`,

		THIRTY_DAY_AVE: `${API_ROOT}dy30/ave/`,
		THIRTY_DAY_MAX: `${API_ROOT}dy30/max/`,
		THIRTY_DAY_RETURNING: `${API_ROOT}dy30/new/returning/`,

		TRAFFIC_BASE: `${API_ROOT}traffic/base/`,
		TRAFFIC_SOURCE: `${API_ROOT}traffic/source/`,
		TRAFFIC_TYPE: `${API_ROOT}traffic/type/`,

		TOP_EVENTS: `${API_ROOT}items/top/`
	},
	skydera: {
		ALB_CREATE: `${API_SRV}srv/alb/create`,
		ALB_LIST_GET: `${API_SRV}srv/alb/list`,
		ALB_STATUS_GET: `${API_SRV}srv/alb/status`,
		ACCESS_LIMITS: `${API_SRV}access/limits`,
		ASSET_ACTIVITY_GET: `${API_SRV}data/asset/activity/get`,
		ASSET_GET: `${API_SRV}data/asset/get`,
		ASSET_LIST: `${API_SRV}data/asset/list`,
		ASSET_STATUS: `${API_SRV}data/asset/status`,
		ASSET_UPDATE: `${API_SRV}data/asset/update`,
		APP_BASE: `${API_SRV}srv/app/`,
		APP_CREATE: `${API_SRV}srv/app/create`,
		APP_DEF_CREATE: `${API_SRV}srv/app/def/create`,
		APP_DEF_LIST_GET: `${API_SRV}srv/def/list`,
		APP_DEF_SPEC_LIST_GET: `${API_SRV}data/app/list`,
		APP_DEPLOY: `${API_SRV}srv/app/deploy/app`,
		// APP_CLONE_CREATE: `${API_ROOT}apps/create/clone`,
		APP_LIST_GET: `${API_SRV}srv/app/spec/list`,
		ATTR_LIST_GET: `${API_SRV}data/attr/list`,
		CERT_DETAIL: `${API_SRV}data/cert/detail`,
		CERT_LIST_GET: `${API_SRV}data/cert/list`,
		CERT_SOURCE_LIST_GET: `${API_SRV}srv/cert/source`,
		CLUSTER_CREATE: `${API_SRV}srv/cluster/create`,
		CLUSTER_LIST_GET: `${API_SRV}srv/cluster/list`,
		DOMAIN_DETAIL_GET: `${API_SRV}data/domain/detail`,
		DOMAIN_LIST_GET: `${API_SRV}data/domain/list`,

		CHANNEL_IMAGE_BASE: `${API_SRV}channel/image/`,
		CHANNEL_IMAGE_LIST_GET: `${API_SRV}channel/image/list`,
		CHANNEL_IMAGE_DETAIL_GET: `${API_SRV}channel/image/detail`,
		CHANNEL_IMAGE_PROMOTE: `${API_SRV}channel/image/promote`,
		CHANNEL_IMAGE_TARGET_ACTIVE: `${API_SRV}channel/image/target/active`,
		CHANNEL_IMAGE_SCAN_FINDINGS: `${API_SRV}channel/image/scan/findings`,
		CHANNEL_IMAGE_SCAN_START: `${API_SRV}channel/image/scan/start`,
		CHANNEL_IMAGE_TAG_MANIFEST: `${API_SRV}channel/image/tag/manifest`,
		CHANNEL_IMAGE_TAG_LIST: `${API_SRV}channel/image/tag/list`,
		CHANNEL_IMAGE_TAG_DELETE: `${API_SRV}channel/image/tag/delete`,
		CHANNEL_IMAGE_BUILD_DETAIL: `${API_SRV}channel/image/build/detail`,
		CHANNEL_IMAGE_BUILD_TRIGGER: `${API_SRV}channel/image/build/trigger`,
		CHANNEL_IMAGE_BUILD_GRAPH: `${API_SRV}channel/image/build/graph`,
		CHANNEL_IMAGE_BUILD_LIST: `${API_SRV}channel/image/build/list`,

		GIT_GITHUB_REPO_LIST: `${API_GIT}github/repo/list`,
		GIT_GITHUB_REPO_BRANCH_LIST: `${API_GIT}github/repo/branch/list`,

		INITIAL_ENV_SETUP: `${API_SRV}infra/initial/env/deploy`,
		INTEGRATION_CREATE: `${API_GIT}integration/create/`,

		SRV_DATA_BASE: `${API_SRV}data/`,
		// IMAGE_DETAIL_GET: `${API_ROOT}image/detail`,
		FILE_SYSTEM_STATUS_GET: `${API_SRV}data/fs/status`,
		FILE_SYSTEM_BIND_LIST_GET: `${API_SRV}data/fs/bind`,
		FILE_SYSTEM_LIST_GET: `${API_SRV}data/fs/list`,
		FILE_SYSTEM_CREATE: `${API_SRV}srv/fs/create`,

		DATABASE_DETAIL_GET: `${API_SRV}data/database/detail`,

		HELM_REPO_LIST: `${API_ROOT}helm/repo/list`,
		HELM_REPO_CREATE: `${API_ROOT}helm/repo/create`,
		HELM_REPO_CHART_LIST: `${API_ROOT}helm/repo/chart/list`,
		HELM_CHART_DETAIL: `${API_ROOT}helm/chart/detail`,
		HELM_CHART_LIST: `${API_ROOT}helm/chart/list`,
		HELM_CHART_PULL: `${API_ROOT}helm/chart/pull`,
		HELM_CHART_VERSION: `${API_ROOT}helm/chart/version`,
		INFRA_BASE: `${API_SRV}infra/`,
		LIMITS_BASE: `${API_ROOT}limits/`,
		LIMITS_DEPARTMENT_LIMITS_GET: `${API_ROOT}limits/department/limits`,
		LIMITS_DEPARTMENT_LIST_GET: `${API_ROOT}limits/departments/list`,
		LIMITS_USER_LIMITS_GET: `${API_ROOT}limits/user/limits`,
		LIMITS_USER_LIST_GET: `${API_ROOT}limits/user/list`,
		LOG_BASE: `${API_ROOT}log/`,
		// NAMESPACE_OVERVIEW_GET: `${API_ROOT}namespace/overview`,
		NAMESPACE_LIST_GET: `${API_SRV}data/namespace/list`,
		NAMESPACE_COST_GET: `${API_SRV}data/namespace/cost`,
		NAMESPACE_NAME_LIST_GET: `${API_SRV}data/namespace/name/list`,
		NAMESPACE_STATUS_GET: `${API_SRV}data/namespace/status`,
		NETWORK_CREATE: `${API_SRV}srv/network/create`,
		METRICS_GET: `${API_ROOT}metrics`,
		GRAPHS_GET: `${API_ROOT}graphs`,
		SERVICE_CREATE: `${API_ROOT}apps/bootstrap/app`,
		SERVICE_CATALOG_BASE: `${API_SRV}srv/catalog/`,
		SERVICE_APP_BASE: `${API_SRV}srv/app/`,
		SERVICE_APP_DATA_BASE: `${API_SRV}data/app/`,
		TASK_DEFINITION_REGISTER: `${API_SRV}tks/register`,
		TASK_LAUNCH: `${API_SRV}tks/launch`,
		TASK_DEFINITION_LIST: `${API_SRV}tks/list`,
		TASK_DEFINITION_DETAIL: `${API_SRV}tks/definition`,
		TASK_RUN_LIST: `${API_SRV}tks/run/list`,
		TASK_RUN_DETAIL: `${API_SRV}tks/run/detail`,
		TASK_REGISTER: `${API_SRV}apps/111/create/register/task`,
		TASK_STATUS: `${API_SRV}apps/111/status/task`,
		TASK_SCHEDULE_SET: `${API_SRV}tks/schedule/set`,
		TASK_SCHEDULE_LIST: `${API_SRV}tks/schedule/list`,
		VPC_CREATE: `${API_SRV}srv/vpc/create`,
		VPC_LIST_GET: `${API_SRV}data/vpc/list`,
		VPC_STATUS_GET: `${API_SRV}data/vpc/status`,
		WELCOME_BASE: `${API_SRV}data/wlc/`,
		WELCOME_APP_LIST: `${API_SRV}data/wlc/app/list`,
		WELCOME_ACTIVITY_LIST: `${API_SRV}data/activity/list`
	},
	synthagents: {
		BLOG_ASSET_GET: `${API_ROOT}blog/asset/get`,
		ASSET_UPDATE: `${API_ROOT}data/asset/update`,
		DATA_ASSET_GET: `${API_ROOT}data/asset/get`,
		DATA_ASSET_LIST: `${API_ROOT}data/asset/list`,
		DATA_ASSET_STATUS: `${API_ROOT}data/asset/status`,
		DATA_ASSET_UPDATE: `${API_ROOT}data/asset/update`
	}
};
const URL_MAP_CURRENT = URL_MAPS[APP_ACTIVE];

// URL Map based on which app is currently active
export const URLMap = { ...URL_MAPS.main, ...URL_MAP_CURRENT };

const URL_PATHS = {
	main: {
		INTEGRATIONS: '/integrations/',

		BASE_PATH: '/',
		PLATFORM_PAGE_PATH: '/platform/',
		FORGOT_PASSWORD_PATH: '/pages/forgot-password/',
		PRICING_PAGE_PATH: '/pricing/',
		CONTACT_PAGE_PATH: '/contact/',
		DOCUMENTATION_PAGE_PATH: '/docs/',
		TERMS_PAGE_PATH: '/terms/',
		AUP_PAGE_PATH: '/aup/',
		ABOUT_PAGE_PATH: '/about/',
		PRIVACY_PAGE_PATH: '/privacy/',
		SITE_MAP_PAGE_PATH: '/sitemap/',
		LOGIN_PATH: '/login/',
		LOGOUT_PATH: '/logout/',
		BLOG_PATH: '/blog/',

		BILLING_PATH: '/billing/',
		DASHBOARD: '/dashboard/',
		REGISTRATION_PATH: '/register/',
		REGISTRATION_INVITE_PATH: '/register/invite/',

		SETTINGS_ACCOUNT_PATH: '/settings/account/',
		SETTINGS_ADMIN_PATH: '/settings/admin/',
		SETTINGS_USER_PATH: '/settings/user/',
		SETTINGS_PREFERENCES_PATH: '/settings/preferences/',
		SETTINGS_PROJECTS_PATH: '/settings/projects/',

		PREFERENCES_PATH: '/account/preferences/',
		PROFILE_PATH: '/account/profile/',
		PROJECTS_PATH: '/account/projects/',

		ADMIN_SETTINGS_ACCOUNT_PATH: '/admin/settings/account/',
		ADMIN_SETTINGS_USER_PATH: '/admin/settings/user/',
		ADMIN_SECURITY_ACCOUNT_PATH: '/admin/security/account/',
		ADMIN_SECURITY_USER_PATH: '/admin/security/user/',

		ADMIN_PATH: '/admin/',
		ADMIN_HELP_MATRIX_PATH: '/admin/help/matrix/',
		ADMIN_HELP_MATRIX_CASE_OVERVIEW_PATH: '/admin/help/matrix/overview/',
		ADMIN_HELP_MATRIX_CASE_LIST_PATH: '/admin/help/matrix/case/list/',
		ADMIN_HELP_MATRIX_CASE_GET_PATH: '/admin/help/matrix/case/',

		SUPPORT_PATH: '/support/',
		SUPPORT_CREATE_PATH: '/support/case/create/',
		SUPPORT_LIST_PATH: '/support/case/list/',
		SUPPORT_CASE_GET_PATH: '/support/case/detail/',

		ON_BOARDING: '/onboarding/',

		ONE_PAGE_PATH: '/one/page/',

		EXTERNAL_REGISTRATION_PATH: `${APP_ROOT}register/`,
		EXTERNAL_LOGIN_PATH: `${APP_ROOT}login/`,
		EXTERNAL_GITHUB_PATH: 'https://github.com/chartleaf/',
		EXTERNAL_TWITTER_PATH: 'https://twitter.com/chartleaf/',
		EXTERNAL_FACEBOOK_PATH: 'https://www.facebook.com/chartleaf/',

		PAGES: '/pages/',
		PAGES_LOCK_SCREEN: '/pages/locked/',
		PAGES_AUTH_LOCK_SCREEN: '/pages/auth/lock/',
		PAGES_VERIFY_EMAIL: '/pages/confirmation-required/',
		PAGES_VERIFY_INCOMING_EMAIL: '/pages/verify/incoming/',
		PAGES_VERIFY_PASS_CODE: '/pages/verify/pass/code/',
		PAGES_PROJECT_SETUP: '/pages/project/setup/',
		PAGES_PROJECT_NOT_SETUP: '/pages/project/not-complete/',
		PAGES_TOKEN_INSTALL: '/pages/token/install/',
		PAGES_STEPPER_EXAMPLE: '/pages/stepper/example/',

		USER_PATH_PATH: '/user/',
		USER_ACTIVE_PATH: '/user/active/',
		USER_APP_PATH: '/user/app/',
		USER_MANAGER_PATH: '/user/manager/'
	},
	buwee: {
		SCRUM_BOARDS: '/scrum/boards/',
		TODOS: '/todos/'
	},
	chartleaf: {
		DASHBOARD_DISPLAY: '/dashboard/display/',
		DASHBOARD_DISPLAY_PUBLIC: '/dashboard/public/',
		DASHBOARD_CREATE: '/dashboard/create/',
		DASHBOARD_EDIT: '/dashboard/edit/',

		EVENT_LIST_PATH: '/events/',

		PRODUCTS_CUSTOMER_PROFILES: '/products/customer/profiles/',
		PRODUCTS_SEGMENTATION: '/products/segmentation/',
		PRODUCTS_FUNNEL: '/products/funnel/',
		PRODUCTS_RETENTION: '/products/retention/',
		PRODUCTS_DASHBOARDS: '/products/dashboards/',

		COHORT_PAGE_PATH: '/cohorts/',
		COHORT_PATH: '/cohorts/',
		COHORT_CREATE_PATH: '/cohorts/create/',
		COHORT_EDIT_PATH: '/cohorts/edit/',
		COHORT_LIST_PATH: '/cohorts/list/',

		FUNNEL_PAGE_PATH: '/funnel/',
		FUNNEL_CHART_PATH: '/funnel/chart/',
		FUNNEL_CREATE_PATH: '/funnel/create/',
		FUNNEL_EDIT_PATH: '/funnel/edit/',
		FUNNEL_LIST_PATH: '/funnel/list/',

		LIVE_VIEW_PATH: '/live/view/',

		RETENTION_PATH: '/retention/',
		REVENUE_PATH: '/revenue/',

		SEGMENT_PAGE_PATH: '/segment/',
		SEGMENT_ANALYZE_PATH: '/segment/analyze/',
		SEGMENT_CREATE_PATH: '/segment/create/',
		SEGMENT_CHART_PATH: '/segment/chart/',
		SEGMENT_EDIT_PATH: '/segment/edit/',
		SEGMENT_LIST_PATH: '/segment/list/'
	},
	nexusData: {
		DASHBOARD_DISPLAY: '/dashboard/display/',
		DASHBOARD_DISPLAY_PUBLIC: '/dashboard/public/',
		DASHBOARD_CREATE: '/dashboard/create/',
		DASHBOARD_EDIT: '/dashboard/edit/',
		// INTEGRATIONS: '/integrations/',
		SCRUM_BOARDS: '/scrumboard/boards/',
		TODOS: '/todos/'
	},
	skydera: {
		// INTEGRATIONS: '/integrations/',
		LEAFCI_REGISTRIES_PATH: '/leafci/registries/',
		LEAFCI_IMAGES_REGISTER_BASE_PATH: '/leafci/register/images/',
		LEAFCI_IMAGES_REGISTER_IMAGE_LIST_PATH: '/leafci/register/images/',
		LEAFCI_IMAGES_REGISTER_IMAGE_DETAIL_PATH: '/leafci/register/images/detail/',
		SERVICE_ALB_PATH: '/service/alb/',
		SERVICE_ALB_DETAIL_PATH: '/service/alb/detail/',
		SERVICE_APP_PATH: '/service/app/',
		SERVICE_APP_CREATE_PATH: '/service/app/create/',
		SERVICE_APP_DEPLOY_PATH: '/service/app/deploy/',
		SERVICE_APP_SERVICE_DETAIL_PATH: '/service/app/service/detail/',
		SERVICE_APP_TASK_DETAIL_PATH: '/service/app/task/detail/',
		SERVICE_APP_PREVIEW_PATH: '/service/preview/',
		SERVICE_APP_PREVIEW_DETAIL_PATH: '/service/preview/service/detail/',
		SERVICE_APP_PREVIEW_TASK_DETAIL_PATH: '/service/preview/task/detail/',
		SERVICE_CATALOG_PATH: '/service/catalog/',
		SERVICE_CATALOG_CREATE_PATH: '/service/catalog/create/',
		SERVICE_CATALOG_REPOS_PATH: '/service/catalog/repos/',
		SERVICE_CATALOG_DETAIL_PATH: '/service/catalog/detail/',
		SERVICE_CATALOG_APP_DEF_PATH: '/service/catalog/app/def/',
		SERVICE_CERT_PATH: '/service/cert/',
		SERVICE_CERT_DETAIL_PATH: '/service/cert/detail/',
		SERVICE_CERT_IMPORT_PATH: '/service/cert/import/',
		SERVICE_DOMAINS_PATH: '/service/domains/',
		SERVICE_DOMAINS_DETAIL_PATH: '/service/domains/detail/',
		SERVICE_DOMAINS_REGISTER_PATH: '/service/domains/register/',
		SERVICE_LIMITS_PATH: '/service/limits/',
		SERVICE_LIMITS_CREATE_PATH: '/service/limits/create/',
		SERVICE_LIMITS_DETAIL_PATH: '/service/limits/detail/',
		SERVICE_LIMITS_USER_DETAIL_PATH: '/service/limits/user/detail/',
		SERVICE_NAMESPACE_PATH: '/service/namespace/',
		SERVICE_NAMESPACE_CREATE_PATH: '/service/namespace/create/',
		SERVICE_NAMESPACE_DETAIL_PATH: '/service/namespace/detail/',
		SERVICE_NETWORKS_PATH: '/service/networks/',
		SERVICE_NETWORKS_CREATE_PATH: '/service/networks/create/',
		SERVICE_NETWORKS_DETAIL_PATH: '/service/networks/detail/',
		SERVICE_SECRETS_PATH: '/service/secrets/',
		SERVICE_SECRETS_DETAIL_PATH: '/service/secrets/detail/',
		SERVICE_SECRETS_CREATE_PATH: '/service/secrets/create/',
		SERVICE_SCHEDULED_PATH: '/service/schedule/',
		SERVICE_SERVICES_PATH: '/service/services/',
		SERVICE_SERVICES_CREATE_PATH: '/service/services/create/',
		SERVICE_SERVICES_DETAIL_PATH: '/service/services/detail/',
		SERVICE_TASK_PATH: '/service/task/',
		SERVICE_TASK_CREATE_PATH: '/service/task/create/',
		SERVICE_TASK_DETAIL_PATH: '/service/task/detail/',
		SERVICE_VPC_PATH: '/service/vpc/',
		SERVICE_VPC_CREATE_PATH: '/service/vpc/create/',
		SERVICE_VPC_DETAIL_PATH: '/service/vpc/detail/',
		TASK_REGISTER_PATH: '/task/register/',
		TASK_DEFINITION_PATH: '/task/definition/',
		TASK_DEFINITION_CREATE_PATH: '/task/definition/create/',
		TASK_DEFINITION_DETAIL_PATH: '/task/definition/detail/',
		TASK_RUN_BASE_PATH: '/task/run/',
		TASK_LAUNCH_PATH: '/task/launch/',
		TASK_RUN_LIST_PATH: '/task/run/list/',
		TASK_RUN_DETAIL_PATH: '/task/run/detail/',
		TASK_SCHEDULE_BASE_PATH: '/task/schedule/',
		TASK_STATUS_BASE_PATH: '/task/status/'
	}
};
const URL_PATH_CURRENT = URL_PATHS[APP_ACTIVE];

export const STRIP_PUBLISH_KEY = APP_STRIP_PUBLISH_KEY[APP_ACTIVE];

// URL Paths based on which app is currently active
export const URLPaths = { ...URL_PATHS.main, ...URL_PATH_CURRENT };

// Defined the available integrations for the application.
export const APP_INTEGRATIONS = APP_INTEGRATION_LIST.filter(
	(x) => APP_CONFIG[APP_ACTIVE].integrationsList && APP_CONFIG[APP_ACTIVE].integrationsList.includes(x.id)
);

const HR_SECONDS = 3600;

const DY_SECONDS = 86400;

export const REGION_ABBREVIATIONS = {
	'us-east-2': 'us:e:2',
	'us-east-1': 'us:e:1',
	'us-west-1': 'us:w:1',
	'us-west-2': 'us:w:2',
	'af-south-1': 'af:s:1',
	'ap-east-1': 'ap:e:1',
	'ap-southeast-3': 'ap:se:3',
	'ap-south-1': 'ap:s:1',
	'ap-northeast-3': 'ap:ne:3',
	'ap-northeast-2': 'ap:ne:2',
	'ap-southeast-1': 'ap:se:1',
	'ap-southeast-2': 'ap:se:2',
	'ap-northeast-1': 'ap:ne:1',
	'ca-central-1': 'ca:c:1',
	'eu-central-1': 'eu:c:1',
	'eu-west-1': 'eu:w:1',
	'eu-west-2': 'eu:w:2',
	'eu-south-1': 'eu:s:1',
	'eu-west-3': 'eu:w:3',
	'eu-north-1': 'eu:n:1',
	'me-south-1': 'me:s:1',
	'me-central-1': 'me:c:1',
	'sa-east-1': 'sa:e:1',
	'us-gov-east-1': 'us:ge:1',
	'us-gov-west-1': 'us:gw:1'
};

export const REGION_NAMES = {
	'us-east-2': 'US East 2 (Ohio)',
	'us-east-1': 'US East 1 (N. Virginia)',
	'us-west-1': 'US West 1 (N. California)',
	'us-west-2': 'US West 2 (Oregon)',
	'af-south-1': 'Africa 1 (Cape Town)',
	'ap-east-1': 'Asia Pacific 1 (Hong Kong)',
	'ap-southeast-3': 'Asia Pacific 3 (Jakarta)',
	'ap-south-1': 'Asia Pacific 1 (Mumbai)',
	'ap-northeast-3': 'Asia Pacific 3 (Osaka)',
	'ap-northeast-2': 'Asia Pacific 2 (Seoul)',
	'ap-southeast-1': 'Asia Pacific 1 (Singapore)',
	'ap-southeast-2': 'Asia Pacific 2 (Sydney)',
	'ap-northeast-1': 'Asia Pacific 1 (Tokyo)',
	'ca-central-1': 'Canada 1 (Central)',
	'eu-central-1': 'Europe 1 (Frankfurt)',
	'eu-west-1': 'Europe 1 (Ireland)',
	'eu-west-2': 'Europe 2 (London)',
	'eu-south-1': 'Europe 1 (Milan)',
	'eu-west-3': 'Europe 3 (Paris)',
	'eu-north-1': 'Europe 1 (Stockholm)',
	'me-south-1': 'Middle East 1 (Bahrain)',
	'me-central-1': 'Middle East 1 (UAE)',
	'sa-east-1': 'South America 1 (São Paulo)',
	'us-gov-east-1': 'AWS GovCloud 1 (US-East)',
	'us-gov-west-1': 'AWS GovCloud 1 (US-West)'
};

export const REGION_NAMES_OPTIONS = [
	{ value: 'us-east-2', label: 'US East (Ohio)' },
	{ value: 'us-east-1', label: 'US East (N. Virginia)' },
	{ value: 'us-west-1', label: 'US West (N. California)' },
	{ value: 'us-west-2', label: 'US West (Oregon)' },
	// { value: 'af-south-1', label: 'Africa (Cape Town)' },
	{ value: 'ap-east-1', label: 'Asia Pacific (Hong Kong)' },
	// { value: 'ap-southeast-3', label: 'Asia Pacific (Jakarta)' },
	{ value: 'ap-south-1', label: 'Asia Pacific (Mumbai)' },
	// { value: 'ap-south-2', label: 'Asia Pacific (Hyderabad)' },
	{ value: 'ap-northeast-3', label: 'Asia Pacific (Osaka)' },
	{ value: 'ap-northeast-2', label: 'Asia Pacific (Seoul)' },
	{ value: 'ap-southeast-1', label: 'Asia Pacific (Singapore)' },
	{ value: 'ap-southeast-2', label: 'Asia Pacific (Sydney)' },
	// { value: 'ap-southeast-3', label: 'Asia Pacific (Melbourne)' },
	// { value: 'ap-southeast-4', label: 'Asia Pacific (Jakarta)' },
	{ value: 'ap-northeast-1', label: 'Asia Pacific (Tokyo)' },
	{ value: 'ca-central-1', label: 'Canada (Central)' },
	{ value: 'eu-central-1', label: 'Europe (Frankfurt)' },
	{ value: 'eu-west-1', label: 'Europe (Ireland)' },
	{ value: 'eu-west-2', label: 'Europe (London)' },
	// { value: 'eu-south-1', label: 'Europe (Milan)' },
	// { value: 'eu-south-1', label: 'Europe (Spain)' },
	{ value: 'eu-west-3', label: 'Europe (Paris)' },
	{ value: 'eu-north-1', label: 'Europe (Stockholm)' },
	// { value: 'me-south-1', label: 'Middle East (Bahrain)' },
	// { value: 'me-central-1', label: 'Middle East (UAE)' },
	{ value: 'sa-east-1', label: 'South America (São Paulo)' }
	// { value: 'us-gov-east-1', label: 'AWS GovCloud (US-East)' },
	// { value: 'us-gov-west-1', label: 'AWS GovCloud (US-West)' },
];

export const FARGATE_OPTIONS = {
	256: [
		{ value: '512', label: '0.5GB' },
		{ value: '1024', label: '1GB' },
		{ value: '2048', label: '2GB' }
	],
	512: [
		{ value: '1024', label: '1GB' },
		{ value: '2048', label: '2GB' },
		{ value: '3072', label: '3GB' },
		{ value: '4096', label: '4GB' }
	],
	1024: [
		{ value: '2048', label: '2GB' },
		{ value: '3072', label: '3GB' },
		{ value: '4096', label: '4GB' },
		{ value: '5120', label: '5GB' },
		{ value: '6144', label: '6GB' },
		{ value: '7168', label: '7GB' },
		{ value: '8192', label: '8GB' }
	],
	2048: [
		{ value: '4096', label: '4GB' },
		{ value: '5120', label: '5GB' },
		{ value: '6144', label: '6GB' },
		{ value: '7168', label: '7GB' },
		{ value: '8192', label: '8GB' },
		{ value: '9216', label: '9GB' },
		{ value: '10240', label: '10GB' },
		{ value: '11264', label: '11GB' },
		{ value: '12288', label: '12GB' },
		{ value: '13312', label: '13GB' },
		{ value: '14336', label: '14GB' },
		{ value: '15360', label: '15GB' },
		{ value: '16384', label: '16GB' }
	],
	4096: [
		{ value: '8192', label: '8GB' },
		{ value: '9216', label: '9GB' },
		{ value: '10240', label: '10GB' },
		{ value: '11264', label: '11GB' },
		{ value: '12288', label: '12GB' },
		{ value: '13312', label: '13GB' },
		{ value: '14336', label: '14GB' },
		{ value: '15360', label: '15GB' },
		{ value: '16384', label: '16GB' },
		{ value: '17408', label: '17GB' },
		{ value: '18432', label: '18GB' },
		{ value: '19456', label: '19GB' },
		{ value: '20480', label: '20GB' },
		{ value: '21504', label: '21GB' },
		{ value: '22528', label: '22GB' },
		{ value: '23552', label: '23GB' },
		{ value: '24576', label: '24GB' },
		{ value: '25600', label: '25GB' },
		{ value: '26624', label: '26GB' },
		{ value: '27648', label: '27GB' },
		{ value: '28672', label: '28GB' },
		{ value: '29696', label: '29GB' },
		{ value: '30720', label: '30GB' }
	],
	8192: [
		{ value: '16384', label: '16GB' },
		{ value: '20480', label: '20GB' },
		{ value: '24576', label: '24GB' },
		{ value: '28672', label: '28GB' },
		{ value: '32768', label: '32GB' },
		{ value: '36864', label: '36GB' },
		{ value: '40960', label: '40GB' },
		{ value: '45056', label: '44GB' },
		{ value: '49152', label: '48GB' },
		{ value: '53248', label: '52GB' },
		{ value: '57344', label: '56GB' },
		{ value: '61440', label: '60GB' }
	],
	16384: [
		{ value: '32768', label: '32GB' },
		{ value: '40960', label: '40GB' },
		{ value: '49152', label: '48GB' },
		{ value: '57344', label: '56GB' },
		{ value: '65536', label: '64GB' },
		{ value: '73728', label: '72GB' },
		{ value: '81920', label: '80GB' },
		{ value: '90112', label: '88GB' },
		{ value: '98304', label: '96GB' },
		{ value: '106496', label: '104GB' },
		{ value: '114688', label: '112GB' },
		{ value: '122880', label: '120GB' }
	]
};

export const CPU_OPTIONS = [
	{ value: '256', label: '0.25 vCPU' },
	{ value: '512', label: '0.5 vCPU' },
	{ value: '1024', label: '1 vCPU' },
	{ value: '2048', label: '2 vCPU' },
	{ value: '4096', label: '4 vCPU' },
	{ value: '8192', label: '8 vCPU' },
	{ value: '16384', label: '16 vCPU' }
];

export const FUNNEL_DURATION = [
	{ value: '1 Day', label: '1 Day' },
	{ value: '2 Days', label: '2 Days' },
	{ value: '4 Days', label: '4 Days' },
	{ value: '7 Days', label: '7 Days' },
	{ value: '30 Days', label: '30 Days' },
	{ value: '120 Days', label: '120 Days' }
];

export const TIMEZONES = [
	{ value: 'pst', label: '(utc-8) Pacific Standard Time' },
	{ value: 'mst', label: '(utc-7) Mountain Standard Time' },
	{ value: 'cst', label: '(utc-6) Central Standard Time' },
	{ value: 'est', label: '(utc-5) Eastern Standard Time' },
	{ value: 'ast', label: '(utc-4) Atlantic Standard Time' },
	{ value: 'sst', label: '(utc-11) Samoa Standard Time' },
	{ value: 'hst', label: '(utc-10) Hawaii-Aleutian Standard Time' },
	{ value: 'akst', label: '(utc-9) Alaska Standard Time' },
	{ value: 'art', label: '(utc-3) Argentina Time' },
	{ value: 'fnt', label: '(utc-2) Fernando de Noronha Time' },
	{ value: 'azot', label: '(utc-1) Azores Time' },
	{ value: 'gmt', label: '(utc+0) Greenwich Mean Time' },
	{ value: 'cet', label: '(utc+1) Central European Time' },
	{ value: 'wat', label: '(utc+1) West Africa Time' },
	{ value: 'eet', label: '(utc+2) Eastern European Time' },
	{ value: 'sast', label: '(utc+2) South Africa Standard Time' },
	{ value: 'eat', label: '(utc+3) East Africa Time' },
	{ value: 'msk', label: '(utc+3) Moscow Standard Time' },
	{ value: 'gst', label: '(utc+4) Gulf Standard Time' },
	{ value: 'pkt', label: '(utc+5) Pakistan Standard Time' },
	{ value: 'bst', label: '(utc+6) Bangladesh Standard Time' },
	{ value: 'ict', label: '(utc+7) Indochina Time' },
	{ value: 'cst', label: '(utc+8) China Standard Time' },
	{ value: 'jst', label: '(utc+9) Japan Standard Time' },
	{ value: 'aest', label: '(utc+10) Australian Eastern Standard Time' },
	{ value: 'aedt', label: '(utc+11) Australian Eastern Daylight Time' },
	{ value: 'nzst', label: '(utc+12) New Zealand Standard Time' }
];

export const DATE_FORMAT = [
	{ value: 'mm/dd/yyyy', label: 'MM/DD/YYYY' },
	{ value: 'dd/mm/yyyy', label: 'DD/MM/YYYY' }
];

export const SESSION_TIMEOUT = [
	{ value: 300, label: '5 Minutes' },
	{ value: 600, label: '10 Minutes' },
	{ value: 1800, label: '30 Minutes' },
	{ value: 3600, label: '1 Hour' },
	{ value: 18000, label: '5 Hours' },
	{ value: 43200, label: '12 Hours' },
	{ value: 86400, label: '24 Hours' }
];

export const PASSWORD_EXPIRATION = [
	{ value: DY_SECONDS * 60, label: '60 Days' },
	{ value: DY_SECONDS * 90, label: '90 Days' },
	{ value: DY_SECONDS * 180, label: '180 Days' },
	{ value: DY_SECONDS * 365, label: '365 Days' }
];

export const PASSWORD_REUSE = [
	{ value: 2, label: '2 Changes' },
	{ value: 5, label: '5 Changes' },
	{ value: 10, label: '10 Changes' }
];

export const PASSWORD_FAIL_ATTEMPTS = [
	{ value: 1, label: '1 Failed Attempts' },
	{ value: 2, label: '2 Failed Attempts' },
	{ value: 3, label: '3 Failed Attempts' },
	{ value: 4, label: '4 Failed Attempts' },
	{ value: 5, label: '5 Failed Attempts' },
	{ value: 6, label: '6 Failed Attempts' },
	{ value: 7, label: '7 Failed Attempts' },
	{ value: 8, label: '8 Failed Attempts' },
	{ value: 9, label: '9 Failed Attempts' },
	{ value: 10, label: '10 Failed Attempts' }
];

export const PASSWORD_FAIL_ATTEMPTS_WITHIN = [
	{ value: 300, label: 'Within 5 Minutes' },
	{ value: 600, label: 'Within 10 Minutes' },
	{ value: 900, label: 'Within 15 Minutes' },
	{ value: 1200, label: 'Within 20 Minutes' },
	{ value: 1500, label: 'Within 25 Minutes' },
	{ value: 1800, label: 'Within 30 Minutes' },
	{ value: HR_SECONDS, label: 'Within 60 Minutes' }
];

export const PASSWORD_FAIL_ATTEMPTS_LOCK_FOR = [
	{ value: 120, label: 'For 2 Minutes' },
	{ value: 300, label: 'For 5 Minutes' },
	{ value: 900, label: 'For 15 Minutes' },
	{ value: 1800, label: 'For 30 Minutes' },
	{ value: HR_SECONDS, label: 'For 1 Hour' },
	{ value: HR_SECONDS * 2, label: 'For 2 Hours' },
	{ value: HR_SECONDS * 4, label: 'For 4 Hours' },
	{ value: HR_SECONDS * 6, label: 'For 6 Hours' }
];

export const COUNTRY_CODES = {
	US: 'United States',
	AF: 'Afghanistan',
	AL: 'Albania',
	DZ: 'Algeria',
	AS: 'American Samoa',
	AD: 'Andorra',
	AO: 'Angola',
	AI: 'Anguilla',
	AQ: 'Antarctica',
	AG: 'Antigua and Barbuda',
	AR: 'Argentina',
	AM: 'Armenia',
	AW: 'Aruba',
	AU: 'Australia',
	AT: 'Austria',
	AZ: 'Azerbaijan',
	BS: 'Bahamas',
	BH: 'Bahrain',
	BD: 'Bangladesh',
	BB: 'Barbados',
	BY: 'Belarus',
	BE: 'Belgium',
	BZ: 'Belize',
	BJ: 'Benin',
	BM: 'Bermuda',
	BT: 'Bhutan',
	BO: 'Bolivia',
	BA: 'Bosnia and Herzegovina',
	BW: 'Botswana',
	BR: 'Brazil',
	IO: 'British Indian Ocean Territory',
	VG: 'British Virgin Islands',
	BN: 'Brunei',
	BG: 'Bulgaria',
	BF: 'Burkina Faso',
	MM: 'Burma (Myanmar)',
	BI: 'Burundi',
	KH: 'Cambodia',
	CM: 'Cameroon',
	CA: 'Canada',
	CV: 'Cape Verde',
	KY: 'Cayman Islands',
	CF: 'Central African Republic',
	TD: 'Chad',
	CL: 'Chile',
	CN: 'China',
	CX: 'Christmas Island',
	CC: 'Cocos (Keeling) Islands',
	CO: 'Colombia',
	KM: 'Comoros',
	CK: 'Cook Islands',
	CR: 'Costa Rica',
	HR: 'Croatia',
	CU: 'Cuba',
	CY: 'Cyprus',
	CZ: 'Czech Republic',
	CD: 'Democratic Republic of the Congo',
	DK: 'Denmark',
	DJ: 'Djibouti',
	DM: 'Dominica',
	DO: 'Dominican Republic',
	EC: 'Ecuador',
	EG: 'Egypt',
	SV: 'El Salvador',
	GQ: 'Equatorial Guinea',
	ER: 'Eritrea',
	EE: 'Estonia',
	ET: 'Ethiopia',
	FK: 'Falkland Islands',
	FO: 'Faroe Islands',
	FJ: 'Fiji',
	FI: 'Finland',
	FR: 'France',
	PF: 'French Polynesia',
	GA: 'Gabon',
	GM: 'Gambia',
	GE: 'Georgia',
	DE: 'Germany',
	GH: 'Ghana',
	GI: 'Gibraltar',
	GR: 'Greece',
	GL: 'Greenland',
	GD: 'Grenada',
	GU: 'Guam',
	GT: 'Guatemala',
	GN: 'Guinea',
	GW: 'Guinea-Bissau',
	GY: 'Guyana',
	HT: 'Haiti',
	VA: 'Holy See (Vatican City)',
	HN: 'Honduras',
	HK: 'Hong Kong',
	HU: 'Hungary',
	IS: 'Iceland',
	IN: 'India',
	ID: 'Indonesia',
	IR: 'Iran',
	IQ: 'Iraq',
	IE: 'Ireland',
	IM: 'Isle of Man',
	IL: 'Israel',
	IT: 'Italy',
	CI: 'Ivory Coast',
	JM: 'Jamaica',
	JP: 'Japan',
	JE: 'Jersey',
	JO: 'Jordan',
	KZ: 'Kazakhstan',
	KE: 'Kenya',
	KI: 'Kiribati',
	KW: 'Kuwait',
	KG: 'Kyrgyzstan',
	LA: 'Laos',
	LV: 'Latvia',
	LB: 'Lebanon',
	LS: 'Lesotho',
	LR: 'Liberia',
	LY: 'Libya',
	LI: 'Liechtenstein',
	LT: 'Lithuania',
	LU: 'Luxembourg',
	MO: 'Macau',
	MK: 'Macedonia',
	MG: 'Madagascar',
	MW: 'Malawi',
	MY: 'Malaysia',
	MV: 'Maldives',
	ML: 'Mali',
	MT: 'Malta',
	MH: 'Marshall Islands',
	MR: 'Mauritania',
	MU: 'Mauritius',
	YT: 'Mayotte',
	MX: 'Mexico',
	FM: 'Micronesia',
	MD: 'Moldova',
	MC: 'Monaco',
	MN: 'Mongolia',
	ME: 'Montenegro',
	MS: 'Montserrat',
	MA: 'Morocco',
	MZ: 'Mozambique',
	NA: 'Namibia',
	NR: 'Nauru',
	NP: 'Nepal',
	NL: 'Netherlands',
	AN: 'Netherlands Antilles',
	NC: 'New Caledonia',
	NZ: 'New Zealand',
	NI: 'Nicaragua',
	NE: 'Niger',
	NG: 'Nigeria',
	NU: 'Niue',
	NFK: 'Norfolk Island',
	KP: 'North Korea',
	MP: 'Northern Mariana Islands',
	NO: 'Norway',
	OM: 'Oman',
	PK: 'Pakistan',
	PW: 'Palau',
	PA: 'Panama',
	PG: 'Papua New Guinea',
	PY: 'Paraguay',
	PE: 'Peru',
	PH: 'Philippines',
	PN: 'Pitcairn Islands',
	PL: 'Poland',
	PT: 'Portugal',
	PR: 'Puerto Rico',
	QA: 'Qatar',
	CG: 'Republic of the Congo',
	RO: 'Romania',
	RU: 'Russia',
	RW: 'Rwanda',
	BL: 'Saint Barthelemy',
	SH: 'Saint Helena',
	KN: 'Saint Kitts and Nevis',
	LC: 'Saint Lucia',
	MF: 'Saint Martin',
	PM: 'Saint Pierre and Miquelon',
	VC: 'Saint Vincent and the Grenadines',
	WS: 'Samoa',
	SM: 'San Marino',
	ST: 'Sao Tome and Principe',
	SA: 'Saudi Arabia',
	SN: 'Senegal',
	RS: 'Serbia',
	SC: 'Seychelles',
	SL: 'Sierra Leone',
	SG: 'Singapore',
	SK: 'Slovakia',
	SI: 'Slovenia',
	SB: 'Solomon Islands',
	SO: 'Somalia',
	ZA: 'South Africa',
	KR: 'South Korea',
	ES: 'Spain',
	LK: 'Sri Lanka',
	SD: 'Sudan',
	SR: 'Suriname',
	SJ: 'Svalbard',
	SZ: 'Swaziland',
	SE: 'Sweden',
	CH: 'Switzerland',
	SY: 'Syria',
	TW: 'Taiwan',
	TJ: 'Tajikistan',
	TZ: 'Tanzania',
	TH: 'Thailand',
	TL: 'Timor-Leste',
	TG: 'Togo',
	TK: 'Tokelau',
	TO: 'Tonga',
	TT: 'Trinidad and Tobago',
	TN: 'Tunisia',
	TR: 'Turkey',
	TM: 'Turkmenistan',
	TC: 'Turks and Caicos Islands',
	TV: 'Tuvalu',
	UG: 'Uganda',
	UA: 'Ukraine',
	AE: 'United Arab Emirates',
	GB: 'United Kingdom',
	UY: 'Uruguay',
	VI: 'US Virgin Islands',
	UZ: 'Uzbekistan',
	VU: 'Vanuatu',
	VE: 'Venezuela',
	VN: 'Vietnam',
	WF: 'Wallis and Futuna',
	EH: 'Western Sahara',
	YE: 'Yemen',
	ZM: 'Zambia',
	ZW: 'Zimbabwe'
};

export const PASSWORD_UNAPPROVED = [
	'123456',
	'porsche',
	'firebird',
	'prince',
	'rosebud',
	'password',
	'guitar',
	'butter',
	'beach',
	'jaguar',
	'12345678',
	'chelsea',
	'united',
	'amateur',
	'great',
	'1234',
	'black',
	'turtle',
	'7777777',
	'cool',
	'pussy',
	'diamond',
	'steelers',
	'muffin',
	'cooper',
	'12345',
	'nascar',
	'tiffany',
	'redsox',
	'1313',
	'dragon',
	'jackson',
	'zxcvbn',
	'star',
	'scorpio',
	'qwerty',
	'cameron',
	'tomcat',
	'testing',
	'mountain',
	'696969',
	'654321',
	'golf',
	'shannon',
	'madison',
	'mustang',
	'computer',
	'bond007',
	'murphy',
	'987654',
	'letmein',
	'amanda',
	'bear',
	'frank',
	'brazil',
	'baseball',
	'wizard',
	'tiger',
	'hannah',
	'lauren',
	'master',
	'xxxxxxxx',
	'doctor',
	'dave',
	'japan',
	'michael',
	'money',
	'gateway',
	'eagle1',
	'naked',
	'football',
	'phoenix',
	'gators',
	'11111',
	'squirt',
	'shadow',
	'mickey',
	'angel',
	'mother',
	'stars',
	'monkey',
	'bailey',
	'junior',
	'nathan',
	'apple',
	'abc123',
	'knight',
	'thx1138',
	'raiders',
	'alexis',
	'pass',
	'iceman',
	'porno',
	'steve',
	'aaaa',
	'fuckme',
	'tigers',
	'badboy',
	'forever',
	'bonnie',
	'6969',
	'purple',
	'debbie',
	'angela',
	'peaches',
	'jordan',
	'andrea',
	'spider',
	'viper',
	'jasmine',
	'harley',
	'horny',
	'melissa',
	'ou812',
	'kevin',
	'ranger',
	'dakota',
	'booger',
	'jake',
	'matt',
	'iwantu',
	'aaaaaa',
	'1212',
	'lovers',
	'qwertyui',
	'jennifer',
	'player',
	'flyers',
	'suckit',
	'danielle',
	'hunter',
	'sunshine',
	'fish',
	'gregory',
	'beaver',
	'fuck',
	'morgan',
	'porn',
	'buddy',
	'4321',
	'2000',
	'starwars',
	'matrix',
	'whatever',
	'4128',
	'test',
	'boomer',
	'teens',
	'young',
	'runner',
	'batman',
	'cowboys',
	'scooby',
	'nicholas',
	'swimming',
	'trustno1',
	'edward',
	'jason',
	'lucky',
	'dolphin',
	'thomas',
	'charles',
	'walter',
	'helpme',
	'gordon',
	'tigger',
	'girls',
	'cumshot',
	'jackie',
	'casper',
	'robert',
	'booboo',
	'boston',
	'monica',
	'stupid',
	'access',
	'coffee',
	'braves',
	'midnight',
	'shit',
	'love',
	'xxxxxx',
	'yankee',
	'college',
	'saturn',
	'buster',
	'bulldog',
	'lover',
	'baby',
	'gemini',
	'1234567',
	'ncc1701',
	'barney',
	'cunt',
	'apples',
	'soccer',
	'rabbit',
	'victor',
	'brian',
	'august',
	'hockey',
	'peanut',
	'tucker',
	'mark',
	'3333',
	'killer',
	'john',
	'princess',
	'startrek',
	'canada',
	'george',
	'johnny',
	'mercedes',
	'sierra',
	'blazer',
	'sexy',
	'gandalf',
	'5150',
	'leather',
	'cumming',
	'andrew',
	'spanky',
	'doggie',
	'232323',
	'hunting',
	'charlie',
	'winter',
	'zzzzzz',
	'4444',
	'kitty',
	'superman',
	'brandy',
	'gunner',
	'beavis',
	'rainbow',
	'asshole',
	'compaq',
	'horney',
	'bigcock',
	'112233',
	'fuckyou',
	'carlos',
	'bubba',
	'happy',
	'arthur',
	'dallas',
	'tennis',
	'2112',
	'sophie',
	'cream',
	'jessica',
	'james',
	'fred',
	'ladies',
	'calvin',
	'panties',
	'mike',
	'johnson',
	'naughty',
	'shaved',
	'pepper',
	'brandon',
	'xxxxx',
	'giants',
	'surfer',
	'1111',
	'fender',
	'tits',
	'booty',
	'samson',
	'austin',
	'anthony',
	'member',
	'blonde',
	'kelly',
	'william',
	'blowme',
	'boobs',
	'fucked',
	'paul',
	'daniel',
	'ferrari',
	'donald',
	'golden',
	'mine',
	'golfer',
	'cookie',
	'bigdaddy',
	'0',
	'king',
	'summer',
	'chicken',
	'bronco',
	'fire',
	'racing',
	'heather',
	'maverick',
	'penis',
	'sandra',
	'5555',
	'hammer',
	'chicago',
	'voyager',
	'pookie',
	'eagle',
	'yankees',
	'joseph',
	'rangers',
	'packers',
	'hentai',
	'joshua',
	'diablo',
	'birdie',
	'einstein',
	'newyork',
	'maggie',
	'sexsex',
	'sex',
	'trouble',
	'dolphins',
	'little',
	'biteme',
	'hardcore',
	'white',
	'0',
	'redwings',
	'enter',
	'666666',
	'topgun',
	'chevy',
	'smith',
	'ashley',
	'willie',
	'bigtits',
	'winston',
	'sticky',
	'thunder',
	'welcome',
	'bitches',
	'warrior',
	'cocacola',
	'cowboy',
	'chris',
	'green',
	'sammy',
	'animal',
	'silver',
	'panther',
	'super',
	'slut',
	'broncos',
	'richard',
	'yamaha',
	'qazwsx',
	'8675309',
	'private',
	'fucker',
	'justin',
	'magic',
	'zxcvbnm',
	'skippy',
	'orange',
	'banana',
	'lakers',
	'nipples',
	'marvin',
	'merlin',
	'driver',
	'rachel',
	'power',
	'blondes',
	'michelle',
	'marine',
	'slayer',
	'victoria',
	'enjoy',
	'corvette',
	'angels',
	'scott',
	'asdfgh',
	'girl',
	'bigdog',
	'fishing',
	'2222',
	'vagina',
	'apollo',
	'cheese',
	'david',
	'asdf',
	'toyota',
	'parker',
	'matthew',
	'maddog',
	'video',
	'travis',
	'qwert',
	'121212',
	'hooters',
	'london',
	'hotdog',
	'time',
	'patrick',
	'wilson',
	'7777',
	'paris',
	'sydney',
	'martin',
	'butthead',
	'marlboro',
	'rock',
	'women',
	'freedom',
	'dennis',
	'srinivas',
	'xxxx',
	'voodoo',
	'ginger',
	'fucking',
	'internet',
	'extreme',
	'magnum',
	'blowjob',
	'captain',
	'action',
	'redskins',
	'juice',
	'nicole',
	'bigdick',
	'carter',
	'erotic',
	'abgrtyu',
	'sparky',
	'chester',
	'jasper',
	'dirty',
	'777777',
	'yellow',
	'smokey',
	'monster',
	'ford',
	'dreams',
	'camaro',
	'xavier',
	'teresa',
	'freddy',
	'maxwell',
	'secret',
	'steven',
	'jeremy',
	'arsenal',
	'music',
	'dick',
	'viking',
	'11111111',
	'access14',
	'rush2112',
	'falcon',
	'snoopy',
	'bill',
	'wolf',
	'russia',
	'taylor',
	'blue',
	'crystal',
	'nipple',
	'scorpion',
	'111111',
	'eagles',
	'peter',
	'iloveyou',
	'rebecca',
	'131313',
	'winner',
	'pussies',
	'alex',
	'tester',
	'123123',
	'samantha',
	'cock',
	'florida',
	'mistress',
	'bitch',
	'house',
	'beer',
	'eric',
	'phantom',
	'hello',
	'miller',
	'rocket',
	'legend',
	'billy',
	'scooter',
	'flower',
	'theman',
	'movie',
	'6666',
	'please',
	'jack',
	'oliver',
	'success',
	'albert'
];
