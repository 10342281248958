import { useEffect, useState } from 'react';
import { URLPaths } from 'src/app/constants';
import { useLocalStorage } from 'react-storage-complete';
import { Base64 } from 'js-base64';
import { useAuthState } from 'src/app/context/useAuth';
import { useSettings } from 'src/app/context/useSettings';
import { useLocation, useNavigate } from 'react-router-dom';

import { noRedirect } from './constants';
import FuseSplashScreen from 'src/lib/@fuse/core/FuseSplashScreen/FuseSplashScreen';

const TwoFaCheck = ({ children }) => {
	const history = useNavigate();
	const location = useLocation();

	const { isAuthenticated, companySettings } = useAuthState();
	const { userPref } = useSettings();
	console.log('TwoFaCheck');
	console.log('companySettings', companySettings);
	console.log('userPref', userPref);
	console.log('location.pathname', location.pathname);

	const [waitTwoFaCheck, setWaitTwoFaCheck] = useState(true);

	const [storedSettings, setStoredSettings] = useLocalStorage(
		'settings',
		{},
		{
			prefix: 'config',
			shouldInitialize: true,
			emitterDisabled: false,
			encode: (t) => Base64.encode(JSON.stringify(t)),
			decode: (t) => JSON.parse(Base64.decode(t))
		}
	);

	console.log('storedSettings', storedSettings);

	useEffect(() => {
		console.log('isAuthenticated', isAuthenticated);
		console.log('!storedSettings?.twoFactorVerified ', !storedSettings?.twoFactorVerified);
		console.log(' userPref.enableTwoFactor', userPref.enableTwoFactor);
		console.log(
			' companySettings?.twoFactor?.enableTimeForce ',
			userPref.companySettings?.twoFactor?.enableTimeForce
		);
		console.log(
			' companySettings?.twoFactor?.enableOrgTwoFa ',
			userPref.companySettings?.twoFactor?.enableOrgTwoFa
		);

		if (
			isAuthenticated &&
			!storedSettings?.twoFactorVerified &&
			(companySettings?.twoFactor?.enableOrgTwoFa || userPref.enableTwoFactor)
		) {
			if (companySettings?.twoFactor?.enableTimeForce && companySettings?.twoFactor?.enableTime === 'every') {
				// If we enforce two factor for each login, we will make the user to the check.
				if (isAuthenticated && !noRedirect.includes(location.pathname)) {
					history(URLPaths.PAGES_VERIFY_PASS_CODE);
					setWaitTwoFaCheck(false);
				} else {
					console.log('Finished waiting for TwoFaCheck.');
					setWaitTwoFaCheck(false);
				}
			} else if (storedSettings?.rememberCode30days) {
				if (
					storedSettings?.rememberCode30daysEnd <= Math.floor(Date.now() / 1000) &&
					storedSettings?.rememberCode30daysCheckNextLogin
				) {
					setStoredSettings({
						...storedSettings,
						rememberCode30daysCheckNextLogin: false
					});

					setWaitTwoFaCheck(false);

					// If we have a 30 day remember set, we will wait till that expires before we ask for the code again.
					history(URLPaths.PAGES_VERIFY_PASS_CODE);
				} else {
					setStoredSettings({
						...storedSettings,
						rememberCode30daysCheckNextLogin: true
					});
				}
			} else {
				// If we have a 30 day remember set, we will wait till that expires before we ask for the code again.
				history(URLPaths.PAGES_VERIFY_PASS_CODE);
			}
		} else if (isAuthenticated && storedSettings?.twoFactorVerified) {
			setWaitTwoFaCheck(false);
		} else if (isAuthenticated && !companySettings?.twoFactor?.enableTimeForce) {
			// Company not enabled two factor
			setWaitTwoFaCheck(false);
		} else if (noRedirect.includes(location.pathname) || location.pathname === '/login') {
			setWaitTwoFaCheck(false);
		}
	}, [
		isAuthenticated,
		storedSettings?.twoFactorVerified,
		userPref.enableTwoFactor,
		location.pathname,
		companySettings?.twoFactor?.enableTimeForce,
		companySettings?.twoFactor?.enableTime,
		history
	]);

	return waitTwoFaCheck ? <FuseSplashScreen /> : <>{children}</>;
};

export default TwoFaCheck;
