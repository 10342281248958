import {useAuthState} from 'src/app/context/useAuth';
import {useMessage} from 'src/app/context/useMessage';
import {useEffect, useLayoutEffect, useRef, useState} from 'react';
import useWebSocket from 'react-use-websocket';
import {WS_API_URL} from 'src/app/constants';

import FingerprintJS from '@fingerprintjs/fingerprintjs';

import {useSessionStorage} from "react-storage-complete";
import {Base64} from "js-base64";

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

const GlobalWsMessage = () => {
    const {token} = useAuthState();
    const {showMessage} = useMessage();
    // Keep alive every 5 min, timeout is 10min.
    const PingInterval = 300;

    const [isConnectionActive, setIsConnectionActive] = useState(false);
    const shouldConnect = useRef(false);
    const mkId = useRef(null);

    const [storedSettings, setStoredSettings] = useSessionStorage(
        'settings',
        {}, // Default value
        {
            prefix: 'config',
            shouldInitialize: true,
            emitterDisabled: false,
            encode: (value) => {
                try {
                    if (value === undefined || value === null) {
                        return Base64.encode(JSON.stringify({}));
                    }

                    if (typeof value !== 'object') {
                        return Base64.encode(JSON.stringify({value}));
                    }

                    const stringified = JSON.stringify(value);
                    return Base64.encode(stringified);
                } catch (error) {
                    return Base64.encode(JSON.stringify({}));
                }
            },
            decode: (text) => {
                try {
                    if (!text) return {};

                    const decoded = Base64.decode(text);
                    return JSON.parse(decoded);
                } catch (error) {
                    console.error('Error decoding stored settings:', error);
                    return {};
                }
            }
        }
    );


    useLayoutEffect(() => {
        const identifyUser = async () => {
            try {
                console.log('identifyUser called, current storedSettings:', storedSettings);

                if (storedSettings && storedSettings._mkId) {
                    console.log('Existing mkId found:', storedSettings._mkId);
                    mkId.current = storedSettings._mkId;
                } else {
                    console.log('No existing mkId, generating new one');
                    const fp = await fpPromise;
                    const result = await fp.get();
                    console.log('Generated new mkId:', result.visitorId);
                    mkId.current = result.visitorId;
                    setStoredSettings({...storedSettings, _mkId: result.visitorId});
                }

                shouldConnect.current = true;
            } catch (error) {
                console.error('Error in identifyUser:', error);
            }
        };

        identifyUser();
    }, []);

    // useLayoutEffect(() => {
    //     shouldConnect.current = !!token;
    // }, [token]);
    //
    useEffect(() => {
		if (token && mkId.current) {
			setIsConnectionActive(true);
		}
	}, [token, mkId.current]);

    const {sendJsonMessage, lastJsonMessage, readyState, getWebSocket} = useWebSocket(
        WS_API_URL,
        {
            protocols: ['Authorization', `token.${token}`, `mkId.${mkId.current}`],
            share: true,
            retryOnError: true,
            onReconnectStop: () => {
                console.log('onReconnectStop');
            },
            filter: (message) => {
                const messageData = JSON.parse(message.data);

                if (messageData?.messageType === 'broadcast') {
                    return true;
                }
            },

            // Always reconnect
            // shouldReconnect: (_closeEvent) => true
            shouldReconnect: (_closeEvent) => !!token

            // heartbeat: {
            //   message: 'ping',
            //   returnMessage: 'pong',
            //   timeout: 60000, // 1 minute, if no response is received, the connection will be closed
            //   interval: 25000, // every 25 seconds, a ping message will be sent
            // },
        },
        // shouldConnect.current,
        isConnectionActive
    );


    useEffect(() => {
        if (lastJsonMessage !== null) {
            if (lastJsonMessage?.message) {
                showMessage({
                    color: lastJsonMessage?.color ? lastJsonMessage.color : 'primary',
                    anchorOrigin: {
                        vertical: lastJsonMessage?.anchorVertical ? lastJsonMessage.anchorVertical : 'top',
                        horizontal: lastJsonMessage?.anchorHorizontal ? lastJsonMessage.anchorHorizontal : 'center'
                    },
                    message: lastJsonMessage.message,
                    variant: lastJsonMessage?.variant ? lastJsonMessage.variant : 'success'
                });
            }
        }
    }, [lastJsonMessage, showMessage]);

    // Only send 'PING' message when readyState is OPEN
    useEffect(() => {
        if (readyState === 1) {
            const interval = setInterval(() => {
                sendJsonMessage({action: 'ping'});
            }, PingInterval * 1000);

            return () => {
                clearInterval(interval);
            };
        }
    }, [readyState, sendJsonMessage]);
};

export default GlobalWsMessage;
