import { useEffect, useState, useRef } from 'react';
import _ from 'src/lib/@lodash';

import { useTracking } from 'src/lib/@react-use-tracking';
import { useLocation } from 'react-router-dom';

const winLocation = (property, value) => {
	// make sure property is valid
	if (!_.isUndefined(window.location[property])) {
		if (!_.isUndefined(value)) {
			window.location[property] = value;
		} else {
			return window.location[property];
		}
	}
};

// This is the Root tacker. It's needed to add config and identify information to the tracker instance.
// Using this Root tracker enables passing things from the session to the backend tracker application.
const PvTracker = (props) => {
	const location = useLocation();
	const tracking = useTracking();

	const [urlTiming, setUrlTiming] = useState({});
	const trackingLocation = useRef(null);

	// Track base page view for every page.
	useEffect(() => {
		const trackingHref = winLocation('href').split('?')[0];

		if (trackingLocation.current !== trackingHref) {
			tracking.trackEvent({
				event: 'pv'
			});

			const epochStart_ = Math.floor(new Date().getTime() / 1000);
			// Save the start time for each page we visit. When we exit that page
			// we will remove that time from the State.
			setUrlTiming({ ...urlTiming, [trackingHref]: epochStart_ });

			if (trackingLocation.current) {
				const epochStart = urlTiming[trackingLocation.current];
				const epochEnd = Math.floor(new Date().getTime() / 1000);

				tracking.trackEvent({
					event: 'pv_exit',
					p_nav_from: trackingLocation.current,
					p_ttl_time: epochEnd - epochStart
				});
				// Remove the time for this path from the State.
				delete urlTiming[trackingLocation.current];
			}

			trackingLocation.current = trackingHref;
		}
	}, [location]);

	return null;
};

export default PvTracker;
