import MaskedInput from 'react-text-mask';
import PropTypes from 'prop-types';

function CustomTextMask(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			ref={(ref) => {
				inputRef(ref ? ref.inputElement : null);
			}}
			mask={['^[0-9]*$']}
			// placeholderChar={'\u2000'}
			// showMask
		/>
	);
}

CustomTextMask.propTypes = {
	inputRef: PropTypes.func.isRequired
};

export default CustomTextMask;
