import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useFieldArray } from 'react-hook-form';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import Input from 'src/app/components/core/form/Input';

const FileField = (props) => {
	const { className, label, subLabel, name, errors, control } = props;

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	});

	return (
		<div className={clsx(className, 'flex flex-col')}>
			<div className="mt-24">
				<div className="flex flex-col">
					<span className="text-15 col-span-3">Add from file</span>
					<span className="text-13 text-gray-600 col-span-3">
						Add environment variables in bulk by providing an environment file hosted on Amazon S3.
					</span>
				</div>
				{fields.length !== 0 && <div className="mt-16">Location</div>}
			</div>
			<div className="space-y-16 mt-8">
				{fields.map((item, index) => (
					<div
						key={index}
						className="flex flex-row w-10/12 space-x-24"
					>
						<Input
							className=""
							control={control}
							name={`${name}.${index}.location`}
							errors={errors}
							fullWidth
							placeholder="arn:aws:s3::::s3_bucket_name/envfile_object_name.env"
							required
						/>
						<IconButton
							key="close"
							aria-label="Close"
							color="secondary"
							onClick={() => remove(index)}
						>
							<Icon>close</Icon>
						</IconButton>
					</div>
				))}
			</div>

			<div className="flex flex-row justify-between mt-16">
				<Button
					variant="contained"
					onClick={() => {
						append({ key: '', value: '', valueType: 'value' });
					}}
				>
					Add
				</Button>
			</div>
		</div>
	);
};

FileField.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.array,
	errors: PropTypes.object,
	control: PropTypes.object.isRequired
};

export default FileField;
