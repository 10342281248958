import { lazy } from 'react';
import authRoles from 'src/app/auth/authRoles';

const Welcome = lazy(() => import('./index'));

const WelcomeAppRoute = {
	path: '/welcome',
	children: [
		{
			path: '',
			element: <Welcome />
		}
	]
};

export default WelcomeAppRoute;
