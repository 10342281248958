import { lazy } from 'react';

import { SubscriptionPaused, SubscriptionUpdate } from './index';

const SubscriptionUpdateRoutes = {
	path: '/pages/subscription',
	children: [
		{
			path: 'paused',
			element: <SubscriptionPaused />
		},
		{
			path: 'update',
			element: <SubscriptionUpdate />
		}
	],
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	}
};

export default SubscriptionUpdateRoutes;
