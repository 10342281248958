import { lazy } from 'react';

import chatbotDetailConfig from './Detail/chatbotDetailConfig';

import { ChatbotApp, ChatbotCreate, ChatbotList } from './lazyComponents';
// const ChatbotApp = lazy(() => import('./ChatbotApp'));
// const ChatbotCreate = lazy(() => import('../shared-components/ChatbotCreate/index'));
// const ChatbotList = lazy(() => import('./List/index'));

const chatbotConfig = {
	path: '/chatbot',
	element: <ChatbotApp />,
	children: [
		{
			path: 'create',
			element: <ChatbotCreate />
		},
		{
			path: 'list',
			element: <ChatbotList />
		},
		...chatbotDetailConfig.routes
	]
};

export default chatbotConfig;
