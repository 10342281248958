import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useEffect } from 'react';
import CustomSelect from 'src/app/components/core/form/CustomSelect';

const Select = (props) => {
	const {
		handleChange,
		inputId,
		defaultValue,
		className,
		name,
		label,
		disableLabel,
		shrinkLabel,
		options,
		register,
		value,
		isMulti
	} = props;

	const selectValue = (value) => options.filter((option) => option.value === value);

	useEffect(() => {
		register(name);
	}, [name, register]);

	return (
		<div className={clsx(className || '', [name], 'w-full')}>
			<CustomSelect
				options={options}
				name={name}
				inputId={inputId}
				defaultValue={defaultValue}
				label={label}
				value={selectValue(value)}
				handleChange={(e) => handleChange(name, e)}
				disableLabel={disableLabel}
				shrinkLabel={shrinkLabel}
				isMulti={isMulti}
			/>
		</div>
	);
};

Select.propTypes = {
	register: PropTypes.func.isRequired,
	handleChange: PropTypes.func.isRequired,
	inputId: PropTypes.string.isRequired,
	defaultValue: PropTypes.object.isRequired,
	className: PropTypes.string,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	disableLabel: PropTypes.bool,
	shrinkLabel: PropTypes.bool,
	isMulti: PropTypes.bool,
	placeholder: PropTypes.string,
	options: PropTypes.object.isRequired,
	value: PropTypes.object.isRequired
};

export default Select;
