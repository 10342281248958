import { lazy } from 'react';

const Overview = lazy(() => import('./Overview/index'));

const DashboardAppConfig = {
	path: '/dashboards',
	children: [
		{
			path: '',
			element: <Overview />
		},
		{
			path: 'overview',
			element: <Overview />
		}
	]
};

export default DashboardAppConfig;
