import IconButton from '@mui/material/IconButton';
import _ from 'src/lib/@lodash';
import useThemeMediaQuery from 'src/lib/@fuse/hooks/useThemeMediaQuery';
import FuseSvgIcon from 'src/lib/@fuse/core/FuseSvgIcon';
import clsx from 'clsx';
import { useNavBar } from 'src/app/context/useNavbar';
import { useSettings } from 'src/app/context/useSettings';

/**
 * The navbar toggle button.
 */
function NavbarToggleButton(props) {
	const {
		className = '',
		children = (
			<FuseSvgIcon
				size={20}
				color="action"
			>
				heroicons-outline:bars-3
			</FuseSvgIcon>
		),
		...rest
	} = props;

	const { navbarToggleMobile, navbarToggle, navbarToggleFolded } = useNavBar();
	const { settingsCurrent: settings } = useSettings();

	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

	const { config } = settings.layout;
	return (
		<IconButton
			onClick={() => {
				if (isMobile) {
					navbarToggleMobile();
				} else if (config?.navbar?.style === 'style-2') {
					navbarToggleFolded();
				} else {
					navbarToggle();
				}
			}}
			{...rest}
			className={clsx('border border-divider', className)}
		>
			{children}
		</IconButton>
	);
}

export default NavbarToggleButton;
