import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { motion } from 'framer-motion';
import Box from '@mui/material/Box';
import { APP_LOGO_CURRENT, APP_URI } from 'src/app/constants';
import clsx from 'clsx';
import { styled, useTheme } from '@mui/material/styles';

const Img = styled('img')(({ theme }) => ({
	logo: {
		'& .st0': {
			fill: APP_LOGO_CURRENT.brandColorMajor
		},
		'& .st1': {
			fill: APP_LOGO_CURRENT.brandColorMinor
		},
		transition: theme.transitions.create('all', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeOut
		})
	}
}));

/**
 * The powered by links.
 */
function PoweredByLinks() {
	const theme = useTheme();

	const container = {
		show: {
			transition: {
				staggerChildren: 0.04
			}
		}
	};
	const item = {
		hidden: { opacity: 0, scale: 0.6 },
		show: { opacity: 1, scale: 1 }
	};
	return (
		<Box
			component={motion.div}
			variants={container}
			initial="hidden"
			animate="show"
			className="flex items-center rounded-lg overflow-hidden"
			sx={{
				border: (theme) => `1px solid ${theme.palette.divider}!important`
			}}
		>
			<Tooltip
				title={APP_LOGO_CURRENT.brandName}
				placement="top"
			>
				<IconButton
					className="w-48 h-48 p-0"
					component={motion.a}
					variants={item}
					href={APP_URI}
					target="_blank"
					rel="noreferrer noopener"
					role="button"
					// size="large"
				>
					<Img
						className={clsx('w-full h-full')}
						src={theme.palette.mode === 'light' ? APP_LOGO_CURRENT.iconLt : APP_LOGO_CURRENT.iconBk}
						alt={`${APP_LOGO_CURRENT.brandName} Logo`}
					/>
				</IconButton>
			</Tooltip>
		</Box>
	);
}

export default PoweredByLinks;
