import { createContext, useContext, useReducer } from 'react';
// import i18n from 'i18n';
import { useSettings } from 'src/app/context/useSettings';

const i18nContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
function I18nProvide({ children }) {
	const i18nValue = useI18nProvide();
	return <i18nContext.Provider value={i18nValue}>{children}</i18nContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
function useI18n() {
	const context = useContext(i18nContext);

	if (context === undefined) {
		throw new Error(`useI18n must be used within a I18nProvide and SettingsProvider`);
	}

	return context;
}

// Provider hook that creates auth object and handles state

function useI18nProvide() {
	const { setDefaultSettings, settingsDirection } = useSettings();

	const [i18nState, setI18nState] = useReducer((state, newState) => ({ ...state, ...newState }), {
		language: 'en' // i18n.options.lng,
	});

	// Wrap any Firebase methods we want to use making sure ...
	// ... to save the user to state.

	const changeLanguage = (languageId) => {
		const newLangDirection = 'ltr'; // i18n.dir(languageId);

		/*
    Change Language
     */
		// i18n.changeLanguage(languageId);

		/*
    If necessary, change theme direction
     */
		if (newLangDirection !== settingsDirection) {
			setDefaultSettings({ direction: newLangDirection });
		}

		languageChanged(languageId);
	};

	const languageChanged = (language) => {
		setI18nState({ language });
	};

	// Return the user object and auth methods
	return {
		currentLanguageId: i18nState.language,
		changeLanguage
	};
}

export { I18nProvide, useI18nProvide, useI18n };
