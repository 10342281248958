import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const IntegrationsApp = lazy(() => import('./index'));

const integrationsAppConfig = {
	path: '/integrations/config',
	element: <IntegrationsApp />
};

export default integrationsAppConfig;
