// Usage
// import useCookie from 'react-use-cookie';
//
// export default (props) => {
//   const [userToken, setUserToken] = useCookie('token', '0');
//
//   render(
//     <div>
//       <p>{userToken}</p>
//       <button onClick={() => setUserToken('123')}>Change token</button>
//     </div>
//   );
// };

// Get cookie
// import { getCookie } from 'react-use-cookie';
//
// const getUser = () => {
//   const xsrfToken = getCookie('XSRF-TOKEN');
//   // use to call your API etc
// };
// Set cookie
// setUserToken('abcd', {
//   days: 365,
//   SameSite: 'Strict',
//   Secure: true,
// });

// You can also specify cookie options as a third argument:
// {
//   // The number of days the cookie is stored (defaults to 7)
//   days?: number;
//
//   // The path of the cookie (defaults to '/')
//   path?: string;
//
//   // Browser defaults unless set
//   domain?: string;
//   SameSite?: 'None' | 'Lax' | 'Strict';
//   Secure?: boolean;
//   HttpOnly?: boolean;
// }

// https://github.com/tylerwolff/useCookie

import { useState } from 'react';

const isBrowser = typeof window !== 'undefined';

export function stringifyOptions(options) {
	return Object.keys(options).reduce((acc, key) => {
		if (key === 'days') {
			return acc;
		} else if (options[key] === false) {
			return acc;
		} else if (options[key] === true) {
			return `${acc}; ${key}`;
		} else {
			return `${acc}; ${key}=${options[key]}`;
		}
	}, '');
}

export const setCookie = (name, value, options) => {
	if (!isBrowser) return;

	const optionsWithDefaults = {
		days: 7,
		path: '/',
		...options
	};

	const expires = new Date(Date.now() + optionsWithDefaults.days * 864e5).toUTCString();

	document.cookie = `${name}=${encodeURIComponent(value)}; expires=${expires}${stringifyOptions(optionsWithDefaults)}`;
};

export const getCookie = (name, initialValue = '') =>
	(isBrowser &&
		document.cookie.split('; ').reduce((r, v) => {
			const parts = v.split('=');
			return parts[0] === name ? decodeURIComponent(parts[1]) : r;
		}, '')) ||
	initialValue;

const useCookie = (key, initialValue) => {
	const [item, setItem] = useState(() => getCookie(key, initialValue));

	const updateItem = (value, options) => {
		setItem(value);
		setCookie(key, value, options);
	};

	return [item, updateItem];
};
export default useCookie;
