import {
	AgentDetailApp,
	AgentOverview,
	ChatWithBotChat,
	ChatWithBotChatApp,
	ChatWithBotFirstScreen,
	ChatHistoryChat,
	ChatHistoryChatFirstScreen,
	ChatbotChatHistory,
	// ChatbotDetailChatWithBot,
	ChatbotDetailDashboard,
	ChatbotDetailEmbed,
	ChatbotDetailShare,
	ChatbotDetailManageSources,
	ChatbotDetailSettings,
	ChatbotDetailSettingsAppearance,
	ChatbotDetailSettingsCollectUserInfo,
	ChatbotDetailSettingsDetails,
	ChatbotDetailSettingsDomains,
	ChatbotDetailSettingsQuickPrompts,
	ChatbotHumanAgent,
	HumanAgentChatFirstScreen,
	HumanAgentChat
} from './lazyComponents';

const chatbotSettingsConfig = {
	routes: [
		{
			path: '/agent/detail',
			element: <AgentDetailApp />,
			children: [
				{
					path: '',
					element: <AgentOverview />,
					exact: true
				},
				{
					path: 'dashboard',
					element: <ChatbotDetailDashboard />,
					exact: true
				},
				{
					path: 'chat-history',
					element: <ChatbotChatHistory />,
					children: [
						{
							path: '',
							element: <ChatHistoryChatFirstScreen />
						},
						{
							path: ':id',
							element: <ChatHistoryChat />
						},
						{
							path: ':id/agent/:agentId',
							element: <ChatHistoryChat />
						}
					]
				},
				{
					path: 'chat-with-bot',
					element: <ChatWithBotChatApp />,
					children: [
						{
							path: '',
							element: <ChatWithBotFirstScreen />
						},
						{
							path: ':id',
							element: <ChatWithBotChat />
						}
					]
				},
				{
					path: 'embed',
					element: <ChatbotDetailEmbed />
				},
				{
					path: 'human-agent',
					element: <ChatbotHumanAgent />,
					children: [
						{
							path: '',
							element: <HumanAgentChatFirstScreen />
						},
						{
							path: ':id',
							element: <HumanAgentChat />
						},
						{
							path: ':id/agent/:agentId',
							element: <HumanAgentChat />
						}
					]
				},
				{
					path: 'share',
					element: <ChatbotDetailShare />
				},
				{
					path: 'manage-sources',
					element: <ChatbotDetailManageSources />
				},
				{
					path: 'settings',
					element: <ChatbotDetailSettings />
				},

				{
					path: 'settings/appearance',
					element: <ChatbotDetailSettingsAppearance />
				},
				{
					path: 'settings/collect-user-info',
					element: <ChatbotDetailSettingsCollectUserInfo />
				},
				{
					path: 'settings/details',
					element: <ChatbotDetailSettingsDetails />
				},
				{
					path: 'settings/domains',
					element: <ChatbotDetailSettingsDomains />
				},
				{
					path: 'settings/quick-prompts',
					element: <ChatbotDetailSettingsQuickPrompts />
				}
			]
		}
	]
};

export default chatbotSettingsConfig;
