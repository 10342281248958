import { createContext, useContext, useState } from 'react';

import i18next from 'i18next';

import navigationConfig from 'src/lib/configs/navigationConfig';

import FuseUtils from 'src/lib/@fuse/utils';
import FuseNavigationHelper from 'src/lib/@fuse/utils/FuseNavigationHelper';

const navigationContext = createContext();

// Provider component that wraps your app and makes navigation object ...
// ... available to any child component that calls useNavigation().
const NavigationProvide = ({ children }) => {
	const navigation = useNavigationProvide();
	return <navigationContext.Provider value={navigation}>{children}</navigationContext.Provider>;
};

// Hook for child components to get the navigation object ...
// ... and re-render when it changes.
const useNavigation = () => {
	const context = useContext(navigationContext);

	if (context === undefined) {
		throw new Error(`useNavigation must be used within a NavigationProvide`);
	}

	return context;
};

// Provider hook that creates navigation object and handles state

const useNavigationProvide = () => {
	const [navigationState, setNavigationState] = useState(FuseNavigationHelper.flattenNavigation(navigationConfig));

	const selectNavigation = (navigationState, userRole, currentLanguage) => {
		const navigation = FuseNavigationHelper.unflattenNavigation(navigationState);

		function setAdditionalData(data) {
			return data?.map((item) => ({
				hasPermission: Boolean(FuseUtils.hasPermission(item?.auth, userRole)),
				...item,
				...(item?.translate && item?.title ? { title: i18next.t(`navigation:${item?.translate}`) } : {}),
				...(item?.children ? { children: setAdditionalData(item?.children) } : {})
			}));
		}

		const translatedValues = setAdditionalData(navigation);
		return translatedValues;
	};

	const appendNavigationItem = (item, parentId) => {
		setNavigationState((prevState) => FuseNavigationHelper.appendNavItem(prevState, item, parentId));
	};

	const prependNavigationItem = (item, parentId) => {
		setNavigationState((prevState) => FuseNavigationHelper.prependNavItem(prevState, item, parentId));
	};

	const updateNavigationItem = (id, item) => {
		setNavigationState((prevState) => FuseNavigationHelper.updateNavItem(prevState, id, item));
	};

	const removeNavigationItem = (id) => {
		setNavigationState((prevState) => FuseNavigationHelper.removeNavItem(prevState, id));
	};

	const setNavigation = (navigationNew) => {
		setNavigationState((prevState) => ({ ...prevState, ...navigationNew }));
	};

	const resetNavigation = () => {
		setNavigationState(navigationConfig);
	};

	// Return the user object and navigation methods
	return {
		navigationState,
		selectNavigation,
		appendNavigationItem,
		prependNavigationItem,
		removeNavigationItem,
		resetNavigation,
		setNavigation,
		updateNavigationItem
	};
};

export { useNavigationProvide, NavigationProvide, useNavigation };
