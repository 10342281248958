import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import clsx from 'clsx';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';

const CustomRadioGroup = (props) => {
	const { control, classNames, name, inline, labelPlacement, groupInput } = props;

	return (
		<div className={clsx(classNames)}>
			<Controller
				name={name}
				control={control}
				defaultValue=""
				render={({
					field: { onChange, onBlur, value, name, ref },
					fieldState: { invalid, isTouched, isDirty, error },
					formState
				}) => (
					<RadioGroup
						className={clsx('flex', inline && 'flex-row')}
						value={value}
						onBlur={onBlur}
						onChange={(e) => {
							onChange(e);
						}}
					>
						{groupInput &&
							groupInput.map((item, index) => (
								<FormControlLabel
									key={index}
									labelPlacement={labelPlacement}
									value={item.value}
									control={<Radio />}
									label={item.label}
								/>
							))}
					</RadioGroup>
				)}
			/>
		</div>
	);
};

CustomRadioGroup.propTypes = {
	classNames: PropTypes.string,
	inline: PropTypes.bool,
	control: PropTypes.object.isRequired,
	groupInput: PropTypes.array.isRequired,
	name: PropTypes.string.isRequired,
	label: PropTypes.string,
	labelPosition: PropTypes.oneOf(['bottom', 'end', 'start', 'top'])
};

export default CustomRadioGroup;
