// Initialize languages
import './i18n';
import './styles/app-base.css';
import './styles/app-components.css';
import './styles/app-utilities.css';

import './App.css';
import './index.css';

// import 'regenerator-runtime/runtime';

import { createRoot } from 'react-dom/client';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';



import { APP_LOGO_CURRENT } from 'src/app/constants';

import routes from 'src/lib/configs/routesConfig';
// import App from './App';

const container = document.getElementById('root');

if (!container) {
	throw new Error('Failed to find the root element');
}

function getFaviconEl() {
	return document.getElementById('favicon');
}

const favicon = getFaviconEl();

if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
	// In dark mode
	favicon.href = APP_LOGO_CURRENT.iconBk;
} else {
	// In light mode
	favicon.href = APP_LOGO_CURRENT.iconLt;
}



// const logoContainer = document.getElementById('logo-container');
// const logoRoot = createRoot(logoContainer);
//
// logoRoot.render(
//   <img
//     src={APP_LOGO_CURRENT.dk}
//     alt={APP_LOGO_CURRENT.brandName}
//     width="128"
//   />,
// );

/**
 * The root component of the application.
 */
const root = createRoot(container);
const router = createBrowserRouter(routes);

// root.render(<App />);

root.render(<RouterProvider router={router} />);

// reportWebVitals();
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
// serviceWorker.unregister();
