import callApi from 'src/utils/fetchAsyncCalls';
import { API_ROOT } from 'src/app/constants';

export const ntActiveUnacknowledged = (token) => callApi(`${API_ROOT}nt/active/unacknowledged`, { token });

export const ntAcknowledge = ({ token, data }) =>
	callApi(`${API_ROOT}nt/acknowledge`, {
		token,
		data
	});
