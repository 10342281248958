import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import clsx from 'clsx';

import Cards from 'react-credit-cards';
import MaskedInput from 'react-text-mask';
import { NumericFormat } from 'react-number-format';

import Input from 'src/app/components/core/form/Input';

import { useDebounce } from 'src/lib/@_fuse/hooks';

import 'react-credit-cards/es/styles-compiled.css';

function maxValueCCNumber(val, max = '9999999999999999') {
	if (Number(val) > Number(max)) {
		val = val.toString().substring(0, 16);
	}

	return val;
}

function CredCardNumberMask(props) {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumericFormat
			{...other}
			format={maxValueCCNumber}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator=" "
			thousandsGroupStyle="wan"
			isNumericString
			allowNegative={false}
		/>
	);
}

CredCardNumberMask.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};

function CredCardExpirationMask(props) {
	const { inputRef, ...other } = props;

	return (
		<MaskedInput
			{...other}
			// ref={ref => {
			//   inputRef(ref ? ref.inputElement : null);
			// }}
			mask={[/[0-1]/, /[1-9]/, '/', /[2-3]/, /[1-9]/]}
			// placeholderChar={'\u2000'}
			// showMask
		/>
	);
}

CredCardExpirationMask.propTypes = {
	inputRef: PropTypes.func.isRequired
};

function limit(val, max) {
	if (Number(val) > Number(max)) {
		val = val.toString().substring(0, 3);
	}

	return val;
}

function maxValue(val, max = '999') {
	return limit(val, max);
}

function CreditCardCVCMask(props) {
	const { inputRef, onChange, ...other } = props;

	const MAX_LIMIT = 9999;

	return (
		<NumericFormat
			{...other}
			format={maxValue}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			isNumericString
			allowNegative={false}
			isAllowed={(values) => {
				const { floatValue } = values;
				return floatValue < MAX_LIMIT;
			}}
		/>
	);
}

CreditCardCVCMask.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};

const CreditCard = (props) => {
	const { className, watch, control, errors, preview, isSubmitSuccessful, ...other } = props;

	const [cardActiveField, setCardActiveField] = useState('number');

	const cardNumber = watch('cardNumber', '');
	const cardName = watch('cardName', '');
	const cardExpiry = watch('cardExpiry', '');
	const cardCvc = watch('cardCvc', '000');

	const updateOnFocusCard = useDebounce((cardField) => {
		setCardActiveField(cardField);
	}, 100);

	useEffect(() => {
		updateOnFocusCard('number');
	}, [updateOnFocusCard, isSubmitSuccessful]);

	useEffect(() => {
		updateOnFocusCard('cvc');
	}, [updateOnFocusCard, cardCvc]);

	useEffect(() => {
		updateOnFocusCard('expiry');
	}, [updateOnFocusCard, cardExpiry]);

	useEffect(() => {
		updateOnFocusCard('name');
	}, [updateOnFocusCard, cardName]);

	useEffect(() => {
		updateOnFocusCard('number');
	}, [updateOnFocusCard, cardNumber]);

	return (
		<div className={clsx(className, 'flex flex-row')}>
			<div className="">
				<Cards
					{...other}
					number={cardNumber || ''}
					name={cardName || ''}
					expiry={cardExpiry || ''}
					cvc={cardCvc || '000'}
					focused={cardActiveField}
				/>
			</div>
			<div className="flex flex-col flex-grow ml-48">
				<Input
					className=""
					label="Card Number"
					name="cardNumber"
					fieldType="text"
					control={control}
					errors={errors}
					disabled={preview}
					InputProps={{
						inputComponent: CredCardNumberMask
					}}
					required={!preview}
					fullWidth
				/>
				<Input
					className="mt-20"
					label="Name"
					name="cardName"
					fieldType="text"
					control={control}
					errors={errors}
					required
					fullWidth
				/>
				<div className="grid grid-cols-2 gap-x-16 w-full mt-20">
					<div className="flex">
						<Input
							label="Valid Thru"
							name="cardExpiry"
							fieldType="text"
							control={control}
							errors={errors}
							InputProps={{
								inputComponent: CredCardExpirationMask
							}}
							required
							fullWidth
						/>
					</div>
					<div className="flex">
						<Input
							label="CVC"
							name="cardCvc"
							fieldType="text"
							control={control}
							errors={errors}
							disabled={preview}
							InputProps={{
								inputComponent: CreditCardCVCMask
							}}
							fullWidth
							required={!preview}
						/>
					</div>
					<div className="hidden">
						<Input
							name="cardId"
							fieldType="text"
							control={control}
							errors={errors}
						/>
						<Input
							name="cardUpdate"
							fieldType="text"
							control={control}
							errors={errors}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

CreditCard.propTypes = {
	control: PropTypes.object.isRequired,
	errors: PropTypes.object.isRequired,
	preview: PropTypes.bool,
	watch: PropTypes.func.isRequired,
	isSubmitSuccessful: PropTypes.bool.isRequired
};
export default CreditCard;
