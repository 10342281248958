import {useEffect, useState} from 'react';
import {useQuery} from '@tanstack/react-query';
import queryString from 'query-string';
import {useAuthState} from 'src/app/context/useAuth';
import {URLPaths} from 'src/app/constants';
import history from 'src/lib/@history';
import checkHttpStatus from './checkHttpStatus';
import parseJSON from './parseJSON';
import _ from 'src/lib/@lodash';

const isDev = process.env.NODE_ENV !== 'production';

export default function callApi(
    endpoint,
    {data, token, tokenType = 'bearer', headers: customHeaders, strQuery, getStrQuery, ...customConfig} = {}
) {
    let url = `${endpoint}`;
    let authTokenType = '';
    let body;
    let files

    if (token) {
        if (tokenType === 'bearer') {
            authTokenType = `Bearer ${token}`;
        } else if (tokenType === 'basic') {
            authTokenType = `Basic ${token}`;
        }
    }

    // If we have files, we will extract them and send everything else base64 encoded.
    if (data && !strQuery) {
        if (data.files && data.files.length > 0) {
            // Extract files
            files = data.files;
            delete data.files;

            body = { files, _payload: btoa(JSON.stringify(data))}
        } else {
            body = {_payload: btoa(JSON.stringify(data))}
        }
    }

    const config = {
        method: data && !strQuery ? 'POST' : 'GET',
        mode: 'cors',
        // body: data && !strQuery ? JSON.stringify(data) : undefined,
        body: JSON.stringify(body),
        headers: {
            Authorization: token ? authTokenType : undefined,
            'Content-Type': data ? 'application/json' : 'text/plain; charset=UTF-8',
            ...customHeaders
        },
        ...customConfig
    };

    if (!_.isEmpty(strQuery) || !_.isEmpty(getStrQuery)) {
        const queryParams = !_.isEmpty(strQuery) ? data : getStrQuery;
        const queryStr = isDev || !_.isEmpty(strQuery)
            ? queryString.stringify(queryParams)
            : `_gqs=${btoa(queryString.stringify(queryParams))}`;

        url = `${url}?${queryStr}`;
    }

    return fetch(url, config)
        .then(checkHttpStatus)
        .then(parseJSON)
        .then((response) => response)
        .catch((error) => {
            console.log('fetch error', error)
            if (error.response.status === 401) {
                history({
                    pathname: URLPaths.LOGIN_PATH
                });
            }
        });
}

export function callImageApi(
    endpoint,
    {data, token, tokenType = 'bearer', headers: customHeaders, strQuery, getStrQuery, ...customConfig} = {}
) {
    let url = `${endpoint}`;
    let authTokenType = '';

    if (token) {
        if (tokenType === 'bearer') {
            authTokenType = `Bearer ${token}`;
        } else if (tokenType === 'basic') {
            authTokenType = `Basic ${token}`;
        }
    }

    const config = {
        method: data && !strQuery ? 'POST' : 'GET',
        mode: 'cors',
        body: data && !strQuery ? JSON.stringify(data) : undefined,
        headers: {
            Authorization: token ? authTokenType : undefined,
            'Content-Type': data ? 'application/json' : 'text/plain; charset=UTF-8',
            ...customHeaders
        },
        ...customConfig
    };

    // if (data && Boolean(strQuery)) {
    //     url = `${url}?${queryString.stringify(data)}`;
    // }

    if (!_.isEmpty(strQuery) || !_.isEmpty(getStrQuery)) {
        const queryParams = !_.isEmpty(strQuery) ? data : getStrQuery;
        const queryStr = isDev || !_.isEmpty(strQuery)
            ? queryString.stringify(queryParams)
            : `_gqs=${btoa(queryString.stringify(queryParams))}`;

        url = `${url}?${queryStr}`;
    }

    return (
        fetch(url, config)
            .then(checkHttpStatus)
            // .then(parseJSON)
            .then((response) => response)
            .catch((error) => {
                if (error.response.status === 401) {
                    history({
                        pathname: URLPaths.LOGIN_PATH
                    });
                }
            })
    );
}

export function useFetchData(fetchSlug, queryProps = [], api, newQueryConfig = {}, meta = {}) {
    const {token} = useAuthState();
    const [enabledState, setEnabledState] = useState(false);

    const {enabled: newEnabled} = newQueryConfig;

    useEffect(() => {
        if (token !== null) {
            if (newEnabled !== undefined && newEnabled) {
                setEnabledState(true);
            } else {
                setEnabledState(true);
            }
        }
    }, [fetchSlug, newEnabled, setEnabledState, token]);

    const queryConfig = {
        enabled: Boolean(enabledState),
        ...newQueryConfig
    };
    return useQuery({
        queryKey: [fetchSlug, token, ...queryProps],
        queryFn: () => api(token, ...queryProps),
        ...queryConfig,
        meta
    });
}
