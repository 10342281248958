import PropTypes from 'prop-types';

import DenseSwitch from './DenseSwitch';
import IOSSwitch from './IOSSwitch';

const CustomSwitch = (props) => {
	const { variant, isChecked, handleOnChange, ...rest } = props;
	return (
		<div>
			{(() => {
				switch (variant) {
					case 'dense':
						return (
							<DenseSwitch
								{...rest}
								isChecked={isChecked}
								handleOnChange={handleOnChange}
							/>
						);
					case 'ios':
						return (
							<IOSSwitch
								{...rest}
								isChecked={isChecked}
								handleOnChange={handleOnChange}
							/>
						);
					default:
						return (
							<DenseSwitch
								isChecked={isChecked}
								handleOnChange={handleOnChange}
							/>
						);
				}
			})()}
		</div>
	);
};

CustomSwitch.propTypes = {
	variant: PropTypes.oneOf(['dense', 'ios']),
	isChecked: PropTypes.bool.isRequired,
	handleOnChange: PropTypes.func.isRequired
};

export default CustomSwitch;
