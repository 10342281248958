import { useForm } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { CustomSelect } from 'src/app/components/core/form';
import Input from 'src/app/components/core/form/Input';
import CustomRadioGroup from 'src/app/components/core/form/CustomRadioGroup';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import SendIcon from '@mui/icons-material/Send';
import Divider from 'src/app/components/Divider';
import { useMutation } from '@tanstack/react-query';
import { supportFeedback } from 'src/app/containers/products/api';
import { useAuthState } from 'src/app/context/useAuth';
import { useSnackbar } from 'notistack';
import NotificationModel from 'src/app/containers/main/Notifications/models/NotificationModel';
import NotificationTemplate from 'src/app/containers/main/Notifications/NotificationTemplate';

import FileUploadMulti from 'src/app/components/CustomUpload/FileUploadMulti';

import { readFileAsBase64 } from 'src/app/components/CustomUpload/FileUploadMulti/utils';
import { useEffect, useState } from 'react';

const FEEDBACK_TYPE = [
	{ value: 'general', label: 'General feedback' },
	{ value: 'feature', label: 'Feature request' },
	{ value: 'issue', label: 'Report an issue' },
	{ value: 'uiFeedback', label: 'UI Feedback' }
];

const maxDescription = 5000;

const FeedBackForm = (props) => {
	const { closeDialog } = props;

	const { token } = useAuthState();
	const location = useLocation();

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();
	const [messageLength, setMessageLength] = useState(0);

	const { mutate, isPending } = useMutation({
		mutationFn: supportFeedback,
		// When mutate is called:
		onMutate: () => {
			// console.log('newVPC', newVPC);
		},
		onSuccess: () => {
			closeDialog();
			handleNotification();
		},
		onSettled: async () => {
			// console.log("I'm second!");
		}
	});

	const {
		handleSubmit,
		formState: { errors },
		watch,
		control,
		setValue
	} = useForm({
		defaultValues: {
			feedbackType: 'general',
			files: []
		}
	});

	const watchedMessage = watch('message');

	const onSubmit = async (data) => {
		const formattedData = {
			...data,
			locationPath: location.pathname,
			files: await Promise.all(
				data.files.map(async (file) => ({
					name: file.name,
					type: file.type,
					size: file.size,
					lastModified: file.lastModified,
					content: await readFileAsBase64(file)
				}))
			)
		};
		console.log('formattedData', formattedData);
		mutate({ token, data: formattedData });
		// Handle form submission
	};

	useEffect(() => {
		setMessageLength(watchedMessage?.length);
	}, [watchedMessage]);

	const handleNotification = () => {
		const item = NotificationModel({ title: 'Thank you for providing feedback.' });
		enqueueSnackbar(item.title, {
			key: item.id,
			// autoHideDuration: 3000,
			content: (
				<NotificationTemplate
					item={item}
					onClose={() => {
						closeSnackbar(item.id);
					}}
				/>
			)
		});
	};

	return (
		<div className="flex flex-col">
			<div className="flex flex-col p-24">
				<div className="text-16">Thank you for taking the time to provide feedback.</div>
				<div>We'll review your feedback and respond if necessary.</div>
			</div>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className="flex flex-col mt-24 w-full">
					<div className="flex flex-col space-y-20  p-24">
						<div>
							<div className="text-8 text-gray-600">Choose the type of feedback you are submitting.</div>
							<CustomSelect
								className="w-full mt-12"
								name="feedbackType"
								label="Feedback Type"
								control={control}
								options={FEEDBACK_TYPE}
								isLoading={false}
								disableLabel
							/>
						</div>
						<div>
							<Input
								className=""
								label="Enter your message below"
								control={control}
								fieldType="text"
								name="message"
								placeholder="Please describe your feedback or issue here..."
								multiline
								maxLength={10}
								rows={5}
								maxChar={maxDescription}
								errors={errors}
								required
								fullWidth
							/>
							<div className="text-8 mt-8">Max reply chars: {maxDescription - messageLength}</div>
						</div>
						<div>
							<div className="text-14 font-semibold">Are you satisfied with your experience?</div>
							<CustomRadioGroup
								control={control}
								name="satisfied"
								groupInput={[
									{ value: 'yes', label: 'Yes' },
									{ value: 'no', label: 'No' }
								]}
							/>
						</div>
						<div>
							<div className="text-14 font-semibold">File attachment</div>
							<div className="text-8 text-gray-500">
								Attach images to show us what you are referencing with your feedback. Please don't
								attach images with Personal Identifiable Information (PII) information
							</div>
							<FileUploadMulti
								name="files"
								control={control}
								errors={errors}
								setValue={setValue}
								watch={watch}
							/>
						</div>
					</div>
					<Divider className="" />
					<div className="flex items-center justify-between px-16 pb-16">
						<Button onClick={() => closeDialog()}>Cancel</Button>
						<LoadingButton
							loading={isPending}
							loadingPosition="start"
							startIcon={<SendIcon />}
							color="secondary"
							variant="contained"
							type="submit"
						>
							Submit
						</LoadingButton>
					</div>
				</div>
			</form>
		</div>
	);
};

export default FeedBackForm;
