import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const UserSettings = lazy(() => import('./index'));

const userSettingsConfig = {
	path: URLPaths.ADMIN_SETTINGS_USER_PATH,
	children: [
		{
			path: '',
			element: <UserSettings />
		}
	]
};

export default userSettingsConfig;
