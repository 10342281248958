/**
 * Helpers Functions
 */
import { DateTime } from 'luxon';
import accounting from 'accounting';
import Base64 from 'crypto-js/enc-base64';
import Utf8 from 'crypto-js/enc-utf8';
import { twMerge } from 'tailwind-merge';
import clsx from 'clsx';

/**
 * Function to convert hex to rgba
 */
export function hexToRgbA(hex, alpha) {
	let c;

	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('');

		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]];
		}

		c = `0x${c.join('')}`;
		return `rgba(${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(',')},${alpha})`;
	}

	throw new Error('Bad Hex');
}

/**
 * Text Truncate
 */
export function textTruncate(str, length, ending) {
	if (length == null) {
		length = 100;
	}

	if (ending == null) {
		ending = '...';
	}

	if (str.length > length) {
		return str.substring(0, length - ending.length) + ending;
	}

	return str;
}

/**
 * Get Date
 */
export function getTheDate(timestamp, format) {
	const time = timestamp * 1000;
	const formatDate = format || 'MM-DD-YYYY';
	return DateTime.fromSeconds(time).toFormat(formatDate);
}

/**
 * Convert Date To Timestamp
 */
export function convertDateToTimeStamp(date, format) {
	const formatDate = format || 'YYYY-MM-DD';
	return DateTime.fromSeconds(date).toFormat(formatDate);
}

/**
 * Function to return current app layout
 */
export function getAppLayout(url) {
	const location = url.pathname;
	const path = location.split('/');
	return path[1];
}

export function genUniqueUUID() {
	// http://stackoverflow.com/questions/105034/how-to-create-a-guid-uuid-in-javascript
	return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
}

export const chunkArray = (arr, size) =>
	Array.from({ length: Math.ceil(arr.length / size) }, (v, i) => arr.slice(i * size, i * size + size));

export const compareArrays = (a, b) => a.toString() === b.toString();

export const printPageArea = (areaID) => {
	const printContent = document.getElementById(areaID);
	const WinPrint = window.open('', '', 'width=900,height=650');
	WinPrint.document.write(printContent.innerHTML);
	WinPrint.document.close();
	WinPrint.focus();
	WinPrint.print();
	WinPrint.close();
};

/**
 * Function to filter out all "key" items in list
 */
export const filterItemInObject = (filterArray, objArrayInclude, objReturn) =>
	filterArray.filter((item) => !objArrayInclude.includes(item[objReturn])).map((obj) => obj[objReturn]);

export const mapObject = (object, callback) => Object.keys(object).map((key) => callback(key, object[key]));

export const setFormValues = (data, setValue) => {
	// Load up form with data from the server
	if (data) {
		for (const [formKey, formValue] of Object.entries(data)) {
			if (typeof formKey === 'string' || formKey instanceof String) {
				setValue(`${formKey}`, formValue);
			}
		}
	}
};

export const slugify = (string, separator = '-') =>
	string
		.toString()
		.normalize('NFD') // split an accented letter in the base letter and the acent
		.replace(/[\u0300-\u036f]/g, '') // remove all previously split accents
		.toLowerCase()
		.trim()
		.replace(/[^a-z0-9 ]/g, '') // remove all chars not letters, numbers and spaces (to be replaced)
		.replace(/\s+/g, separator);

export const compoundInterest = (principle, rate, time) => {
	const amount = principle * (1 + rate / 100) ** time;
	const ci = amount - principle;

	return {
		amount: accounting.formatMoney(amount, '', 2),
		ci
	};
};

export const utf8Stringify = (payload) => Utf8.parse(JSON.stringify(payload));

export const base64url = (source) => {
	// Encode in classical base64
	let encodedSource = Base64.stringify(source);

	// Remove padding equal characters
	// encodedSource = encodedSource.replace(/=+$/, '');

	// Replace characters according to base64url specifications
	encodedSource = encodedSource.replace(/\+/g, '-');
	encodedSource = encodedSource.replace(/\//g, '_');

	// Return the base64 encoded string
	return encodedSource;
};

/**
 * Function takes a repository path and return a segmented list for the repository path
 */
export const insertSlash = (array_str) => {
	let cnt = 1;
	const repo_arr = array_str.split('/');

	while (cnt < repo_arr.length) {
		repo_arr.splice(cnt, 0, '/');
		cnt += 2;
	}

	return repo_arr;
};

export const isInt = (n) => n % 1 === 0;

export const formatNumber = (statType, stat, precision = 2) => {
	if (isInt(stat) === true) {
		if (statType === 'cpu' || statType === 'memory' || statType === 'disk') {
			if (0 === stat % 1024) {
				// if we have a full vCpu or Memory
				precision = 0;
			} else {
				precision = 2;
			}
		} else {
			precision = 0;
		}
	}

	if (statType === 'money') {
		return accounting.formatMoney(stat, '$', precision);
	}

	if (statType === 'percentage') {
		return `${accounting.formatNumber(stat, precision)} %`;
	}

	if (statType === 'cpu') {
		return `${accounting.formatNumber(stat / 1024, precision)} vCPUs`;
	}

	if (statType === 'memory') {
		return `${accounting.formatNumber(stat / 1024, precision)} GB`;
	}

	if (statType === 'disk') {
		return `${accounting.formatNumber(stat / 1024, precision)} GiB`;
	}
};

/**
 * Function takes a list of Tailwind classes and resolves any tailwind class conflicted
 * with twMerge after all classes have been generated by clsx
 */
export const cn = (...inputs) => twMerge(clsx(inputs));

/**
 * Function takes a list attributes and encodes them into a base64 string to pass to the server.
 */
export const attribsAdd = (attribs) => {
	const stringifiesPayload = utf8Stringify(attribs);
	return base64url(stringifiesPayload);
};

const SYMBOLS = {
	storage: ['B', 'KB', 'MB', 'GB', 'TB'],
	storage_ext: ['byte', 'kilobyte', 'megabyte', 'gigabyte', 'terabyte'],
	customary: ['B', 'K', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'],
	customary_ext: ['byte', 'kilo', 'mega', 'giga', 'tera', 'peta', 'exa', 'zetta', 'yotta'],
	iec: ['B', 'Ki', 'Mi', 'Gi', 'Ti', 'Pi', 'Ei', 'Zi', 'Yi'],
	iec_ext: ['byte', 'kibi', 'mebi', 'gibi', 'tebi', 'pebi', 'exbi', 'zebi', 'yobi']
};

export const bytesToHuman = (n, strFormat = '%(value) %(symbol)s', symbolsType = 'customary') => {
	if (!n) {
		return '-';
	}

	n = Math.abs(Number(n));

	if (Number.isNaN(n)) {
		throw new Error('Input is not a valid number');
	}

	const symbols = SYMBOLS[symbolsType];

	if (!symbols) {
		throw new Error('Invalid symbols type');
	}

	const prefix = {};
	for (let i = 1; i < symbols.length; i++) {
		prefix[symbols[i]] = 2 ** (i * 10);
	}

	for (let i = symbols.length - 1; i > 0; i--) {
		const symbol = symbols[i];

		if (n >= prefix[symbol]) {
			const value = n / prefix[symbol];
			return strFormat.replace('%(value)', value.toFixed(1)).replace('%(symbol)', symbol);
		}
	}

	return strFormat.replace('%(value)', n.toFixed(1)).replace('%(symbol)', symbols[0]);
};

export const deepGet = (dictionary, keys, default_value = null, sep = '.') =>
	/**
	 * Deep get value from a nested object.
	 *
	 * Usage:
	 * const build_guid = deep_get(image_def_data, 'channel_build_images.stable.build_guid');
	 */
	keys.split(sep).reduce((obj, key) => (obj && obj.hasOwnProperty(key) ? obj[key] : default_value), dictionary);

export const deepSet = (dictionary, keys, value, sep = '.') => {
	/**
	 * Deep set value in a nested object.
	 *
	 * Usage:
	 * deep_set(image_def_data, 'channel_build_images.stable.build_guid', 'new_guid');
	 *
	 * This function will create the necessary nested structure if it doesn't exist.
	 */
	const key_array = keys.split(sep);
	const last_key = key_array.pop();
	const target = key_array.reduce((obj, key) => (obj.hasOwnProperty(key) ? obj[key] : (obj[key] = {})), dictionary);
	target[last_key] = value;
};

export const deepSetReturn = (dictionary, keys, value, sep = '.') => {
	/**
	 * Deep set value in a nested object, returning a new object.
	 *
	 * Usage:
	 * const newObj = deepSet(image_def_data, 'channel_build_images.stable.build_guid', 'new_guid');
	 *
	 * This function will create the necessary nested structure if it doesn't exist.
	 */
	const key_array = keys.split(sep);
	const last_key = key_array.pop();

	// Create a deep copy of the original dictionary
	const new_dictionary = JSON.parse(JSON.stringify(dictionary));

	const target = key_array.reduce((obj, key) => {
		if (!obj.hasOwnProperty(key)) {
			obj[key] = {};
		}

		return obj[key];
	}, new_dictionary);

	target[last_key] = value;

	return new_dictionary;
};

// export const mergeDictDeep = (dct, merge_dct) => {
//   /**
//    * Recursive object merge. Inspired by `Object.assign()`, instead of
//    * updating only top-level keys, merge_dict_deep recurses down into objects nested
//    * to an arbitrary depth, updating keys. The `merge_dct` is merged into
//    * `dct`.
//    *
//    * @param {Object} dct - Object onto which the merge is executed
//    * @param {Object} merge_dct - Object merged into dct
//    * @return {void}
//    */
//   for (const [k, v] of Object.entries(merge_dct)) {
//     if (
//       k in dct &&
//       typeof dct[k] === 'object' &&
//       dct[k] !== null &&
//       typeof v === 'object' &&
//       v !== null &&
//       !Array.isArray(v)
//     ) {
//       mergeDictDeep(dct[k], v);
//     }
//     // Uncomment the following block if you want to merge arrays
//     // else if (k in dct && Array.isArray(dct[k]) && Array.isArray(v)) {
//     //     dct[k] = [...dct[k], ...v];
//     // }
//     else {
//       dct[k] = v;
//     }
//   }
// };

export const mergeDictDeep = (dct, merge_dct) => {
	/**
	 * Recursive object merge. Creates a new object with properties from both
	 * `dct` and `merge_dct`. If a key exists in both objects and the value is an object,
	 * it recursively merges those objects.
	 *
	 * @param {Object} dct - First object
	 * @param {Object} merge_dct - Second object to merge into the first
	 * @return {Object} New merged object
	 */
	const result = { ...dct };

	for (const [k, v] of Object.entries(merge_dct)) {
		if (
			k in result &&
			typeof result[k] === 'object' &&
			result[k] !== null &&
			typeof v === 'object' &&
			v !== null &&
			!Array.isArray(v)
		) {
			result[k] = mergeDictDeep(result[k], v);
		} else if (Array.isArray(result[k]) && Array.isArray(v)) {
			result[k] = [...result[k], ...v];
		} else {
			result[k] = v;
		}
	}

	return result;
};
