import { useEffect, useState, forwardRef, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Controller, useWatch } from 'react-hook-form';
import Select from 'react-select';
import { emphasize, styled, useTheme } from '@mui/material/styles';
import Typography from '@mui/material/Typography';
import NoSsr from '@mui/material/NoSsr';
import TextField from '@mui/material/TextField';
import Paper from '@mui/material/Paper';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import CancelIcon from '@mui/icons-material/Cancel';
import FormHelperText from '@mui/material/FormHelperText';

const SelectStyled = styled(Select)(({ theme }) => ({
	'& .Input': {
		display: 'flex',
		padding: 0,
		height: 'auto'
	},
	'& .ValueContainer': {
		display: 'flex',
		flexWrap: 'wrap',
		flex: 1,
		alignItems: 'center',
		overflow: 'hidden',
		padding: '16.5px 14px'
	},
	'& .Chip': {
		margin: theme.spacing(0.5, 0.25)
	},
	'& .ChipFocused': {
		backgroundColor: emphasize(
			theme.palette.mode === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
			0.08
		)
	},
	'& .NoOptionsMessage': {
		padding: theme.spacing(1, 2)
	},
	'& .SingleValue': {
		font: 'inherit'
	},
	'& .Placeholder': {
		position: 'absolute',
		left: 2,
		bottom: 6,
		font: 'inherit'
	},
	'& .Paper': {
		position: 'absolute',
		zIndex: 9,
		marginTop: theme.spacing(1),
		left: 0,
		right: 0
	},
	'& .Divider': {
		height: theme.spacing(2)
	}
}));

function NoOptionsMessage(props) {
	return (
		<Typography
			color="textSecondary"
			className="NoOptionsMessage"
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

NoOptionsMessage.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.node,
	/**
	 * Props to be passed on to the wrapper.
	 */
	innerProps: PropTypes.object.isRequired,
	selectProps: PropTypes.object.isRequired
};

const inputComponent = forwardRef((props, inputRef) => (
	<div
		ref={inputRef}
		{...props}
	/>
));

inputComponent.propTypes = {
	inputRef: PropTypes.oneOfType([
		PropTypes.func,
		PropTypes.shape({
			current: PropTypes.any.isRequired
		})
	])
};

function Control(props) {
	const {
		children,
		innerProps,
		innerRef,
		selectProps: { TextFieldProps }
	} = props;

	return (
		<TextField
			fullWidth
			InputProps={{
				inputComponent,
				inputProps: {
					className: 'Input',
					ref: innerRef,
					children,
					...innerProps
				}
			}}
			{...TextFieldProps}
		/>
	);
}

Control.propTypes = {
	/**
	 * Children to render.
	 */
	children: PropTypes.node,
	/**
	 * The mouse down event and the innerRef to pass down to the controller element.
	 */
	innerProps: PropTypes.shape({
		onMouseDown: PropTypes.func.isRequired
	}).isRequired,
	innerRef: PropTypes.oneOfType([
		PropTypes.oneOf([null]),
		PropTypes.func,
		PropTypes.shape({
			current: PropTypes.any.isRequired
		})
	]).isRequired,
	selectProps: PropTypes.object.isRequired
};

function Option(props) {
	return (
		<MenuItem
			ref={props.innerRef}
			selected={props.isFocused}
			component="div"
			style={{
				fontWeight: props.isSelected ? 500 : 400
			}}
			{...props.innerProps}
		>
			{props.children}
		</MenuItem>
	);
}

Option.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.node,
	/**
	 * props passed to the wrapping element for the group.
	 */
	innerProps: PropTypes.shape({
		id: PropTypes.string.isRequired,
		key: PropTypes.string.isRequired,
		onClick: PropTypes.func.isRequired,
		onMouseMove: PropTypes.func.isRequired,
		onMouseOver: PropTypes.func.isRequired,
		tabIndex: PropTypes.number.isRequired
	}).isRequired,
	/**
	 * Inner ref to DOM Node
	 */
	innerRef: PropTypes.oneOfType([
		PropTypes.oneOf([null]),
		PropTypes.func,
		PropTypes.shape({
			current: PropTypes.any.isRequired
		})
	]).isRequired,
	/**
	 * Whether the option is focused.
	 */
	isFocused: PropTypes.bool.isRequired,
	/**
	 * Whether the option is selected.
	 */
	isSelected: PropTypes.bool.isRequired
};

function Placeholder(props) {
	const { selectProps, innerProps = {}, children } = props;
	return (
		<Typography
			color="textSecondary"
			className="Placeholder"
			{...innerProps}
		>
			{children}
		</Typography>
	);
}

Placeholder.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.node,
	/**
	 * props passed to the wrapping element for the group.
	 */
	innerProps: PropTypes.object,
	selectProps: PropTypes.object.isRequired
};

function SingleValue(props) {
	return (
		<Typography
			className="SingleValue"
			{...props.innerProps}
		>
			{props.children}
		</Typography>
	);
}

SingleValue.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.node,
	/**
	 * Props passed to the wrapping element for the group.
	 */
	innerProps: PropTypes.any.isRequired,
	selectProps: PropTypes.object.isRequired
};

function ValueContainer(props) {
	return <div className="ValueContainer">{props.children}</div>;
}

ValueContainer.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.node,
	selectProps: PropTypes.object.isRequired
};

function MultiValue(props) {
	return (
		<Chip
			tabIndex={-1}
			label={props.children}
			className={clsx('Chip', {
				ChipFocused: props.isFocused
			})}
			onDelete={props.removeProps.onClick}
			deleteIcon={<CancelIcon {...props.removeProps} />}
		/>
	);
}

MultiValue.propTypes = {
	children: PropTypes.node,
	isFocused: PropTypes.bool.isRequired,
	removeProps: PropTypes.shape({
		onClick: PropTypes.func.isRequired,
		onMouseDown: PropTypes.func.isRequired,
		onTouchEnd: PropTypes.func.isRequired
	}).isRequired,
	selectProps: PropTypes.object.isRequired
};

function Menu(props) {
	return (
		<Paper
			square
			className={clsx('Paper', 'z-999')}
			{...props.innerProps}
		>
			{props.children}
		</Paper>
	);
}

Menu.propTypes = {
	/**
	 * The children to be rendered.
	 */
	children: PropTypes.element.isRequired,
	/**
	 * Props to be passed to the menu wrapper.
	 */
	innerProps: PropTypes.object.isRequired,
	selectProps: PropTypes.object.isRequired
};

const components = {
	Control,
	Menu,
	MultiValue,
	NoOptionsMessage,
	Option,
	Placeholder,
	SingleValue,
	ValueContainer
};

const CustomSelect = (props) => {
	const theme = useTheme();

	const currentValue = useRef(null);

	const selectStyles = {
		input: (base) => ({
			...base,
			color: theme.palette.text.primary,
			'& input': {
				font: 'inherit'
			}
		})
	};

	const {
		className,
		name,
		control,
		setValue,
		label,
		shrinkLabel,
		disableLabel,
		options,
		placeholder,
		isLoading,
		isDisabled,
		isMulti,
		isClearable
	} = props;

	const [shrink, setShrink] = useState(false);

	const itemSelected = useWatch({
		control,
		name,
		defaultValue: null
	});

	const menuIsOpen = (status) => {
		if (status === 'open') {
			setShrink(true);
		} else if (status === 'close' && itemSelected === null) {
			setShrink(false);
		}
	};

	useEffect(() => {
		if (shrinkLabel === true) {
			setShrink(true);
		} else if (itemSelected !== null) {
			setShrink(true);
		}
	}, [itemSelected, shrinkLabel]);

	return (
		<div className={clsx('flex-grow', className)}>
			<NoSsr>
				<Controller
					name={name}
					control={control}
					render={({
						field: { onChange, onBlur, value, name, ref, hasValue },
						fieldState: { invalid, isTouched, isDirty, error },
						formState
					}) => {
						// This section is to calculate what we want to send to the display. We only send the
						// value to the backed, so to make sure the display is correct, we dereference it using the
						// options list.

						// eslint-disable-next-line react-hooks/rules-of-hooks
						const [storedFormValue, setStoredFormValue] = useState();

						// eslint-disable-next-line react-hooks/rules-of-hooks
						useEffect(() => {
							// If we are cleaning the value
							if (value) {
								// If we have a default or just a value at all, we want to make sure we shrink the label.
								setShrink(true);

								// If we have a multi or single value, we check if the changed value,
								// is an object or an array
								if (typeof value === 'object' && !Array.isArray(value)) {
									setStoredFormValue(options.find((c) => c.value === value));
								} else if (Array.isArray(value)) {
									const result = value.map((item) => {
										if (item.value) {
											return options.find((c) => c.value === item.value);
										} else {
											return options.find((c) => c.value === item);
										}
									});
									setStoredFormValue(result);
								} else {
									setStoredFormValue(options.find((c) => c.value === value));
								}
							}
						}, [value]);

						return (
							<div>
								<SelectStyled
									// classes={classes}
									inputRef={ref}
									styles={selectStyles}
									inputId={name}
									TextFieldProps={{
										label: label || null,
										InputLabelProps: {
											htmlFor: name,
											shrink,
											disabled: disableLabel || false
										}
									}}
									placeholder={placeholder || null}
									options={options}
									components={components}
									value={storedFormValue}
									onChange={(val) => {
										// If we only want to send back the value of the select
										if (val) {
											// If we have a multi or single value, we check if the changed value,
											// is an object or an array
											if (typeof val === 'object' && !Array.isArray(val)) {
												return onChange(val.value);
											} else {
												const result = val.map((item) => item.value);
												return onChange(result);
											}
										}

										// if (setValue) {
										//   console.log('resting value', name);
										//   setValue(name, '', {
										//     shouldValidate: true,
										//     shouldDirt: true,
										//   });
										// }
										setShrink(false);
										return onChange(null);
									}}
									isLoading={isLoading}
									isDisabled={isDisabled}
									isMulti={isMulti}
									isClearable={isClearable}
									onMenuOpen={() => menuIsOpen('open')}
									onMenuClose={() => menuIsOpen('close')}
								/>
								{error !== undefined ? (
									<FormHelperText
										id={`${name}-text`}
										className="text-red-600"
									>
										{error.type === 'required' ? error.message : null}
									</FormHelperText>
								) : null}
							</div>
						);
					}}
				/>
			</NoSsr>
		</div>
	);
};

CustomSelect.propTypes = {
	className: PropTypes.string,
	control: PropTypes.object.isRequired,
	name: PropTypes.string.isRequired,
	setValue: PropTypes.func,
	label: PropTypes.string,
	disableLabel: PropTypes.bool,
	shrinkLabel: PropTypes.bool,
	placeholder: PropTypes.string,
	options: PropTypes.array.isRequired,
	isClearable: PropTypes.bool,
	isLoading: PropTypes.bool,
	isDisabled: PropTypes.bool,
	isMulti: PropTypes.bool
};

export default CustomSelect;
