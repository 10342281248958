import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';
import authRoles from '../../../auth/authRoles';

const RegisterApp = lazy(() => import('./index'));

const registerConfig = {
	path: URLPaths.REGISTRATION_PATH,
	// element: <RegisterApp/>,
	auth: [...authRoles.onlyGuest, '', ...authRoles.user], // guest only, and forward if logged in
	children: [
		{
			path: '',
			element: <RegisterApp />
		}
	],
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	}
};

export default registerConfig;
