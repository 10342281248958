import {
  useEffect,
  useMemo,
  useRef,
  Fragment,
  useState,
  useCallback,
} from 'react';

import { DateTime } from 'luxon';

import FingerprintJS from '@fingerprintjs/fingerprintjs';

import ArrowBackRoundedIcon from '@mui/icons-material/ArrowBackRounded';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import CheckRoundedIcon from '@mui/icons-material/CheckRounded';
import ClearRoundedIcon from '@mui/icons-material/ClearRounded';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import ThreePRoundedIcon from '@mui/icons-material/ThreePRounded';

import Divider from '@mui/joy/Divider';
import Input from '@mui/joy/Input';
import Typography from '@mui/joy/Typography';

import { Transition } from '@headlessui/react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import Card from '@mui/joy/Card';
import Chip from '@mui/joy/Chip';
import colors from '@mui/joy/colors';
import Stack from '@mui/material/Stack';
import IconButton from '@mui/joy/IconButton';

import { extendTheme } from '@mui/joy/styles';

import useStateReducer from '../../../app/hooks/useStateReducer';

import pickColorBasedOnBgColor from '../../../utils/pickColorBasedOnBgColor';
import clsx from 'clsx';

// import ChatBox from './components/ChatBox';
import useWebSocket from 'react-use-websocket';
import MessagesPaneHeader from '../../../app/widgets/ChatBubble/components/MessagesPaneHeader';
import MessageInput from '../../../app/widgets/ChatBubble/components/MessageInput';
import AvatarWithStatus from '../../../app/widgets/ChatBubble/components/AvatarWithStatus';
import ChatBubble from '../../../app/widgets/ChatBubble/components/ChatBubble';
import generateKey from '../../../utils/generateKey';

export const theme = extendTheme({
  cssVarPrefix: 'databerry-chat-bubble',
  colorSchemes: {
    dark: {
      palette: {
        primary: colors.yellow,
        contrastText: '#FFFFFF',
      },
    },
    light: {
      palette: {
        primary: colors.green,
        contrastText: '#FFFFFF',
      },
    },
  },
});

// FIXME @@LC: Need to add tooltip colors to the template:
// TypeError: can't access property "bg", e.vars.palette.Tooltip is undefined

const defaultChatBubbleConfig = {
  // displayName: 'Agent Smith',
  theme: 'light',
  primaryColor: '#1B9E85',
  buttonColor: '#180F3D',
  visitorChatColor: '#3C102C',
  placeHolderText: 'What can I help you with?',
  // initialMessage: 'Hi, how can I help you?',
  position: 'right',
  // messageTemplates: ["What's the pricing?"],
};

const transitionStyles = {
  entering: { opacity: 0 },
  entered: { opacity: 1 },
  exiting: { opacity: 0 },
  exited: { opacity: 0 },
};

const WS_API_URL = 'wss://ws.synthagents.ai';

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

const chat = {
  id: '1',
  sender: {
    name: 'Melissa Van Der Berg',
    username: '@melissa',
    avatar: '/static/images/avatar/7.jpg',
    online: true,
  },
  messages: [],
};

const App = props => {
  const { initConfig } = props;

  const botInfoObj = document.querySelector(
    'script[data-name="databerry-chat-bubble"]',
  );

  const user = {
    id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
    name: 'Lecole Cole',
    email: 'lecole@company.com',
    status: 'online',
    online: false,
    avatar: 'assets/images/avatars/brian-hughes.jpg',
    about: "Hi there! I'm using SynthAgentChat.",
  };

  const chatWith = {
    agent: 'guestChatWithAgent',
    aig: 'guestChatWithGptAgent',
  };

  let chatKey = '';
  let clientType = 'guest';
  const contactId = '123';
  const paramsAgentId = null; // '568';

  let clientKey = generateKey(8);

  if (paramsAgentId) {
    chatKey = paramsAgentId;
    clientType = 'guest';
    // clientType = 'agent';
  } else {
    chatKey = generateKey(24);
  }

  const shouldConnect = useRef(false);
  const chatId = useRef(chatKey);
  const clientId = useRef(clientKey);
  // const accountId = 'bm7sxr9itszahqtpkg5mrwgdqle65x';
  const closeTimeout = useRef();

  const chatBoxRef = useRef(null);
  const chatRef = useRef(null);
  const [messageText, setMessageText] = useState('');
  const [chatLocalState, setChatLocalState] = useState([]);
  const [chatMessages, setChatMessages] = useState(chat.messages);
  const [textAreaValue, setTextAreaValue] = useState('');
  const [chatWithType, setChatWithType] = useState('guestChatWithGptAgent');
  const [mkId, setMkId] = useState('');
  const [botId, setBotId] = useState('');

  console.log('chatWithType', chatWithType);

  const [state, setState] = useStateReducer({
    isOpen: false,
    agent: undefined,
    config: initConfig || defaultChatBubbleConfig,
    hasOpenOnce: false,
    showInitialMessage: false,
    showHelp: true,
    showCaptureForm: false,
    isCaptureLoading: false,
    visitorEmail: '',
  });

  const { sendJsonMessage, lastJsonMessage, readyState, getWebSocket } =
    useWebSocket(
      WS_API_URL,
      {
        protocols: [
          'Authorization',
          // `token.${token}`,
          `mkId.${mkId}`,
          `botId.${botId}`,
        ],
        share: true,
        retryOnError: true,
        onOpen: () => {
          console.log('onOpen');
          handelJoinRoom(chatId);
          // always clearTimeout if one exists on new connection
          if (closeTimeout.current) clearTimeout(closeTimeout.current);
          closeTimeout.current = setTimeout(() => {
            if (getWebSocket().readyState === 1) {
              getWebSocket().close();
            }
          }, 6 * 1000);
        },
        // onClose: () => {
        //   handelExitRoom(chatId);
        //   console.log('onClose');
        //   if (closeTimeout.current) clearTimeout(closeTimeout.current);
        // },
        // Always reconnect
        shouldReconnect: _closeEvent => {
          return true;
        },
      },
      shouldConnect.current,
    );

  const handleChangeChatWith = agentType => {
    console.log('Update agent type', agentType);
    setChatWithType(chatWith[agentType]);
  };

  useEffect(() => {
    if (chatLocalState) {
      setTimeout(scrollToBottom);
    }
  }, [chatLocalState]);

  useEffect(() => {
    if (chatMessages) {
      setTimeout(scrollToBottom);
    }
  }, [chatMessages]);

  useEffect(() => {
    if (botInfoObj?.id) {
      setBotId(botInfoObj.id);
    }
  }, [botInfoObj?.id]);

  useEffect(() => {
    console.log('lastJsonMessage', lastJsonMessage);
    if (
      lastJsonMessage &&
      lastJsonMessage?.subAction &&
      lastJsonMessage.subAction === 'agentTypeChange'
    ) {
      /*
       * We can change from GPT to Agent chat
       */
      handleChangeChatWith(lastJsonMessage.agentType);
    } else if (
      lastJsonMessage &&
      lastJsonMessage?.action &&
      lastJsonMessage.action === 'gptChatWithGuest'
    ) {
      setChatMessages(chatMessages => [
        ...chatMessages,
        {
          id: 'ca8523d8-faed-45f7-af09-f6bd5c3f3875',
          chatId: '',
          contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
          content: lastJsonMessage?.answer,
          // timestamp: lastJsonMessage?.timestamp,
          // This should come from the message.
          timestamp: DateTime.utc().toSeconds(),
          createdAt: lastJsonMessage?.createdAt,
          sender: {
            name: lastJsonMessage?.agent,
            username: '@melissa',
            avatar: '/static/images/avatar/7.jpg',
            online: false,
          },
        },
      ]);
    } else if (
      lastJsonMessage &&
      lastJsonMessage?.action &&
      lastJsonMessage.action === 'agentChatWithGuest'
    ) {
      setChatMessages(chatMessages => [
        ...chatMessages,
        {
          id: 'ca8523d8-faed-45f7-af09-f6bd5c3f3875',
          chatId: '',
          contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
          content: lastJsonMessage?.answer,
          // timestamp: lastJsonMessage?.timestamp,
          // This should come from the message.
          timestamp: DateTime.utc().toSeconds(),
          // createdAt: DateTime.utc().toSeconds(),
          sender: {
            name: lastJsonMessage?.agent,
            username: '@melissa',
            avatar: '/static/images/avatar/7.jpg',
            online: false,
          },
        },
      ]);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    setChatMessages(chat.messages);
  }, [chat.messages]);

  function scrollToBottom() {
    if (!chatRef.current) {
      return;
    }
    chatRef.current.scrollTo({
      top: chatRef.current.scrollHeight,
      behavior: 'smooth',
    });
  }

  function onInputChange(ev) {
    setMessageText(ev.target.value);
  }

  function onMessageSubmit(ev) {
    ev.preventDefault();
    if (messageText === '') {
      return;
    }
    setChatMessages(chatMessages => [
      ...chatMessages,
      {
        id: '39944b00-1ffe-4ffb-8ca6-13c292812e06',
        chatId: '',
        contactId: '9d3f0e7f-dcbd-4e56-a5e8-87b8154e9edf',
        content: messageText,
        timestamp: DateTime.utc().toSeconds(),
      },
    ]);
  }

  const textColor = useMemo(() => {
    return pickColorBasedOnBgColor(
      state.config.primaryColor || '#ffffff',
      '#ffffff',
      '#000000',
    );
  }, [state.config.primaryColor]);

  const handleClickSendMessage = useCallback((chatWithType, userQuery) => {
    console.log('chatWithType', chatWithType);

    return sendJsonMessage({
      action: chatWithType,
      // action: 'guestChatWithGptAgent',
      // action: 'guestChatWithAgent',
      // accountId: accountId,
      chatId: chatId.current,
      requestKey: 'requestKey',
      query: userQuery,
      // clientId: clientId.current,
    });
  }, []);

  const handelJoinRoom = useCallback(accountId => {
    return sendJsonMessage({
      action: 'chatJoin',
      chatId: chatId.current,
      // accountId: accountId,
      clientType: clientType,
      // clientId: clientId.current,
    });
  }, []);

  const handelExitRoom = useCallback(accountId => {
    return sendJsonMessage({
      action: 'chatExit',
      chatId: chatId.current,
      // accountId: accountId,
      clientType: clientType,
      // clientId: clientId.current,
    });
  }, []);

  useEffect(() => {
    // Calculate visitor id
    const identifyUser = async () => {
      // Get the visitor identifier when you need it.
      const fp = await fpPromise;
      const result = await fp.get();
      // console.log('_mkId', result.visitorId);
      setMkId(result.visitorId);
      shouldConnect.current = true;
      // setStoredSettings({ ...storedSettings, _mkId: result.visitorId });
    };

    identifyUser().catch(console.error);
  }, []);

  return (
    <Box
      sx={{
        // bgcolor: 'yellow',
        overflow: 'visible',
        position: 'fixed',
        height: '360px',
        bottom: '70px',
        zIndex: 9999999999,

        ...(state.config.position === 'left'
          ? {
              left: '180px',
            }
          : {}),
        ...(state.config.position === 'right'
          ? {
              right: '180px',
            }
          : {}),
      }}
    >
      <Transition
        as={Fragment}
        show={state.isOpen}
        enter="transition-opacity duration-600"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-500"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {s => (
          <Card
            ref={chatBoxRef}
            variant="outlined"
            sx={theme => ({
              // bgcolor: 'purple',
              zIndex: 9999,
              position: 'absolute',
              bottom: '80px',
              display: 'flex',
              flexDirection: 'column',
              overflow: 'hidden',
              boxSizing: 'border-box',
              boxShadow: 'md',

              transition: `opacity 150ms ease-in-out`,
              // opacity: 0,
              ...transitionStyles[s],

              ...(state.config.position === 'right'
                ? {
                    transform: `translateX(${-400 + 50}px)`,
                  }
                : {}),

              width: '500px',
              [theme.breakpoints.down('sm')]: {
                width: '100vw',
                maxWidth: '100vw',

                bottom: '-20px',

                ...(state.config.position === 'left'
                  ? {
                      left: '-20px',
                    }
                  : {}),
                ...(state.config.position === 'right'
                  ? {
                      transform: `translateX(0px)`,
                      right: '-20px',
                    }
                  : {}),
              },
            })}
          >
            <Box
              sx={{
                bgcolor: state.config.primaryColor,
                // width: '100%',
                mx: -2,
                mt: -2,
                mb: -2,
                py: 1,
              }}
            >
              <Stack direction="row" alignItems={'center'}>
                <MessagesPaneHeader
                  config={state.config}
                  sender={user}
                  setState={setState}
                />
              </Stack>
            </Box>
            <Divider />
            <Box
              ref={chatRef}
              sx={theme => ({
                // flex: 1,
                // display: 'flex',
                width: '100%',
                position: 'relative',

                // height: 'calc(100vh - 200px)',

                maxHeight: '480px',
                minHeight: '380px',
                overflow: 'auto',

                [theme.breakpoints.down('sm')]: {
                  height: '80vh',
                  maxHeight: '80vh',
                  maxWidth: '100vw',
                },

                '& .message-agent': {
                  backgroundColor: state.config.primaryColor,
                  // borderColor: state.config.primaryColor,
                  color: pickColorBasedOnBgColor(
                    state.config?.primaryColor || '#ffffff',
                    '#ffffff',
                    '#000000',
                  ),
                },
              })}
            >
              <Stack spacing={2} justifyContent="flex-end">
                {chatMessages.map((message, index) => {
                  const isYou = message.sender === 'You';
                  return (
                    <Stack
                      key={index}
                      direction="row"
                      spacing={2}
                      flexDirection={isYou ? 'row-reverse' : 'row'}
                    >
                      {message.sender !== 'You' && (
                        <AvatarWithStatus
                          online={message.sender.online}
                          src={message.sender.avatar}
                        />
                      )}
                      <ChatBubble
                        config={state.config}
                        variant={isYou ? 'sent' : 'received'}
                        {...message}
                      />
                    </Stack>
                  );
                })}
              </Stack>

              {/*<ChatBox*/}
              {/*  // messages={history}*/}
              {/*  // onSubmit={handleChatSubmit}*/}
              {/*  messageTemplates={state.config.messageTemplates}*/}
              {/*  initialMessage={state.config.initialMessage}*/}
              {/*  // renderAfterMessages={Capture}*/}
              {/*  agentIconUrl={state.agent?.iconUrl}*/}
              {/*/>*/}
            </Box>
            <Divider />
            <Box
              sx={{
                width: '100%',
                // mt: 1,
                py: 1,
                // bgcolor: 'green',
              }}
            >
              <MessageInput
                config={state.config}
                textAreaValue={textAreaValue}
                setTextAreaValue={setTextAreaValue}
                onSubmit={() => {
                  const newId = chatMessages.length + 1;
                  const newIdString = newId.toString();
                  handleClickSendMessage(chatWithType, textAreaValue);
                  setChatMessages([
                    ...chatMessages,
                    {
                      id: newIdString,
                      sender: 'You',
                      content: textAreaValue,
                      timestamp: DateTime.now().toUnixInteger(),
                    },
                  ]);
                }}
              />
            </Box>
            <Box>
              <Button onClick={() => handelJoinRoom(chatId)}>Join</Button>
              <Button onClick={() => handleChangeChatWith('agent')}>
                Agent
              </Button>
              <Button onClick={() => handleChangeChatWith('aig')}>AIG</Button>
            </Box>
            <Box
              sx={{
                alignItems: 'self-end',
                justifyContent: 'flex-end',
                display: 'flex',
                width: '100%',
              }}
            >
              Powered by SynthAgents
            </Box>
          </Card>
        )}
      </Transition>
      <Stack
        sx={{
          // bgcolor: 'red',
          overflow: 'visible',
          position: 'fixed',
          height: '60px',
          bottom: '80px',
          zIndex: 9999999999,

          ...(state.config.position === 'left'
            ? {
                left: '20px',
              }
            : {}),
          ...(state.config.position === 'right'
            ? {
                right: '20px',
              }
            : {}),
        }}
      >
        <div>
          <IconButton
            // color={'neutral'}
            variant="solid"
            onClick={() =>
              setState({
                isOpen: !state.isOpen,
                ...(!state.isOpen
                  ? {
                      hasOpenOnce: true,
                    }
                  : {}),
              })
            }
            sx={theme => ({
              backgroundColor: state.config.primaryColor,
              width: '60px',
              height: '60px',
              borderRadius: '100%',
              color: textColor,
              transition: 'all 100ms ease-in-out',

              '&:hover': {
                backgroundColor: state.config.primaryColor,
                filter: 'brightness(0.9)',
                transform: 'scale(1.05)',
              },
            })}
          >
            {state.isOpen ? <ClearRoundedIcon /> : <AutoAwesomeIcon />}
          </IconButton>
        </div>
      </Stack>
    </Box>
  );
};

export default App;
