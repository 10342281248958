import { lazy, memo, Suspense } from 'react';
import { useAuthState } from 'src/app/context/useAuth';

// const QuickPanel = lazy(() =>
//   import('src/lib/theme-layouts/shared-components/quickPanel/QuickPanel'),
// );
const MessengerPanel = lazy(() => import('src/app/containers/main/Messenger/messengerPanel/MessengerPanel'));
const NotificationPanel = lazy(() => import('src/app/containers/main/Notifications/NotificationPanel'));

/**
 * The right side layout 1.
 */
function RightSideLayout1() {
	const { role } = useAuthState();
	return (
		<Suspense>
			{/* <QuickPanel /> */}

			{role && (role === 'analyst' || role === 'admin') && <MessengerPanel />}

			<NotificationPanel />
		</Suspense>
	);
}

export default memo(RightSideLayout1);
