import FuseScrollbars from 'src/lib/@_fuse/core/FuseScrollbars';
import { lighten, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useContext, useEffect, useRef, useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from 'src/lib/@_fuse/core/FuseSvgIcon';
import Toolbar from '@mui/material/Toolbar';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
// import { getChat, selectChat, sendMessage } from '../store/chatSlice';
// import { selectContactById } from '../store/contactsSlice';
// import { selectUser } from '../store/userSlice';
import ContactAvatar from '../ContactAvatar';
import ChatMoreMenu from './ChatMoreMenu';
import { ChatAppContext } from '../ChatApp';

const StyledMessageRow = styled('div')(({ theme }) => ({
	'&.contact': {
		'& .bubble': {
			backgroundColor: theme.palette.secondary.light,
			color: theme.palette.secondary.contrastText,
			borderTopLeftRadius: 5,
			borderBottomLeftRadius: 5,
			borderTopRightRadius: 20,
			borderBottomRightRadius: 20,
			'& .time': {
				marginLeft: 12
			}
		},
		'&.first-of-group': {
			'& .bubble': {
				borderTopLeftRadius: 20
			}
		},
		'&.last-of-group': {
			'& .bubble': {
				borderBottomLeftRadius: 20
			}
		}
	},
	'&.me': {
		paddingLeft: 40,

		'& .bubble': {
			marginLeft: 'auto',
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.primary.contrastText,
			borderTopLeftRadius: 20,
			borderBottomLeftRadius: 20,
			borderTopRightRadius: 5,
			borderBottomRightRadius: 5,
			'& .time': {
				justifyContent: 'flex-end',
				right: 0,
				marginRight: 12
			}
		},
		'&.first-of-group': {
			'& .bubble': {
				borderTopRightRadius: 20
			}
		},

		'&.last-of-group': {
			'& .bubble': {
				borderBottomRightRadius: 20
			}
		}
	},
	'&.contact + .me, &.me + .contact': {
		paddingTop: 20,
		marginTop: 20
	},
	'&.first-of-group': {
		'& .bubble': {
			borderTopLeftRadius: 20,
			paddingTop: 13
		}
	},
	'&.last-of-group': {
		'& .bubble': {
			borderBottomLeftRadius: 20,
			paddingBottom: 13,
			'& .time': {
				display: 'flex'
			}
		}
	}
}));

function Chat(props) {
	const { setMainSidebarOpen, setContactSidebarOpen } = useContext(ChatAppContext);
	// const dispatch = useDispatch();
	// const chat = useSelector(selectChat);
	// const user = useSelector(selectUser);
	// const chat = [];
	const user = {};

	const chat = [
		{
			id: 'e6b2b82f-b199-4a60-9696-5f3e40d2715d',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: 'Hi!',
			createdAt: '2022-01-05T15:56:48.732Z'
		},
		{
			id: 'eb82cf4b-fa93-4bf4-a88a-99e987ddb7ea',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: 'Hey, dude!',
			createdAt: '2022-01-05T16:04:48.732Z'
		},
		{
			id: '3cf9b2a6-ae54-47db-97b2-ee139a8f84e5',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: 'Long time no see.',
			createdAt: '2022-01-05T16:04:48.732Z'
		},
		{
			id: '2ab91b0f-fafb-45f3-88df-7efaff29134b',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: 'Yeah, man... Things were quite busy for me and my family.',
			createdAt: '2022-01-05T16:06:48.732Z'
		},
		{
			id: '10e81481-378f-49ac-b06b-7c59dcc639ae',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: "What's up? Anything I can help with?",
			createdAt: '2022-01-05T16:06:48.732Z'
		},
		{
			id: '3b334e72-6605-4ebd-a4f6-3850067048de',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: "We've been on the move, changed 3 places over 4 months",
			createdAt: '2022-01-05T16:07:48.732Z'
		},
		{
			id: '25998113-3a96-4dd0-a7b9-4d2bb58db3f3',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: "Wow! That's crazy! 🤯 What happened?",
			createdAt: '2022-01-05T16:07:48.732Z'
		},
		{
			id: '30adb3da-0e4f-487e-aec2-6d9f31e097f6',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: 'You know I got a job in that big software company. First move was because of that.',
			createdAt: '2022-01-05T16:08:48.732Z'
		},
		{
			id: 'c0d6fd6e-d294-4845-8751-e84b8f2c4d3b',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: 'Then they decided to re-locate me after a month',
			createdAt: '2022-01-05T16:08:48.732Z'
		},
		{
			id: '8d3c442b-62fa-496f-bffa-210ff5c1866b',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: 'Which was an absolute pain because we just set up everything, house, kids school and all that.',
			createdAt: '2022-01-05T16:08:48.732Z'
		},
		{
			id: '3cf26ef0-e81f-4698-ac39-487454413332',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: 'So we moved the second time.',
			createdAt: '2022-01-05T16:09:48.732Z'
		},
		{
			id: '415151b9-9ee9-40a4-a4ad-2d88146bc71b',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: "It's crazy!",
			createdAt: '2022-01-05T16:09:48.732Z'
		},
		{
			id: '3a2d3a0e-839b-46e7-86ae-ca0826ecda7c',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: 'Then this virus thing happened and just after a week we moved in, they decided the whole department will be working remotely.',
			createdAt: '2022-01-05T16:10:48.732Z'
		},
		{
			id: '5329c20d-6754-47ec-af8c-660c72be3528',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: "And then we decided to move back our first location because, you know, everything was already setup so that's the third time.",
			createdAt: '2022-01-05T16:10:48.732Z'
		},
		{
			id: '415151b9-9ee9-40a4-a4ad-2d88146bc71b',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: "Ohh dude, I'm really sorry you had to go through all that in such a short period of time",
			createdAt: '2022-01-05T16:11:48.732Z'
		},
		{
			id: 'ea7662d5-7b72-4c19-ad6c-f80320541001',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: '😕',
			createdAt: '2022-01-05T16:11:48.732Z'
		},
		{
			id: '3a2d3a0e-839b-46e7-86ae-ca0826ecda7c',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: 'Thanks, man! It was good catching up with you.',
			createdAt: '2022-01-05T16:11:48.732Z'
		},
		{
			id: '5329c20d-6754-47ec-af8c-660c72be3528',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: 'Yeah dude. Hit me again next week so we can grab a coffee, remotely!',
			createdAt: '2022-01-05T16:12:48.732Z'
		},
		{
			id: '5329c20d-6754-47ec-af8c-660c72be3528',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: ':) Sure, man! See you next week!',
			createdAt: '2022-01-05T16:12:48.732Z'
		},
		{
			id: '5329c20d-6754-47ec-af8c-660c72be3528',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: 'See you later!',
			createdAt: '2022-01-05T16:12:48.732Z'
		},
		{
			id: 'bab8ca0e-b8e5-4375-807b-1c91fca25a5d',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: 'Hey! Are you available right now? How about if we grab that coffee today? Remotely, of course :)',
			createdAt: '2022-01-12T09:45:48.732Z'
		},
		{
			id: '8445a84d-599d-4e2d-a31c-5f4f29ad2b4c',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: 'Hi!',
			createdAt: '2022-01-12T09:56:48.732Z'
		},
		{
			id: '9f506742-50da-4350-af9d-61e53392fa08',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: "Sure thing! I'm gonna call you in 5, is it okay?",
			createdAt: '2022-01-12T09:56:48.732Z'
		},
		{
			id: 'ca8523d8-faed-45f7-af09-f6bd5c3f3875',
			chatId: '',
			contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
			value: 'Awesome! Call me in 5 minutes..',
			createdAt: '2022-01-12T09:58:48.732Z'
		},
		{
			id: '39944b00-1ffe-4ffb-8ca6-13c292812e06',
			chatId: '',
			contactId: 'abd9e78b-9e96-428f-b3ff-4d934c401bee',
			value: '👍🏻',
			createdAt: '2022-01-12T10:00:48.732Z'
		}
	];

	const routeParams = useParams();
	// const contactId = routeParams.id;
	// const selectedContact = useSelector(state =>
	//   selectContactById(state, contactId),
	// );
	const chatRef = useRef(null);
	const [messageText, setMessageText] = useState('');

	// useEffect(() => {
	//   dispatch(getChat(contactId));
	// }, [contactId, dispatch]);

	useEffect(() => {
		if (chat) {
			setTimeout(scrollToBottom);
		}
	}, [chat]);

	function scrollToBottom() {
		if (!chatRef.current) {
			return;
		}

		chatRef.current.scrollTo({
			top: chatRef.current.scrollHeight,
			behavior: 'smooth'
		});
	}

	function isFirstMessageOfGroup(item, i) {
		return i === 0 || (chat[i - 1] && chat[i - 1].contactId !== item.contactId);
	}

	function isLastMessageOfGroup(item, i) {
		return i === chat.length - 1 || (chat[i + 1] && chat[i + 1].contactId !== item.contactId);
	}

	function onInputChange(ev) {
		setMessageText(ev.target.value);
	}

	function onMessageSubmit(ev) {
		ev.preventDefault();

		if (messageText === '') {
			return;
		}

		// dispatch(
		//   sendMessage({
		//     messageText,
		//     contactId,
		//   }),
		// ).then(() => {
		//   setMessageText('');
		// });
	}

	// if (!user || !chat || !selectedContact) {
	//   return null;
	// }

	return (
		<>
			<Box
				className="w-full border-b-1"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === 'light'
							? lighten(theme.palette.background.default, 0.4)
							: lighten(theme.palette.background.default, 0.02)
				}}
			>
				<Toolbar className="flex items-center justify-between px-16 w-full">
					<div className="flex items-center">
						<IconButton
							aria-label="Open drawer"
							onClick={() => setMainSidebarOpen(true)}
							className="flex lg:hidden"
							size="large"
						>
							<FuseSvgIcon>heroicons-outline:chat</FuseSvgIcon>
						</IconButton>
						<div
							className="flex items-center cursor-pointer"
							onClick={() => {
								setContactSidebarOpen(true);
							}}
							onKeyDown={() => setContactSidebarOpen(true)}
							role="button"
							tabIndex={0}
						>
							<ContactAvatar
								className="relative mx-8"
								// contact={selectedContact}
							/>
							<Typography
								color="inherit"
								className="text-16 font-semibold px-4"
							>
								{/* {selectedContact.name} */}
							</Typography>
						</div>
					</div>
					<ChatMoreMenu className="-mx-8" />
				</Toolbar>
			</Box>

			<div className="flex flex-auto h-full min-h-0 w-full">
				<div className={clsx('flex flex-1 z-10 flex-col relative', props.className)}>
					<FuseScrollbars
						ref={chatRef}
						className="flex flex-1 flex-col overflow-y-auto"
					>
						{chat?.length > 0 && (
							<div className="flex flex-col pt-16 px-16 pb-40">
								{chat.map((item, i) => (
									<StyledMessageRow
										key={i}
										className={clsx(
											'flex flex-col grow-0 shrink-0 items-start justify-end relative px-16 pb-4',
											item.contactId === user.id ? 'me' : 'contact',
											{ 'first-of-group': isFirstMessageOfGroup(item, i) },
											{ 'last-of-group': isLastMessageOfGroup(item, i) },
											i + 1 === chat.length && 'pb-96'
										)}
									>
										<div className="bubble flex relative items-center justify-center p-12 max-w-full">
											<div className="leading-tight whitespace-pre-wrap">{item.value}</div>
											<Typography
												className="time absolute hidden w-full text-11 mt-8 -mb-24 ltr:left-0 rtl:right-0 bottom-0 whitespace-nowrap"
												color="text.secondary"
											>
												{formatDistanceToNow(new Date(item.createdAt), {
													addSuffix: true
												})}
											</Typography>
										</div>
									</StyledMessageRow>
								))}
							</div>
						)}
					</FuseScrollbars>
					{chat && (
						<Paper
							square
							component="form"
							onSubmit={onMessageSubmit}
							className="absolute border-t-1 bottom-0 right-0 left-0 py-16 px-16"
							sx={{
								backgroundColor: (theme) =>
									theme.palette.mode === 'light'
										? lighten(theme.palette.background.default, 0.4)
										: lighten(theme.palette.background.default, 0.02)
							}}
						>
							<div className="flex items-center relative">
								<IconButton
									className=""
									type="submit"
									size="large"
								>
									<FuseSvgIcon
										className="text-24"
										color="action"
									>
										heroicons-outline:face-smile
									</FuseSvgIcon>
								</IconButton>

								<IconButton
									className=""
									type="submit"
									size="large"
								>
									<FuseSvgIcon
										className="text-24"
										color="action"
									>
										heroicons-outline:paper-clip
									</FuseSvgIcon>
								</IconButton>

								<InputBase
									autoFocus={false}
									id="message-input"
									className="flex-1 flex grow shrink-0 h-44 mx-8 px-16 border-2 rounded-full"
									placeholder="Type your message"
									onChange={onInputChange}
									value={messageText}
									sx={{ backgroundColor: 'background.paper' }}
								/>
								<IconButton
									className=""
									type="submit"
									size="large"
								>
									<FuseSvgIcon
										// className="rotate-90"
										color="action"
									>
										heroicons-outline:paper-airplane
									</FuseSvgIcon>
								</IconButton>
							</div>
						</Paper>
					)}
				</div>
			</div>
		</>
	);
}

export default Chat;
