import { useEffect, createContext, useContext, useReducer } from 'react';
import _ from 'src/lib/@lodash';

import { useSettings, useSettingsUserUpdate } from 'src/app/context/useSettings';

const navBarContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
const NavBarProvide = ({ children }) => {
	const navBarValue = useNavBarProvide();
	return <navBarContext.Provider value={navBarValue}>{children}</navBarContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
const useNavBar = () => {
	const context = useContext(navBarContext);

	if (context === undefined) {
		throw new Error(`useNavBar must be used within a NavBarProvide`);
	}

	return context;
};

// Provider hook that creates auth object and handles state

const useNavBarProvide = () => {
	const { userPref, settingsCurrent, setDefaultSettings } = useSettings();

	const { isLoading, setIsSubmitting, userUpdate } = useSettingsUserUpdate();

	const navbar = settingsCurrent?.layout?.config?.navbar;

	const [navBarState, setNavBarState] = useReducer((state, newState) => ({ ...state, ...newState }), { ...navbar });

	useEffect(() => {
		setNavBarState(navbar);
	}, [navbar]);

	// Wrap any Firebase methods we want to use making sure ...
	// ... to save the user to state.

	const navbarOpenFolded = () => {
		setNavBarState({ foldedOpen: true });

		userUpdate({
			updateType: 'settings',
			data: _.set({}, 'layout.config.navbar.foldedOpen', true)
		});
	};
	const navbarCloseFolded = () => {
		setNavBarState({ foldedOpen: false });

		userUpdate({
			updateType: 'settings',
			data: _.set({}, 'layout.config.navbar.foldedOpen', false)
		});
	};
	const navbarOpenFoldedLocal = () => {
		setNavBarState({ foldedOpen: true });
	};
	const navbarCloseFoldedLocal = () => {
		setNavBarState({ foldedOpen: false });
	};

	const navbarToggleFoldedOpen = () => {
		setNavBarState({ foldedOpen: !navBarState.foldedOpen });

		userUpdate({
			updateType: 'settings',
			data: _.set({}, 'layout.config.navbar.foldedOpen', !navBarState.foldedOpen)
		});
	};
	const navbarToggleFolded = () => {
		console.log('navbarToggleFolded');
		setNavBarState({ folded: !navBarState.folded });

		setDefaultSettings(_.set({}, 'layout.config.navbar.folded', !settingsCurrent?.layout?.config?.navbar?.folded));

		userUpdate({
			updateType: 'settings',
			data: _.set({}, 'layout.config.navbar.folded', !navBarState.folded)
		});
	};

	const navbarOpenMobile = () => {
		setNavBarState({ mobileOpen: true });

		userUpdate({
			updateType: 'settings',
			data: _.set({}, 'layout.config.navbar.mobileOpen', true)
		});
	};
	const navbarCloseMobile = () => {
		setNavBarState({ mobileOpen: false });
		userUpdate({
			updateType: 'settings',
			data: _.set({}, 'layout.config.navbar.mobileOpen', false)
		});
	};
	const navbarToggleMobile = () => {
		setNavBarState({ mobileOpen: !navBarState.mobileOpen });

		userUpdate({
			updateType: 'settings',
			data: _.set({}, 'layout.config.navbar.mobileOpen', !navBarState.mobileOpen)
		});
	};

	const navbarOpen = () => {
		setNavBarState({ open: true });
		userUpdate({
			updateType: 'settings',
			data: _.set({}, 'layout.config.navbar.open', true)
		});
	};
	const navbarClose = () => {
		setNavBarState({ open: false });
		userUpdate({
			updateType: 'settings',
			data: _.set({}, 'layout.config.navbar.open', false)
		});
	};
	const navbarToggle = () => {
		setNavBarState({ open: !navBarState.open });
		userUpdate({
			updateType: 'settings',
			data: _.set({}, 'layout.config.navbar.open', !navBarState.open)
		});
	};
	// Return the user object and auth methods
	return {
		navBarState,
		navbarOpenFoldedLocal,
		navbarCloseFoldedLocal,
		navbarOpenFolded,
		navbarCloseFolded,
		navbarToggleFolded,
		navbarToggleFoldedOpen,
		navbarOpenMobile,
		navbarCloseMobile,
		navbarToggleMobile,
		navbarClose,
		navbarOpen,
		navbarToggle
	};
};

export { NavBarProvide, useNavBar, useNavBarProvide };
