import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const RowDataItem = (props) => {
	const { title, value, className } = props;
	return (
		<div className={clsx(className, 'py-10 sm:py-12 sm:grid sm:grid-cols-3 sm:gap-4')}>
			<dt className="text-sm font-medium text-gray-500">{title}</dt>
			<dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
				{value ? <>{value}</> : <div> - </div>}
			</dd>
		</div>
	);
};

RowDataItem.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
};

export default memo(RowDataItem);
