// export default function dispatchTrackingEvent(data) {
//   if (Object.keys(data).length > 0) {
//     (window.dataLayer = window.dataLayer || []).push(data);
//   }
// }

// export default function dispatchTrackingEvent(data) {
//   if (Object.keys(data).length > 0) {
//     (window.dataLayer = window.dataLayer || []).push(data);
//   }
// }

// https://github.com/nytimes/react-tracking

// import js_md5 from 'js-md5';
import { callApiPostNonAuth } from 'src/utils/fetchCalls';
import { API_TRACKER, API_TRACKER_IDENTIFY, APP_ACTIVE, APP_DOMAIN } from 'src/app/constants';

export default function dispatchTrackingEvent(data) {
	const URL_STRING = window.location.href.split('?')[0];
	// const hash = js_md5.create();
	// hash.update(USER);

	const eventData = {};
	const baseData = {
		project: APP_DOMAIN,
		// alias: alias,
		// account: TOKEN,
		// cookie: hash.hex(),
		app: 'reactjs',
		active_app: APP_ACTIVE,
		// domain: APP_DOMAIN,
		referer: document.referrer,
		language: window.navigator.browserLanguage || window.navigator.language || '',
		screen: `${window.screen.width}x${window.screen.height}`,
		// epoch: Math.floor(new Date().getTime() / 1000),
		// ip: '4.15.127.43',
		url: URL_STRING
		// user_agent:
		//   'Mozilla/5.0 (iPad; CPU OS 10_2 like Mac OS X) AppleWebKit/602.3.12 (KHTML like Gecko) Version/10.0 Mobile/14C92 Safari/602.1',
	};

	// // Identify only in prod
	// if (process.env.NODE_ENV !== 'development') {
	//   dispatchTrackingIdentify();
	// }

	if (data.hasOwnProperty('config')) {
		Object.keys(data.config).forEach((key) => {
			eventData[key] = data.config[key];
		});
		delete data.config;
	}

	if (data.hasOwnProperty('identity')) {
		Object.keys(data.identity).forEach((key) => {
			eventData[`cv_${key}`] = data.identity[key];
		});
		delete data.identity;
	}

	if (data.hasOwnProperty('event')) {
		if (data.event === 'pv') {
			const stData = {
				page: data.page,
				url: window.location,
				epoch_start: Math.floor(new Date().getTime() / 1000)
			};

			if (Object.keys(stData).length > 0) {
				(window.dataLayerTiming = window.dataLayerTiming || []).push(stData);
			}
		}
	}

	// Track user time within a page
	// if (data.hasOwnProperty('event')) {
	//   if (data.event === 'pv_exit') {
	//     const retrievedPageTiming = window.dataLayerTiming[0];
	//     if (
	//       retrievedPageTiming !== null &&
	//       data.page === retrievedPageTiming.page
	//     ) {
	//       const epochEnd = Math.floor(new Date().getTime() / 1000);
	//       eventData.ce_page_total_time =
	//         epochEnd - retrievedPageTiming.epoch_start;
	//       // Remove all pages if we are on the right page.
	//       window.dataLayerTiming = [];
	//     } else {
	//       // Remove all pages.
	//       window.dataLayerTiming = [];
	//     }
	//   }
	// }

	if (data.hasOwnProperty('event')) {
		eventData.ce_name = data.event;
		eventData.evtype = 'event';
	}

	if (data.hasOwnProperty('action')) {
		eventData.ce_name = data.action;
		eventData.evtype = 'action';
	}

	Object.keys(data).forEach((key) => {
		if (key !== 'event' && key !== 'action') {
			eventData[`ce_${key}`] = data[key];
		}
	});

	Object.keys(baseData).forEach((key) => {
		eventData[key] = baseData[key];
	});

	// if (Object.keys(data).length > 0) {
	//   (window.dataLayer = window.dataLayer || []).push(data);
	// }

	// Track events only in prod else console log them out.
	if (process.env.NODE_ENV !== 'development') {
		callApiPostNonAuth(API_TRACKER, eventData);
	} else {
		// callApiPostNonAuth(API_TRACKER, eventData);
		console.log('Track event', eventData);
	}
}

function dispatchTrackingIdentify() {
	const URL_STRING = window.location.href.split('?')[0];
	// const hash = js_md5.create();
	// hash.update('lecole@skydera.com');

	const baseIdentifyData = {
		project: APP_DOMAIN,
		// account: TOKEN,
		// cookie: hash.hex(),
		app: 'reactjs',
		epoch: Math.floor(new Date().getTime() / 1000)
		// cv_name: 'Lecole Cole',
		// cv_company_name: 'Chartleaf',
		// cv_email: 'lecole@gmail.com',
		// cv_admin: false,
		// cv_office: 'Palo Alto',
		// ce_url: URL_STRING,
		// ip: '4.15.127.43',
		// user_agent:
		//   'Mozilla/5.0 (iPad; CPU OS 10_2 like Mac OS X) AppleWebKit/602.3.12 (KHTML like Gecko) Version/10.0 Mobile/14C92 Safari/602.1',
	};

	// Track events only in prod else console log them out.
	if (process.env.NODE_ENV !== 'development') {
		callApiPostNonAuth(API_TRACKER_IDENTIFY, baseIdentifyData);
	} else {
		console.log('Track identity', baseIdentifyData);
	}
}
