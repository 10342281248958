import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

import { useWatch } from 'react-hook-form';
import { CPU_OPTIONS, FARGATE_OPTIONS } from 'src/app/constants';
import CustomSelect from 'src/app/components/core/form/CustomSelect';

const SelectCpuMemory = (props) => {
	const { className, control, arrayName, index, setValue, reset } = props;
	const [fargateMemory, setFargateMemory] = useState([]);

	let name_base = '';

	if (arrayName && index) {
		name_base = `${arrayName}.${index}.`;
	}

	const cpuSelected = useWatch({
		control,
		name: `${name_base}cpu`,
		defaultValue: ''
	});

	const memorySelected = useWatch({
		control,
		name: `${name_base}memory`,
		defaultValue: ''
	});

	console.log('name_base', name_base);
	console.log('cpuSelected memorySelected', cpuSelected, memorySelected);

	useEffect(() => {
		let newFargateMemoryOptions = [];

		console.log('cpuSelected', cpuSelected);

		if (cpuSelected) {
			newFargateMemoryOptions = FARGATE_OPTIONS[cpuSelected];

			setFargateMemory(newFargateMemoryOptions);
		}

		if (
			cpuSelected &&
			FARGATE_OPTIONS[cpuSelected] &&
			FARGATE_OPTIONS[cpuSelected].filter((e) => e.value === memorySelected).length === 0
		) {
			console.log('set mem to null: cpuSelected', cpuSelected);
			console.log(
				'memorySelected)',
				FARGATE_OPTIONS[cpuSelected].filter((e) => e.value === memorySelected)
			);
			// reset({ memory: '' });
			setValue(`${name_base}memory`, null);
		}
	}, [memorySelected, cpuSelected, setValue, reset, index, arrayName, name_base]);

	return (
		<div className={clsx(className, 'flex flex-row w-full')}>
			<div className="flex w-1/2 mr-16">
				<CustomSelect
					name={`${name_base}cpu`}
					control={control}
					options={CPU_OPTIONS}
					label="CPU *"
					disableLabel
					shrinkLabel={!!cpuSelected}
				/>
			</div>
			<div className="flex w-1/2">
				<CustomSelect
					name={`${name_base}memory`}
					control={control}
					options={fargateMemory}
					label="Memory *"
					disableLabel
					shrinkLabel={!!memorySelected}
				/>
			</div>
		</div>
	);
};

SelectCpuMemory.propTypes = {
	className: PropTypes.string,
	control: PropTypes.object.isRequired,
	arrayName: PropTypes.string,
	index: PropTypes.string,
	setValue: PropTypes.func.isRequired,
	reset: PropTypes.func
};

export default SelectCpuMemory;
