import { useReducer } from 'react';

const useStateReducer = (initialState) =>
	useReducer(
		(state, newState) => ({
			...state,
			...newState
		}),
		{
			...initialState
		}
	);

export default useStateReducer;
