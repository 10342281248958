import PropTypes from 'prop-types';

import Switch from '@mui/material/Switch';

import { styled } from '@mui/material/styles';

const useStyles = styled((theme) => ({
	root: {
		width: 42,
		height: 26,
		padding: 0,
		margin: theme.spacing(1)
	},
	switchBase: {
		padding: 1,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: theme.palette.secondary.main,
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: theme.palette.secondary.main,
			border: '6px solid #fff'
		}
	},
	thumb: {
		width: 24,
		height: 24
	},
	track: {
		borderRadius: 26 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	checked: {},
	focusVisible: {}
}));

const IOSSwitch = (props) => {
	const classes = useStyles();

	const { isChecked, handleOnChange } = props;

	return (
		<Switch
			checked={isChecked}
			onChange={handleOnChange}
			focusVisibleClassName={classes.focusVisible}
			disableRipple
			classes={{
				root: classes.root,
				switchBase: classes.switchBase,
				thumb: classes.thumb,
				track: classes.track,
				checked: classes.checked
			}}
			{...props}
		/>
	);
};

IOSSwitch.propTypes = {
	isChecked: PropTypes.bool.isRequired,
	handleOnChange: PropTypes.func.isRequired
};

export default IOSSwitch;
