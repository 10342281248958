import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSessionStorage } from 'react-storage-complete';
import { useAuthState } from 'src/app/context/useAuth';
import { Base64 } from 'js-base64';
import { URLPaths } from 'src/app/constants';
import { useFetchData } from 'src/utils/fetchAsyncCalls';
import { subscriptionStatus } from '../api';

import FuseSplashScreen from 'src/lib/@fuse/core/FuseSplashScreen';

const SubscriptionCheck = (props) => {
	const { isAuthenticated } = useAuthState();

	// FIXME @@LC: Not implemented

	console.log('isAuthenticated', isAuthenticated);

	const [timerComplete, setTimerComplete] = useState(false);
	const [waitSubCheck, setWaitSubCheck] = useState(true);

	const history = useNavigate();
	const location = useLocation();

	const { status, data, error, isFetching } = useFetchData('subscriptionStatus', [], subscriptionStatus, {
		enabled: isAuthenticated !== undefined && isAuthenticated !== false
	});

	console.log('SubscriptionCheck status', status);
	console.log('SubscriptionCheck isAuthenticated', isAuthenticated);
	console.log('SubscriptionCheck data', data);

	const [storedSettings, setStoredSettings] = useSessionStorage(
		'settings',
		{},
		{
			prefix: 'config',
			shouldInitialize: true,
			emitterDisabled: false,
			decode: (t) => JSON.parse(Base64.decode(t))
		}
	);

	// Timer effect
	useEffect(() => {
		const timer = setTimeout(() => {
			setTimerComplete(true);
		}, 500); // 500ms = 0.5 seconds

		return () => clearTimeout(timer);
	}, []);

	useEffect(() => {
		if (isAuthenticated && data) {
			console.log('SubscriptionCheck isAuthenticated', isAuthenticated);

			data.map((sub) => {
				if (sub.status === 'canceled') {
					console.log('Canceled');

					setWaitSubCheck(false);
					// history('/pages/subscription-update');
				}
			});

			setWaitSubCheck(false);
		}
	}, [data, isAuthenticated]);

	// Combine timer and subscription check
	useEffect(() => {
		if (timerComplete && !isAuthenticated) {
			setWaitSubCheck(false);
		}
	}, [timerComplete, isAuthenticated]);

	return waitSubCheck ? <FuseSplashScreen /> : <>{props.children}</>;
};

export default SubscriptionCheck;
