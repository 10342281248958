import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const AiBrainApp = lazy(() => import('./AiBrainApp'));
const AiBrainCreate = lazy(() => import('./Create/index'));
const AiBrainList = lazy(() => import('./List/index'));
const AiBrainDetail = lazy(() => import('./Detail/index'));
const AiBrainDocumentUpload = lazy(() => import('./DocumentUpload/index'));

const WebScrapeManager = lazy(() => import('./WebScrapeManager/index'));

const ScrapContentWizard = lazy(() => import('./WebScrapeManager/ScrapContentWizard'));

const FileManagerApp = lazy(() => import('./FileManager/FileManagerApp'));

const AiBrainAppRoute = {
	path: '/ai/brain',
	element: <AiBrainApp />,
	children: [
		{
			path: '',
			element: <Navigate to="/ai/brain/list" />
		},
		{
			path: 'create',
			element: <AiBrainCreate />
		},
		{
			path: 'detail/:aiBrainId',
			element: <AiBrainDetail />
		},
		{
			path: 'list',
			element: <AiBrainList />
		},
		{
			path: 'doc/upload',
			element: <AiBrainDocumentUpload />
		},
		{
			path: 'fm/file-manager',
			element: <FileManagerApp />
		},
		{
			path: 'fm/file-manager/:folderId',
			element: <FileManagerApp />
		},
		{
			path: 'ws/scrap-manager',
			element: <WebScrapeManager />
		},
		{
			path: 'ws/scrap-wizard',
			element: <ScrapContentWizard />
		}
	]
};

export default AiBrainAppRoute;
