import { NumericFormat } from 'react-number-format';
import PropTypes from 'prop-types';

const limit = (val, max) => {
	// if (val.length === 1 && val[0] > max[0]) {
	//   val = '0' + val;
	// }

	if (val.length === 2) {
		if (Number(val) === 0) {
			val = '01';
			// this can happen when user paste number
		} else if (val > max) {
			val = max;
		}
	}

	if (Number(val) > Number(max)) {
		val = max;
	}

	return val;
};

const maxValue = (val, max = '124') => limit(val, max);

const CustomNumberFormat = (props) => {
	const { inputRef, onChange, ...other } = props;

	return (
		<NumericFormat
			{...other}
			format={maxValue}
			getInputRef={inputRef}
			onValueChange={(values) => {
				onChange({
					target: {
						name: props.name,
						value: values.value
					}
				});
			}}
			thousandSeparator
			isNumericString
			allowNegative={false}
		/>
	);
};

CustomNumberFormat.propTypes = {
	inputRef: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	onChange: PropTypes.func.isRequired
};

export default CustomNumberFormat;
