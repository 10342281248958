import clsx from 'clsx';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';

const DividerDefault = (props) => {
	const { className, padding = 'py-5' } = props;
	return (
		<div
			className={clsx(className, 'hidden sm:block')}
			aria-hidden="true"
		>
			<div className={clsx(padding)}>
				<div className="border-t border-gray-200" />
			</div>
		</div>
	);
};

const DividerString = (props) => {
	const { className, label, padding } = props;
	return (
		<div className={clsx(className, padding && padding, 'relative w-full')}>
			<div
				className="absolute inset-0 flex items-center"
				aria-hidden="true"
			>
				<div className="w-full border-t border-gray-300" />
			</div>
			<div className="relative flex justify-center">
				<Box
					className="px-16 text-lg text-gray-500"
					sx={{ backgroundColor: 'background.paper' }}
				>
					{label}
				</Box>
			</div>
		</div>
	);
};

const Divider = (props) => {
	const { className, label, variant } = props;
	return (
		<>
			{(() => {
				switch (variant) {
					case 'labeled':
						return (
							<DividerString
								className={className}
								label={label}
							/>
						);
					default:
						return <DividerDefault className={className} />;
				}
			})()}
		</>
	);
};
Divider.propTypes = {
	padding: PropTypes.string,
	label: PropTypes.string,
	variant: PropTypes.string
};

export default Divider;
