import {useMemo} from 'react';
import createCache from '@emotion/cache';
import {CacheProvider} from '@emotion/react';

import {SnackbarProvider} from 'notistack';

import rtlPlugin from 'stylis-plugin-rtl';

import FuseLayout from 'src/lib/@fuse/core/FuseLayout';
import FuseTheme from 'src/lib/@fuse/core/FuseTheme';

import themeLayouts from 'src/lib/theme-layouts/themeLayouts';

import useDocumentTitle from 'src/app/hooks/useDocumentTitle';

// import FuseLayout from 'src/lib/@_fuse/core/FuseLayout';
// import FuseTheme from 'src/lib/@_fuse/core/FuseTheme';

import {FlagsProvider} from 'src/app/context/useUserFlags';

import {NotificationProvide} from './app/context/useNotification';
import GlobalWsMessage from './app/context/GlobalWsMessage';

import withAppProviders from './withAppProviders';

import {APP_ACTIVE, APP_LOGO_CURRENT} from './app/constants';
import track from 'src/lib/@react-use-tracking';
import {useSettings} from 'src/app/context/useSettings';

import {PostHogProvider} from 'posthog-js/react';

const options = {
    api_host: import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_HOST,
};


const emotionCacheOptions = {
    rtl: {
        key: 'muirtl',
        stylisPlugins: [rtlPlugin],
        insertionPoint: document.getElementById('emotion-insertion-point')
    },
    ltr: {
        key: 'muiltr',
        stylisPlugins: [],
        insertionPoint: document.getElementById('emotion-insertion-point')
    }
};

function App() {
    const langDirection = 'ltr';
    const {selectMainTheme} = useSettings();
    const mainTheme = selectMainTheme();

    const cacheProviderValue = useMemo(() => createCache(emotionCacheOptions[langDirection]), [langDirection]);

    useDocumentTitle(APP_LOGO_CURRENT.brandName);

    // const { userFlags } = useUserFlags();

    const userFlags = [
        {isActive: false, name: 'vipOnly'},
        {isActive: true, name: 'productive'}
    ];

    // console.log('userFlags', userFlags);

    return (
        <CacheProvider value={cacheProviderValue}>
            <PostHogProvider
                apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
                options={options}
            >
                <GlobalWsMessage/>
                <FuseTheme
                    theme={mainTheme}
                    root
                >
                    <SnackbarProvider
                        maxSnack={5}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'right'
                        }}
                        classes={{
                            containerRoot: 'bottom-0 right-0 mb-52 md:mb-68 mr-8 lg:mr-80 z-99'
                        }}
                    >
                        <NotificationProvide>
                            <FlagsProvider value={userFlags}>
                                <FuseLayout layouts={themeLayouts}/>
                            </FlagsProvider>
                        </NotificationProvide>
                    </SnackbarProvider>
                </FuseTheme>
            </PostHogProvider>
        </CacheProvider>
    );
}

// const TrackedApp = track(
//   {
//     config: { app: APP_ACTIVE },
//   },
//   {
//     dispatch: data => {
//       console.log('dd', data);
//     },
//   },
// )(App);
//
// const AppWithProviders = withAppProviders(TrackedApp)();
// export default AppWithProviders;

const AppWithProviders = withAppProviders(App);
export default AppWithProviders;
