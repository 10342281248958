// nodejs library to set properties for components
import PropTypes from 'prop-types';
// nodejs library that concatenates classes
import clsx from 'clsx';
// @material-ui/core components
import { styled } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';
import Input from '@mui/material/Input';

const useStyles = styled((theme) => ({
	disabled: {
		'&:before': {
			borderColor: 'transparent !important'
		}
	},
	underline: {
		'&:hover:not($disabled):before,&:before': {
			borderColor: `${theme.palette.grey['400']} !important`
		},
		'&:after': {
			borderColor: theme.palette.secondary.main
		},
		'& + p': {
			fontWeight: '300'
		}
	},
	underlineError: {
		'&:after': {
			borderColor: theme.palette.error['600']
		}
	},
	underlineSuccess: {
		'&:after': {
			borderColor: theme.palette.success.light
		}
	},
	labelRoot: {
		color: `${theme.palette.grey['500']} !important`,
		fontWeight: '400',
		fontSize: '14px',
		lineHeight: '1.42857',
		top: '10px',
		letterSpacing: 'unset',
		'& + $underline': {
			marginTop: '0px'
		}
	},
	labelRootError: {
		color: `${theme.palette.error['600']} !important`
	},
	labelRootSuccess: {
		color: `${theme.palette.success.light} !important`
	},
	formControl: {
		margin: '0 0 17px 0',
		paddingTop: '27px',
		position: 'relative',
		verticalAlign: 'unset',
		'& svg,& .fab,& .far,& .fal,& .fas,& .material-icons': {
			color: theme.palette.grey['400']
		}
	},
	whiteUnderline: {
		'&:hover:not($disabled):before,&:before': {
			backgroundColor: theme.palette.common.white
		},
		'&:after': {
			backgroundColor: theme.palette.common.white
		}
	},
	input: {
		color: theme.palette.grey['400'],
		height: 'unset',
		'&,&::placeholder': {
			fontSize: '14px',
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: '400',
			lineHeight: '1.42857',
			opacity: '1'
		},
		'&::placeholder': {
			color: theme.palette.grey['300']
		}
	},
	whiteInput: {
		'&,&::placeholder': {
			color: theme.palette.common.white,
			opacity: '1'
		}
	}
}));

export default function CustomInput(props) {
	const classes = useStyles();
	const {
		formControlProps,
		labelText,
		id,
		labelProps,
		inputProps,
		error,
		white,
		inputRootCustomClasses,
		success,
		helperText,
		register
	} = props;

	const labelClasses = clsx({
		[` ${classes.labelRootError}`]: error,
		[` ${classes.labelRootSuccess}`]: success && !error
	});
	const underlineClasses = clsx({
		[classes.underlineError]: error,
		[classes.underlineSuccess]: success && !error,
		[classes.underline]: true,
		[classes.whiteUnderline]: white
	});
	const marginTop = clsx({
		[inputRootCustomClasses]: inputRootCustomClasses !== undefined
	});
	const inputClasses = clsx({
		[classes.input]: true,
		[classes.whiteInput]: white
	});
	let formControlClasses;

	if (formControlProps !== undefined) {
		formControlClasses = clsx(formControlProps.className, classes.formControl);
	} else {
		formControlClasses = classes.formControl;
	}

	const helpTextClasses = clsx({
		[classes.labelRootError]: error,
		[classes.labelRootSuccess]: success && !error
	});
	return (
		<FormControl
			{...formControlProps}
			className={formControlClasses}
		>
			{labelText !== undefined ? (
				<InputLabel
					className={`${classes.labelRoot} ${labelClasses}`}
					htmlFor={id}
					{...labelProps}
				>
					{labelText}
				</InputLabel>
			) : null}
			<Input
				classes={{
					input: inputClasses,
					root: marginTop,
					disabled: classes.disabled,
					underline: underlineClasses
				}}
				id={id}
				name={id}
				inputRef={register(id)}
				{...inputProps}
			/>
			{helperText !== undefined ? (
				<FormHelperText
					id={`${id}-text`}
					className={helpTextClasses}
				>
					{helperText}
				</FormHelperText>
			) : null}
		</FormControl>
	);
}

CustomInput.propTypes = {
	labelText: PropTypes.node,
	labelProps: PropTypes.object,
	id: PropTypes.string,
	register: PropTypes.func.isRequired,
	inputProps: PropTypes.object,
	formControlProps: PropTypes.object,
	inputRootCustomClasses: PropTypes.string,
	error: PropTypes.bool,
	success: PropTypes.bool,
	white: PropTypes.bool,
	helperText: PropTypes.node
};
