import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const PreferencesApp = lazy(() => import('./index'));

const preferencesAppConfig = {
	path: URLPaths.SETTINGS_PREFERENCES_PATH,
	element: <PreferencesApp />
};

export default preferencesAppConfig;
