import callApi from 'src/utils/fetchAsyncCalls';
import {APP_DOMAIN, URLMap} from 'src/app/constants';
import {callApiGet} from 'src/utils/fetchCalls';

export const fetchPricingList = (token) => callApi(URLMap.PRICING_LIST, {token});

export const accountClose = ({token, data}) =>
    callApi(URLMap.ACCOUNT_CLOSE, {
        token,
        data: {...data}
    });

export const authPasswordChange = ({token, data}) =>
    callApi(URLMap.AUTH_PASSWORD_CHANGE, {
        token,
        data: {...data, domain: APP_DOMAIN}
    });

export const updateCompanyData = ({token, data}) =>
    callApi(URLMap.COMPANY_UPDATE, {
        token,
        data: {...data}
    });

export const authUserVerifyTwoFaCode = ({token, data}) =>
    callApi(URLMap.AUTH_USER_TWO_VERIFY_CODE, {
        token,
        data: {...data}
    });

export const userCheckInvite = (token, inviteId) => {
    const getStrQuery = {
        inviteId,
        appDomain: APP_DOMAIN
    }

    return callApi(`${URLMap.AUTH_USER_INVITE_CHECK}`, {getStrQuery})
};

export const userInviteJoinProject = ({token, data}) =>
    callApi(URLMap.AUTH_USER_INVITE_JOIN_PROJECT, {
        token,
        data: {...data, appDomain: APP_DOMAIN}
    });

export const fetchTwoFactorURL = (token) => callApi(URLMap.AUTH_USER_TWO_FA, {token});

export const fetchTwoFactorRecoverCode = (token) => callApi(URLMap.AUTH_USER_TWO_RECOVER_CODE, {token});

export const fetchTwoFactorStatus = (token) => callApi(URLMap.AUTH_USER_TWO_STATUS, {token});

export const fetchUserTwoFAImage = (token) => callApi(URLMap.AUTH_USER_TWO_FA, {token});

export const fetchCompanyInfo = (token) => callApi(URLMap.COMPANY_INFO, {token});

export const fetchOverviewData = (token) => callApi(URLMap.OVERVIEW_ACCOUNT, {token});

export const fetchProjectList = (token) => callApi(URLMap.PROJECT_LIST, {token});

export const fetchInviteData = (inviteId) => callApi(`${URLMap.USER_INVITE}/${inviteId}`, {token: null});

export const integrationCreate = ({token, data}) =>
    callApi(URLMap.INTEGRATION_CREATE, {
        token,
        data
    });

export const fetchClientId = (token, subId, subscriptionId) => {
    const getStrQuery = {
        subId,
        subscriptionId
    }
    callApi(`${URLMap.BILLING_SUB_SETUP_INTENT}`, {token, getStrQuery})
};

export const fetchAccountOwner = (token) => callApi(URLMap.ACCOUNT_OWNER, {token});

export const fetchAccountSettings = (token) => callApi(URLMap.ACCOUNT_SETTINGS, {token});

export const updateUser = ({token, data}) =>
    callApi(URLMap.USER_UPDATE, {
        token,
        data: {...data}
    });

// Support Endpoints:
export const supportCaseFetch = (token, caseId) => callApi(`${URLMap.SUPPORT_CASE_GET}/${caseId}`, {token});

export const supportCaseCreate = ({token, data}) =>
    callApi(URLMap.SUPPORT_CASE_CREATE, {
        token,
        data: {...data},
        domain: APP_DOMAIN
    });

export const supportCaseUpdate = ({token, data}) =>
    callApi(URLMap.SUPPORT_CASE_UPDATE, {
        token,
        data: {...data}
    });

export const supportCaseList = (token, status = null) => {
    let url = URLMap.SUPPORT_CASE_LIST;

    const getStrQuery = {}

    if (status) {
        getStrQuery.status = status;
    }

    return callApi(url, {
        token,
        getStrQuery
    });
};

export const createUserTwoRecoverCode = ({token}) => callApi(URLMap.AUTH_USER_TWO_RECOVER_CODE, {token});

export const setUserTwoSetupComplete = (token) => callApi(URLMap.AUTH_USER_TWO_SETUP_COMPLETE, {token});

export const apiKeyCreate = ({token, data}) =>
    callApi(URLMap.PROJECT_API_KEY_CREATE, {
        token,
        data: {...data}
    });

export const apiKeyUpdate = ({token, data}) =>
    callApi(URLMap.PROJECT_API_KEY_UPDATE, {
        token,
        data: {...data}
    });

export const apiKeyDelete = ({token, projectId, apiKeyId}) =>
    callApi(`${URLMap.PROJECT_API_KEY_DELETE}/${projectId}/${apiKeyId}`, {
        token
    });

export const apiKeyFetch = (token, projectId, apiKeyId) =>
    callApi(`${URLMap.PROJECT_API_KEY_GET}/${projectId}/${apiKeyId}`, {token});

export const apiKeyListFetch = (token, projectId) => callApi(`${URLMap.PROJECT_API_KEY_LIST}/${projectId}`, {token});

export const fetchUserLockList = (token) => callApi(URLMap.AUTH_USER_LOCK_LIST, {token});

export const CreditCardFetch = (token, cardId) => callApi(`${URLMap.BILLING_CC_GET}/${cardId}`, {token});

export const CreditCardListFetch = (token) => callApi(URLMap.BILLING_CC_LIST, {token});

export const CreditCardUpdate = ({token, data}) =>
    callApi(URLMap.BILLING_CC_UPDATE, {
        token,
        data: {...data}
    });
export const CreditCardDefaultSet = ({token, data}) =>
    callApi(URLMap.BILLING_CC_DEFAULT_SET, {
        token,
        data: {...data}
    });
export const CreditCardAdd = ({token, data}) =>
    callApi(URLMap.BILLING_CC_ADD, {
        token,
        data: {...data}
    });
export const CreditCardDelete = ({token, cardId, data}) =>
    callApi(`${URLMap.BILLING_CC_DELETE}/${cardId}`, {
        token,
        data: {...data}
    });

export const fetchSubscriptions = (token) => callApi(URLMap.BILLING_SUB_LIST, {token});

export const SubscriptionEdit = ({token, data}) =>
    callApi(URLMap.BILLING_SUB_EDIT, {
        token,
        data: {...data}
    });

export const SubscriptionCancel = ({token, data}) =>
    callApi(URLMap.BILLING_SUB_CANCEL, {
        token,
        data: {...data}
    });

export const fetchInvoices = (token) => callApi(URLMap.BILLING_INV_LIST, {token});

export const userListFetch = (token) => callApi(URLMap.USER_LIST_BASE, {token});

export const userDetailFetch = (token, userKey) => callApi(`${URLMap.USER_DETAIL}/${userKey}`, {token});
