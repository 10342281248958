import useThemeMediaQuery from 'src/lib/@fuse/hooks/useThemeMediaQuery';
import NavbarToggleFab from 'src/lib/theme-layouts/shared-components/navbar/NavbarToggleFab';
import { useSettings } from 'src/app/context/useSettings';
import { useNavBar } from 'src/app/context/useNavbar';

/**
 * The navbar toggle fab layout 1.
 */
function NavbarToggleFabLayout1(props) {
	const { className } = props;

	const { navbarToggleMobile, navbarToggle } = useNavBar();
	const { settingsCurrent } = useSettings();

	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
	const { config } = settingsCurrent.layout;
	return (
		<NavbarToggleFab
			className={className}
			onClick={() => {
				isMobile ? navbarToggleMobile() : navbarToggle();
			}}
			position={config.navbar.position}
		/>
	);
}

export default NavbarToggleFabLayout1;
