import queryString from 'query-string';
import checkHttpStatus from './checkHttpStatus';
import parseJSON from './parseJSON';
// import { URLPaths } from '../constants';

export function callApiGet(token, url, data) {
	if (data) {
		url = `${url}?${queryString.stringify(data)}`;
	}

	return fetch(url, {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Content-Type': 'text/plain; charset=UTF-8',
			Authorization: `Bearer ${token}`
		}
	})
		.then(checkHttpStatus)
		.then(parseJSON)
		.then((response) => response)
		.catch((error) => {
			if (error?.response?.status === 401) {
				// push(URLPaths.LOGIN_PATH);
			} else {
				console.log('error', error);
			}
		});
}

export function callApiPost(token, url, data) {
	return fetch(url, {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
			Authorization: `Bearer ${token}`
		}
	})
		.then(checkHttpStatus)
		.then(parseJSON)
		.then((response) => response)
		.catch((error) => {
			if (error.response.status === 401) {
				// push(URLPaths.LOGIN_PATH);
			}
		});
}

export function callApiPatch(token, url, data) {
	return fetch(url, {
		method: 'PATCH',
		mode: 'cors',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
			Authorization: `Bearer ${token}`
		}
	})
		.then(checkHttpStatus)
		.then(parseJSON)
		.then((response) => response)
		.catch((error) => {
			if (error.response.status === 401) {
				// push(URLPaths.LOGIN_PATH);
			}
		});
}

export function callApiDelete(token, url, data) {
	return fetch(url, {
		method: 'DELETE',
		mode: 'cors',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
			Authorization: `Bearer ${token}`
		}
	})
		.then(checkHttpStatus)
		.then(parseJSON)
		.then((response) => response)
		.catch((error) => {
			if (error.response.status === 401) {
				// push(URLPaths.LOGIN_PATH);
			}
		});
}

export function callApiPostAuth(url, data) {
	return fetch(url, {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify(data)
		// headers: {
		//   'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
		//   Authorization: `Bearer ${token}`,
		// },
	})
		.then(checkHttpStatus)
		.then(parseJSON)
		.then((response) => response)
		.catch((error) => {
			console.log('error ddd', error);

			if (error.response.status === 401) {
				// push(URLPaths.LOGIN_PATH);
			}
		});
}

export function callApiGetNonAuth(url, data) {
	if (data) {
		url = `${url}?${queryString.stringify(data)}`;
	}

	return fetch(url, {
		method: 'GET',
		mode: 'cors',
		headers: {
			'Content-Type': 'text/plain; charset=UTF-8'
		}
	})
		.then(checkHttpStatus)
		.then(parseJSON)
		.then((response) => response)
		.catch((error) => {
			if (error.response.status === 401) {
				// push(URLPaths.LOGIN_PATH);
			}
		});
}

export function callApiPostNonAuth(endpoint, data) {
	// console.log('endpoint data', endpoint, data);
	return fetch(endpoint, {
		method: 'POST',
		// mode: 'cors',
		body: JSON.stringify(data),
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
		}
	})
		.then(checkHttpStatus)
		.then(parseJSON)
		.then((response) => response)
		.catch((error) => {
			if (error.response.status === 401) {
			}
		});
}

export function callImagePost(endpoint, { token, data }) {
	return fetch(endpoint, {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify(data),
		headers: {
			// 'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8',
			Authorization: `Bearer ${token}`
		}
	})
		.then(checkHttpStatus)
		.then(parseJSON)
		.then((response) => response)
		.catch((error) => {
			if (error.response.status === 401) {
				// push(URLPaths.LOGIN_PATH);
			}
		});
}

export function callImagePostNonAuth(url, data) {
	return fetch(url, {
		method: 'POST',
		mode: 'cors',
		body: JSON.stringify(data),
		headers: {
			// 'x-cloudbuff': 'profile-image',
		}
	})
		.then(checkHttpStatus)
		.then(parseJSON)
		.then((response) => response)
		.catch((error) => {
			if (error.response.status === 401) {
				// push(URLPaths.LOGIN_PATH);
			}
		});
}

export function callMediaPostNonAuth(url, data) {
	return fetch(url, {
		method: 'POST',
		mode: 'cors',
		body: data
	})
		.then(checkHttpStatus)
		.then(parseJSON)
		.then((response) => response)
		.catch((error) => {
			if (error.response.status === 401) {
				// push(URLPaths.LOGIN_PATH);
			}
		});
}
