import PropTypes from 'prop-types';

const ResourceDisplay = (props) => {
	const { resourceType, resourceValue } = props;

	if (resourceValue === undefined) {
		return '--';
	}

	return (
		<div>
			{resourceType === 'cpu' ? (
				<span>{Math.round((parseFloat(resourceValue) / 1024 + Number.EPSILON) * 100) / 100} CPUs</span>
			) : (
				<span>{Math.round((parseFloat(resourceValue) / 1024 + Number.EPSILON) * 100) / 100} GiB</span>
			)}
		</div>
	);
};

ResourceDisplay.propTypes = {
	resourceType: PropTypes.oneOf(['cpu', 'memory']).isRequired,
	resourceValue: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired
};

export default ResourceDisplay;
