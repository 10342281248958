import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Controller } from 'react-hook-form';
import TextField from '@mui/material/TextField';

const InputControlled = (props) => {
	const {
		control,
		color,
		name,
		errors,
		className,
		placeholder,
		fieldType,
		maxChar,
		maxNum,
		InputLabelProps,
		InputProps,
		inputProps,
		// label,
		required,
		// fullWidth,
		// disabled,
		// autoFocus,
		// autoComplete,
		...other
	} = props;

	const onInput = (e) => {
		if (maxChar) {
			e.target.value = e.target.value.toString().slice(0, maxChar);
		} else if (maxNum) {
			e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxNum);
		}
	};

	return (
		<Controller
			name={name}
			control={control}
			render={({
				field: { onChange, onBlur, value, name, ref },
				fieldState: { invalid, isTouched, isDirty, error },
				formState
			}) => (
				<TextField
					{...other}
					className={clsx(className, '')}
					color={color || 'secondary'}
					onBlur={onBlur}
					// variant={variant}
					onChange={onChange}
					// label={label}
					inputRef={ref}
					onInput={(e) => onInput(e)}
					id={name}
					value={value}
					placeholder={placeholder || ''}
					// name={name}
					type={fieldType || 'text'}
					// fullWidth={fullWidth}
					required={required}
					// If we have a value, we will raise the name up.
					InputLabelProps={value ? { ...InputLabelProps, shrink: true } : { ...InputLabelProps }}
					InputProps={InputProps}
					inputProps={inputProps}
					helperText={errors?.[name] ? errors?.[name].message : ''}
					error={Boolean(errors?.[name])}
					// autoFocus={autoFocus}
					// disabled={disabled}
					// autoComplete='off'
				/>
			)}
		/>
	);
};

InputControlled.propTypes = {
	control: PropTypes.object.isRequired,
	color: PropTypes.string,
	name: PropTypes.string.isRequired,
	errors: PropTypes.object.isRequired,
	className: PropTypes.string,
	fieldType: PropTypes.string,
	placeholder: PropTypes.string,
	InputLabelProps: PropTypes.object,
	InputProps: PropTypes.object,
	inputProps: PropTypes.object,
	required: PropTypes.bool,
	maxChar: PropTypes.string,
	maxNum: PropTypes.string
};

const InputUnControlled = (props) => {
	const {
		register,
		registerArgs = {},
		color,
		name,
		placeholder,
		errors,
		className,
		maxChar,
		maxNum,
		fieldType,
		InputLabelProps,
		InputProps,
		inputProps,
		label,
		required,
		rows,
		multiline,
		maxRows,
		fullWidth,
		disabled,
		autoFocus
	} = props;

	const registerFiled = register(name, registerArgs);

	const onInput = (e) => {
		if (maxChar) {
			e.target.value = e.target.value.toString().slice(0, maxChar);
		} else if (maxNum) {
			e.target.value = Math.max(0, parseInt(e.target.value)).toString().slice(0, maxNum);
		}
	};

	return (
		<TextField
			className={clsx(className, '')}
			color={color || 'secondary'}
			onBlur={registerFiled.onBlur}
			// variant="filled"
			onChange={registerFiled.onChange}
			label={label}
			inputRef={registerFiled.ref}
			id={name}
			name={name}
			type={fieldType || 'text'}
			placeholder={placeholder || ''}
			fullWidth={fullWidth}
			required={required}
			onInput={(e) => onInput(e)}
			InputLabelProps={InputLabelProps}
			InputProps={InputProps}
			inputProps={inputProps}
			helperText={errors?.[name] ? errors?.[name].message : ''}
			error={Boolean(errors?.[name])}
			rows={rows}
			maxRows={maxRows}
			multiline={multiline}
			autoFocus={autoFocus}
			disabled={disabled}
			autoComplete="off"
		/>
	);
};

const Input = (props) => {
	const { control, register } = props;

	if (control) {
		return <InputControlled {...props} />;
	} else if (register) {
		return <InputUnControlled {...props} />;
	}
};

Input.propTypes = {
	control: PropTypes.object,
	register: PropTypes.object,
	color: PropTypes.string,
	name: PropTypes.string.isRequired,
	errors: PropTypes.object.isRequired,
	className: PropTypes.string,
	placeholder: PropTypes.string,
	fieldType: PropTypes.string,
	InputLabelProps: PropTypes.object,
	InputProps: PropTypes.object,
	inputProps: PropTypes.object,
	required: PropTypes.bool,
	rows: PropTypes.number,
	multiline: PropTypes.bool,
	maxRows: PropTypes.number,
	maxChar: PropTypes.string,
	maxNum: PropTypes.string
};

export default Input;
