import FuseSearch from 'src/lib/@fuse/core/FuseSearch';
// import withSlices from 'app/store/withSlices';
// import { useAppSelector } from 'app/store/hooks';
// import { navigationSlice, selectFlatNavigation } from './store/navigationSlice';
import { useNavigation } from 'src/app/context/useNavigation';

/**
 * The navigation search.
 */
function NavigationSearch(props) {
	const { variant, className } = props;

	const { navigationState: navigation } = useNavigation();

	return (
		<FuseSearch
			className={className}
			variant={variant}
			navigation={navigation}
		/>
	);
}

// const NavigationSearchWithSlices = withSlices([navigationSlice])(
//   NavigationSearch,
// );
// export default NavigationSearchWithSlices;
export default NavigationSearch;
