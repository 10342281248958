import { useReducer } from 'react';

const useStateReducer = initialState => {
  return useReducer(
    (state, newState) => ({
      ...state,
      ...newState,
    }),
    {
      ...initialState,
    },
  );
};

export default useStateReducer;
