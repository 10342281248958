import { useEffect } from 'react';
import { ThemeProvider } from '@mui/material/styles';
import NavbarToggleFabLayout1 from 'src/lib/theme-layouts/layout1/components/NavbarToggleFabLayout1';
import { useLocation } from 'react-router';
import useThemeMediaQuery from 'src/lib/@fuse/hooks/useThemeMediaQuery';
import NavbarStyle1 from './navbar/style-1/NavbarStyle1';
import NavbarStyle2 from './navbar/style-2/NavbarStyle2';
import NavbarStyle3 from './navbar/style-3/NavbarStyle3';
import { useNavBar } from 'src/app/context/useNavbar';
import { useSettings } from 'src/app/context/useSettings';

/**
 * The navbar wrapper layout 1.
 */
function NavbarWrapperLayout1() {
	const { selectNavbarTheme, settingsCurrent } = useSettings();
	const { navBarState: navbar, navbarCloseMobile } = useNavBar();
	const location = useLocation();
	const { pathname } = location;

	const { config } = settingsCurrent.layout;
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
	const navbarTheme = selectNavbarTheme();

	useEffect(() => {
		if (isMobile) {
			navbarCloseMobile();
		}
	}, [pathname, isMobile]);

	return (
		<>
			<ThemeProvider theme={navbarTheme}>
				<>
					{config.navbar.style === 'style-1' && <NavbarStyle1 />}
					{config.navbar.style === 'style-2' && <NavbarStyle2 />}
					{config.navbar.style === 'style-3' && <NavbarStyle3 />}
					{config.navbar.style === 'style-3-dense' && <NavbarStyle3 dense />}
				</>
			</ThemeProvider>
			{config.navbar.display && !config.toolbar.display && !navbar.open && <NavbarToggleFabLayout1 />}
		</>
	);
}

export default NavbarWrapperLayout1;
