import settingsConfig from 'src/lib/configs/settingsConfig';
import { Navigate } from 'react-router-dom';
import FuseLoading from 'src/lib/@fuse/core/FuseLoading';
import { layoutConfigOnlyMain } from 'src/lib/configs/layoutConfigTemplates';
import ErrorBoundary from 'src/lib/@fuse/utils/ErrorBoundary';
import App from 'src/App';
import Error404Page from '../../app/containers/main/404/Error404Page';
import Error401Page from '../../app/containers/main/401/Error401Page';

// Get product routs. We can use this to select the product we've configured as the active product.
const configDevModules = import.meta.glob('/src/app/containers/dev/**/*Route.jsx', {
	eager: true
});

const devRoutes = Object.keys(configDevModules)
	.map((modulePath) => {
		const moduleConfigs = configDevModules[modulePath].default;
		return Array.isArray(moduleConfigs) ? moduleConfigs : [moduleConfigs];
	})
	.flat();

// Get product routs. We can use this to select the product we've configured as the active product.
const configProductModules = import.meta.glob('/src/app/containers/products/SynthAgents/**/*Route.jsx', {
	eager: true
});

const productRoutes = Object.keys(configProductModules)
	.map((modulePath) => {
		const moduleConfigs = configProductModules[modulePath].default;
		return Array.isArray(moduleConfigs) ? moduleConfigs : [moduleConfigs];
	})
	.flat();

const configMainModules = import.meta.glob('/src/app/containers/main/**/*Route.jsx', {
	eager: true
});

const mainRoutes = Object.keys(configMainModules)
	.map((modulePath) => {
		const moduleConfigs = configMainModules[modulePath].default;
		return Array.isArray(moduleConfigs) ? moduleConfigs : [moduleConfigs];
	})
	.flat();

const routes = [
	{
		path: '/',
		element: <App />,
		auth: settingsConfig.defaultAuth,
		errorElement: <ErrorBoundary />,
		children: [
			{
				path: '/',
				element: <Navigate to="/welcome" />
			},
			...devRoutes,
			...mainRoutes,
			...productRoutes,
			{
				path: 'loading',
				element: <FuseLoading />,
				settings: { layout: layoutConfigOnlyMain }
			},
			{
				path: '401',
				element: <Error401Page />
			},
			{
				path: '404',
				element: <Error404Page />,
				settings: { layout: layoutConfigOnlyMain },
				auth: null
			}
		]
	},
	{
		path: '*',
		element: <Navigate to="404" />
	}
];
export default routes;
