import { lazy, Suspense } from 'react';

import { APP_ACTIVE } from 'src/app/constants';

import DefaultUserMenu from './variants/Default';
import NexusDataUserMenu from './variants/NexusData';
import SkyderaUserMenu from './variants/Skydera';
import SynthagentsUserMenu from './variants/Synthagents';

function UserMenuVariant(props) {
	const { role, userMenuClose } = props;
	return (
		<div>
			{(() => {
				switch (APP_ACTIVE) {
					case 'skydera':
						return (
							<SkyderaUserMenu
								role={role}
								userMenuClose={userMenuClose}
							/>
						);
					case 'nexus':
						return (
							<NexusDataUserMenu
								role={role}
								userMenuClose={userMenuClose}
							/>
						);
					case 'synthagents':
						return (
							<SynthagentsUserMenu
								role={role}
								userMenuClose={userMenuClose}
							/>
						);
					default:
						return (
							<DefaultUserMenu
								role={role}
								userMenuClose={userMenuClose}
							/>
						);
				}
			})()}
		</div>
	);
}

export default UserMenuVariant;
