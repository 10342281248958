import { useState } from 'react';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import FuseSvgIcon from 'src/lib/@fuse/core/FuseSvgIcon';
import clsx from 'clsx';
import { useSettings } from 'src/app/context/useSettings';
import { useDebounce } from 'src/lib/@_fuse/hooks';

import { darkTheme, lightTheme } from 'src/app/constants';

function LightDarkModeToggle(props) {
	// const { className = '', lightTheme, darkTheme } = props;
	const { className = '' } = props;

	const { userUpdate, handleUserSettingsUpdate } = useSettings();

	const [anchorEl, setAnchorEl] = useState(null);

	const { selectMainTheme } = useSettings();
	const mainTheme = selectMainTheme();

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	const handleSelectionChange = (selection) => {
		if (selection === 'light') {
			// handleThemeSelect(lightTheme);
			updateThemeColor(false);
		} else {
			updateThemeColor(true);
			// handleThemeSelect(darkTheme);
		}

		handleClose();
	};

	// function handleThemeSelect(_theme) {
	// 	console.log('_theme', _theme);
	// 	// dispatch(changeFuseTheme(_theme?.section)).then(() => {});
	// }

	const updateThemeColor = useDebounce((isDark) => {
		if (isDark === true) {
			userUpdate({
				updateType: 'settings',
				data: {
					theme: darkTheme
				}
			});
			handleUserSettingsUpdate({
				theme: darkTheme
			});
		} else {
			userUpdate({
				updateType: 'settings',
				data: {
					theme: lightTheme
				}
			});
			handleUserSettingsUpdate({
				theme: lightTheme
			});
		}
	}, 100);

	return (
		<>
			<IconButton
				aria-controls="light-dark-toggle-menu"
				aria-haspopup="true"
				onClick={handleClick}
				className={clsx('border border-divider', className)}
			>
				{mainTheme.palette.mode === 'light' && <FuseSvgIcon>heroicons-outline:sun</FuseSvgIcon>}
				{mainTheme.palette.mode === 'dark' && <FuseSvgIcon>heroicons-outline:moon</FuseSvgIcon>}
			</IconButton>
			<Menu
				id="light-dark-toggle-menu"
				anchorEl={anchorEl}
				keepMounted
				open={Boolean(anchorEl)}
				onClose={handleClose}
			>
				<MenuItem
					selected={mainTheme.palette.mode === 'light'}
					onClick={() => handleSelectionChange('light')}
				>
					Light
				</MenuItem>
				<MenuItem
					selected={mainTheme.palette.mode === 'dark'}
					onClick={() => handleSelectionChange('dark')}
				>
					Dark
				</MenuItem>
			</Menu>
		</>
	);
}

export default LightDarkModeToggle;
