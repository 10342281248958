import { lazy } from 'react';

const VerifyEmailPage = lazy(() => import('./index'));

const verifyEmailPageConfig = {
	path: '/pages/confirmation-required',
	element: <VerifyEmailPage />,
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	}
};

export default verifyEmailPageConfig;
