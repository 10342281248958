import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const VerifyPasscodePage = lazy(() => import('./index'));

const verifyPasscodePageConfig = {
	path: URLPaths.PAGES_VERIFY_PASS_CODE,
	element: <VerifyPasscodePage />,
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	}
};

export default verifyPasscodePageConfig;
