import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useFieldArray } from 'react-hook-form';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import CustomSelect from 'src/app/components/core/form/CustomSelect';
import Input from 'src/app/components/core/form/Input';

const PORT_PROTOCOL = [
	{ value: 'tcp', label: 'TCP' },
	{ value: 'udp', label: 'UDP' }
];

const APP_PROTOCOL = [
	{ value: 'grpc', label: 'gRPC' },
	{ value: 'http', label: 'HTTP' },
	{ value: 'http2', label: 'HTTP2' }
];

const PortsField = (props) => {
	const { className, label, subLabel, name, errors, control } = props;

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	});

	const verifyPortRange = (port) => {
		console.log(port);
		return true;
	};

	return (
		<div className={clsx(className, 'flex flex-col')}>
			<span className="text-15">{label}</span>
			<span className="text-13 text-gray-600">{subLabel}</span>
			<div className="mt-24">
				{fields.length !== 0 && (
					<div className="grid grid-cols-9 space-x-24">
						<span className="col-span-3">Port Name</span>
						<span className="col-span-1">Port</span>
						<span className="col-span-2 pl-24">Protocol</span>
						<span className="col-span-3 pl-24">App Protocol (optional)</span>
					</div>
				)}
			</div>

			<div className="space-y-16 mt-16">
				{fields.map((item, index) => (
					<div className="grid grid-cols-9 space-x-24">
						<Input
							className="col-span-3"
							label="Port Name"
							fieldType="text"
							control={control}
							name={`${name}.${index}.name`}
							errors={errors}
							// placeholder="Port name"
							fullWidth
						/>
						<Input
							className="col-span-1"
							fieldType="number"
							control={control}
							name={`${name}.${index}.port`}
							errors={errors}
							placeholder="80"
							fullWidth
						/>

						<div className="flex flex-row pl-24 col-span-5 space-x-16">
							<CustomSelect
								name={`${name}.${index}.protocol`}
								control={control}
								options={PORT_PROTOCOL}
								// label="Protocol *"
								// disableLabel
								// shrinkLabel
							/>
							<CustomSelect
								name={`${name}.${index}.appProtocol`}
								control={control}
								options={APP_PROTOCOL}
								// label="Protocol *"
								// disableLabel
								// shrinkLabel
							/>

							<IconButton
								key={`close.${index}`}
								aria-label="Close"
								color="inherit"
								onClick={() => remove(index)}
							>
								<Icon>close</Icon>
							</IconButton>
						</div>
					</div>
				))}
				<div className="flex flex-row justify-between">
					<Button
						variant="contained"
						onClick={() => {
							append({ port: '', protocol: 'tcp' });
						}}
					>
						Add
					</Button>
				</div>
			</div>
		</div>
	);
};

PortsField.propTypes = {
	className: PropTypes.string,
	label: PropTypes.oneOf([PropTypes.string, PropTypes.node, PropTypes.element]),
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.array,
	errors: PropTypes.object,
	control: PropTypes.object.isRequired
};

export default PortsField;
