import { useMemo } from 'react';
import AppContext from './app/context/AppContext';
import BrowserRouter from 'src/lib/@_fuse/core/BrowserRouter';

import { AuthCheck, UserIdleCheck, SubscriptionCheck } from 'src/app/checkers';

import RootTracker from 'src/app/tracker/RootTracker';
import PvTracker from 'src/app/tracker/PvTracker';

import { AuthProvide } from 'src/app/context/useAuth';
import { ChatbotAppProvide } from 'src/app/context/useChatbot';
import { SettingsProvide } from 'src/app/context/useSettings';
import { NavBarProvide } from 'src/app/context/useNavbar';
import { MessageProvide } from 'src/app/context/useMessage';
import { NavigationProvide } from 'src/app/context/useNavigation';
import { DialogProvide } from 'src/app/context/useDialog';
import { I18nProvide } from 'src/app/context/useI18n';
import { NamespaceProvide } from 'src/app/context/useNamespace';
import { SubscriptionProvide } from 'src/app/context/useSubscription';
// import { SocketProvide } from 'src/app/context/useSocketContext';
import FuseTheme from 'src/lib/@_fuse/core/FuseTheme';

import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StyledEngineProvider } from '@mui/material/styles';
import { enUS } from 'date-fns/locale/en-US';

import ErrorBoundary from 'src/lib/@fuse/utils/ErrorBoundary';

import routes from 'src/lib/configs/routesConfig';
import { QueryClient, QueryCache, QueryClientProvider } from '@tanstack/react-query';

import { APP_ACTIVE } from './app/constants';
import TwoFaCheck from 'src/app/checkers/auth/TwoFaCheck';

// const queryClient = new QueryClient({
//   defaultOptions: {
//     queries: {
//       refetchOnWindowFocus: false,
//     },
//   },
//   queryCache: new QueryCache({
//     onError: (error, query) => {
//       if (query?.meta?.errorMessage) {
//         console.log('query.meta.errorMessage', query.meta.errorMessage);
//         // toast.error(query.meta.errorMessage);
//       }
//     },
//   }),
// });

// Create a client
const queryClient = new QueryClient();

/**
 * A Higher Order Component that provides the necessary context providers for the app.
 */
function withAppProviders(Component) {
	/**
	 * The component that wraps the provided component with the necessary context providers.
	 */
	function WithAppProviders(props) {
		/**
		 * The value to pass to the AppContext provider.
		 */
		const val = useMemo(
			() => ({
				routes
			}),
			[routes]
		);
		return (
			<ErrorBoundary>
				<AppContext.Provider value={val}>
					{/* <BrowserRouter> */}
					<LocalizationProvider
						dateAdapter={AdapterDateFns}
						adapterLocale={enUS}
					>
						<QueryClientProvider client={queryClient}>
							<MessageProvide>
								<AuthProvide>
									 <SubscriptionProvide>
									<RootTracker>
										<PvTracker />
										{/* <SocketProvide> */}
										<SettingsProvide>
											<NavigationProvide>
												<FuseTheme>
													<AuthCheck>
														<TwoFaCheck>
															<NavBarProvide>
																<DialogProvide>
																	<I18nProvide>
																		<SubscriptionCheck />
																		{/* <UserIdleCheck /> */}
																		<StyledEngineProvider injectFirst>
																			{(() => {
																				switch (APP_ACTIVE) {
																					case 'skydera':
																						return (
																							<NamespaceProvide>
																								<Component {...props} />
																							</NamespaceProvide>
																						);
																					case 'synthagents':
																						return <Component {...props} />;
																					default:
																						return <Component {...props} />;
																				}
																			})()}
																		</StyledEngineProvider>
																	</I18nProvide>
																</DialogProvide>
															</NavBarProvide>
														</TwoFaCheck>
													</AuthCheck>
												</FuseTheme>
											</NavigationProvide>
										</SettingsProvide>
										{/* </SocketProvide> */}
									</RootTracker>
									 </SubscriptionProvide>
								</AuthProvide>
							</MessageProvide>
						</QueryClientProvider>
					</LocalizationProvider>
					{/* </BrowserRouter> */}
				</AppContext.Provider>
			</ErrorBoundary>
		);
	}

	return WithAppProviders;
}

export default withAppProviders;
