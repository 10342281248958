import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const SecurityAccountList = lazy(() => import('./index'));

const securityAccountAppConfig = {
	path: URLPaths.ADMIN_SECURITY_ACCOUNT_PATH,
	children: [
		{
			path: '',
			element: <SecurityAccountList />
		}
	]
};

export default securityAccountAppConfig;
