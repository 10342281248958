import FuseNavigation from 'src/lib/@fuse/core/FuseNavigation';
import clsx from 'clsx';
import { memo, useMemo } from 'react';
import useThemeMediaQuery from 'src/lib/@fuse/hooks/useThemeMediaQuery';

import { useNavigation } from 'src/app/context/useNavigation';
import { useNavBar } from 'src/app/context/useNavbar';
import { useAuthState } from 'src/app/context/useAuth';

function Navigation(props) {
	const { className = '', layout = 'vertical', dense, active } = props;
	const { role: userRole } = useAuthState();

	const { navigationState, selectNavigation } = useNavigation();
	const { navbarCloseMobile } = useNavBar();
	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));

	const navigation = selectNavigation(navigationState, userRole);

	return useMemo(() => {
		function handleItemClick() {
			if (isMobile) {
				navbarCloseMobile();
			}
		}

		return (
			<FuseNavigation
				className={clsx('navigation flex-1', className)}
				navigation={navigation}
				layout={layout}
				dense={dense}
				active={active}
				onItemClick={handleItemClick}
				checkPermission={true} // FIXME @@LC Set this to false for now to build out the nav.
			/>
		);
	}, [isMobile, navigation, active, className, dense, layout]);
}

// const NavigationWIthSlices = withSlices([navigationSlice])(Navigation);
// export default NavigationWIthSlices;
export default memo(Navigation);
