import { lazy } from 'react';
const AgentDetailApp = lazy(() => import('./ChatbotDetailApp'));

const AgentOverview = lazy(() => import('./AgentOverview'));

const HumanAgentChatFirstScreen = lazy(
	() => import('../../shared-components/ChatBotDetail/HumanAgent/ChatFirstScreen')
);
const HumanAgentChat = lazy(() => import('../../shared-components/Chat/chat/Chat'));

const ChatbotChatHistory = lazy(() => import('../../shared-components/ChatBotDetail/ChatHistory/ChatApp'));
const ChatHistoryChatFirstScreen = lazy(
	() => import('../../shared-components/ChatBotDetail/ChatHistory/ChatFirstScreen')
);
const ChatHistoryChat = lazy(() => import('../../shared-components/ChatBotDetail/ChatHistory/chat/Chat'));

const ChatbotDetailDashboard = lazy(() => import('../../shared-components/ChatBotDetail/Dashboard/index'));
// export const ChatbotDetailChatWithBot = lazy(() => import('../../shared-components/ChatBotDetail/ChatWithBot/ChatApp'));

const ChatWithBotChatApp = lazy(() => import('../../shared-components/ChatBotDetail/ChatWithBot/ChatApp'));
const ChatWithBotChat = lazy(() => import('../../shared-components/ChatBotDetail/ChatWithBot/chat/Chat'));
const ChatWithBotFirstScreen = lazy(() => import('../../shared-components/ChatBotDetail/ChatWithBot/ChatFirstScreen'));

const ChatbotDetailEmbed = lazy(() => import('../../shared-components/ChatBotDetail/Embed/index'));
const ChatbotDetailShare = lazy(() => import('../../shared-components/ChatBotDetail/Share/index'));
const ChatbotDetailManageSources = lazy(() => import('../../shared-components/ChatBotDetail/ManageSources/index'));
const ChatbotDetailSettingsAppearance = lazy(
	() => import('../../shared-components/ChatBotDetail/Settings/Appearance/index')
);
const ChatbotDetailSettingsCollectUserInfo = lazy(
	() => import('../../shared-components/ChatBotDetail/Settings/CollectUserInfo/index')
);
const ChatbotDetailSettingsDetails = lazy(() => import('../../shared-components/ChatBotDetail/Settings/Details/index'));
const ChatbotDetailSettingsDomains = lazy(() => import('../../shared-components/ChatBotDetail/Settings/Domains/index'));
const ChatbotDetailSettingsQuickPrompts = lazy(
	() => import('../../shared-components/ChatBotDetail/Settings/QuickPrompts/index')
);
const ChatbotHumanAgent = lazy(() => import('../../shared-components/ChatBotDetail/HumanAgent/ChatApp'));
const ChatbotDetailSettings = lazy(() => import('../../shared-components/ChatBotDetail/Settings/index'));

export {
	AgentDetailApp,
	AgentOverview,
	HumanAgentChatFirstScreen,
	HumanAgentChat,
	ChatbotChatHistory,
	ChatHistoryChatFirstScreen,
	ChatHistoryChat,
	ChatbotDetailDashboard,
	ChatWithBotChatApp,
	ChatWithBotChat,
	ChatWithBotFirstScreen,
	ChatbotDetailEmbed,
	ChatbotDetailShare,
	ChatbotDetailManageSources,
	ChatbotDetailSettingsAppearance,
	ChatbotDetailSettingsCollectUserInfo,
	ChatbotDetailSettingsDetails,
	ChatbotDetailSettingsDomains,
	ChatbotDetailSettingsQuickPrompts,
	ChatbotHumanAgent,
	ChatbotDetailSettings
};
