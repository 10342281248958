import { useEffect, useState, memo } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import Button from '@mui/material/Button';

import { useDialog } from 'src/app/context/useDialog';
import { useSettings } from 'src/app/context/useSettings';
import { useAuth } from 'src/app/context/useAuth';

import { useTracking } from 'src/lib/@react-use-tracking';
import { useNavigate } from 'react-router-dom';

const UserIdleCheck = (props) => {
	const { authState, signOut } = useAuth();
	const { openDialog, closeDialog } = useDialog();
	const history = useNavigate();
	const { userPref } = useSettings();

	const { trackEvent } = useTracking({ model: 'UserIdle' });

	// If we don't have the web session timeout yet, we will set it to 5 min.
	const timeout = userPref?.webSession ? userPref?.webSession * 1000 : 300000;

	// const timeout = 20000;
	const promptBeforeIdle = 9000;
	const [state, setState] = useState('Active');
	const [remaining, setRemaining] = useState(timeout);

	const onIdle = () => {
		setState('Idle');
		trackEvent({ action: 'user_logout_from_idle' });
		closeDialog();
		signOut();
		handleSetToPause();
		// Move to the login page.
		history('/login');
	};

	const onActive = () => {
		setState('Active');
		closeDialog();
	};

	const onPrompt = () => {
		setState('Prompted');
		openDialog({
			children: (
				<div className="flex flex-col p-24 items-center space-y-16 h-256 w-256">
					<div className="text-24">Are you still here?</div>
					<div className="text-16 flex flex-row gap-4">
						<span>Logging out in</span>
						<span className="text-red-300">{remaining} seconds</span>
					</div>
					<div className="flex items-center mt-24">
						<Button
							variant="contained"
							onClick={handleStillHere}
						>
							Im still here
						</Button>
					</div>
				</div>
			)
		});
	};

	const { getRemainingTime, activate, start, pause } = useIdleTimer({
		onIdle,
		onActive,
		onPrompt,
		timeout,
		startManually: true,
		promptBeforeIdle,
		throttle: 500
	});

	const handleStillHere = () => {
		trackEvent({ action: 'user_still_here' });
		activate();
		closeDialog();
	};

	const handleSetToPause = () => {
		pause();
	};

	const timeTillPrompt = Math.max(remaining - promptBeforeIdle / 1000, 0);
	const seconds = timeTillPrompt > 1 ? 'seconds' : 'second';

	useEffect(() => {
		if (authState?.isAuthenticated) {
			start();
			// console.log('Start timer');
		} else {
			pause();
			// console.log('Pause timer');
		}
	}, [start, authState?.isAuthenticated, pause]);

	useEffect(() => {
		const interval = setInterval(() => {
			setRemaining(Math.ceil(getRemainingTime() / 1000));
		}, 500);

		return () => {
			clearInterval(interval);
		};
	});

	return (
		<>
			<div className="hidden">
				<p>Current State: {state}</p>
				{timeTillPrompt > 0 && (
					<p>
						{timeTillPrompt} {seconds} until prompt
					</p>
				)}
			</div>
		</>
	);
};

export default memo(UserIdleCheck);
