import clsx from 'clsx';
import NavbarToggleButton from 'src/lib/theme-layouts/shared-components/navbar/NavbarToggleButton';
import { useSettings } from 'src/app/context/useSettings';

/**
 * Navbar pin toggle button.
 */
function NavbarPinToggleButton(props) {
	const { ...rest } = props;
	const { settingsCurrent: settings } = useSettings();

	const { config } = settings.layout;
	const folded = config.navbar?.folded;
	return (
		<NavbarToggleButton
			{...rest}
			className={clsx(folded ? 'opacity-50' : 'opacity-100', rest.className)}
		/>
	);
}

export default NavbarPinToggleButton;
