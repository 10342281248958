import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const UserApp = lazy(() => import('./index'));

const userAppConfig = {
	path: URLPaths.USER_APP_PATH,
	children: [
		{
			path: '',
			element: <UserApp />
		}
	]
};

export default userAppConfig;
