import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { Link } from 'react-router-dom';
import { APP_CONFIG_CURRENT, URLPaths } from 'src/app/constants';

function SkyderaUserMenu(props) {
	return (
		<div>
			{APP_CONFIG_CURRENT.appType && APP_CONFIG_CURRENT.appType === 'complex' ? (
				<div>
					{
						{
							guest: (
								<>
									<MenuItem
										component={Link}
										to={URLPaths.LOGIN_PATH}
									>
										<ListItemIcon>
											<Icon>lock</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Login"
										/>
									</MenuItem>
									<MenuItem
										component={Link}
										to={URLPaths.REGISTRATION_PATH}
									>
										<ListItemIcon>
											<Icon>person_add</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Register"
										/>
									</MenuItem>
								</>
							),
							analyst: (
								<>
									<MenuItem
										component={Link}
										to={URLPaths.SETTINGS_USER_PATH}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>engineering</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="User Settings"
										/>
									</MenuItem>
								</>
							),
							user: (
								<>
									<MenuItem
										component={Link}
										to={URLPaths.SETTINGS_USER_PATH}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>engineering</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="User Settings"
										/>
									</MenuItem>
								</>
							),
							admin: (
								<>
									<MenuItem
										component={Link}
										to={`${URLPaths.SETTINGS_ADMIN_PATH}?tabId=account`}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>settings</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Admin Settings"
										/>
									</MenuItem>
									<MenuItem
										component={Link}
										to={URLPaths.SETTINGS_USER_PATH}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>engineering</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="User Settings"
										/>
									</MenuItem>
									<MenuItem
										component={Link}
										to={`${URLPaths.SETTINGS_ADMIN_PATH}?tabId=users`}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>group</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Account User List"
										/>
									</MenuItem>
									<MenuItem
										component={Link}
										to={`${URLPaths.SETTINGS_ADMIN_PATH}?tabId=billing`}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>credit_card</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Billing Settings & History"
										/>
									</MenuItem>
								</>
							)
						}[props.role]
					}
				</div>
			) : (
				<div>
					{
						{
							guest: (
								<div>
									<MenuItem
										component={Link}
										to={URLPaths.LOGIN_PATH}
									>
										<ListItemIcon>
											<Icon>lock</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Login"
										/>
									</MenuItem>
									<MenuItem
										component={Link}
										to={URLPaths.REGISTRATION_PATH}
									>
										<ListItemIcon>
											<Icon>person_add</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Register"
										/>
									</MenuItem>
								</div>
							),
							analyst: (
								<>
									<MenuItem
										component={Link}
										to={URLPaths.PROFILE_PATH}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>account_circle</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="My Profile"
										/>
									</MenuItem>
								</>
							),
							user: (
								<>
									<MenuItem
										component={Link}
										to={URLPaths.PROFILE_PATH}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>account_circle</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Account"
										/>
									</MenuItem>
								</>
							),
							admin: (
								<>
									<MenuItem
										component={Link}
										to={URLPaths.PROFILE_PATH}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>account_circle</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Account"
										/>
									</MenuItem>
									<MenuItem
										component={Link}
										to={`${URLPaths.SETTINGS_ADMIN_PATH}?tabId=users`}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>mail</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Account User List"
										/>
									</MenuItem>
									<MenuItem
										component={Link}
										to={URLPaths.BILLING_PATH}
										onClick={props.userMenuClose}
									>
										<ListItemIcon>
											<Icon>mail</Icon>
										</ListItemIcon>
										<ListItemText
											className="pl-0"
											primary="Billing Settings & History"
										/>
									</MenuItem>
								</>
							)
						}[props.role]
					}
				</div>
			)}
		</div>
	);
}

export default SkyderaUserMenu;
