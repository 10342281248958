import { lazy } from 'react';

const AgentApp = lazy(() => import('./index'));
const AgentCreate = lazy(() => import('../shared-components/AgentCreate/index'));
const AgentList = lazy(() => import('./List/index'));
// const AgentDetail = lazy(() => import('./Detail/index'));

const DocumentUpload = lazy(() => import('./DocumentUpload/index'));

export { AgentApp, AgentCreate, AgentList, DocumentUpload };
