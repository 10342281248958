import { lazy } from 'react';

const ForgotPassword2Page = lazy(() => import('./index'));

const ForgotPassword2PageConfig = {
	path: '/pages/forgot-password',
	element: <ForgotPassword2Page />,
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	}
};

export default ForgotPassword2PageConfig;
