import { createContext, useContext, useEffect, useReducer, useMemo } from 'react';
import FuseAuthorization from 'src/lib/@fuse/core/FuseAuthorization/FuseAuthorization';
import FuseSplashScreen from 'src/lib/@fuse/core/FuseSplashScreen/FuseSplashScreen';
import { useMessage } from './useMessage';

import jwtDecode from 'jwt-decode';

import { useMutation } from '@tanstack/react-query';
import { useLocalStorage } from '../hooks';
import { apiAuthLogin, apiAuthSignup } from './api';
import { useSessionStorage } from 'react-storage-complete';
import { Base64 } from 'js-base64';
import { URLPaths } from 'src/app/constants';
import { useNavigate } from 'react-router-dom';

const authContext = createContext();

const Authentication = (props) => {
	const { children } = props;

	const { isAuthenticating, isAuthenticated, role: userRole, token } = useAuthState();

	// return children;
	return children;

	// // return children;
	// return useMemo(
	// 	() =>
	// 		isAuthenticating ? (
	// 			<FuseSplashScreen />
	// 		) : (
	// 			<>
	// 				<FuseAuthorization
	// 					isAuthenticated={isAuthenticated}
	// 					isAuthenticating={isAuthenticating}
	// 					userRole={userRole}
	// 				>
	// 					{children}
	// 				</FuseAuthorization>
	// 			</>
	// 		),
	// 	[userRole, children, isAuthenticating]
	// );
};

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
function AuthProvide({ children }) {
	const auth = useAuthProvide();
	return (
		<authContext.Provider value={auth}>
			<Authentication>{children}</Authentication>
		</authContext.Provider>
	);
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
function useAuth() {
	const context = useContext(authContext);

	if (context === undefined) {
		throw new Error(`useAuth must be used within a AuthProvide`);
	}

	return context;
}

function useAuthState() {
	const { authState } = useAuth();

	return {
		isAuthenticated: authState.isAuthenticated,
		isAuthenticating: authState.isAuthenticating,
		accountId: authState.accountId,
		displayName: authState.displayName,
		firstName: authState.firstName,
		lastName: authState.lastName,
		email: authState.email,
		phone: authState.phone,
		role: authState.role,
		department: authState.department,
		token: authState.token,
		userFlags: authState.userFlags,

		alias: authState.alias,
		capabilities: authState.capabilities,
		photoUrl: authState.photoUrl,
		projectId: authState.projectId,
		companySettings: authState.companySettings,
		settings: authState.settings,
		preferences: authState.preferences,
		subscriptions: authState.subscriptions,
		fundingSource: authState.fundingSource,
		statusText: authState.statusText,
		userKey: authState.userKey
	};
}

function useAuthToken() {
	const { token } = useAuthState();

	return {
		token
	};
}

// Provider hook that creates auth object and handles state

const initialUserState = {
	isAuthenticated: undefined,
	isLoading: false,
	isLoadingPersistence: false,
	isLocked: false,
	isAuthenticating: false,

	isLoginOut: false,

	messageErrorLogin: '',
	messageErrorRegistration: '',

	token: null,
	userKey: '',
	accountId: '',
	alias: '',
	applications: null,
	subscriptions: null,
	displayName: '',
	firstName: '',
	lastName: '',

	lockedPreScreen: null,

	twoFactorPassed: true,
	fundingSource: false,
	firstRun: false,
	email: '',
	phone: '',
	photoUrl: {},
	projectId: '',
	role: '',
	department: '',
	companySettings: {},
	settings: { shortcuts: [] },
	preferences: { twoFactorEnabled: false },
	statusText: '',
	userFlags: [],
	capabilities: []
};

function useAuthProvide() {
	const { showMessage } = useMessage();
	const history = useNavigate();

	const [authState, setAuthState] = useReducer((state, newState) => ({ ...state, ...newState }), {
		isAuthenticated: undefined,
		isLoading: false,
		isLoadingPersistence: false,
		isLocked: false,
		isAuthenticating: false,

		isLoginOut: false,

		messageErrorLogin: '',
		messageErrorRegistration: '',

		token: null,
		userKey: '',
		accountId: '',
		alias: '',
		applications: null,
		subscriptions: null,
		fundingSource: null,
		displayName: '',
		firstName: '',
		lastName: '',

		lockedPreScreen: null,

		twoFactorPassed: true,
		email: '',
		phone: '',
		photoUrl: {},
		projectId: '',
		role: '',
		department: '',
		companySettings: {},
		settings: { shortcuts: [] },
		preferences: { twoFactorEnabled: false },
		statusText: '',
		userFlags: [],
		capabilities: []
	});

	// FIXME @@LC: This should be moved to the SessionStorage
	const { storedValue, setValue, removeStorageKey } = useLocalStorage('sky-auth', {});

	const [settingsSession, setSettingsSession] = useSessionStorage(
		'settings',
		{},
		{
			prefix: 'config',
			shouldInitialize: true,
			emitterDisabled: false,
			encode: (t) => Base64.encode(JSON.stringify(t)),
			decode: (t) => JSON.parse(Base64.decode(t))
		}
	);

	const { mutate: mutateLogin } = useMutation({
		mutationFn: apiAuthLogin,
		// When mutate is called:
		onMutate: (newTodo) => {
			const authData = {
				isAuthenticated: false,
				isAuthenticating: true,

				messageErrorLogin: '',
				messageErrorRegistration: '',
				statusText: "You're authenticating."
			};

			setAuthState(authData);
		},
		onSuccess: (resultSet) => {
			console.log('resultSet', resultSet);

			if (resultSet.statusCode && (resultSet.statusCode === 401 || resultSet.statusCode === 404)) {
				// Send login error message. Dismiss after 5 seconds

				const authData = {
					isAuthenticated: false,
					isAuthenticating: false,
					messageErrorLogin: resultSet.statusText,
					statusText: resultSet.statusText
				};

				setAuthState(authData);

				// Send a status popup message to the user.
				showMessage({
					color: 'error',
					anchorOrigin: {
						vertical: 'top',
						horizontal: 'right'
					},
					message: resultSet.statusText,
					resumeHideDuration: 100,
					variant: 'error'
				});
			} else {
				const decoded = jwtDecode(resultSet.token);

				const authData = {
					isAuthenticated: true,
					isAuthenticating: false,

					accountId: decoded.accountId,
					displayName: resultSet.displayName,
					firstName: resultSet.firstName,
					lastName: resultSet.lastName,
					email: resultSet.email,
					phone: resultSet.phone,
					role: resultSet.role,
					department: resultSet.department,
					token: resultSet.token,
					photoUrl: resultSet.photoUrl || {},

					userFlags: resultSet.userFlags || [],
					companySettings: resultSet.companySettings || {},
					settings: resultSet.settings || {},
					preferences: resultSet.preferences,
					capabilities: resultSet.capabilities,

					// firstRun: resultSet.firstRun || false,

					alias: decoded.alias,
					applications: decoded.apps || [],
					projectId: decoded.projectId || '',
					subscriptions: decoded.subs || [],
					fundingSource: decoded.fundingSource || false,
					userKey: decoded.userKey,

					statusText: 'You have been successfully logged in.'
				};

				setSettingsSession({
					...settingsSession,
					firstRun: resultSet.firstRun || false,
					photoUrl: resultSet.photoUrl || {}
				});

				setAuthState(authData);
				setValue(authData);

				if (
					resultSet.companySettings?.twoFactor?.enableTimeForce &&
					resultSet.companySettings?.twoFactor?.enableTime === 'every'
				) {
					// If we enforce two factor for each login, we will make the user to the check.
					history(URLPaths.PAGES_VERIFY_PASS_CODE);
				} else if (resultSet?.twoFactor?.enableTwoFactor && resultSet?.twoFactor?.enableTime === 'every') {
					// If the user wants to set two factor. This will be overridden if the admin set it for the org.
					history(URLPaths.PAGES_VERIFY_PASS_CODE);
				} else if (resultSet?.twoFactor?.enableTwoFactor && resultSet?.twoFactor?.enableTime === 'once') {
					// If we only check once every 30 days, when we need to check and store the 30day mark date.
					if (settingsSession?.rememberCode30days) {
						if (
							settingsSession?.rememberCode30daysEnd <= Math.floor(Date.now() / 1000) &&
							settingsSession?.rememberCode30daysCheckNextLogin
						) {
							setSettingsSession({
								...settingsSession,
								rememberCode30daysCheckNextLogin: false
							});
							// If we have a 30 day remember set, we will wait till that expires before we ask for the code again.
							history(URLPaths.PAGES_VERIFY_PASS_CODE);
						} else {
							setSettingsSession({
								...settingsSession,
								rememberCode30daysCheckNextLogin: true
							});
						}
					} else {
						// If we have a 30 day remember set, we will wait till that expires before we ask for the code again.
						history(URLPaths.PAGES_VERIFY_PASS_CODE);
					}
				}
			}
		},
		onSettled: async () => {
			// console.log("I'm second!");
		}
	});

	const { mutate: mutateSignup } = useMutation({
		mutationFn: apiAuthSignup,
		// When mutate is called:
		onMutate: (regData) => {
			console.log('regData', regData);
		},
		onSuccess: (resultSet) => {
			console.log('resultSet', resultSet);
		},
		onSettled: async () => {
			// console.log("I'm second!");
		}
	});

	const signIn = (email, password) =>
		mutateLogin({
			email,
			password
		});

	const signUp = (data) => {
		mutateSignup(data);
	};

	const signOut = () => {
		const newUserState = { ...initialUserState, isLoginOut: true };
		setAuthState(newUserState);
		removeStorageKey();

		if (
			authState?.companySettings?.twoFactor?.enableTimeForce &&
			authState?.companySettings?.twoFactor?.enableTime === 'every'
		) {
			// If we enforce two factor for each login, we will make the user to the check.
			setSettingsSession({
				...settingsSession,
				twoFactorVerified: false,
				rememberCode30days: false,
				rememberCode30daysEnd: 0
			});
		}
	};

	const sendPasswordResetEmail = (email) => {};

	const confirmPasswordReset = (code, password) => {};

	useEffect(() => {
		// If we don't have a token, try to load from localstorage
		if (authState.token === null) {
			if (storedValue && storedValue.token !== null && authState.isLoginOut === false) {
				// console.log('Loading from disk');
				setAuthState({
					...storedValue,
					isAuthenticated: storedValue?.isAuthenticated === undefined ? false : storedValue.isAuthenticated
				});
			}
		}
	}, [authState.isLoginOut, authState.token, storedValue]);

	// Subscribe to user on mount
	// Because this sets state in the callback it will cause any ...
	// ... component that utilizes this hook to re-render with the ...
	// ... latest auth object.

	useEffect(() => {
		const unsubscribe = () => {};

		// Cleanup subscription on unmount
		return () => unsubscribe();
	}, []);

	// Return the user object and auth methods
	return {
		authState,
		signIn,
		signUp,
		signOut,
		sendPasswordResetEmail,
		confirmPasswordReset
	};
}

export { AuthProvide, useAuthProvide, useAuth, useAuthState, useAuthToken };
