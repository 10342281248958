import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

import chatbotDetailConfig from './Detail/chatbotDetailConfig';

import { AgentApp, AgentCreate, AgentList, DocumentUpload } from './lazyComponents';
// const AgentApp = lazy(() => import('./index'));
// const AgentCreate = lazy(() => import('../shared-components/AgentCreate/index'));
// const AgentList = lazy(() => import('./List/index'));
// // const AgentDetail = lazy(() => import('./Detail/index'));
//
// const DocumentUpload = lazy(() => import('./DocumentUpload/index'));

const AgentAppRoute = {
	path: '/agent',
	element: <AgentApp />,
	children: [
		{
			path: '',
			element: <Navigate to="/agent/list" />
		},
		{
			path: 'create',
			element: <AgentCreate />
		},
		{
			path: 'list',
			element: <AgentList />
		},
		// {
		// 	path: 'detail_current/:agentId',
		// 	element: <AgentDetail />
		// },
		{
			path: 'doc/upload',
			element: <DocumentUpload />
		},
		...chatbotDetailConfig.routes
	]
};
export default AgentAppRoute;
