import { useRef } from 'react';
import Box from '@mui/joy/Box';
import Button from '@mui/joy/Button';
import FormControl from '@mui/joy/FormControl';
import Textarea from '@mui/joy/Textarea';
import { IconButton, Stack } from '@mui/joy';
import pickColorBasedOnBgColor from '../../../../utils/pickColorBasedOnBgColor';
import { darken } from '@mui/material/styles';

const MessageInput = props => {
  const { config, textAreaValue, setTextAreaValue, onSubmit } = props;

  const textAreaRef = useRef(null);

  const handleClick = () => {
    if (textAreaValue.trim() !== '') {
      onSubmit();
      setTextAreaValue('');
    }
  };

  return (
    <Box sx={{ px: 2 }}>
      <FormControl>
        <Textarea
          // sx={{ bgcolor: 'orange' }}
          placeholder={
            config?.placeHolderText
              ? config?.placeHolderText
              : 'Type something here…'
          }
          aria-label="Message"
          ref={textAreaRef}
          onChange={e => {
            setTextAreaValue(e.target.value);
          }}
          value={textAreaValue}
          minRows={1}
          maxRows={10}
          endDecorator={
            <Stack
              // sx={{ bgcolor: 'black' }}
              direction="row"
              spacing={1}
              justifyContent="flex-end"
              flexGrow={1}
              minHeight={10}
            >
              <IconButton variant="plain" color="neutral">
                <i data-feather="smile" />
              </IconButton>
              <IconButton variant="plain" color="neutral">
                <i data-feather="more-horizontal" />
              </IconButton>
              <Button
                onClick={handleClick}
                sx={{
                  bgcolor: config.buttonColor,
                  ':hover': {
                    bgcolor: darken(config.buttonColor, 0.2),
                    color: pickColorBasedOnBgColor(
                      config?.buttonColor || '#ffffff',
                      '#ffffff',
                      '#000000',
                    ),
                  },
                  color: pickColorBasedOnBgColor(
                    config?.buttonColor || '#ffffff',
                    '#ffffff',
                    '#000000',
                  ),
                }}
              >
                Send
              </Button>
            </Stack>
          }
          onKeyDown={event => {
            if (event.key === 'Enter' && (event.metaKey || event.ctrlKey)) {
              handleClick();
            }
          }}
        />
      </FormControl>
    </Box>
  );
};

export default MessageInput;
