import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const CaseOverview = lazy(() => import('./Overview/index'));
const CaseList = lazy(() => import('./CaseList/index'));
const CaseDetails = lazy(() => import('./CaseDetails/index'));
const CaseCreate = lazy(() => import('./CaseCreate/index'));

const supportConfigs = {
	path: URLPaths.SUPPORT_PATH,
	children: [
		{
			path: '',
			exact: true,
			element: <CaseOverview />
		},
		{
			path: URLPaths.SUPPORT_LIST_PATH,
			element: <CaseList />
		},
		{
			path: `${URLPaths.SUPPORT_CASE_GET_PATH}:caseId`,
			element: <CaseDetails />
		},
		{
			path: URLPaths.SUPPORT_CREATE_PATH,
			element: <CaseCreate />
		}
	]
};

export default supportConfigs;
