import { styled } from '@mui/material/styles';
import FuseMessage from 'src/lib/@fuse/core/FuseMessage';
import { lazy, memo, Suspense } from 'react';
import { Outlet } from 'react-router-dom';
// import Configurator from 'src/lib/theme-layouts/shared-components/configurator/Configurator';
import FuseSuspense from 'src/lib/@fuse/core/FuseSuspense';
import FooterLayout1 from './components/FooterLayout1';
import LeftSideLayout1 from './components/LeftSideLayout1';
import NavbarWrapperLayout1 from './components/NavbarWrapperLayout1';
import RightSideLayout1 from './components/RightSideLayout1';
import ToolbarLayout1 from './components/ToolbarLayout1';
import { useSettings } from 'src/app/context/useSettings';
import { useNotification } from 'src/app/context/useNotification';

import GlobalBanner from '../shared-components/GlobalBanner';

const FuseDialog = lazy(() => import('src/lib/@fuse/core/FuseDialog/FuseDialog'));
const Root = styled('div')(({ config }) => ({
	...(config.mode === 'boxed' && {
		clipPath: 'inset(0)',
		maxWidth: `${config.containerWidth}px`,
		margin: '0 auto',
		boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)'
	}),
	...(config.mode === 'container' && {
		'& .container': {
			maxWidth: `${config.containerWidth}px`,
			width: '100%',
			margin: '0 auto'
		}
	})
}));

/**
 * The layout 1.
 */
function Layout1(props) {
	const { children } = props;

	const { settingsCurrent } = useSettings();
	const { notificationSystem, deleteNotification } = useNotification();

	const { config } = settingsCurrent.layout;

	const handleDismiss = (id) => {
		deleteNotification(id, 'notification_system');
	};

	return (
		<Root
			id="fuse-layout"
			config={config}
			className="flex w-full"
		>
			{config.leftSidePanel.display && <LeftSideLayout1 />}

			<div className="flex min-w-0 flex-auto">
				{config.navbar.display && config.navbar.position === 'left' && <NavbarWrapperLayout1 />}

				<main
					id="fuse-main"
					className="relative z-10 flex min-h-full min-w-0 flex-auto flex-col"
				>
					{config.toolbar.display && (
						<ToolbarLayout1 className={config.toolbar.style === 'fixed' ? 'sticky top-0' : ''} />
					)}

					{/* <div className="sticky top-0 z-99"> */}
					{/*  <Configurator /> */}
					{/* </div> */}

					<div className="relative z-10 flex min-h-0 flex-auto flex-col">
						<FuseSuspense>
							<div className="bg-red">
								{notificationSystem &&
									notificationSystem.length !== 0 &&
									notificationSystem.map((note) => (
										<GlobalBanner
											item={note}
											onClose={handleDismiss}
										/>
									))}
							</div>
							<Outlet />
						</FuseSuspense>

						<Suspense>
							<FuseDialog />
						</Suspense>
						{children}
					</div>

					{config.footer.display && (
						<FooterLayout1 className={config.footer.style === 'fixed' ? 'sticky bottom-0' : ''} />
					)}
				</main>

				{config.navbar.display && config.navbar.position === 'right' && <NavbarWrapperLayout1 />}
			</div>

			{config.rightSidePanel.display && <RightSideLayout1 />}
			<FuseMessage />
		</Root>
	);
}

export default memo(Layout1);
