import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

import { useSessionStorage } from 'react-storage-complete';
import useThemeMediaQuery from 'src/lib/@_fuse/hooks/useThemeMediaQuery';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import useWebSocket from 'react-use-websocket';
import { WS_API_URL } from 'src/app/constants';
import { useChatbotApp } from 'src/app/context/useChatbot';
import { useAuthState } from 'src/app/context/useAuth';
import { useDebounce } from 'src/lib/@_fuse/hooks';

import FingerprintJS from '@fingerprintjs/fingerprintjs';

import generateKey from 'src/utils/generateKey';
const chatAppContext = createContext({});

// Initialize an agent at application startup.
const fpPromise = FingerprintJS.load();

// Provider component that wraps your app and makes chat object ...
// ... available to any child component that calls useChatApp().
const ChatAppProvide = ({ children }) => {
	const chatValue = useChatAppProvide();

	return <chatAppContext.Provider value={chatValue}>{children}</chatAppContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
const useChatApp = () => {
	const context = useContext(chatAppContext);

	if (context === undefined) {
		throw new Error(`useChatApp must be used within a ChatAppProvide`);
	}

	return context;
};

const useChatAppProvide = () => {
	/*

  This Chat provider controls the websocket connection and communication with the server.
  It's different from the useChatBot context as the useChatBot controls the specific chatbot data.
  
  */

	const [searchParams, setSearchParams] = useSearchParams({});

	const { token, accountId, userKey } = useAuthState();
	const routeParams = useParams();
	const { chatbotState } = useChatbotApp();
	const contactId = routeParams.id;
	const paramsAgentId = routeParams.agentId;

	const isMobile = useThemeMediaQuery((theme) => theme.breakpoints.down('lg'));
	const location = useLocation();

	const shouldConnect = useRef(false);
	const chatJoined = useRef(false);
	// const [mkId, setMkId] = useState('');
	const mkId = useRef();

	const [agentOnline, setAgentOnline] = useState(false);

	const [mainSidebarOpen, setMainSidebarOpen] = useState(!isMobile);
	const [contactSidebarOpen, setContactSidebarOpen] = useState(false);
	const [sidebarContent, setSidebarContent] = useState('contactSidebar');
	const [userSidebarOpen, setUserSidebarOpen] = useState(false);

	const [selectedContactId, setSelectedContactId] = useState(false);
	const [chatPanelOpen, setChatPanelOpen] = useState(false);

	const [messageSending, setMessageSending] = useState(false);

	let clientType = 'guest';
	// let clientKey = searchParams.get('chatId'); // generateKey(8);

	if (paramsAgentId) {
		clientType = 'guest';
	} else {
		clientType = 'agent';
	}

	const pramChatId = searchParams.get('chatId');

	const chatId = useRef(null);

	const closeTimeout = useRef();

	const handleSetAgentOnline = useDebounce((newState) => {
		setAgentOnline(newState);
	}, 600);

	const { sendJsonMessage, lastJsonMessage, getWebSocket, readyState } = useWebSocket(
		WS_API_URL,
		{
			// Pass token to the backend as a header
			// protocols: ['Authorization', `token.${token}`, `agentMkId.${mkId}`],
			protocols: ['Authorization', `token.${token}`],
			share: true,
			retryOnError: true,

			// initiate timeout to close websocket on open event;
			onOpen: () => {
				console.log('onOpen');

				// if (chatId.current && !chatJoined.current)
				//   handelJoinRoom(accountId, chatId.current);
				// always clearTimeout if one exists on new connection
				if (closeTimeout.current) clearTimeout(closeTimeout.current);

				closeTimeout.current = setTimeout(() => {
					if (getWebSocket().readyState === 1) {
						getWebSocket().close();
					}
				}, 6 * 1000);
			},
			// Whenever a message is received, reset the close timeout
			onMessage: ({ data }) => {
				// console.log('onMessage');
				const message = JSON.parse(data);

				if (message.error) {
					console.error(message.error);
					return;
				}

				if (closeTimeout.current) clearTimeout(closeTimeout.current);

				closeTimeout.current = setTimeout(() => {
					if (getWebSocket().readyState === 1) {
						// getWebSocket().close();
					}
				}, 6 * 1000);
			},
			// Cancel timeout so that we don't attempt to close a closed websocket
			onClose: () => {
				// if (chatId.current) handelExitRoom(accountId);
				if (closeTimeout.current) clearTimeout(closeTimeout.current);
				// console.log('onClose');
			},
			// Always reconnect
			shouldReconnect: (_closeEvent) => true
		},
		shouldConnect.current
	);

	useEffect(() => {
		setMainSidebarOpen(!isMobile);
	}, [isMobile]);

	useEffect(() => {
		if (pramChatId) {
			chatId.current = pramChatId;
		}
	}, [pramChatId]);

	// useEffect(() => {
	//   console.log('Join the chat effect', chatId.current);
	//   if (chatId.current && !chatJoined.current) {
	//     handelJoinRoom(accountId, chatId.current);
	//   }
	// }, [chatId.current]);

	useEffect(() => {
		if (isMobile) {
			setMainSidebarOpen(false);
		}
	}, [location, isMobile]);

	useEffect(() => {
		// Calculate visitor id
		const identifyUser = async () => {
			// Get the visitor identifier when you need it.
			const fp = await fpPromise;
			const result = await fp.get();
			console.log('mkId.current', result.visitorId);
			mkId.current = result.visitorId;
			shouldConnect.current = true;
			// setStoredSettings({ ...storedSettings, _mkId: result.visitorId });
		};

		identifyUser().catch(console.error);
	}, []);

	useEffect(() => {
		console.log('Online mkId', mkId.current);
		handleSetAgentOnline(true);

		return () => {
			console.log('Offline mkId', mkId.current);
			handleSetAgentOnline(false);
		};
	}, [mkId.current]);

	const handelJoinRoom = useCallback((accountId, chatId) => {
		sendJsonMessage({
			action: 'chatJoin',
			chatId,
			accountId,
			clientType,
			mkId: mkId.current
			// clientId: clientId.current,
		});
		chatJoined.current = true;
	}, []);

	const handelExitRoom = useCallback((accountId, chatId) => {
		sendJsonMessage({
			action: 'chatExit',
			chatId,
			accountId,
			clientType
			// clientId: clientId.current,
		});
		chatJoined.current = false;
	}, []);

	const handelSendControlMessage = useCallback((chatId, clientType, action, botAgentId = null) => {
		sendJsonMessage({
			action,
			chatId,
			accountId,
			userKey,
			clientType,
			botAgentId
		});
		chatJoined.current = false;
	}, []);

	const handelExitSession = useCallback(
		(accountId) =>
			sendJsonMessage({
				action: 'agentEndsChatSession',
				chatId: chatId.current,
				clientType
			}),
		[]
	);

	const handleClickSendMessage = useCallback(
		(userQuery, chatId, action = 'agentChatWithGuest', requestKey = 'requestKey') => {
			setMessageSending(true);
			sendJsonMessage({
				action,
				accountId,
				chatId,
				requestKey,
				// query: userQuery,
				answer: userQuery
				// clientId: clientId.current,
			});
		},
		[]
	);

	const handleSetChatId = (NewChatId) => {
		chatId.current = NewChatId;
	};

	return {
		isMobile,
		chatPanelOpen,
		mainSidebarOpen,
		contactSidebarOpen,
		userSidebarOpen,
		sidebarContent,
		selectedContactId,
		setMainSidebarOpen,
		setContactSidebarOpen,
		setChatPanelOpen,
		setSidebarContent,
		setSelectedContactId,
		setUserSidebarOpen,

		// setClientType,
		messageSending,
		handelSendControlMessage,
		handleClickSendMessage,
		handleSetChatId,
		handelExitSession,
		chatIdCurrent: chatId.current,
		lastJsonMessage
	};
};

export { ChatAppProvide, useChatAppProvide, useChatApp };
