import IconButton from '@mui/material/IconButton';
import FuseSvgIcon from 'src/lib/@fuse/core/FuseSvgIcon';
import { CustomTime } from 'src/app/components/core/data';

import { cn } from 'src/utils/helpers';

const GlobalBanner = ({ item, onClose }) => {
	const handleClose = (ev) => {
		ev.preventDefault();
		ev.stopPropagation();

		if (onClose) {
			onClose(item?.skGsi1Pk);
		}
	};
	return (
		<div
			className={cn(
				item?.notificationType === 'subscription_trial' ? 'bg-yellow-300' : 'bg-gray-50',
				'relative isolate flex items-center gap-x-6 overflow-hidden  px-6 py-2.5 sm:px-3.5 sm:before:flex-1'
			)}
		>
			<div
				aria-hidden="true"
				className="absolute left-[max(-7rem,calc(50%-52rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
			>
				<div
					style={{
						clipPath:
							'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)'
					}}
					className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
				/>
			</div>
			<div
				aria-hidden="true"
				className="absolute left-[max(45rem,calc(50%+8rem))] top-1/2 -z-10 -translate-y-1/2 transform-gpu blur-2xl"
			>
				<div
					style={{
						clipPath:
							'polygon(74.8% 41.9%, 97.2% 73.2%, 100% 34.9%, 92.5% 0.4%, 87.5% 0%, 75% 28.6%, 58.5% 54.6%, 50.1% 56.8%, 46.9% 44%, 48.3% 17.4%, 24.7% 53.9%, 0% 27.9%, 11.9% 74.2%, 24.9% 54.1%, 68.6% 100%, 74.8% 41.9%)'
					}}
					className="aspect-[577/310] w-[36.0625rem] bg-gradient-to-r from-[#ff80b5] to-[#9089fc] opacity-30"
				/>
			</div>
			<div className="flex flex-wrap items-center gap-x-4 gap-y-2">
				<p className="text-sm leading-6 text-gray-900">
					<strong className="font-semibold">{item?.title}</strong>
					<svg
						viewBox="0 0 2 2"
						aria-hidden="true"
						className="mx-2 inline h-0.5 w-0.5 fill-current"
					>
						<circle
							r={1}
							cx={1}
							cy={1}
						/>
					</svg>
					{item?.description}
					{item?.notificationType === 'subscription_trial' && (
						<CustomTime
							timeStamp={item?.endTime}
							timeAgo
						/>
					)}
				</p>
			</div>
			<div className="flex flex-1 justify-end">
				{item?.excusable && (
					<IconButton
						disableRipple
						className="absolute right-0 top-0 p-8"
						color="inherit"
						size="small"
						onClick={handleClose}
					>
						<FuseSvgIcon
							size={12}
							className="opacity-75"
							color="inherit"
						>
							heroicons-solid:x-mark
						</FuseSvgIcon>
					</IconButton>
				)}
			</div>
		</div>
	);
};

export default GlobalBanner;
