import { useEffect, useState } from 'react';
import _ from 'src/lib/@lodash';

import { useAuthState } from '../context/useAuth';
import track from 'src/lib/@react-use-tracking';
import { getCookie, setCookie } from 'src/app/hooks/useCookie';
import generateKey from 'src/utils/generateKey';
import { useLocalStorage } from 'react-storage-complete';
import { Base64 } from 'js-base64';

const winLocation = (property, value) => {
	// make sure property is valid
	if (!_.isUndefined(window.location[property])) {
		if (!_.isUndefined(value)) {
			window.location[property] = value;
		} else {
			return window.location[property];
		}
	}
};

function getHostname() {
	return winLocation('hostname');
}

const getHostnameNoWww = () => {
	const hostname = getHostname();
	// const hostname = window.location.href.split('?')[0];

	if (hostname.indexOf('www.') === 0) {
		return hostname.replace('www.', '');
	}

	return hostname;
};

const RootChild = (props) => <>{props.children}</>;

const TrackedRootChild = track((props) => ({
	identity: {
		email: props?.email,
		name: props?.displayName
	},
	config: {
		accountId: props?.accountId,
		cookie: props?.cookieId,
		_mkId: props?._mkId
	}
}))(RootChild);

// This is the Root tacker. It's needed to add config and identify information to the tracker instance.
// Using this Root tracker enables passing things from the session to the backend tracker application.
const RootTracker = (props) => {
	const { displayName, alias, email, account } = useAuthState();
	const [cookieId, setCookieId] = useState('');

	const cookieName = 'cfTracker';
	const domain = getHostnameNoWww();

	const [storedSettings, _] = useLocalStorage(
		'settings',
		{},
		{
			prefix: 'config',
			shouldInitialize: true,
			emitterDisabled: false,
			decode: (t) => JSON.parse(Base64.decode(t))
		}
	);

	useEffect(() => {
		const cookie = getCookie(cookieName);

		if (cookie) {
			setCookieId(cookie);
		} else {
			const newCookieId = generateKey(16, false);
			setCookieId(newCookieId);
			setCookie(cookieName, newCookieId, {
				days: 730,
				domain,
				SameSite: 'Lax',
				Secure: winLocation('protocol') === 'https:'
			});
		}
	}, []);

	return (
		<TrackedRootChild
			children={props.children}
			_mkId={storedSettings?._mkId}
			cookieId={cookieId}
			displayName={displayName}
			alias={alias}
			account={account}
			email={email}
		/>
	);
};

export default RootTracker;
