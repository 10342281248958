import { createContext, useContext, useEffect, useReducer, useState } from 'react';
import { useFetchData } from 'src/utils/fetchAsyncCalls';
import { ntActiveUnacknowledged, ntAcknowledge } from 'src/app/containers/main/Notifications/api';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAuthState } from 'src/app/context/useAuth';
import { map } from 'd3';
import { assetUpdate } from 'src/app/containers/products/Skydera/ServiceManager/api';

const notificationContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
function NotificationProvide({ children }) {
	const messageValue = useNotificationProvide();
	return <notificationContext.Provider value={messageValue}>{children}</notificationContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
function useNotification() {
	const context = useContext(notificationContext);

	if (context === undefined) {
		throw new Error(`useMessage must be used within a MessageProvide`);
	}

	return context;
}

// Provider hook that creates auth object and handles state

function useNotificationProvide() {
	const { token } = useAuthState();

	const queryClient = useQueryClient();

	const [notificationState, setNotificationState] = useState(false);

	const [notificationUser, setNotificationUser] = useState([]);

	const [notificationSystem, setNotificationSystem] = useState([]);

	const [messageState, setMessageState] = useReducer((state, newState) => ({ ...state, ...newState }), {
		state: null,
		options: {
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			},
			autoHideDuration: 6000,
			message: 'Hi',
			variant: null
		}
	});

	const { data, isFetching, refetch, isIdle } = useFetchData(`ntActiveUnacknowledged`, [], ntActiveUnacknowledged);

	const { mutate: mutateNtAcknowledge } = useMutation({
		mutationFn: ntAcknowledge,
		onMutate: (res) => {
			if (res.data.notificationType === 'notification_user') {
				// Remove the acknowledged notification from notificationUser
				setNotificationUser((prevNotifications) =>
					prevNotifications.filter((notification) => notification.skGsi1Pk !== res.data.notificationId)
				);
			} else if (res.data.notificationType === 'notification_system') {
				// Remove the acknowledged notification from notificationSystem
				setNotificationSystem((prevNotifications) =>
					prevNotifications.filter((notification) => notification.skGsi1Pk !== res.data.notificationId)
				);
			}
		},
		onSuccess: () => {},
		onSettled: async () => {
			await queryClient.invalidateQueries('ntActiveUnacknowledged');
		}
	});

	const { mutate: mutateNtRead, isLoading } = useMutation({
		mutationFn: assetUpdate,
		// When mutate is called:
		onMutate: (createdApp) => {
			console.log('createdApp', createdApp);
		},
		onSuccess: () => {
			console.log('Success');
		},
		onSettled: async () => {
			console.log('Settled');
		}
	});

	// Wrap any Firebase methods we want to use making sure ...
	// ... to save the user to state.

	const showMessage = (options) => {
		setMessageState({
			state: true,
			options: { ...messageState.options, ...options }
		});
	};
	const hideMessage = () => {
		setMessageState({ state: null });
	};

	const toggleNotificationPanel = () => {
		setNotificationState(!notificationState);
	};

	const closeNotificationPanel = () => {
		setNotificationState(false);
	};

	const readUserNotifications = (id) => {
		const catalogUpdateData = {
			assetType: 'notification_user',
			assetId: id,
			table: 'app-records',
			hash_key: 'pk',
			range_key: 'sk_gsi_1_pk',
			toUpdateDict: { read: true }
		};

		mutateNtRead({
			token,
			data: catalogUpdateData
		});
	};
	const deleteNotification = (id, notificationType = 'notification_user') => {
		console.log('deleteNotification', id, notificationType);
		mutateNtAcknowledge({
			token,
			data: {
				notificationId: id,
				notificationType
			}
		});
	};

	const deleteAllNotifications = () => {
		notificationUser.map(({ skGsi1Pk, notificationType }) => deleteNotification(skGsi1Pk, notificationType));
	};

	useEffect(() => {
		if (data) {
			setNotificationUser(data?.user);
			setNotificationSystem(data?.system);
		}
	}, [data]);

	// Return the user object and auth methods
	return {
		closeNotificationPanel,
		toggleNotificationPanel,
		deleteNotification,
		deleteAllNotifications,
		readUserNotifications,
		notificationUser,
		notificationSystem,
		notificationState,
		setNotificationState,
		state: messageState.state,
		options: messageState.options,
		showMessage,
		hideMessage
	};
}

export { NotificationProvide, useNotificationProvide, useNotification };
