import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const AccountSettings = lazy(() => import('./index'));

const accountSettingsConfig = {
	path: URLPaths.SETTINGS_ACCOUNT_PATH,
	exact: true,
	element: <AccountSettings />
};

export default accountSettingsConfig;
