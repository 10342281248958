const colors = {
  grey: {
    50: '#F7F7F8',
    100: '#EBEBEF',
    200: '#D8D8DF',
    300: '#B9B9C6',
    400: '#8F8FA3',
    500: '#73738C',
    600: '#5A5A72',
    700: '#434356',
    800: '#25252D',
    900: '#131318'
  },
  blue: {
    50: '#F4FAFF',
    100: '#DDF1FF',
    200: '#ADDBFF',
    300: '#6FB6FF',
    400: '#3990FF',
    500: '#096BDE',
    600: '#054DA7',
    700: '#02367D',
    800: '#072859',
    900: '#00153C'
  },
  yellow: {
    50: '#FFF8C5',
    100: '#FAE17D',
    200: '#EAC54F',
    300: '#D4A72C',
    400: '#BF8700',
    500: '#9A6700',
    600: '#7D4E00',
    700: '#633C01',
    800: '#4D2D00',
    900: '#3B2300'
  },
  red: {
    50: '#FFF8F6',
    100: '#FFE9E8',
    200: '#FFC7C5',
    300: '#FF9192',
    400: '#FA5255',
    500: '#D3232F',
    600: '#A10E25',
    700: '#77061B',
    800: '#580013',
    900: '#39000D'
  },
  green: {
    50: '#F3FEF5',
    100: '#D7F5DD',
    200: '#77EC95',
    300: '#4CC76E',
    400: '#2CA24D',
    500: '#1A7D36',
    600: '#0F5D26',
    700: '#034318',
    800: '#002F0F',
    900: '#001D09'
  },
  purple: {
    50: '#FDF7FF',
    100: '#F4EAFF',
    200: '#E1CBFF',
    300: '#C69EFF',
    400: '#A374F9',
    500: '#814DDE',
    600: '#5F35AE',
    700: '#452382',
    800: '#301761',
    900: '#1D0A42'
  }
};
export default colors;