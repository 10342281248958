import { lazy } from 'react';
import { Navigate } from 'react-router-dom';

const ToolUpdate = lazy(() => import('./Update/index'));
const ToolsList = lazy(() => import('./List/index'));
const ToolsDetail = lazy(() => import('./Detail/index'));

const ExceptionView = lazy(() => import('./ExceptionView'));

const ToolsRoute = {
	path: '/tools',
	children: [
		{
			path: '',
			element: <Navigate to="/tools/list" />
		},
		{
			path: 'edit/:toolId',
			element: <ToolUpdate />
		},
		{
			path: 'detail/:toolId',
			element: <ToolsDetail />
		},
		{
			path: 'list',
			element: <ToolsList />,
			exact: true
		},
		{
			path: 'exception',
			element: <ExceptionView />,
			exact: true
		}
	]
};

export default ToolsRoute;
