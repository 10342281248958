import { createContext, useContext, useEffect, useLayoutEffect, useState, useRef } from 'react';

import { useFetchData } from 'src/utils/fetchAsyncCalls';

import { chatbotGet, chatbotUpdate } from './api';
import useStateReducer from 'src/app/hooks/useStateReducer';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { assetUpdate } from 'src/app/containers/products/Skydera/ServiceManager/api';
import { useAuthState } from 'src/app/context/useAuth';
import { useLocalStorage } from 'src/app/hooks';
import { useSessionStorage } from 'react-storage-complete';
import _ from 'src/lib/@lodash';
import { Base64 } from 'js-base64';

const chatbotAppContext = createContext({});

// Provider component that wraps your app and makes chat object ...
// ... available to any child component that calls useChatApp().
const ChatbotAppProvide = ({ children }) => {
	const chatbotValue = useChatbotAppProvide();

	return <chatbotAppContext.Provider value={chatbotValue}>{children}</chatbotAppContext.Provider>;
};

function useChatbotApp() {
	const context = useContext(chatbotAppContext);

	if (context === undefined) {
		throw new Error(`useChatbotApp must be used within a ChatbotAppProvide`);
	}

	return context;
}

const useChatbotAppProvide = () => {
	{
		/*

  This Chatbot provider controls the specific chatbot data.
  It's different from the useChat context as the useChat controls the websocket connection and communication with the server.

  */
	}
	const { token } = useAuthState();

	const queryClient = useQueryClient();

	const chatBotInitialState = {
		ids: [],
		entities: {},
		selectedItemId: null,
		path: [],
		chatbotId: null,
		chatbotType: null,
		appearance: { chatBoxPosition: 'right' },
		name: '',
		description: '',
		userChatCount: 5,
		rateLimit: 2,
		rateLimitTime: 1,
		rateLimitMessage: 5,
		aiModel: 'gpt-4',
		aiBrains: '',
		visibility: 'private',
		botCreativity: '',
		temperature: 0,
		questionChatLimit: 128,
		topK: 4
	};

	const [chatbotState, setChatbotState] = useStateReducer(chatBotInitialState);
	const chatbotIdActive = useRef(null);

	const {
		storedValue: storedValueChatBot,
		setValue: setValueChatBot,
		removeStorageKey: removeStorageChatBot
	} = useLocalStorage('chatbot-active', {});

	// const [settingsSession, setSettingsSession] = useSessionStorage(
	//   'chatbot',
	//   {},
	//   {
	//     prefix: 'active',
	//     shouldInitialize: true,
	//     emitterDisabled: false,
	//     encode: t => Base64.encode(JSON.stringify(t)),
	//     decode: t => JSON.parse(Base64.decode(t)),
	//   },
	// );

	const { mutate, isLoading: chatbotUpdateIsLoading } = useMutation({
		mutationFn: chatbotUpdate,
		// When mutate is called:
		onMutate: (chatbotNew) => {
			console.log('chatbotNew', chatbotNew);
		},
		onSuccess: () => {
			console.log('Success');
		},
		onSettled: async () => {
			await queryClient.invalidateQueries(`chatBotDetail-${chatbotState.chatbotId}`);
		}
	});

	const handleSubmitUpdate = (data, evt) => {
		mutate({
			token,
			chatbotId: chatbotState.chatbotId,
			chatbotType: chatbotState.chatbotType,
			data
		});
	};

	const {
		data,
		isFetching: chatbotDataIsFetching,
		refetch,
		isIdle
	} = useFetchData(
		`chatBotDetail-${chatbotState.chatbotId}`,
		[chatbotState.chatbotId, chatbotState.chatbotType],
		chatbotGet,
		{
			onSuccess: (res) => {
				console.log('Got new chatbot config', res);

				if (res) {
					chatbotIdActive.current = res.skGsi1Pk;
					setValueChatBot({
						...res,
						chatbotId: res.skGsi1Pk,
						chatbotType: res.botType
					});
				}
			},
			enabled: !!chatbotState?.chatbotId
		}
	);

	const setChatbotMeta = (chatbotId, chatbotType) => {
		if (chatbotIdActive.current !== chatbotId) {
			chatbotIdActive.current = chatbotId;

			console.log('setChatbotId');
			setValueChatBot({
				...storedValueChatBot,
				chatbotId,
				chatbotType
			});
			setChatbotState({
				...chatBotInitialState,
				chatbotId,
				chatbotType
			});

			// New session
			// setSettingsSession({
			//   ...chatBotInitialState,
			//   chatbotId: chatbotId,
			//   chatbotType: chatbotType,
			// });
		}

		return true;
	};

	const setChatbotType = (chatbotType) => {
		console.log('setChatbotType');
		setValueChatBot({ ...storedValueChatBot, chatbotType });
		return setChatbotState({ chatbotType });
	};

	const clearChatbotMeta = () => {
		console.log('clearChatbotId');
		chatbotIdActive.current = null;
		removeStorageChatBot();
		setChatbotState({
			...chatBotInitialState
		});

		return true;
	};

	useEffect(() => {
		console.log('!_.isEmpty(data)', !_.isEmpty(data));

		if (!_.isEmpty(data)) {
			console.log('Updating chat state. data', data);

			// data.chatbotId = data.skGsi1Pk;
			// data.chatbotType = data.botType;

			// let tmpData = {
			//   ...data,
			//   chatbotId: data.skGsi1Pk,
			//   chatbotType: data.botType,
			// };

			const tmpData = {
				chatbotId: data.skGsi1Pk,
				chatbotType: data.botType,
				...data
			};

			console.log('Updating chat state. tmpData', tmpData);

			setChatbotState({
				// chatbotId: data.skGsi1Pk,
				// chatbotType: data.botType,
				...data
			});
			// Add to local storage
			setValueChatBot({
				// chatbotId: data.skGsi1Pk,
				// chatbotType: data.botType,
				...tmpData
			});
		}
	}, [data, setChatbotState]);

	useLayoutEffect(() => {
		console.log(
			'chatbotState.chatbotId === null && !_.isEmpty(storedValueChatBot)',
			chatbotState.chatbotId === null,
			!_.isEmpty(storedValueChatBot)
		);

		// Reset chatbot values from stored data
		if (chatbotState.chatbotId === null && !_.isEmpty(storedValueChatBot)) {
			console.log('reset chatbotState storedValueChatBot', storedValueChatBot);
			setChatbotState({
				...storedValueChatBot
			});
		}
	}, [setChatbotState, storedValueChatBot]);

	return {
		handleSubmitUpdate,
		chatbotUpdateIsLoading,
		chatbotState,
		chatbotDataIsFetching,
		setChatbotMeta,
		clearChatbotMeta,
		setChatbotType
	};
};

export { ChatbotAppProvide, useChatbotAppProvide, useChatbotApp };
