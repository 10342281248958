import Hidden from '@mui/material/Hidden';
import { styled } from '@mui/material/styles';
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import NavbarStyle1Content from './NavbarStyle1Content';
import { useNavBar } from 'src/app/context/useNavbar';
import { useSettings } from 'src/app/context/useSettings';

const navbarWidth = 280;
const StyledNavBar = styled('div')(({ theme, open, position }) => ({
	minWidth: navbarWidth,
	width: navbarWidth,
	maxWidth: navbarWidth,
	...(!open && {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.leavingScreen
		}),
		...(position === 'left' && {
			marginLeft: `-${navbarWidth}px`
		}),
		...(position === 'right' && {
			marginRight: `-${navbarWidth}px`
		})
	}),
	...(open && {
		...(position === 'left' && {
			borderRight: `1px solid ${theme.palette.divider}`
		}),
		...(position === 'right' && {
			borderLeft: `1px solid ${theme.palette.divider}`
		}),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen
		})
	})
}));
const StyledNavBarMobile = styled(SwipeableDrawer)(() => ({
	'& .MuiDrawer-paper': {
		minWidth: navbarWidth,
		width: navbarWidth,
		maxWidth: navbarWidth
	}
}));

/**
 * The navbar style 1.
 */
function NavbarStyle1() {
	const { navBarState: navbar, navbarCloseMobile } = useNavBar();
	const { settingsCurrent } = useSettings();

	const { config } = settingsCurrent.layout;

	// FIXME @@LC: We dont have a navbar reset
	// useEffect(() => {
	//   return () => {
	//     dispatch(resetNavbar());
	//   };
	// }, [dispatch]);
	return (
		<>
			<Hidden lgDown>
				<StyledNavBar
					className="sticky top-0 z-20 h-screen flex-auto shrink-0 flex-col overflow-hidden shadow"
					open={navbar.open}
					position={config.navbar.position}
				>
					<NavbarStyle1Content />
				</StyledNavBar>
			</Hidden>

			<Hidden lgUp>
				<StyledNavBarMobile
					classes={{
						paper: 'flex-col flex-auto h-full'
					}}
					anchor={config.navbar.position}
					variant="temporary"
					open={navbar.mobileOpen}
					onClose={() => navbarCloseMobile()}
					onOpen={() => {}}
					disableSwipeToOpen
					ModalProps={{
						keepMounted: true // Better open performance on mobile.
					}}
				>
					<NavbarStyle1Content />
				</StyledNavBarMobile>
			</Hidden>
		</>
	);
}

export default NavbarStyle1;
