import Button from '@mui/material/Button';
import FuseSvgIcon from 'src/lib/@fuse/core/FuseSvgIcon';
import { useDialog } from 'src/app/context/useDialog';

import FeedBackForm from './FeedbackForm';
import IconButton from '@mui/material/IconButton';
import Icon from '@mui/material/Icon';
import Divider from 'src/app/components/Divider';

/**
 * The documentation button.
 */
function FeedbackButton(props) {
	const { className = '' } = props;
	const { openDialog, closeDialog } = useDialog();
	return (
		<Button
			role="button"
			className={className}
			variant="contained"
			color="primary"
			startIcon={<FuseSvgIcon size={16}>heroicons-outline:light-bulb</FuseSvgIcon>}
			onClick={() =>
				openDialog({
					children: (
						<div className="">
							<div className="">
								<div className="flex justify-between items-center px-16 py-4">
									<div className="text-20 font-semibold leading-5">Feedback</div>
									<IconButton
										className=""
										key="close"
										aria-label="Close"
										color="secondary"
										onClick={() => closeDialog()}
									>
										<Icon>close</Icon>
									</IconButton>
								</div>
								<Divider className="" />
							</div>
							<FeedBackForm closeDialog={closeDialog} />
						</div>
					)
				})
			}
		>
			Feedback
		</Button>
	);
}

export default FeedbackButton;
