import { createContext } from 'react';
import PropTypes from 'prop-types';
import { useAuthState } from './useAuth';

// https://github.com/romaindso/react-feature-flags

const FeatureFlags = createContext();

const Flags = (props) => {
	const { authorizedFlags, exactFlags } = props;

	const handleMatchingFlags = (flags) =>
		flags.filter((flag) => flag.isActive && props.authorizedFlags.includes(flag.name));

	const resolveRender = (matchingFlags) =>
		props.children && props.children ? props.children : props.renderOn(matchingFlags);

	return (
		<FeatureFlags.Consumer>
			{(flags) => {
				const matchingFlags = handleMatchingFlags(flags);

				if (exactFlags) {
					return matchingFlags.length === authorizedFlags.length
						? resolveRender(matchingFlags)
						: props.renderOff(matchingFlags);
				} else {
					return matchingFlags.length ? resolveRender(matchingFlags) : props.renderOff(matchingFlags);
				}
			}}
		</FeatureFlags.Consumer>
	);
};

Flags.propTypes = {
	authorizedFlags: PropTypes.arrayOf(PropTypes.string).isRequired,
	exactFlags: PropTypes.bool,
	renderOn: PropTypes.func,
	renderOff: PropTypes.func
};

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const FlagsProvider = (props) => {
	const { children, value } = props;

	return <FeatureFlags.Provider value={value}>{children}</FeatureFlags.Provider>;
};

FeatureFlags.propTypes = {
	value: PropTypes.arrayOf(
		PropTypes.shape({
			name: PropTypes.string,
			isActive: PropTypes.bool
		})
	).isRequired
};

export const useUserFlags = () => {
	const { userFlags } = useAuthState();

	return {
		userFlags,
		Flags
	};
};
