import callApi from 'src/utils/fetchAsyncCalls';
import { APP_DOMAIN, URLMap, API_ROOT } from '../constants';

export const apiAuthLogin = (data) =>
	callApi(URLMap.AUTH_USER_LOGIN, {
		data: { ...data, app_domain: APP_DOMAIN }
	});

export const apiAuthSignup = (data) =>
	callApi(URLMap.AUTH_USER_SIGNUP, {
		data: {
			...data,
			app_domain: APP_DOMAIN
		}
	});

export const apiUserUpdate = ({ token, updateType, data }) => {
	let url = URLMap.USER_UPDATE;

	if (updateType) {
		url += `?update_type=${updateType}`;
	}

	return callApi(url, {
		token,
		data: { ...data, domain: APP_DOMAIN }
	});
};

export const apiUserSettings = (token) =>
	callApi(URLMap.USER_SETTINGS, {
		token
	});
export const apiUserPref = (token) =>
	callApi(URLMap.USER_PREFERENCES_INFO, {
		token
	});

export const namespaceNameListGet = (token) => callApi(URLMap.NAMESPACE_NAME_LIST_GET, { token });

export const chatbotGet = (token, chatbotId, chatbotType) => {
	let url = API_ROOT;

	if (chatbotType === 'chatbot') {
		url = `${url}chatbot/${chatbotId}`;
	} else if (chatbotType === 'agent') {
		url = `${url}agent/${chatbotId}`;
	}

	return callApi(url, {
		token
	});
};

export const chatbotUpdate = ({ token, chatbotId, chatbotType, data }) => {
	let url = API_ROOT;

	console.log('chatbotId, chatbotType, data', chatbotId, chatbotType, data);

	if (chatbotType === 'chatbot') {
		url = `${url}chatbot/${chatbotId}`;
	} else if (chatbotType === 'agent') {
		url = `${url}agent/${chatbotId}`;
	}

	return callApi(url, {
		token,
		data
	});
};
