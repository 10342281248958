import Avatar from '@mui/joy/Avatar';
import Button from '@mui/joy/Button';
import Chip from '@mui/joy/Chip';
import IconButton from '@mui/joy/IconButton';
import Stack from '@mui/joy/Stack';
import Typography from '@mui/joy/Typography';
import CircleIcon from '@mui/icons-material/Circle';
import CloseRoundedIcon from '@mui/icons-material/CloseRounded';
import pickColorBasedOnBgColor from '../../../../utils/pickColorBasedOnBgColor';
import { lighten } from '@mui/material/styles';

const MessagesPaneHeader = props => {
  const { sender, config, setState } = props;

  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{
        width: '100%',
        // borderBottom: '1px solid',
        borderColor: 'divider',
        // bgcolor: 'red',
      }}
      py={{ xs: 2, md: 2 }}
      px={{ xs: 1, md: 2 }}
    >
      <Stack direction="row" spacing={{ xs: 1, md: 2 }} alignItems="center">
        <IconButton
          variant="plain"
          color="neutral"
          size="sm"
          sx={{
            display: { xs: 'inline-flex', sm: 'none' },
            ':hover': {
              bgcolor: lighten(config.buttonColor, 0.2),
              color: pickColorBasedOnBgColor(
                config?.buttonColor || '#000000',
                '#000000',
                '#ffffff',
              ),
            },
          }}
          // onClick={() => toggleMessagesPane()}
        >
          <i data-feather="chevron-left" />
        </IconButton>
        <Avatar size="lg" src={sender.avatar} />

        <div>
          <Typography
            fontWeight="lg"
            fontSize="lg"
            noWrap
            sx={{
              color: pickColorBasedOnBgColor(
                config?.primaryColor || '#ffffff',
                '#ffffff',
                '#000000',
              ),
            }}
            endDecorator={
              sender.online ? (
                <Chip
                  variant="outlined"
                  size="sm"
                  color="neutral"
                  sx={{
                    '--Chip-radius': '6px',
                    color: pickColorBasedOnBgColor(
                      config?.primaryColor || '#ffffff',
                      '#ffffff',
                      '#000000',
                    ),
                  }}
                  startDecorator={
                    <CircleIcon sx={{ fontSize: 8 }} color={'success'} />
                  }
                  slotProps={{ root: { component: 'span' } }}
                >
                  Online
                </Chip>
              ) : undefined
            }
          >
            {sender.name}
          </Typography>

          <Typography level="body2">{sender.username}</Typography>
        </div>
      </Stack>
      <Stack spacing={1} direction="row" alignItems="center">
        <IconButton
          variant="outlined"
          // sx={{ ml: 'auto' }}
          sx={{
            color: pickColorBasedOnBgColor(
              config?.primaryColor || '#ffffff',
              '#ffffff',
              '#000000',
            ),
          }}
          size="sm"
          onClick={() => setState({ isOpen: false })}
        >
          <CloseRoundedIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

export default MessagesPaneHeader;
