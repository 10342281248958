import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useFieldArray } from 'react-hook-form';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import Input from 'src/app/components/core/form/Input';
import CustomSelect from 'src/app/components/core/form/CustomSelect';

function EnvField(props) {
	const { className, label, subLabel, name, errors, control } = props;

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	});

	return (
		<div className={clsx(className, 'flex flex-col')}>
			<div className="">
				<div className="flex flex-col">
					<span className="text-15 col-span-3">Add individually</span>
					<span className="text-13 text-gray-600 col-span-3">
						Add a key-value pair to specify an environment variable.
					</span>
				</div>
				{fields.length !== 0 && (
					<div className="grid grid-cols-4 space-x-24 mt-16">
						<div>Key</div>
						{/* <div>Value Type</div> */}
						<div>Value</div>
					</div>
				)}
			</div>
			<div className="space-y-16 mt-8">
				{fields.map((item, index) => (
					<div
						key={index}
						className="grid grid-cols-3 space-x-24 sm:border-b-1 md:border-none"
					>
						<Input
							className=""
							label=""
							control={control}
							name={`${name}.${index}.key`}
							errors={errors}
							fullWidth
							required
						/>
						<CustomSelect
							name={`${name}.${index}.valueType`}
							label=""
							control={control}
							options={[
								{ label: 'Value', value: 'value' },
								{ label: 'ValueFrom', value: 'valueFrom' }
							]}
							isLoading={false}
							disableLabel
						/>
						<div className="flex flex-row items-center space-x-8">
							<Input
								className=""
								label=""
								control={control}
								name={`${name}.${index}.value`}
								errors={errors}
								fullWidth
								required
							/>
							<IconButton
								key="close"
								aria-label="Close"
								color="secondary"
								onClick={() => remove(index)}
							>
								<Icon>close</Icon>
							</IconButton>
						</div>
					</div>
				))}
			</div>

			<div className="flex flex-row justify-between mt-16">
				<Button
					variant="contained"
					onClick={() => {
						// append({ key: '', value: '', valueType: 'value' });
						append({ key: '', value: '' });
					}}
				>
					Add
				</Button>
			</div>
		</div>
	);
}

EnvField.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.array,
	errors: PropTypes.object,
	control: PropTypes.object.isRequired
};

export default EnvField;
