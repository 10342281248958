import { useEffect, useState } from 'react';
import clsx from 'clsx';

import { lighten, styled } from '@mui/material/styles';
import { Transition } from '@headlessui/react';

import zxcvbn from 'zxcvbn';
import Input from '../Input';
import PropTypes from 'prop-types';

const Root = styled('div')(({ theme }) => ({
	'& .weakBackgroundColor': {
		backgroundColor: lighten(theme.palette.error.main, 0.6)
	},
	'& .fairBackgroundColor': {
		backgroundColor: lighten(theme.palette.error.main, 0.3)
	},
	'& .goodBackgroundColor': {
		backgroundColor: theme.palette.secondary.main
	},
	'& .strongBackgroundColor': {
		backgroundColor: theme.palette.secondary.dark
	},
	'& .inputAdornmentIcon': {
		color: '#555'
	},
	'& .inputAdornment': {
		position: 'relative'
	}
}));

const PasswordMeter = (props) => {
	const { className, control, setValue, inputPassword } = props;

	const [expanded, setExpanded] = useState(false);
	const [passStrength, setPassStrength] = useState({ score: 0 });
	const [enabled, setEnabled] = useState({
		weak: false,
		fair: false,
		good: false,
		strong: false
	});

	const createPasswordLabel = (score) => {
		switch (score) {
			case 0:
				return 'Too short';
			case 1:
				return 'Weak';
			case 2:
				return 'Fair';
			case 3:
				return 'Good';
			case 4:
				return 'Strong';
			default:
				return 'Weak';
		}
	};

	useEffect(() => {
		if (inputPassword) {
			setExpanded(true);

			const passwordObj = zxcvbn(inputPassword);
			setPassStrength(passwordObj);

			if (passwordObj.score === 0) {
				setValue('passwordStrength', 'short');
				setEnabled({
					weak: false,
					fair: false,
					good: false,
					strong: false
				});
			} else if (passwordObj.score === 1) {
				setValue('passwordStrength', 'weak');
				setEnabled({
					weak: true,
					fair: false,
					good: false,
					strong: false
				});
			} else if (passwordObj.score === 2) {
				setValue('passwordStrength', 'fair');
				setEnabled({
					weak: true,
					fair: true,
					good: false,
					strong: false
				});
			} else if (passwordObj.score === 3) {
				setValue('passwordStrength', 'good');
				setEnabled({
					weak: true,
					fair: true,
					good: true,
					strong: false
				});
			} else if (passwordObj.score === 4) {
				setValue('passwordStrength', 'strong');
				setEnabled({
					weak: true,
					fair: true,
					good: true,
					strong: true
				});
			}
		}
	}, [inputPassword, setValue]);

	return (
		<Root className={clsx(className, 'flex flex-col mt-4')}>
			<Input
				className="hidden"
				name="passwordStrength"
				fieldType="text"
				control={control}
				fullWidth
			/>

			<Transition
				className=""
				show={expanded}
				enter="transition-opacity duration-600"
				enterFrom="opacity-0"
				enterTo="opacity-100"
				leave="transition-opacity duration-150"
				leaveFrom="opacity-100"
				leaveTo="opacity-0"
			>
				<div>
					<div className="flex flex-row h-6 space-x-4">
						<div className="relative w-1/4">
							<div className="w-full h-full bg-gray-200 absolute rounded-l-12" />
							<div
								className={clsx(
									enabled.weak ? 'w-full' : 'w-0',
									'weakBackgroundColor',
									'transition-all ease-out duration-1000 h-full relative rounded-l-12 overflow-hidden'
								)}
							/>
						</div>
						<div className="relative w-1/4">
							<div className="w-full h-full bg-gray-200 absolute" />
							<div
								className={clsx(
									enabled.fair ? 'w-full' : 'w-0',
									'fairBackgroundColor',
									'transition-all ease-out duration-1000 h-full relative'
								)}
							/>
						</div>
						<div className="relative w-1/4">
							<div className="w-full h-full bg-gray-200 absolute" />
							<div
								className={clsx(
									enabled.good ? 'w-full' : 'w-0',
									'goodBackgroundColor',
									'transition-all ease-out duration-1000 h-full relative'
								)}
							/>
						</div>
						<div className="relative w-1/4">
							<div className="w-full h-full bg-gray-200 absolute rounded-r-12" />
							<div
								className={clsx(
									enabled.strong ? 'w-full' : 'w-0',
									'strongBackgroundColor',
									'transition-all ease-out duration-1000 h-full relative rounded-r-12 overflow-hidden'
								)}
							/>
						</div>
					</div>
					{passStrength ? (
						<div className="flex text-sm font-light text-gray-700 justify-end h-28">
							{createPasswordLabel(passStrength.score)}
						</div>
					) : null}
				</div>
			</Transition>
		</Root>
	);
};

PasswordMeter.propTypes = {
	className: PropTypes.string,
	control: PropTypes.object.isRequired,
	setValue: PropTypes.func.isRequired,
	inputPassword: PropTypes.string
};

export default PasswordMeter;
