import { useEffect, useLayoutEffect, createContext, useContext, useState } from 'react';
import _ from 'src/lib/@lodash';
import { useAuthState } from 'src/app/context/useAuth';
import { useFetchData } from 'src/utils/fetchAsyncCalls';
import { fetchSubscriptions } from 'src/app/containers/main/api';
import { useNavigate } from 'react-router-dom';

import FuseSplashScreen from 'src/lib/@fuse/core/FuseSplashScreen';

const subscriptionContext = createContext();

// Provider component that wraps your app and makes auth object ...
const SubscriptionProvide = ({ children }) => {
	const subscriptionValue = useSubscriptionProvide();
	return <subscriptionContext.Provider value={subscriptionValue}>{children}</subscriptionContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
const useSubscription = () => {
	const context = useContext(subscriptionContext);

	if (context === undefined) {
		throw new Error(`useSubscription must be used within a NavBarProvide`);
	}

	return context;
};

// Provider hook that creates auth object and handles state

const useSubscriptionProvide = (props) => {
	const { token } = useAuthState();
	const history = useNavigate();

	const [subscriptionList, setSubscriptionList] = useState({});

	const { status, data, error, isFetching, isIdle } = useFetchData('subscriptionList', [], fetchSubscriptions, {
		onSuccess: () => {},
		enabled: !!token
	});

	// useLayoutEffect(() => {
	// 	console.log('useSubscriptionProvide data', data);
	//
	// 	if (data) {
	// 		console.log('Finished waiting for AuthCheck.');
	// 		setSubscriptionList(data);
	//
	// 		// Object.entries(data).forEach(([key, value]) => {
	// 		// 	if (value.status.status === 'canceled') {
	// 		// 		console.log('Canceled');
	// 		// 		history('/pages/subscription/update');
	// 		// 	} else if (value.status.status === 'paused') {
	// 		// 		console.log('Paused');
	// 		// 		history('/pages/subscription/paused');
	// 		// 	}
	// 		// });
	// 	}
	// }, [data]);

	return { subscriptionList };
};

export { SubscriptionProvide, useSubscription, useSubscriptionProvide };
