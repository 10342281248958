import { createContext, useContext, useEffect, useReducer } from 'react';

import { useLocalStorage } from '../hooks';
import { namespaceNameListGet } from './api';
import { useAuthState } from './useAuth';

import { useFetchData } from 'src/utils/fetchAsyncCalls';
const namespaceContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
const NamespaceProvide = ({ children }) => {
	const namespace = useNamespaceProvide();
	return <namespaceContext.Provider value={namespace}>{children}</namespaceContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
const useNamespace = () => {
	const context = useContext(namespaceContext);

	if (context === undefined) {
		throw new Error(`useNamespace must be used within a NamespaceProvide`);
	}

	return context;
};

// Provider hook that creates auth object and handles state

const useNamespaceProvide = () => {
	const { token } = useAuthState();

	const [state, setState] = useReducer((state, newState) => ({ ...state, ...newState }), {
		namespace: '',
		namespaceList: [],
		namespaceData: {}
	});

	const { storedValue, setValue, removeStorageKey } = useLocalStorage('sky-namespace', {});

	const { status, data, error, isFetching } = useFetchData('namespaceNameList', [], namespaceNameListGet, {
		enabled: !!token
	});

	useEffect(() => {
		if (state.namespace === '' && data && data['nameList'] !== undefined && data['nameList'][0]) {
			setState({ namespace: data['nameList'][0].value });
		}

		if (data) {
			if (data['nameList'] !== undefined) {
				setState({ namespaceList: data['nameList'] });
			}

			if (data['data'] !== undefined) {
				setState({ namespaceData: data['data'] });
			}
		}
	}, [data, state.namespace]);

	const setNamespace = (namespace) => {
		setState({ namespace });
	};

	// Return the user object and auth methods
	return {
		namespace: state.namespace,
		namespaceList: state.namespaceList,
		namespaceData: state.namespaceData,
		setNamespace
	};
};

export { NamespaceProvide, useNamespace, useNamespaceProvide };
