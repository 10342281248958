import FuseScrollbars from 'src/lib/@_fuse/core/FuseScrollbars';
import { lighten, styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';
import { useEffect, useRef, useState } from 'react';
import InputBase from '@mui/material/InputBase';
import Paper from '@mui/material/Paper';
import FuseSvgIcon from 'src/lib/@_fuse/core/FuseSvgIcon';
import Toolbar from '@mui/material/Toolbar';
import { useParams } from 'react-router-dom';
import Box from '@mui/material/Box';
import ContactAvatar from '../ContactAvatar';
import ChatMoreMenu from './ChatMoreMenu';
import { useChatApp } from 'src/app/context/useChat';
import { useFetchData } from 'src/utils/fetchAsyncCalls';
import { chatGet } from 'src/app/containers/products/SynthAgents/Chat/api';

const StyledMessageRow = styled('div')(({ theme }) => ({
	'&.contact': {
		'& .bubble': {
			backgroundColor: theme.palette.secondary.light,
			color: theme.palette.secondary.contrastText,
			borderTopLeftRadius: 5,
			borderBottomLeftRadius: 5,
			borderTopRightRadius: 20,
			borderBottomRightRadius: 20,
			'& .time': {
				marginLeft: 12
			}
		},
		'&.first-of-group': {
			'& .bubble': {
				borderTopLeftRadius: 20
			}
		},
		'&.last-of-group': {
			'& .bubble': {
				borderBottomLeftRadius: 20
			}
		}
	},
	'&.me': {
		paddingLeft: 40,

		'& .bubble': {
			marginLeft: 'auto',
			backgroundColor: theme.palette.primary.light,
			color: theme.palette.primary.contrastText,
			borderTopLeftRadius: 20,
			borderBottomLeftRadius: 20,
			borderTopRightRadius: 5,
			borderBottomRightRadius: 5,
			'& .time': {
				justifyContent: 'flex-end',
				right: 0,
				marginRight: 12
			}
		},
		'&.first-of-group': {
			'& .bubble': {
				borderTopRightRadius: 20
			}
		},

		'&.last-of-group': {
			'& .bubble': {
				borderBottomRightRadius: 20
			}
		}
	},
	'&.contact + .me, &.me + .contact': {
		paddingTop: 20,
		marginTop: 20
	},
	'&.first-of-group': {
		'& .bubble': {
			borderTopLeftRadius: 20,
			paddingTop: 13
		}
	},
	'&.last-of-group': {
		'& .bubble': {
			borderBottomLeftRadius: 20,
			paddingBottom: 13,
			'& .time': {
				display: 'flex'
			}
		}
	}
}));

function Chat(props) {
	const { setMainSidebarOpen, setContactSidebarOpen, handleClickSendMessage, lastJsonMessage } = useChatApp();

	const { data: chat = { dialog: [] } } = useFetchData('chatGet', [], chatGet);

	const [chatLocalState, setChatLocalState] = useState([]);

	// const chat = {};
	const user = {
		id: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
		name: 'Lecole Cole',
		email: 'lecole@company.com',
		status: 'online',
		avatar: 'assets/images/avatars/brian-hughes.jpg',
		about: "Hi there! I'm using SynthAgentChat."
	};

	const routeParams = useParams();
	const contactId = routeParams.id;
	const paramsAgentId = routeParams.agentId;

	const chatRef = useRef(null);
	const [messageText, setMessageText] = useState('');

	// useEffect(() => {
	//   dispatch(getChat(contactId));
	// }, [contactId, dispatch]);

	// useEffect(() => {
	//   if (chat) {
	//     setChatLocalState(chat);
	//   }
	// }, [chat]);

	useEffect(() => {
		if (chatLocalState) {
			setTimeout(scrollToBottom);
		}
	}, [chatLocalState]);

	useEffect(() => {
		console.log('lastJsonMessage', lastJsonMessage?.action);

		if (lastJsonMessage && lastJsonMessage?.action === 'gptChatWithGuest') {
			setChatLocalState((chatLocalState) => [
				...chatLocalState,
				{
					id: 'ca8523d8-faed-45f7-af09-f6bd5c3f3875',
					chatId: '',
					contactId: 'cfaad35d-07a3-4447-a6c3-d8c3d54fd5df',
					value: lastJsonMessage?.answer,
					createdAt: '2022-01-12T09:58:48.732Z'
				}
			]);
		}
	}, [lastJsonMessage]);

	function scrollToBottom() {
		if (!chatRef.current) {
			return;
		}

		chatRef.current.scrollTo({
			top: chatRef.current.scrollHeight,
			behavior: 'smooth'
		});
	}

	function isFirstMessageOfGroup(item, i) {
		return i === 0 || (chatLocalState[i - 1] && chatLocalState[i - 1].contactId !== item.contactId);
	}

	function isLastMessageOfGroup(item, i) {
		return (
			i === chatLocalState.length - 1 ||
			(chatLocalState[i + 1] && chatLocalState[i + 1].contactId !== item.contactId)
		);
	}

	function onInputChange(ev) {
		setMessageText(ev.target.value);
	}

	function onMessageSubmit(ev) {
		ev.preventDefault();

		if (messageText === '') {
			return;
		}

		setChatLocalState((chatLocalState) => [
			...chatLocalState,
			{
				id: '39944b00-1ffe-4ffb-8ca6-13c292812e06',
				chatId: '',
				contactId: '9d3f0e7f-dcbd-4e56-a5e8-87b8154e9edf',
				value: messageText,
				createdAt: '2022-01-12T10:00:48.732Z'
			}
		]);

		handleClickSendMessage(messageText);
		setMessageText('');
	}

	// if (!user || !chat || !selectedContact) {
	//   return null;
	// }

	return (
		<>
			<Box
				className="w-full border-b-1"
				sx={{
					backgroundColor: (theme) =>
						theme.palette.mode === 'light'
							? lighten(theme.palette.background.default, 0.4)
							: lighten(theme.palette.background.default, 0.02)
				}}
			>
				<Toolbar className="flex items-center justify-between px-16 w-full">
					<div className="flex items-center">
						<IconButton
							aria-label="Open drawer"
							onClick={() => setMainSidebarOpen(true)}
							className="flex lg:hidden"
							size="large"
						>
							<FuseSvgIcon>heroicons-outline:chat</FuseSvgIcon>
						</IconButton>
						<div
							className="flex items-center cursor-pointer"
							onClick={() => {
								setContactSidebarOpen(true);
							}}
							onKeyDown={() => setContactSidebarOpen(true)}
							role="button"
							tabIndex={0}
						>
							<ContactAvatar
								className="relative mx-8"
								// contact={selectedContact}
							/>
							<Typography
								color="inherit"
								className="text-16 font-semibold px-4"
							>
								{/* {selectedContact.name} */}
							</Typography>
						</div>
					</div>
					<ChatMoreMenu className="-mx-8" />
				</Toolbar>
			</Box>

			<div className="flex flex-auto h-full min-h-0 w-full">
				<div className={clsx('flex flex-1 z-10 flex-col relative', props.className)}>
					<FuseScrollbars
						ref={chatRef}
						className="flex flex-1 flex-col overflow-y-auto"
					>
						{chatLocalState?.length > 0 && (
							<div className="flex flex-col pt-16 px-16 pb-40">
								{chatLocalState.map((item, i) => (
									<StyledMessageRow
										key={i}
										className={clsx(
											'flex flex-col grow-0 shrink-0 items-start justify-end relative px-16 pb-4',
											item.contactId === user.id ? 'me' : 'contact',
											{ 'first-of-group': isFirstMessageOfGroup(item, i) },
											{ 'last-of-group': isLastMessageOfGroup(item, i) },
											i + 1 === chatLocalState.length && 'pb-96'
										)}
									>
										<div className="bubble flex relative items-center justify-center p-12 max-w-full">
											<div className="leading-tight whitespace-pre-wrap">{item.value}</div>
											<Typography
												className="time absolute hidden w-full text-11 mt-8 -mb-24 ltr:left-0 rtl:right-0 bottom-0 whitespace-nowrap"
												color="text.secondary"
											>
												{formatDistanceToNow(new Date(item.createdAt), {
													addSuffix: true
												})}
											</Typography>
										</div>
									</StyledMessageRow>
								))}
							</div>
						)}
					</FuseScrollbars>
					{chatLocalState && (
						<Paper
							square
							component="form"
							onSubmit={onMessageSubmit}
							className="absolute border-t-1 bottom-0 right-0 left-0 py-16 px-16"
							sx={{
								backgroundColor: (theme) =>
									theme.palette.mode === 'light'
										? lighten(theme.palette.background.default, 0.4)
										: lighten(theme.palette.background.default, 0.02)
							}}
						>
							<div className="flex items-center relative">
								<IconButton
									className=""
									type="submit"
									size="large"
								>
									<FuseSvgIcon
										className="text-24"
										color="action"
									>
										heroicons-outline:face-smile
									</FuseSvgIcon>
								</IconButton>

								<IconButton
									className=""
									type="submit"
									size="large"
								>
									<FuseSvgIcon
										className="text-24"
										color="action"
									>
										heroicons-outline:paper-clip
									</FuseSvgIcon>
								</IconButton>

								<InputBase
									autoFocus={false}
									id="message-input"
									className="flex-1 flex grow shrink-0 h-44 mx-8 px-16 border-2 rounded-full"
									placeholder="Type your message"
									onChange={onInputChange}
									value={messageText}
									sx={{ backgroundColor: 'background.paper' }}
								/>
								<IconButton
									className=""
									type="submit"
									size="large"
								>
									<FuseSvgIcon
										// className="rotate-90"
										color="action"
									>
										heroicons-outline:paper-airplane
									</FuseSvgIcon>
								</IconButton>
							</div>
						</Paper>
					)}
				</div>
			</div>
		</>
	);
}

export default Chat;
