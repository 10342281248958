import { styled, useTheme } from '@mui/material/styles';

import clsx from 'clsx';

import { APP_LOGO_CURRENT } from 'src/app/constants';

const Root = styled('div')(({ theme }) => ({
	'& > .logo-icon': {
		transition: theme.transitions.create(['width', 'height'], {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		})
	},
	'& > .logo': {
		'& .st0': {
			fill: APP_LOGO_CURRENT.brandColorMajor
		},
		'& .st1': {
			fill: APP_LOGO_CURRENT.brandColorMinor
		},
		transition: theme.transitions.create('all', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeOut
		})
	},
	'& > .badge': {
		transition: theme.transitions.create('opacity', {
			duration: theme.transitions.duration.shortest,
			easing: theme.transitions.easing.easeInOut
		})
	}
}));

const Logo = (props) => {
	const theme = useTheme();
	const { className } = props;

	return (
		<Root className="flex items-center">
			<div className="flex items-center py-4 px-8">
				<img
					className={clsx(className || 'h-48 sm:h-72', 'logo')}
					src={theme.palette.mode === 'light' ? APP_LOGO_CURRENT.lt : APP_LOGO_CURRENT.dk}
					alt={`${APP_LOGO_CURRENT.brandName} Logo`}
				/>
			</div>
		</Root>
	);
};

export default Logo;
