import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const AccountSettings = lazy(() => import('./index'));

const accountSettingsConfig = {
	path: URLPaths.ADMIN_SETTINGS_ACCOUNT_PATH,
	children: [
		{
			path: '',
			element: <AccountSettings />
		}
	]
};

export default accountSettingsConfig;
