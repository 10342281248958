import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Tooltip from '@mui/material/Tooltip';
import { DateTime } from 'luxon';
import _ from '/src/lib/@lodash';

const CustomTime = (props) => {
	const { timeStamp, timeFormat, timeAgo, undefinedString = '--', className } = props;

	if (timeStamp === undefined || timeStamp === null) {
		return <>{undefinedString}</>;
	}

	const isToday = (timeStamp) => {
		const dt = DateTime.utc();
		return dt.toFormat('yyyy-LLL-dd') === DateTime.fromSeconds(timeStamp).toFormat('yyyy-LLL-dd');
	};

	if (timeAgo) {
		return (
			<span className={clsx(className)}>
				<Tooltip title={DateTime.fromSeconds(timeStamp).toFormat('DD, h:mm a')}>
					<time dateTime={DateTime.fromSeconds(timeStamp).toFormat('yyyy-LLL-dd')}>
						{isToday(timeStamp)
							? _.startCase(DateTime.fromSeconds(timeStamp).toRelative())
							: _.startCase(
									DateTime.fromSeconds(timeStamp).toRelativeCalendar({
										numeric: 'auto',
										units: ['years', 'months', 'days'],
										calendary: true
									})
								)}
					</time>
				</Tooltip>
			</span>
		);
	}

	return (
		<span className={clsx(className)}>
			{timeFormat ? (
				<time dateTime={DateTime.fromSeconds(timeStamp).toFormat(timeFormat)}>
					{DateTime.fromSeconds(timeStamp).toFormat(timeFormat)}
				</time>
			) : (
				<Tooltip title={_.startCase(DateTime.fromSeconds(timeStamp).toRelativeCalendar())}>
					<time dateTime={DateTime.fromSeconds(timeStamp).toFormat('DD, h:mm a')}>
						{DateTime.fromSeconds(timeStamp).toFormat('DD, h:mm a')}
					</time>
				</Tooltip>
			)}
		</span>
	);
};

CustomTime.propTypes = {
	className: PropTypes.string,
	timeAgo: PropTypes.bool,
	timeFormat: PropTypes.string,
	timeStamp: PropTypes.number,
	undefinedString: PropTypes.string
};

export default memo(CustomTime);
