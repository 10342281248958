import { lazy } from 'react';

const AgentPromptCompare = lazy(() => import('./AgentPromptCompare'));
const ChatBoxTemplate = lazy(() => import('./ChatBoxTemplate'));

const TestingSlugRoute = {
	path: '/ai-testing',
	children: [
		{
			path: 'agent-prompt',
			element: <AgentPromptCompare />
		},
		{
			path: 'chat-box',
			element: <ChatBoxTemplate />
		}
	]
};

export default TestingSlugRoute;

// '/ai-testing/agent-prompt';
// '/ai-testing/chat-box';
