import { useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import Button from '@mui/material/Button';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';

const MAX_FILE_SIZE = 4 * 1024 * 1024; // 4 MB in bytes
const MAX_FILES = 5;

const FileUploadMulti = (props) => {
	const { name, control, errors, setValue, watch } = props;

	const [fileError, setFileError] = useState('');
	const fileInputRef = useRef(null);

	const watchFiles = watch(name);

	const handleFileChange = (e, onChange) => {
		const files = Array.from(e.target.files);
		const currentFiles = watchFiles || [];
		const totalFiles = [...currentFiles, ...files];

		if (totalFiles.length > MAX_FILES) {
			setFileError(`You can only upload up to ${MAX_FILES} files.`);
			return;
		}

		const totalSize = totalFiles.reduce((acc, file) => acc + file.size, 0);

		if (totalSize > MAX_FILE_SIZE) {
			setFileError('Total file size exceeds 4 MB limit.');
			return;
		}

		setFileError('');
		setValue('files', totalFiles); // This line commits the files to the form object

		onChange(totalFiles);

		// Reset file input
		if (fileInputRef.current) {
			fileInputRef.current.value = '';
		}
	};

	const removeFile = (index) => {
		const updatedFiles = [...watchFiles];
		updatedFiles.splice(index, 1);
		setValue(name, updatedFiles);
		setFileError('');
	};

	return (
		<div>
			<Controller
				name={name}
				control={control}
				rules={{
					validate: (files) => {
						if (files && files?.length && files?.length > MAX_FILES) {
							return `You can only upload up to ${MAX_FILES} files`;
						}

						let error = null;

						if (files && files?.length !== 0) {
							Array.from(files).forEach((file) => {
								if (file?.size > MAX_FILE_SIZE) {
									error = `${file?.name} is too large. Max size is 4 MB`;
								}

								const fileType = file?.type.toLowerCase();

								if (
									![
										'application/pdf',
										'application/msword',
										'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
										'text/plain',
										'image/jpeg',
										'image/png'
									].includes(fileType)
								) {
									error = `${file?.name} has an unsupported file type`;
								}
							});
						}

						return error || true;
					}
				}}
				render={({ field }) => (
					<>
						<input
							type="file"
							multiple
							onChange={(e) => handleFileChange(e, field.onChange)}
							style={{ display: 'none' }}
							ref={fileInputRef}
							accept=".pdf,.doc,.docx,.txt,.jpeg,.jpg,.png" // Optional: limit file types
						/>
						<Button
							className="mt-12"
							variant="contained"
							startIcon={<CloudUploadIcon />}
							onClick={() => fileInputRef.current.click()}
							disabled={watchFiles && watchFiles.length >= MAX_FILES}
						>
							Upload Files
						</Button>
						<Typography
							className="mt-4"
							variant="caption"
							display="block"
						>
							Maximum 5 files, total size cannot exceed 4MB.
						</Typography>
						{fileError && <Typography color="error">{fileError}</Typography>}
					</>
				)}
			/>
			{errors.files && <p>{errors.files.message}</p>}

			<div className="mt-12">
				{/* Display selected file names */}
				{watchFiles &&
					Array.from(watchFiles).map((file, index) => (
						<div
							className="grid grid-cols-4"
							key={index}
						>
							<div className="col-span-2 items-center">{file.name}</div>
							<div className="items-center">{(file.size / (1024 * 1024)).toFixed(2)} MB</div>
							<div className="items-center">
								<Button
									size="small"
									onClick={() => removeFile(index)}
									startIcon={<DeleteIcon />}
								>
									Remove
								</Button>
							</div>
						</div>
					))}
			</div>
			<div className="mt-12 text-8 text-gray-500">
				Accepted formats: JPG, PNG, PDF, DOC, DOCX, TXT -- Up to 5 files
			</div>
		</div>
	);
};

export default FileUploadMulti;
