import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const SecurityUser = lazy(() => import('./index'));

const securityUserAppConfig = {
	path: URLPaths.ADMIN_SECURITY_USER_PATH,
	children: [
		{
			path: '',
			element: <SecurityUser />
		}
	]
};

export default securityUserAppConfig;
