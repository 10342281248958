import { lazy } from 'react';
const HumanAgentChatFirstScreen = lazy(
	() => import('../../shared-components/ChatBotDetail/HumanAgent/ChatFirstScreen')
);
const HumanAgentChat = lazy(() => import('../../shared-components/Chat/chat/Chat'));

const ChatWithBotChatFirstScreen = lazy(
	() => import('../../shared-components/ChatBotDetail/ChatWithBot/ChatFirstScreen')
);
const ChatWithBotChat = lazy(
	() => import('src/app/containers/products/SynthAgents/shared-components/ChatBotDetail/ChatWithBot/chat/Chat')
);

const ChatbotChatHistory = lazy(() => import('../../shared-components/ChatBotDetail/ChatHistory/ChatApp'));
const ChatHistoryChatFirstScreen = lazy(
	() => import('../../shared-components/ChatBotDetail/ChatHistory/ChatFirstScreen')
);
const ChatHistoryChat = lazy(() => import('../../shared-components/ChatBotDetail/ChatHistory/chat/Chat'));

const ChatbotDetailApp = lazy(() => import('./ChatbotDetailApp'));

const ChatbotOverview = lazy(() => import('./ChatbotOverview'));

const ChatbotDetailDashboard = lazy(() => import('../../shared-components/ChatBotDetail/Dashboard/index'));

const ChatbotChatWithBot = lazy(() => import('../../shared-components/ChatBotDetail/ChatWithBot/ChatApp'));

const ChatbotDetailEmbed = lazy(() => import('../../shared-components/ChatBotDetail/Embed/index'));
const ChatbotDetailShare = lazy(() => import('../../shared-components/ChatBotDetail/Share/index'));
const ChatbotDetailManageSources = lazy(() => import('../../shared-components/ChatBotDetail/ManageSources/index'));
const ChatbotDetailSettingsAppearance = lazy(
	() => import('../../shared-components/ChatBotDetail/Settings/Appearance/index')
);
const ChatbotDetailSettingsCollectUserInfo = lazy(
	() => import('../../shared-components/ChatBotDetail/Settings/CollectUserInfo/index')
);
const ChatbotDetailSettingsDetails = lazy(() => import('../../shared-components/ChatBotDetail/Settings/Details/index'));
const ChatbotDetailSettingsDomains = lazy(() => import('../../shared-components/ChatBotDetail/Settings/Domains/index'));
const ChatbotDetailSettingsQuickPrompts = lazy(
	() => import('../../shared-components/ChatBotDetail/Settings/QuickPrompts/index')
);
const ChatbotHumanAgent = lazy(() => import('../../shared-components/ChatBotDetail/HumanAgent/ChatApp'));
const ChatbotDetailSettings = lazy(() => import('../../shared-components/ChatBotDetail/Settings/index'));

export {
	HumanAgentChatFirstScreen,
	HumanAgentChat,
	ChatHistoryChat,
	ChatWithBotChat,
	ChatHistoryChatFirstScreen,
	ChatWithBotChatFirstScreen,
	ChatbotChatHistory,
	ChatbotDetailApp,
	ChatbotOverview,
	ChatbotDetailDashboard,
	ChatbotChatWithBot,
	ChatbotDetailEmbed,
	ChatbotDetailShare,
	ChatbotDetailManageSources,
	ChatbotDetailSettingsAppearance,
	ChatbotDetailSettingsCollectUserInfo,
	ChatbotDetailSettingsDetails,
	ChatbotDetailSettingsDomains,
	ChatbotDetailSettingsQuickPrompts,
	ChatbotHumanAgent,
	ChatbotDetailSettings
};
