import { createContext, useContext, useReducer } from 'react';

const dialogContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
const DialogProvide = ({ children }) => {
	const dialogValue = useDialogProvide();
	return <dialogContext.Provider value={dialogValue}>{children}</dialogContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
const useDialog = () => {
	const context = useContext(dialogContext);

	if (context === undefined) {
		throw new Error(`useDialog must be used within a DialogProvide`);
	}

	return context;
};

// Provider hook that creates auth object and handles state

const useDialogProvide = () => {
	const [dialogState, setDialogState] = useReducer((state, newState) => ({ ...state, ...newState }), {
		state: false,
		options: {
			children: 'Hi'
		}
	});

	// Wrap any Firebase methods we want to use making sure ...
	// ... to save the user to state.

	const openDialog = (options) => {
		setDialogState({
			state: true,
			options
		});
	};
	const closeDialog = () => {
		setDialogState({ state: false });
	};

	// Return the user object and auth methods
	return {
		state: dialogState.state,
		options: dialogState.options,
		openDialog,
		closeDialog
	};
};

export { DialogProvide, useDialogProvide, useDialog };
