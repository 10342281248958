import _ from 'src/lib/@lodash';
import PropTypes from 'prop-types';
import { Controller } from 'react-hook-form';
import { default as CB } from '@mui/material/Checkbox';
import FormHelperText from '@mui/material/FormHelperText';
import clsx from 'clsx';

const Checkbox = (props) => {
	const { control, className, name, label, labelPosition = 'rt' } = props;

	const importedProps = _.pick(props, [
		'checkedIcon',
		'classes',
		'color',
		'disabled',
		'disableRipple',
		'icon',
		'id',
		'indeterminate',
		'indeterminateIcon',
		'inputProps',
		'inputRef',
		'onChange',
		'variant'
	]);

	return (
		<Controller
			name={name}
			control={control}
			render={({
				field: { onChange, onBlur, value, name, ref },
				fieldState: { invalid, isTouched, isDirty, error },
				formState
			}) => (
				<div className="flex flex-col">
					<div className={clsx(className, 'flex flex-row items-center')}>
						<div className="flex flex-col">{Boolean(label) && labelPosition === 'lt' && label}</div>
						<CB
							{...importedProps}
							type="checkbox"
							onBlur={onBlur}
							onChange={onChange}
							checked={value}
							inputRef={ref}
						/>
						<div className="flex flex-col">{Boolean(label) && labelPosition === 'rt' && label}</div>
					</div>
					{Boolean(error) && (
						<FormHelperText
							id={`${props.name}-text`}
							className="mt-0 text-red-600"
						>
							{error.type === 'required' ? error.message : null}
							{error.type === 'optionality' ? error.message : null}
						</FormHelperText>
					)}
				</div>
			)}
		/>
	);
};

Checkbox.propTypes = {
	control: PropTypes.object,
	name: PropTypes.string.isRequired,
	classNames: PropTypes.string,
	label: PropTypes.string,
	labelPosition: PropTypes.string
};

export default Checkbox;
