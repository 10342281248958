import { URLMap } from 'src/app/constants';
import callApi from 'src/utils/fetchAsyncCalls';
import { attribsAdd } from 'src/utils/helpers';

export const assetDelete = ({ token, data }) => {
	const postData = { _payload: btoa(JSON.stringify(data)) };

	return callApi(URLMap.DATA_ASSET_DELETE, { token, data: postData });
};

export const supportFeedback = ({ token, data }) => {
	console.log('supportFeedback data', data)
	return callApi(URLMap.SUPPORT_FEEDBACK_CREATE, { token, data })
};

export const assetGet = (token, assetType, assetId, attribs = null, assetTable = null) => {
	let url = `${URLMap.DATA_ASSET_GET}`;

	 let getStrQuery = {}

	if (assetType) {
		getStrQuery.assetType = assetType
		// url += `?asset_type=${assetType}`;
	}

	if (assetId) {
		getStrQuery.assetId = assetId
		// url += `&asset_id=${assetId}`;
	}

	if (assetTable) {
		getStrQuery.assetTable = assetTable
		// url += `&asset_table=${assetTable}`;
	}

	if (attribs) {
		getStrQuery.attribs = attribsAdd(attribs)
		// url = `${url}&attribs=${attribsAdd(attribs)}`;
	}

	return callApi(url, {
		token,
		getStrQuery
	});
};

export const assetUpdate = ({ token, data }) => {

	return callApi(URLMap.DATA_ASSET_UPDATE, { token, data });

	// const postData = { _payload: btoa(JSON.stringify(data)) };
	//
	// return callApi(URLMap.DATA_ASSET_UPDATE, { token, data: postData });
};

export const assetListGet = (
	token,
	assetType,
	attribs = null,
	assetId = null,
	assetEq = null,
	dereference = null,
	dereferenceType = null,
	dereferenceAttribs = null
) => {
	let url = URLMap.DATA_ASSET_LIST;

	let getStrQuery = {
		assetType
	}

	// url += `?assetType=${assetType}`;

	if (assetId) {
		getStrQuery.assetId = assetId
		url += `&assetId=${assetId}`;
	}

	if (assetEq) {
		getStrQuery.assetEq = assetEq
		url += `&assetEq=${assetEq}`;
	}

	if (attribs) {
		getStrQuery.attribs = attribsAdd(attribs)
		// url += `&attribs=${btoa(JSON.stringify(attribs))}`;
	}

	if (dereference) {
		getStrQuery.dereference = dereference
		// url += `&dereference=${dereference}`;
	}

	if (dereferenceType) {
		getStrQuery.dereferenceType = adereferenceType
		url += `&dereferenceType=${dereferenceType}`;
	}

	if (dereferenceAttribs) {
		getStrQuery.dereferenceAttribs = attribsAdd(dereferenceAttribs)
		// url += `&dereferenceAttribs=${btoa(JSON.stringify(dereferenceAttribs))}`;
	}

	return callApi(url, { token, getStrQuery });
};

export const metricActualGet = (
	token,
	metricDefId,
	metricGraphId,
	metricDetailId,
	metricSubType = null,
	fillZeros = false
) => {
	let url = `${URLMap.METRICS_GET}/actual`;

	let getStrQuery = {
		metricDefId,
		metricGraphId,
		metricDetailId
	}

	if (metricSubType) {
		getStrQuery.metricSubType = metricSubType
		// url += `&metric_sub_type=${metricSubType}`;
	}

	if (fillZeros !== undefined) {
		getStrQuery.fillZeros = fillZeros
		// url += `&fill_zeros=${fillZeros}`;
	}

	return callApi(url, {
		token,
		getStrQuery
	});
};
