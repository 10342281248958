import i18next from 'i18next';
import ar from './navigation-i18n/ar';
import en from './navigation-i18n/en';
import tr from './navigation-i18n/tr';
import { authRoles } from 'src/app/auth';
import { APP_ACTIVE, URLPaths } from 'src/app/constants';
// import { authRoles } from '../auth';
// import DocumentationNavigation from '../main/documentation/DocumentationNavigation';
// import SettingsAppNavigation from '../main/apps/settings/SettingsAppNavigation';

i18next.addResourceBundle('en', 'navigation', en);
i18next.addResourceBundle('tr', 'navigation', tr);
i18next.addResourceBundle('ar', 'navigation', ar);
/**
 * The navigationConfig object is an array of navigation items for the Fuse application.
 */

const navigationConfigList = {
	main: [
		{
			id: 'divider-1',
			type: 'divider',
			auth: authRoles.admin
		},
		{
			id: 'settings.main',
			title: 'Administration',
			type: 'group',
			icon: 'web',
			children: [
				{
					id: 'adminMultiSettings',
					title: 'Admin Settings',
					type: 'item',
					auth: authRoles.admin,
					icon: 'heroicons-outline:cog-8-tooth',
					url: '/settings/admin/'
				},
				{
					id: 'userMultiSettings',
					title: 'User Settings',
					type: 'item',
					icon: 'heroicons-outline:cog',
					url: '/settings/user/'
				}
			]
		},
		// {
		// 	type: 'divider',
		// 	id: 'divider-2',
		// 	auth: authRoles.admin
		// },
		{
			id: 'support',
			title: 'Support',
			type: 'group',
			icon: 'web',
			children: [
				{
					id: 'support-center',
					title: 'Support Center',
					type: 'item',
					icon: 'heroicons-outline:lifebuoy',
					url: URLPaths.SUPPORT_PATH
				}
				// {
				//   id: 'documentation',
				//   title: 'Documentation',
				//   type: 'item',
				//   icon: 'help',
				//   url: '/documentation/',
				// },
				// {
				//   id: 'forum',
				//   title: 'Forum',
				//   type: 'item',
				//   icon: 'help',
				//   url: '/account/projects/',
				// },
			]
		},
		{
			id: 'divider-3',
			type: 'divider'
		}
		// {
		//   id: 'settings',
		//   title: 'User Settings',
		//   type: 'group',
		//   auth: authRoles.user,
		//   icon: 'web',
		//   children: [
		//     {
		//       id: 'settings-preferences',
		//       title: 'Preferences',
		//       type: 'item',
		//       icon: 'help',
		//       url: URLPaths.ADMIN_SECURITY_ACCOUNT_PATH,
		//     },
		//     {
		//       id: 'settings-settings',
		//       title: 'Settings',
		//       type: 'item',
		//       icon: 'help',
		//       url: URLPaths.ADMIN_SETTINGS_ACCOUNT_PATH,
		//     },
		//   ],
		// },
		// {
		//   id: 'divider-4',
		//   type: 'divider',
		// },
		// {
		//   id: 'admin',
		//   title: 'Administration',
		//   type: 'group',
		//   auth: authRoles.admin,
		//   icon: 'web',
		//   children: [
		//     {
		//       id: 'admin-account',
		//       title: 'Account',
		//       type: 'item',
		//       icon: 'help',
		//       url: URLPaths.ADMIN_SECURITY_ACCOUNT_PATH,
		//     },
		//     {
		//       id: 'admin-users',
		//       title: 'Users',
		//       type: 'item',
		//       icon: 'help',
		//       url: URLPaths.ADMIN_SETTINGS_USER_PATH,
		//     },
		//     {
		//       id: 'admin-settings',
		//       title: 'Settings',
		//       type: 'item',
		//       icon: 'help',
		//       url: URLPaths.ADMIN_SETTINGS_ACCOUNT_PATH,
		//     },
		//   ],
		// },
		// {
		//   id: 'divider-5',
		//   type: 'divider',
		// },
		// {
		//   id: 'testing',
		//   title: 'testing',
		//   type: 'group',
		//   auth: authRoles.admin,
		//   icon: 'web',
		//   children: [
		//     {
		//       id: 'testComponent',
		//       title: 'Test Component',
		//       type: 'item',
		//       icon: 'help',
		//       url: '/test/component/',
		//     },
		//     {
		//       id: 'playTest',
		//       title: 'Play Component',
		//       type: 'item',
		//       icon: 'help',
		//       url: '/apps/playground/',
		//     },
		//   ],
		// },
	],
	buwee: [
		{
			id: 'goals',
			title: 'Scrum board',
			type: 'item',
			icon: 'assessment',
			url: '/scrum/boards/'
		},
		{
			id: 'templates',
			title: 'Todos',
			type: 'item',
			icon: 'check_box',
			url: '/todo/'
		}
	],
	chartleaf: [
		{
			id: 'chartleaf-dashboards',
			title: 'Dashboards',
			type: 'item',
			icon: 'dashboard',
			url: '/dashboard/',
			exact: true
		},
		{
			type: 'divider',
			id: 'divider-6'
		},
		{
			id: 'live',
			title: 'Live View',
			type: 'item',
			icon: 'error',
			url: '/live/view/',
			exact: true
		},
		{
			type: 'divider',
			id: 'divider-7'
		},
		{
			id: 'interactive',
			title: 'Interactive',
			type: 'group',
			icon: 'pages',
			children: [
				{
					id: 'retention',
					title: 'Retention',
					type: 'item',
					icon: 'error',
					url: '/retention/'
				},
				{
					id: 'revenue',
					title: 'Revenue',
					type: 'item',
					icon: 'receipt',
					url: '/revenue/'
				},
				{
					id: 'segmentation',
					title: 'Segmentation',
					type: 'item',
					icon: 'error',
					url: '/segment/analyze/'
				}
			]
		},
		{
			type: 'divider',
			id: 'divider-8'
		},
		{
			id: 'analytics',
			title: 'Analytics',
			type: 'group',
			icon: 'pages',
			children: [
				{
					id: 'engagment',
					title: 'Engagement',
					type: 'collapse',
					auth: authRoles.user,
					icon: 'search',
					children: [
						{
							id: 'user-active',
							title: 'User Active',
							type: 'item',
							url: '/user/active/'
						},
						{
							id: 'user-manager',
							title: 'User Manager',
							type: 'item',
							url: '/user/manager/'
						}
					]
				},
				{
					id: 'cohorts',
					title: 'Cohorts',
					type: 'collapse',
					icon: 'lock',
					children: [
						{
							id: 'cohort-list',
							title: 'Cohort List',
							type: 'item',
							url: '/cohorts/list/'
						},
						{
							id: 'cohort-create',
							title: 'Cohort Create',
							type: 'item',
							url: '/cohorts/create/'
						}
					]
				},
				{
					id: 'funnels',
					title: 'Funnels',
					type: 'collapse',
					icon: 'alarm',
					children: [
						{
							id: 'funnel-list',
							title: 'Funnel List',
							type: 'item',
							url: '/funnel/list/'
						},
						{
							id: 'funnel-create',
							title: 'Funnel Create',
							type: 'item',
							url: '/funnel/create/'
						}
					]
				},
				{
					id: 'segments',
					title: 'Segments',
					type: 'collapse',
					icon: 'build',
					children: [
						{
							id: 'segment-list',
							title: 'Segment List',
							type: 'item',
							url: '/segment/list/'
						},
						{
							id: 'segment-create',
							title: 'Segment Create',
							type: 'item',
							url: '/segment/create/'
						}
					]
				}
			]
		}
	],
	helpmatrix: [
		{
			id: 'helpmatrix',
			title: 'Help',
			type: 'item',
			icon: 'dashboard',
			url: '/admin/help/matrix/overview/',
			exact: true
		}
	],
	jetjaw: [],
	leafLayer: [],
	logdera: [],
	nexusData: [
		{
			id: 'nexus-dashboards',
			title: 'Dashboards',
			type: 'item',
			icon: 'dashboard',
			url: '/dashboard/',
			exact: true
		},
		{
			id: 'goals',
			title: 'Goals',
			type: 'item',
			icon: 'assessment',
			url: '/scrumboard/boards/'
		},
		{
			id: 'templates',
			title: 'Templates',
			type: 'item',
			icon: 'check_box',
			url: '/todo/'
		},
		{
			id: 'integrations',
			title: 'Integrations',
			type: 'item',
			icon: 'assessment',
			url: '/integrations/'
		}
	],
	pingdera: [],
	redisNow: [],
	snapNow: [],
	skydera: [
		{
			id: 'dashboard2',
			title: 'Welcome',
			translate: 'DASHBOARDS',
			type: 'item',
			icon: 'dashboard',
			url: '/',
			exact: true
		},
		{
			id: 'divider-9',
			type: 'divider'
		},
		{
			id: 'dashboard3',
			title: 'Dashboards',
			type: 'collapse',
			icon: 'dashboard',
			children: [
				{
					id: 'dashboardCosts',
					title: 'Costs',
					type: 'item',
					icon: 'dashboard',
					url: '/apps/dashboards/cost/',
					exact: true
				}
				// {
				//   id: 'dashboardInfra',
				//   title: 'Infrastructure',
				//   type: 'item',
				//   icon: 'dashboard',
				//   url: '/apps/dashboards/infra/',
				//   exact: true,
				// },
			]
		},
		{
			id: 'divider-10',
			type: 'divider'
		},
		{
			id: 'namespaces',
			title: 'Namespaces',
			type: 'item',
			icon: 'dashboard',
			url: URLPaths.SERVICE_NAMESPACE_PATH,
			exact: true
		},
		{
			id: 'divider-11',
			type: 'divider'
		},
		{
			id: 'tasks',
			title: 'Service',
			type: 'group',
			icon: 'web',
			children: [
				{
					id: 'servicesService',
					title: 'Application Services',
					type: 'collapse',
					icon: 'dashboard',
					children: [
						{
							id: 'applicationCatalogs',
							title: 'Catalog',
							type: 'item',
							icon: 'dashboard',
							url: URLPaths.SERVICE_CATALOG_PATH,
							exact: true
						},
						// {
						//   id: 'serviceHelmCharts',
						//   title: 'Helm Charts',
						//   type: 'item',
						//   icon: 'dashboard',
						//   url: '/helm/repo/list/',
						//   exact: true,
						// },
						{
							id: 'services',
							title: 'Services',
							type: 'item',
							icon: 'dashboard',
							url: '/service/app/',
							exact: true
						}
					]
				},
				// {
				//   id: 'taskService',
				//   title: 'Task Services',
				//   type: 'collapse',
				//   icon: 'dashboard',
				//   children: [
				//     {
				//       id: 'taskList',
				//       title: 'Definitions',
				//       type: 'item',
				//       icon: 'dashboard',
				//       url: URLPaths.TASK_DEFINITION_PATH,
				//       exact: true,
				//     },
				//     // {
				//     //   id: 'taskRunList',
				//     //   title: 'Run List',
				//     //   type: 'item',
				//     //   icon: 'dashboard',
				//     //   url: '/task/run/list/',
				//     //   exact: true,
				//     // },
				//     {
				//       id: 'taskSchedule',
				//       title: 'Scheduled Tasks',
				//       type: 'item',
				//       icon: 'dashboard',
				//       url: '/task/schedule/list/',
				//       exact: true,
				//     },
				//   ],
				// },
				{
					id: 'preview',
					title: 'Preview Environments',
					type: 'item',
					icon: 'dashboard',
					url: '/service/preview/',
					exact: true
				}
			]
		},
		{
			id: 'divider-12',
			type: 'divider'
		},
		{
			id: 'persistenceControls',
			title: 'Persistence',
			type: 'group',
			auth: authRoles.admin,
			icon: 'web',
			children: [
				// {
				//   id: 'persistenceServices',
				//   title: 'Services',
				//   type: 'item',
				//   icon: 'dashboard',
				//   url: '/persistence/services',
				//   exact: true,
				// },
				{
					id: 'persistenceDatabases',
					title: 'Databases',
					type: 'item',
					icon: 'dashboard',
					url: '/persistence/databases/',
					exact: true
				},
				{
					id: 'persistenceVolumes',
					title: 'Filesystems',
					type: 'item',
					icon: 'dashboard',
					url: '/persistence/filesystems/',
					exact: true
				}
			]
		},
		{
			id: 'divider-13',
			type: 'divider'
		},
		// {
		//   id: 'financialControls',
		//   title: 'Financial Controls',
		//   type: 'group',
		//   auth: authRoles.admin,
		//   icon: 'web',
		//   children: [
		//     {
		//       id: 'limits',
		//       title: 'Limits',
		//       type: 'collapse',
		//       icon: 'dashboard',
		//       children: [
		//         {
		//           id: 'limitsDashboard',
		//           title: 'Dashboard',
		//           type: 'item',
		//           icon: 'dashboard',
		//           url: '/service/limits/',
		//           exact: true,
		//         },
		//         // {
		//         //   id: 'limitsDepartmentsOverview',
		//         //   title: 'Departments Overview',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/department/overview/',
		//         //   exact: true,
		//         // },
		//         // {
		//         //   id: 'limitsDepartmentsCreate',
		//         //   title: 'Departments Create',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/department/create/dev-north-america',
		//         //   exact: true,
		//         // },
		//         // {
		//         //   id: 'limitsDepartmentDetail',
		//         //   title: 'Departments Detail',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/department/detail/dev-north-america',
		//         //   exact: true,
		//         // },
		//         // {
		//         //   id: 'limitsUserCreate',
		//         //   title: 'Users Create',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/user/create/',
		//         //   exact: true,
		//         // },
		//         // {
		//         //   id: 'limitsUsersDetail',
		//         //   title: 'User Detail',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/user/detail/',
		//         //   exact: true,
		//         // },
		//         // {
		//         //   id: 'limitsUsers',
		//         //   title: 'Users',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/',
		//         //   exact: true,
		//         // },
		//       ],
		//     },
		//   ],
		// },
		// {
		//   id: 'divider-14',
		//   type: 'divider',
		// },
		// {
		//   id: 'leafCi',
		//   title: 'LeafCi',
		//   type: 'group',
		//   auth: authRoles.admin,
		//   icon: 'web',
		//   children: [
		//     {
		//       id: 'LeafRegistries',
		//       title: 'Image Registries',
		//       type: 'collapse',
		//       icon: 'dashboard',
		//       children: [
		//         {
		//           id: 'limitsDashboard',
		//           title: 'Image Registries',
		//           type: 'item',
		//           icon: 'dashboard',
		//           url: URLPaths.LEAFCI_REGISTRIES_PATH,
		//           exact: true,
		//         },
		//         // {
		//         //   id: 'limitsDepartmentsOverview',
		//         //   title: 'Departments Overview',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/department/overview/',
		//         //   exact: true,
		//         // },
		//         // {
		//         //   id: 'limitsDepartmentsCreate',
		//         //   title: 'Departments Create',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/department/create/dev-north-america',
		//         //   exact: true,
		//         // },
		//         // {
		//         //   id: 'limitsDepartmentDetail',
		//         //   title: 'Departments Detail',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/department/detail/dev-north-america',
		//         //   exact: true,
		//         // },
		//         // {
		//         //   id: 'limitsUserCreate',
		//         //   title: 'Users Create',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/user/create/',
		//         //   exact: true,
		//         // },
		//         // {
		//         //   id: 'limitsUsersDetail',
		//         //   title: 'User Detail',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/user/detail/',
		//         //   exact: true,
		//         // },
		//         // {
		//         //   id: 'limitsUsers',
		//         //   title: 'Users',
		//         //   type: 'item',
		//         //   icon: 'dashboard',
		//         //   url: '/service/limits/',
		//         //   exact: true,
		//         // },
		//       ],
		//     },
		//   ],
		// },
		// {
		//   id: 'divider-15',
		//   type: 'divider',
		// },
		{
			id: 'assets',
			title: 'Application Assets',
			type: 'group',
			icon: 'web',
			children: [
				{
					id: 'alb',
					title: 'Application LoadBalancers',
					type: 'item',
					icon: 'dashboard',
					url: URLPaths.SERVICE_ALB_PATH,
					exact: true
				},
				{
					id: 'certs',
					title: 'Certifications',
					type: 'item',
					icon: 'dashboard',
					url: URLPaths.SERVICE_CERT_PATH,
					exact: true
				},
				// {
				//   id: 'domains',
				//   title: 'Domains',
				//   type: 'item',
				//   icon: 'dashboard',
				//   url: URLPaths.SERVICE_DOMAINS_PATH,
				//   exact: true,
				// },
				// {
				//   id: 'secretsManager',
				//   title: 'Secrets Manager',
				//   type: 'item',
				//   icon: 'dashboard',
				//   url: URLPaths.SERVICE_SECRETS_PATH,
				//   exact: true,
				// },
				// {
				//   id: 'imageRegistries',
				//   title: 'Image Registries',
				//   type: 'item',
				//   icon: 'dashboard',
				//   url: URLPaths.SERVICE_IMAGES_REGISTER_PATH,
				//   exact: true,
				// },
				{
					id: 'networks',
					title: 'Networks',
					type: 'item',
					icon: 'dashboard',
					url: URLPaths.SERVICE_NETWORKS_PATH,
					exact: true
				},
				{
					id: 'vpc',
					title: 'VPCs',
					type: 'item',
					icon: 'dashboard',
					url: URLPaths.SERVICE_VPC_PATH,
					exact: true
				}
			]
		}
	],
	skynas: [],
	synthagents: [
		{
			id: 'dashboards',
			title: 'Dashboards',
			type: 'group',
			icon: 'presentation-chart-line',
			children: [
				{
					id: 'dashboard-overview',
					title: 'Overview',
					type: 'item',
					icon: 'heroicons-outline:presentation-chart-line',
					url: '/',
					exact: true
				}
			]
		},
		{
			id: 'chat-bots',
			title: 'Ai Bots',
			type: 'group',
			icon: 'web',
			children: [
				{
					id: 'chatbot_list',
					title: 'ChatBots',
					type: 'item',
					icon: 'heroicons-outline:users',
					url: '/chatbot/list',
					exact: true
				},
				{
					id: 'agent_list',
					title: 'Agents',
					type: 'item',
					icon: 'heroicons-outline:user-group',
					url: '/agent/list',
					exact: true
				}
			]
		},
		{
			id: 'content',
			title: 'Ai Brains',
			type: 'group',
			icon: 'web',
			children: [
				{
					id: 'ai_brain_list',
					title: 'Ai Brains',
					type: 'item',
					icon: 'heroicons-outline:server-stack',
					url: '/ai/brain/list',
					exact: true
				}
			]
		},
		{
			id: 'tools',
			title: 'Tools',
			type: 'group',
			icon: 'web',
			children: [
				// {
				//   id: 'tool_create',
				//   title: 'Create Tool',
				//   type: 'item',
				//   icon: 'dashboard',
				//   url: '/tools/create',
				//   exact: true,
				// },
				{
					id: 'tool_list',
					title: 'Tools',
					type: 'item',
					icon: 'heroicons-outline:wrench-screwdriver',
					url: '/tools/list',
					exact: true
				}
			]
		},
		// {
		// 	id: 'integrations',
		// 	title: 'Integrations',
		// 	type: 'group',
		// 	icon: 'web',
		// 	children: [
		// 		{
		// 			id: 'integrations_list',
		// 			title: 'Integrations',
		// 			type: 'item',
		// 			icon: 'heroicons-outline:share',
		// 			url: '/integrations',
		// 			exact: true
		// 		}
		// 	]
		// }
	]
};

const navigationConfig = navigationConfigList[APP_ACTIVE].concat(navigationConfigList.main);

export default navigationConfig;
