import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const ProfileApp = lazy(() => import('./index'));

const ProfileAppConfig = {
	path: URLPaths.PROFILE_PATH,
	element: <ProfileApp />
};

export default ProfileAppConfig;
