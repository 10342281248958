import Layout1 from './layout1/Layout1';
// import Layout2 from './layout2/Layout2';
// import Layout3 from './layout3/Layout3';
/**
 * The theme layouts.
 */
const themeLayouts = {
	layout1: Layout1
	// layout2: Layout2,
	// layout3: Layout3,
};
export default themeLayouts;
