import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const BillingApp = lazy(() => import('./index'));

const billingAppConfig = {
	path: URLPaths.BILLING_PATH,
	children: [
		{
			path: '',
			element: <BillingApp />
		}
	]
};

export default billingAppConfig;
