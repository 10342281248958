import Icon from '@mui/material/Icon';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import MenuItem from '@mui/material/MenuItem';

import { Link } from 'react-router-dom';
import { URLPaths } from 'src/app/constants';

function NexusDataUserMenu(props) {
	return (
		<div>
			{
				{
					guest: (
						<>
							<MenuItem
								component={Link}
								to={URLPaths.LOGIN_PATH}
							>
								<ListItemIcon>
									<Icon>lock</Icon>
								</ListItemIcon>
								<ListItemText
									className="pl-0"
									primary="Login"
								/>
							</MenuItem>
							<MenuItem
								component={Link}
								to={URLPaths.REGISTRATION_PATH}
							>
								<ListItemIcon>
									<Icon>person_add</Icon>
								</ListItemIcon>
								<ListItemText
									className="pl-0"
									primary="Register"
								/>
							</MenuItem>
						</>
					),
					analyst: (
						<>
							<MenuItem
								component={Link}
								to={URLPaths.PROFILE_PATH}
								onClick={props.userMenuClose}
							>
								<ListItemIcon>
									<Icon>account_circle</Icon>
								</ListItemIcon>
								<ListItemText
									className="pl-0"
									primary="Account"
								/>
							</MenuItem>
						</>
					),
					user: (
						<>
							<MenuItem
								component={Link}
								to={URLPaths.PROFILE_PATH}
								onClick={props.userMenuClose}
							>
								<ListItemIcon>
									<Icon>account_circle</Icon>
								</ListItemIcon>
								<ListItemText
									className="pl-0"
									primary="Account"
								/>
							</MenuItem>
						</>
					),
					admin: (
						<>
							<MenuItem
								component={Link}
								to={URLPaths.PROFILE_PATH}
								onClick={props.userMenuClose}
							>
								<ListItemIcon>
									<Icon>account_circle</Icon>
								</ListItemIcon>
								<ListItemText
									className="pl-0"
									primary="My Profile"
								/>
							</MenuItem>
							<MenuItem
								component={Link}
								to={URLPaths.ACCOUNT_PATH}
								onClick={props.userMenuClose}
							>
								<ListItemIcon>
									<Icon>mail</Icon>
								</ListItemIcon>
								<ListItemText
									className="pl-0"
									primary="Account Usage"
								/>
							</MenuItem>
							<MenuItem
								component={Link}
								to={URLPaths.PROJECTS_PATH}
								onClick={props.userMenuClose}
							>
								<ListItemIcon>
									<Icon>mail</Icon>
								</ListItemIcon>
								<ListItemText
									className="pl-0"
									primary="Projects"
								/>
							</MenuItem>
							<MenuItem
								component={Link}
								to={URLPaths.USER_APP_PATH}
								onClick={props.userMenuClose}
							>
								<ListItemIcon>
									<Icon>mail</Icon>
								</ListItemIcon>
								<ListItemText
									className="pl-0"
									primary="Account User List"
								/>
							</MenuItem>
							<MenuItem
								component={Link}
								to={URLPaths.BILLING_PATH}
								onClick={props.userMenuClose}
							>
								<ListItemIcon>
									<Icon>mail</Icon>
								</ListItemIcon>
								<ListItemText
									className="pl-0"
									primary="Billing Settings & History"
								/>
							</MenuItem>
						</>
					)
				}[props.role]
			}
		</div>
	);
}

export default NexusDataUserMenu;
