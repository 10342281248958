import FuseShortcuts from 'src/lib/@fuse/core/FuseShortcuts';
// import {
//   selectIsUserGuest,
//   selectUser,
//   selectUserShortcuts,
//   setUserShortcuts,
// } from 'src/app/auth/user/store/userSlice';
import { usePrevious } from 'src/lib/@fuse/hooks';
import { useEffect } from 'react';
import _ from 'src/lib/@lodash';
// import useAuth from 'src/app/auth/useAuth';
// import withSlices from 'app/store/withSlices';
// import { navigationSlice, selectFlatNavigation } from './store/navigationSlice';
import { useNavigation } from 'src/app/context/useNavigation';

/**
 * The navigation shortcuts.
 */
function NavigationShortcuts(props) {
	const { variant, className } = props;
	const { navigationState: navigation } = useNavigation();

	// const user = useAppSelector(selectUser);
	//   const userShortcuts = useAppSelector(selectUserShortcuts) || [];
	// const isUserGuest = useAppSelector(selectIsUserGuest);
	const user = null;

	const userShortcuts = [];
	const isUserGuest = false;

	const prevUserShortcuts = usePrevious(userShortcuts);
	// const { updateUser: updateUserService } = useAuth();
	useEffect(() => {
		if (!isUserGuest && prevUserShortcuts && !_.isEqual(userShortcuts, prevUserShortcuts)) {
			// updateUserService(_.setIn(user, 'data.shortcuts', userShortcuts));
		}
	}, [isUserGuest, userShortcuts]);

	function handleShortcutsChange(newShortcuts) {
		// dispatch(setUserShortcuts(newShortcuts));
	}

	return (
		<FuseShortcuts
			className={className}
			variant={variant}
			navigation={navigation}
			shortcuts={userShortcuts}
			onChange={handleShortcutsChange}
		/>
	);
}

// const NavigationShortcutsWithSlices = withSlices([navigationSlice])(
//   NavigationShortcuts,
// );
// export default NavigationShortcutsWithSlices;
export default NavigationShortcuts;
