import { createContext, useContext, useReducer } from 'react';

const messageContext = createContext();

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
function MessageProvide({ children }) {
	const messageValue = useMessageProvide();
	return <messageContext.Provider value={messageValue}>{children}</messageContext.Provider>;
}

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
function useMessage() {
	const context = useContext(messageContext);

	if (context === undefined) {
		throw new Error(`useMessage must be used within a MessageProvide`);
	}

	return context;
}

// Provider hook that creates auth object and handles state

function useMessageProvide() {
	const [messageState, setMessageState] = useReducer((state, newState) => ({ ...state, ...newState }), {
		state: null,
		options: {
			anchorOrigin: {
				vertical: 'top',
				horizontal: 'center'
			},
			autoHideDuration: 6000,
			message: 'Hi',
			variant: null
		}
	});

	// Wrap any Firebase methods we want to use making sure ...
	// ... to save the user to state.

	const showMessage = (options) => {
		setMessageState({
			state: true,
			options: { ...messageState.options, ...options }
		});
	};
	const hideMessage = () => {
		setMessageState({ state: null });
	};

	// Return the user object and auth methods
	return {
		state: messageState.state,
		options: messageState.options,
		showMessage,
		hideMessage
	};
}

export { MessageProvide, useMessageProvide, useMessage };
