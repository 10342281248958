import callApi from 'src/utils/fetchAsyncCalls';
import { API_ROOT } from 'src/app/constants';

export const botUserCreate = ({ token, data }) =>
	callApi(`${API_ROOT}bot/user/create`, {
		token,
		data: { ...data }
	});

export const botUserGet = (token) =>
	callApi(`${API_ROOT}bot/user/get`, {
		token
	});

export const chatAppend = (token) =>
	callApi(`${API_ROOT}chat/append`, {
		token
	});

export const chatList = (token) =>
	callApi(`${API_ROOT}chat/list`, {
		token
	});

export const chatHistoryGet = (token, contactId, botId) => {
	let url = `${API_ROOT}chat/history/get`;

	if (contactId) {
		url = `${url}?contactId=${contactId}`;
	}

	if (botId && !contactId) {
		url = `${url}?botId=${botId}`;
	}

	if (botId && contactId) {
		url = `${url}&botId=${botId}`;
	}

	return callApi(url, {
		token
	});
};

export const chatGet = (token, contactId, botId) => {
	let url = `${API_ROOT}chat/get`;

	if (contactId) {
		url = `${url}?contactId=${contactId}`;
	}

	if (botId) {
		url = `${url}&botId=${botId}`;
	}

	return callApi(url, {
		token
	});
};

export const chatCreate = ({ token, data }) =>
	callApi(`${API_ROOT}chat/create`, {
		token,
		data: { ...data }
	});

export const contactCreate = ({ token, data }) =>
	callApi(`${API_ROOT}contact/create`, {
		token,
		data: { ...data }
	});

export const contactList = (token) =>
	callApi(`${API_ROOT}contact/list`, {
		token
	});
