import { memo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';

const ColumnDataContainer = (props) => {
	const { className, children } = props;
	return <div className={clsx(className || 'grid-cols-4 gap-8')}>{children}</div>;
};

ColumnDataContainer.propTypes = {
	className: PropTypes.string,
	children: PropTypes.node
};

export default memo(ColumnDataContainer);
