import {
	createContext,
	useCallback,
	useContext,
	useEffect,
	useLayoutEffect,
	useReducer,
	useRef,
	useState
} from 'react';

import { useMutation, useQueryClient } from '@tanstack/react-query';

import _ from 'src/lib/@lodash';

import { useSnackbar } from 'notistack';

import { createTheme, getContrastRatio } from '@mui/material/styles';

import {
	defaultSettings,
	defaultThemeOptions,
	defaultThemes,
	extendThemeWithMixins,
	getParsedQuerySettings,
	mainThemeVariations,
	mustHaveThemeOptions
} from 'src/lib/@_fuse/default-settings';

import FuseLayoutConfigs from 'src/lib/theme-layouts/themeLayoutConfigs';
import FuseSettingsConfig from 'src/lib/configs/settingsConfig';
import FuseThemesConfig from 'src/lib/configs/themesConfig';

import { useFetchData } from 'src/utils/fetchAsyncCalls';

import { useAuthState } from './useAuth';
import { useLocalStorage } from '../hooks';

import { apiUserPref, apiUserSettings, apiUserUpdate } from './api';
import { fetchAccountSettings, updateCompanyData } from '../containers/main/api';
import { useDebouncedCallback } from 'use-debounce';
import NotificationModel from 'src/app/containers/main/Notifications/models/NotificationModel';
import NotificationTemplate from 'src/app/containers/main/Notifications/NotificationTemplate';

const getInitialSettings = () => {
	const defaultLayoutStyle =
		FuseSettingsConfig.layout && FuseSettingsConfig.layout.style ? FuseSettingsConfig.layout.style : 'layout1';
	const layout = {
		style: defaultLayoutStyle,
		config: FuseLayoutConfigs[defaultLayoutStyle].defaults
	};
	return _.merge({}, defaultSettings, { layout }, FuseSettingsConfig, getParsedQuerySettings());
};

export const generateSettings = (_defaultSettings, _newSettings) => {
	const response = _.merge(
		{},
		{
			layout: {
				config: FuseLayoutConfigs[_newSettings?.layout?.style]?.defaults
			}
		},
		_defaultSettings,
		_newSettings
	);

	/**
	 * Making theme values failsafe
	 */
	Object.entries(response.theme).forEach(([key, value]) => {
		if (value !== 'mainThemeDark' && value !== 'mainThemeLight' && !FuseThemesConfig[value]) {
			response.theme[key] = 'default';
		}
	});

	return response;
};

const generateMuiTheme = (themes, id, direction) => {
	const data = _.merge({}, defaultThemeOptions, themes[id], mustHaveThemeOptions);
	return createTheme(
		_.merge({}, data, {
			mixins: extendThemeWithMixins(data),
			direction
		})
	);
};

const themesObjRaw = Object.keys(FuseThemesConfig).length !== 0 ? FuseThemesConfig : defaultThemes;
const initialSettings = getInitialSettings();
const initialThemes = {
	...themesObjRaw,
	...mainThemeVariations(themesObjRaw[initialSettings.theme.main])
};

const initialState = {
	initial: initialSettings,
	defaults: _.merge({}, initialSettings),
	current: _.merge({}, initialSettings),
	themes: initialThemes
};

const settingsContext = createContext(initialState);

// Provider hook that creates auth object and handles state
const useSettingsProvide = () => {
	const { token } = useAuthState();

	const { enqueueSnackbar, closeSnackbar } = useSnackbar();

	const themeRef = useRef(null);

	const queryClient = useQueryClient({
		defaultOptions: {
			queries: {
				networkMode: 'offlineFirst'
			},
			mutations: {
				networkMode: 'offlineFirst'
			}
		}
	});

	const [accountState, setAccountState] = useState({});
	const [fetchUserPref, setFetchUserPref] = useState(false);
	const [settingsState, setSettingsState] = useState(initialState);
	const [userPref, setUserPref] = useState({});
	const [userState, setUserState] = useState({});

	const {
		storedValue: storedValuePref,
		setValue: setValuePref,
		removeStorageKey: removeStoragePref
	} = useLocalStorage('user-pref', {});

	const {
		storedValue: storedValueSettings,
		setValue: setValueSettings,
		removeStorageKey: removeStorageSettings
	} = useLocalStorage('user-settings', {});

	const {
		status,
		data: userPrefData,
		error,
		isFetching
	} = useFetchData('userPref', [], apiUserPref, {
		onSuccess: () => {},
		enabled: !!token
	});

	const { data: userSettings } = useFetchData('userSettings', [], apiUserSettings, {
		onSuccess: (res) => {
			// console.log('Got new user settings', res);
			if (res) {
				const localUserState = userState;

				// Update user pref
				const resSettings = {
					...localUserState,
					settings: _.merge({}, localUserState.settings || {}, res)
				};

				console.log('resSettings', resSettings);

				// To deal with shortcuts list not handled correctly in the merge of the object
				if (res.shortcuts) {
					resSettings.settings.shortcuts = res.shortcuts;
				}

				// setUserState(resSettings);
				setUserState((prevState) => ({
					...prevState,
					...resSettings
				}));
				setValueSettings(res);

				if (res.theme && !_.isEqual(themeRef.current, res.theme)) {
					themeRef.current = res.theme;
					setDefaultSettings({ theme: res.theme }, res);
				}

				if (res.layout) {
					setDefaultSettings({ layout: res.layout });
				}
			}
		},
		enabled: !!token
	});

	const { data: accountSettings, isLoading } = useFetchData('accountSettings', [], fetchAccountSettings, {
		onSuccess: () => {},
		enabled: !!token
	});

	useEffect(() => {
		if (userSettings) {
			const localUserState = userState;

			// Update user pref
			const resSettings = {
				...localUserState,
				settings: _.merge({}, localUserState.settings || {}, userSettings)
			};

			// To deal with shortcuts list not handled correctly in the merge of the object
			if (userSettings.shortcuts) {
				resSettings.settings.shortcuts = userSettings.shortcuts;
			}

			// setUserState(resSettings);
			setUserState((prevState) => ({
				...prevState,
				...resSettings
			}));
			setValueSettings(userSettings);

			if (userSettings.theme && !_.isEqual(themeRef.current, userSettings.theme)) {
				themeRef.current = userSettings.theme;
				setDefaultSettings({ theme: userSettings.theme }, userSettings);
			}

			if (userSettings.layout) {
				setDefaultSettings({ layout: userSettings.layout });
			}
		}
	}, [userSettings]);

	useEffect(() => {
		if (userPrefData) {
			setUserPref((prevState) => ({
				...prevState,
				...userPrefData
			}));

			setValuePref(userPrefData);
		}
	}, [userPrefData]);

	useEffect(() => {
		if (accountSettings) {
			setAccountState((prevState) => ({
				...prevState,
				...accountSettings
			}));
		}
	}, [accountSettings]);

	const { mutate, isLoading: userUpdateIsLoading } = useMutation({
		mutationFn: apiUserUpdate,
		// When mutate is called:
		onMutate: (newUserPref) => {
			console.log('newUserPref', newUserPref);

			if (newUserPref.data) {
				if (newUserPref.data.preferences) {
					// User preference settings
					// Update user settings
					const resPref = {
						...userPref,
						...newUserPref.data.preferences
					};

					// setUserPref(resPref);
					setUserPref((prevState) => ({
						...prevState,
						...resPref
					}));
				} else {
					// User Settings
					if (newUserPref.data.theme) {
						themeRef.current = newUserPref.data.theme;
						setDefaultSettings({ theme: newUserPref.data.theme });
					}

					if (newUserPref.data.layout) {
						console.log('Update Layout', newUserPref.data.layout);
						setDefaultSettings({
							layout: { config: newUserPref.data.layout }
						});
					}

					const localUserState = userState;

					// Update user settings
					const resSettings = {
						...localUserState,
						settings: _.merge({}, localUserState.settings || {}, newUserPref.data)
					};

					// To deal with shortcuts list not handled correctly in the merge of the object
					if (newUserPref.shortcuts) {
						resSettings.settings.shortcuts = newUserPref.shortcuts;
					}

					// setUserState({ ...resSettings });
					setUserState((prevState) => ({
						...prevState,
						...resSettings
					}));
				}
			}
		},
		onSuccess: (resultSet) => {},
		onSettled: async () => {
			await queryClient.invalidateQueries('userSettings');
		}
	});

	const { mutate: userPrefMutate, isLoading: userPrefIsLoading } = useMutation({
		mutationFn: apiUserUpdate,
		// When mutate is called:
		onMutate: (newUserPref) => {
			if (newUserPref?.data?.preferences) {
				// User preference settings
				// Update user settings
				const resPref = {
					...userPref,
					...newUserPref.data.preferences
				};

				// setUserPref(resPref);
				setUserPref((prevState) => ({
					...prevState,
					...resPref
				}));
			}
		},
		onSuccess: (resultSet) => {},
		onSettled: async () => {
			await queryClient.invalidateQueries('userPref');
		}
	});

	const { mutate: companyMutate, isLoading: companyUpdateIsLoading } = useMutation({
		mutationFn: updateCompanyData,
		// When mutate is called:
		onMutate: (newCompanySettings) => {
			// setIsSubmitting(true);
			console.log('newCompanySettings', newCompanySettings);
		},
		onSuccess: (response) => {
			console.log('companyMutate response', response);

			if (Object.keys(response).includes('message')) {
				console.log('found message', response);
				const item = NotificationModel({ title: response.message });
				enqueueSnackbar(item.title, {
					key: item.id,
					// autoHideDuration: 3000,
					content: (
						<NotificationTemplate
							item={item}
							onClose={() => {
								closeSnackbar(item.id);
							}}
						/>
					)
				});

				// showMessage({
				// 	color: 'primary',
				// 	anchorOrigin: {
				// 		vertical: 'top',
				// 		horizontal: 'middle'
				// 	},
				// 	message: response.message,
				// 	variant: 'success'
				// });
			} else if ('error' in response) {
				// showMessage({
				//   color: 'primary',
				//   anchorOrigin: {
				//     vertical: 'top',
				//     horizontal: 'middle',
				//   },
				//   message: response.error,
				//   variant: 'error',
				// });
			}
		},
		onSettled: async () => {
			await queryClient.invalidateQueries('accountSettings');
		}
	});

	const debouncedUserUpdate = useDebouncedCallback(
		async (data) => {
			if (data?.data?.preferences) {
				await userPrefMutate({ token, ...data });
			} else {
				await mutate({ token, ...data });
			}
		},
		300 // Debounce delay in milliseconds
	);

	const userUpdate = (data) => {
		// Optimistic local update
		// setUser((prevUser) => ({ ...prevUser, ...data }));
		// Debounced server update
		debouncedUserUpdate(data);
	};

	// const userUpdate = async (data) => {
	// 	// console.log('Updating user', data?.data?.preferences);
	// 	if (data?.data?.preferences) {
	// 		userPrefMutate({ token, ...data });
	// 	} else {
	// 		mutate({ token, ...data });
	// 	}
	// };

	const companyUpdate = (data) => {
		console.log('Updating company');
		companyMutate({ token, ...data });
	};

	const selectContrastMainTheme = (bgColor) => {
		function isDark(color) {
			return getContrastRatio(color, '#ffffff') >= 3;
		}

		return isDark(bgColor) ? selectMainThemeDark : selectMainThemeLight;
	};

	const selectMainTheme = () =>
		generateMuiTheme(settingsState.themes, settingsState.current.theme.main, settingsState.current.direction);

	const selectMainThemeDark = () =>
		generateMuiTheme(settingsState.themes, 'mainThemeDark', settingsState.current.direction);

	const selectMainThemeLight = () =>
		generateMuiTheme(settingsState.themes, 'mainThemeLight', settingsState.current.direction);

	const selectNavbarTheme = () =>
		generateMuiTheme(settingsState.themes, settingsState.current.theme.navbar, settingsState.current.direction);

	const selectToolbarTheme = () =>
		generateMuiTheme(settingsState.themes, settingsState.current.theme.toolbar, settingsState.current.direction);

	const selectFooterTheme = () =>
		generateMuiTheme(settingsState.themes, settingsState.current.theme.footer, settingsState.current.direction);

	const handleRemoveStorageValues = () => {
		removeStorageSettings();
		removeStoragePref();
	};

	async function handleUserSettingsUpdate(data) {
		if (data && data.theme) {
			setDefaultSettings({ theme: data.theme }, storedValueSettings);
		}

		const resSettings = _.merge({}, storedValueSettings || {}, data);

		setValueSettings({ ...resSettings });
	}

	const setDefaultSettings = useCallback(
		(data, localSettings) => {
			// If we don't have the user state loaded into the store
			const localUserState = !_.isEmpty(userState) ? userState : localSettings;
			let newUserSettings = null;

			if (localUserState?.settings) {
				// Update user pref
				newUserSettings = {
					...localUserState.settings,
					...data
				};
			} else {
				// Update user pref
				newUserSettings = {
					...localUserState,
					...data
				};
			}

			// setUserState({ settings: newUserSettings });
			setUserState((prevState) => ({
				...prevState,
				...newUserSettings
			}));

			// update stored value for user pref
			// setValue({ ...storedValue, settings: newUserSettings });

			const defaults = generateSettings(settingsState.defaults, data);

			const themes =
				defaults.theme.main !== settingsState.defaults.theme.main
					? {
							...settingsState.themes,
							...mainThemeVariations(themesObjRaw[defaults.theme.main])
						}
					: settingsState.themes;

			const newSettings = {
				...settingsState,
				defaults: _.merge({}, defaults),
				current: _.merge({}, defaults),
				themes
			};
			// setSettingsState(newSettings);
			setSettingsState((prevState) => ({
				...prevState,
				...newSettings
			}));
			// console.log('Updating settings');
		},
		[settingsState, userState]
	);

	const setInitialSettings = () => {
		const newSettings = _.merge({}, initialState);
		// setSettingsState(newSettings);
		setSettingsState((prevState) => ({
			...prevState,
			...newSettings
		}));
	};

	const setSettings = (data) => {
		const current = generateSettings(settingsState.defaults, data);
		const themes =
			current.theme.main !== settingsState.current.theme.main
				? {
						...settingsState.themes,
						...mainThemeVariations(themesObjRaw[current.theme.main])
					}
				: settingsState.themes;

		const newSettings = {
			...settingsState,
			current,
			themes
		};
		// setSettingsState(newSettings);
		setSettingsState((prevState) => ({
			...prevState,
			...newSettings
		}));
	};

	const resetSettings = () => {
		const themes = {
			...settingsState.themes,
			...mainThemeVariations(themesObjRaw[settingsState.defaults.theme.main])
		};

		const newSettings = {
			...settingsState,
			defaults: _.merge({}, settingsState.defaults),
			current: _.merge({}, settingsState.defaults),
			themes
		};
		// setSettingsState(newSettings);
		setSettingsState((prevState) => ({
			...prevState,
			...newSettings
		}));
	};

	useLayoutEffect(() => {
		// If we don't have a token, try to load from localstorage
		if (_.isEmpty(userState) && _.isEmpty(storedValuePref) === false) {
			// setUserState(storedValuePref);
			setUserState((prevState) => ({
				...prevState,
				...storedValuePref
			}));
		}
	}, [userState, storedValuePref]);

	useLayoutEffect(() => {
		// Reset theme values from stored data
		if (_.isEmpty(userState) && _.isEmpty(storedValueSettings) === false) {
			if (storedValueSettings.theme) {
				setDefaultSettings({ theme: storedValueSettings.theme }, storedValueSettings);
			}
		}
	}, [setDefaultSettings, storedValueSettings, userState]);

	useEffect(() => {
		if (fetchUserPref === false && token !== null && _.isEmpty(storedValuePref) === true) {
			// console.log('Fetch Prefs');
			setFetchUserPref(true);
		} else if (_.isEmpty(storedValuePref) === false && _.isEmpty(userState) === true) {
			// setUserState(storedValuePref);
			setUserState((prevState) => ({
				...prevState,
				...storedValuePref
			}));
		}
	}, [fetchUserPref, storedValuePref, token, userState]);

	useEffect(
		() =>
			// Cleanup remove localstorage data
			() => {
				// console.log('Clear local storage for user Pref');
				// removeStorageKey();
				// removeStorageKeyTheme();
			},
		[]
	);

	// Return the user object and auth methods
	return {
		settings: settingsState,
		settingsDefaults: settingsState.defaults,
		settingsCurrent: settingsState.current,
		settingsDirection: settingsState.current.direction,
		settingsMainThemeId: settingsState.current.theme.main,
		settingsNavbarThemeId: settingsState.current.theme.navbar,
		settingsToolbarThemeId: settingsState.current.theme.toolbar,
		settingsFooterThemeId: settingsState.current.theme.footer,
		themes: settingsState.themes,
		userPref,
		userSettings: userState,
		accountSettings: accountState,
		userUpdateIsLoading,
		companyUpdateIsLoading,
		handleUserSettingsUpdate,
		selectContrastMainTheme,
		selectMainTheme,
		selectMainThemeDark,
		selectMainThemeLight,
		selectNavbarTheme,
		selectToolbarTheme,
		selectFooterTheme,
		userUpdate,
		companyUpdate,
		setSettings,
		setDefaultSettings,
		setInitialSettings,
		resetSettings,
		removeStorageUser: handleRemoveStorageValues
	};
};

// Provider component that wraps your app and makes auth object ...
// ... available to any child component that calls useAuth().
export const SettingsProvide = ({ children }) => {
	const settings = useSettingsProvide();

	return <settingsContext.Provider value={settings}>{children}</settingsContext.Provider>;
};

// Hook for child components to get the auth object ...
// ... and re-render when it changes.
export const useSettings = () => {
	const context = useContext(settingsContext);

	if (context === undefined) {
		throw new Error(`useSettings must be used within a SettingsProvide`);
	}

	return context;
};

export const useSettingsUserUpdate = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { userUpdate, userUpdateIsLoading } = useSettings();

	useEffect(() => {
		if (isSubmitting) {
			setIsLoading(true);
		}

		if (isSubmitting && isLoading && !userUpdateIsLoading) {
			setIsLoading(false);
			setIsSubmitting(false);
		}
	}, [userUpdateIsLoading, isSubmitting, isLoading]);

	return { isSubmitting, isLoading, setIsSubmitting, userUpdate };
};

export const useSettingsCompanyUpdate = () => {
	const [isSubmitting, setIsSubmitting] = useState(false);
	const [isLoading, setIsLoading] = useState(false);
	const { companyUpdate, companyUpdateIsLoading } = useSettings();

	const handleSetSubmitting = (value) => {
		setIsSubmitting(value);
	};
	useEffect(() => {
		if (isSubmitting) {
			setIsLoading(true);
		}

		if (isSubmitting && isLoading && !companyUpdateIsLoading) {
			setIsLoading(false);
			setIsSubmitting(false);
		}
	}, [companyUpdateIsLoading, isSubmitting, isLoading]);

	return { isSubmitting, isLoading, setIsSubmitting, companyUpdate };
};
