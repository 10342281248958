import PropTypes from 'prop-types';

import clsx from 'clsx';

import { useFieldArray } from 'react-hook-form';

import Button from '@mui/material/Button';
import Icon from '@mui/material/Icon';
import IconButton from '@mui/material/IconButton';

import Input from 'src/app/components/core/form/Input';

function BuildArgField(props) {
	const { className, label, subLabel, name, errors, control } = props;

	const { fields, append, prepend, remove, swap, move, insert } = useFieldArray({
		control, // control props comes from useForm (optional: if you are using FormContext)
		name // unique name for your Field Array
		// keyName: "id", default to "id", you can change the key name
	});

	return (
		<div className={clsx(className, 'flex flex-col')}>
			<span className="text-15">{label}</span>
			<span className="text-13 text-gray-600">{subLabel}</span>
			<div className="mt-24">
				{fields.length !== 0 && (
					<div className="grid grid-cols-7 space-x-24">
						<span className="col-span-3">Key</span>
						<span className="col-span-3">Value</span>
					</div>
				)}
			</div>
			<div className="space-y-16">
				{fields.map((item, index) => (
					<div
						key={index}
						className="flex flex-row items-center pt-4 space-x-24"
					>
						<Input
							className="pt-14"
							// label="Key"
							control={control}
							name={`${name}.${index}.key`}
							errors={errors}
							fullWidth
							required
						/>

						<Input
							className="pt-14"
							// label="Value"
							control={control}
							name={`${name}.${index}.value`}
							errors={errors}
							fullWidth
							required
						/>

						<IconButton
							key="close"
							aria-label="Close"
							color="inherit"
							onClick={() => remove(index)}
						>
							<Icon>close</Icon>
						</IconButton>
					</div>
				))}
				<div className="flex flex-row justify-between">
					<Button
						variant="contained"
						onClick={() => {
							append({ key: '', value: '' });
						}}
					>
						Add
					</Button>
				</div>
			</div>
		</div>
	);
}

BuildArgField.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string,
	id: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.array,
	errors: PropTypes.object,
	control: PropTypes.object.isRequired
};

export default BuildArgField;
