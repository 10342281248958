import { lazy } from 'react';

import authRoles from '../../../../auth/authRoles';
// import { URLPaths } from 'src/app/constants';

const MultiSettings = lazy(() => import('./index'));

const multiSettingsConfig = {
	path: '/settings/admin', // URLPaths.SETTINGS_ACCOUNT_PATH,
	exact: true,
	element: <MultiSettings />,
	auth: authRoles.admin
};

export default multiSettingsConfig;
