import PropTypes from 'prop-types';
import clsx from 'clsx';
import { memo } from 'react';

const ColumnDataItem = (props) => {
	const { title, value, className, classNameTitle, classNameValue } = props;
	return (
		<div className={clsx('sm:col-span-1', className)}>
			<dt className={clsx('text-lg font-medium text-gray-500', classNameTitle)}>{title}:</dt>
			<dd className={clsx('mt-2 text-lg text-gray-900', classNameValue)}>
				{value ? <>{value}</> : <div> - </div>}
			</dd>
		</div>
	);
};

ColumnDataItem.propTypes = {
	className: PropTypes.string,
	title: PropTypes.string.isRequired,
	value: PropTypes.oneOfType([PropTypes.string, PropTypes.number, PropTypes.node])
};

export default memo(ColumnDataItem);
