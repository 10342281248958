import { lazy } from 'react';

const PricingPage = lazy(() => import('./PricingPage'));

const pricingPageConfig = {
	path: '/pricing',
    element: <PricingPage />,
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	}
};

export default pricingPageConfig;
