import {
	HumanAgentChatFirstScreen,
	HumanAgentChat,
	ChatHistoryChat,
	ChatWithBotChat,
	ChatHistoryChatFirstScreen,
	ChatWithBotChatFirstScreen,
	ChatbotChatHistory,
	ChatbotDetailApp,
	ChatbotOverview,
	ChatbotDetailDashboard,
	ChatbotChatWithBot,
	ChatbotDetailEmbed,
	ChatbotDetailShare,
	ChatbotDetailManageSources,
	ChatbotDetailSettingsAppearance,
	ChatbotDetailSettingsCollectUserInfo,
	ChatbotDetailSettingsDetails,
	ChatbotDetailSettingsDomains,
	ChatbotDetailSettingsQuickPrompts,
	ChatbotHumanAgent,
	ChatbotDetailSettings
} from './lazyComponents';

const chatbotSettingsConfig = {
	routes: [
		{
			path: '/chatbot/detail',
			element: <ChatbotDetailApp />,
			children: [
				{
					path: '',
					element: <ChatbotOverview />,
					exact: true
				},
				{
					path: 'dashboard',
					element: <ChatbotDetailDashboard />,
					exact: true
				},
				{
					path: 'chat-history',
					element: <ChatbotChatHistory />,
					children: [
						{
							path: '',
							element: <ChatHistoryChatFirstScreen />
						},
						{
							path: ':id',
							element: <ChatHistoryChat />
						}
						// {
						//   path: ':id/agent/:agentId',
						//   element: <ChatHistoryChat />,
						// },
					]
				},
				{
					path: 'chat-with-bot',
					element: <ChatbotChatWithBot />,
					children: [
						{
							path: '',
							element: <ChatWithBotChatFirstScreen />
						},
						{
							path: 'chat/:id',
							element: <ChatWithBotChat />
						}
						// {
						//   path: 'chat/:id/agent/:agentId',
						//   element: <ChatWithBotChat />,
						// },
					]
				},
				{
					path: 'embed',
					element: <ChatbotDetailEmbed />
				},
				{
					path: 'human-agent',
					element: <ChatbotHumanAgent />,
					children: [
						{
							path: '',
							element: <HumanAgentChatFirstScreen />
						},
						{
							path: ':id',
							element: <HumanAgentChat />
						}
						// {
						//   path: ':id/agent/:agentId',
						//   element: <HumanAgentChat />,
						// },
					]
				},
				{
					path: 'share',
					element: <ChatbotDetailShare />
				},
				{
					path: 'manage-sources',
					element: <ChatbotDetailManageSources />
				},
				{
					path: 'settings',
					element: <ChatbotDetailSettings />
				},

				{
					path: 'settings/appearance',
					element: <ChatbotDetailSettingsAppearance />
				},
				{
					path: 'settings/collect-user-info',
					element: <ChatbotDetailSettingsCollectUserInfo />
				},
				{
					path: 'settings/details',
					element: <ChatbotDetailSettingsDetails />
				},
				{
					path: 'settings/domains',
					element: <ChatbotDetailSettingsDomains />
				},
				{
					path: 'settings/quick-prompts',
					element: <ChatbotDetailSettingsQuickPrompts />
				}
			]
		}
	]
};

export default chatbotSettingsConfig;
