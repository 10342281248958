import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';
// import { authRoles } from 'app/auth';

const VerifyIncomingEmail = lazy(() => import('./index'));

const VerifyIncomingEmailConfig = {
	path: URLPaths.PAGES_VERIFY_INCOMING_EMAIL,
	element: <VerifyIncomingEmail />,
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	}
};

export default VerifyIncomingEmailConfig;
