import PropTypes from 'prop-types';

import Switch from '@mui/material/Switch';

import { styled } from '@mui/material/styles';

const Root = styled('div')(({ theme }) => ({
	'& .root': {
		width: 34,
		height: 15,
		padding: 0,
		margin: theme.spacing(1)
	},
	'& .switchBase': {
		padding: 1.6,
		'&$checked': {
			transform: 'translateX(16px)',
			color: theme.palette.common.white,
			'& + $track': {
				backgroundColor: theme.palette.secondary.main,
				opacity: 1,
				border: 'none'
			}
		},
		'&$focusVisible $thumb': {
			color: theme.palette.secondary.main,
			border: '6px solid #fff'
		}
	},
	'& .thumb': {
		width: 12,
		height: 12
	},
	'& .track': {
		borderRadius: 13 / 2,
		border: `1px solid ${theme.palette.grey[400]}`,
		backgroundColor: theme.palette.grey[50],
		opacity: 1,
		transition: theme.transitions.create(['background-color', 'border'])
	},
	'& .checked': {},
	'& .focusVisible': {}
}));

const DenseSwitch = (props) => {
	const { isChecked, handleOnChange } = props;

	return (
		<Root>
			<Switch
				checked={isChecked}
				onChange={handleOnChange}
				focusVisibleClassName={'focusVisible'}
				disableRipple
				classes={{
					root: 'root',
					switchBase: 'switchBase',
					thumb: 'thumb',
					track: 'track',
					checked: 'checked'
				}}
				{...props}
			/>
		</Root>
	);
};

DenseSwitch.propTypes = {
	isChecked: PropTypes.bool.isRequired,
	handleOnChange: PropTypes.func.isRequired
};

export default DenseSwitch;
