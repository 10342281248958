// import axios from 'axios';
// import { URLMap } from 'src/app/constants';
// import callApi from 'src/utils/fetchAsyncCalls';

import { URLMap } from 'src/app/constants';
import callApi from 'src/utils/fetchAsyncCalls';

// Outside Service...
// export const getTaskLogs = (
//   _,
//   token,
//   appId,
//   taskExecutionId,
//   taskNextToken,
//   taskMaxRecords,
// ) => {
//   let url = `https://gicmjixqge.execute-api.us-east-2.amazonaws.com/dev/apps/${appId}/log/sessions/?&execution_id=${taskExecutionId}`;
//   if (taskNextToken) {
//     url += `&next_key=${taskNextToken}`;
//   }
//   if (taskMaxRecords) {
//     url += `&max_records=${taskMaxRecords}`;
//   }
//   return callApiGet2(token, url);
// };

export const getTaskLogs = (token, taskExecutionId, taskNextToken, taskMaxRecords) => {
	let url = `${URLMap.LOG_BASE}sessions?executionId=${taskExecutionId}`;

	if (taskNextToken) {
		url += `&next_key=${taskNextToken}`;
	}

	if (taskMaxRecords) {
		url += `&max_records=${taskMaxRecords}`;
	}

	return callApi(url, { token });
};

export const searchLogRequest = ({ token, appDefId, appGuid, namespace, region, logType, data }) =>
	callApi(
		`${URLMap.LOG_BASE}sessions?namespace=${namespace}&appDefId=${appDefId}&appGuid=${appGuid}&region=${region}&logType=${logType}`,
		{ token, data }
	);

// export const getAppList = (token, namespace) =>
//   callApi(`${URLMap.APP_LIST_GET}${namespace}`, { token });

export const getAppSpecList = (token, namespace, listType, appType = null) => {
	let url = `${URLMap.APP_DEF_SPEC_LIST_GET}/${listType}?namespace=${namespace}`;

	if (appType) {
		url = `${url}&appType=${appType}`;
	}

	return callApi(url, {
		token
	});
};

export const getAttrList = (token, attrType) => callApi(`${URLMap.ATTR_LIST_GET}/${attrType}`, { token });

export const appModAttr = ({ token, appId, namespace, data }) =>
	callApi(`${URLMap.APP_BASE}${appId}/mod/attr?namespace=${namespace}`, {
		token,
		data
	});

export const appModAction = ({ token, appSpecId, serviceId, data }) => {
	let url = `${URLMap.APP_BASE}actions?appSpecId=${appSpecId}`;

	if (serviceId) {
		url = `${url}&service_id=${serviceId}`;
	}

	return callApi(url, {
		token,
		data
	});
};

export const appModScale = ({ token, appSpecId, serviceId, data }) => {
	let url = `${URLMap.APP_BASE}scale?appSpecId=${appSpecId}`;

	if (serviceId) {
		url = `${url}&service_id=${serviceId}`;
	}

	return callApi(url, {
		token,
		data
	});
};

export const appCreate = ({ token, data }) => callApi(`${URLMap.APP_CREATE}`, { token, data });

export const appCloneCreate = ({ token, namespace, data }) =>
	callApi(`${URLMap.APP_CLONE_CREATE}/${namespace}`, { token, data });

// export const appDefCreate = ({ token, namespace, data }) =>
//   callApi(`${URLMap.APP_DEF_CREATE}/${namespace}`, { token, data });

export const appDefCreate = ({ token, data }) => callApi(`${URLMap.APP_DEF_CREATE}`, { token, data });

export const appDefList = (token, catalog, appDefId) => {
	let URL = `${URLMap.APP_DEF_LIST_GET}/${catalog}`;

	if (appDefId) {
		URL = `${URL}/${appDefId}`;
	}

	return callApi(URL, { token });
};

export const appDeploy = ({ token, namespace, data }) => callApi(`${URLMap.APP_DEPLOY}/${namespace}`, { token, data });

export const getALBList = (token) => callApi(URLMap.ALB_LIST_GET, { token });

export const getAlbStatus = (token, albId) => callApi(`${URLMap.ALB_STATUS_GET}/${albId}`, { token });

export const getAppStatus = (token, catalogId, appDefId, region, cluster, guid, appType = null) => {
	let url = `${URLMap.APP_BASE}status?catalogId=${catalogId}&appDefId=${appDefId}&region=${region}&cluster=${cluster}&guid=${guid}`;

	if (appType) {
		url = `${url}&app_type=${appType}`;
	}

	return callApi(url, {
		token
	});
};

export const assetActivityGet = (token, assetType, assetId, sortBy = null, attribs = null) => {
	let url = URLMap.ASSET_ACTIVITY_GET;

	url += `?assetType=${assetType}&assetId=${assetId}`;

	if (sortBy) {
		url += `&sortBy=${sortBy}`;
	}

	if (attribs) {
		url += `&attribs=${btoa(JSON.stringify(attribs))}`;
	}

	return callApi(url, { token });
};

export const getAssetStatus = (token, assetType, assetId) =>
	callApi(`${URLMap.ASSET_STATUS}?assetType=${assetType}&assetId=${assetId}`, {
		token
	});

export const assetUpdate = ({ token, data }) => {
	const postData = { _payload: btoa(JSON.stringify(data)) };

	return callApi(URLMap.ASSET_UPDATE, { token, data: postData });
};

export const assetItemGet = (token, assetType, assetId, attribs = null, assetEq = null) => {
	let url = URLMap.ASSET_GET;

	url += `?&assetId=${assetId}&assetType=${assetType}`;

	if (assetEq) {
		url += `&assetEq=${assetEq}`;
	}

	if (attribs) {
		url += `&attribs=${btoa(JSON.stringify(attribs))}`;
	}

	return callApi(url, { token });
};

export const getAppDetail = (token, appSpecId, serviceId, appType = null) => {
	let url = `${URLMap.APP_BASE}detail?appSpecId=${appSpecId}`;

	if (serviceId) {
		url = `${url}&serviceId=${serviceId}`;
	}

	if (appType) {
		url = `${url}&appType=${appType}`;
	}

	return callApi(url, { token });
};

export const getAppCost = (token, appId, namespace) =>
	callApi(`${URLMap.APP_BASE}cost${namespace}/${appId}`, { token });

export const namespaceCostGet = (token, namespace) => callApi(`${URLMap.NAMESPACE_COST_GET}/${namespace}`, { token });

export const namespaceListGet = (token) => callApi(URLMap.NAMESPACE_LIST_GET, { token });

export const namespaceNameListGet = (token) => callApi(URLMap.NAMESPACE_NAME_LIST_GET, { token });

export const namespaceStatusGet = (token, namespaceId) =>
	callApi(`${URLMap.NAMESPACE_STATUS_GET}/${namespaceId}?includeCosts=true`, {
		token
	});

export const clusterCreate = ({ token, data }) => callApi(URLMap.CLUSTER_CREATE, { token, data });

export const getClusterList = (token) => callApi(URLMap.CLUSTER_LIST_GET, { token });

export const getVPCStatus = (token, vpcId) => callApi(`${URLMap.VPC_STATUS_GET}/${vpcId}`, { token });

export const getCert = (token, certId) => callApi(`${URLMap.CERT_DETAIL}/${certId}`, { token });

export const getCertList = (token) => callApi(`${URLMap.CERT_LIST_GET}`, { token });

export const getCertSourceList = (token) => callApi(`${URLMap.CERT_SOURCE_LIST_GET}`, { token });

export const getVPCList = (token) => callApi(URLMap.VPC_LIST_GET, { token });

export const limitsGet = (token) => callApi(URLMap.LIMITS_BASE, { token });

export const limitsSet = ({ token, data }) =>
	callApi(URLMap.LIMITS_BASE, {
		token,
		data
	});

export const limitsDepartmentGet = (token, departmentId) =>
	callApi(`${URLMap.LIMITS_DEPARTMENT_LIMITS_GET}/${departmentId}`, { token });

export const domainListGet = (token) => callApi(URLMap.DOMAIN_LIST_GET, { token });

export const domainDetailGet = (token, hostedZoneId) =>
	callApi(`${URLMap.DOMAIN_DETAIL_GET}/${hostedZoneId}`, { token });

export const vpcCreate = ({ token, data }) => callApi(URLMap.VPC_CREATE, { data, token });

export const networkCreate = ({ token, data }) => callApi(URLMap.NETWORK_CREATE, { data, token });

export const scheduleSet = ({ token, appId, namespace, data }) =>
	callApi(`${URLMap.APP_BASE}${appId}/schedule?namespace=${namespace}`, {
		token,
		data
	});

export const serviceCatalogList = (token) => callApi(`${URLMap.SERVICE_CATALOG_BASE}list`, { token });

export const serviceCatalogGet = (token, catalogId) =>
	callApi(`${URLMap.SERVICE_CATALOG_BASE}get/${catalogId}`, { token });

export const serviceCatalogCreate = ({ token, data }) =>
	callApi(`${URLMap.SERVICE_CATALOG_BASE}create`, {
		token,
		data
	});

export const serviceGitHubRepoList = (token, githubToken) =>
	callApi(`${URLMap.GIT_GITHUB_REPO_LIST}?githubToken=${githubToken}`, {
		token
	});

export const serviceGitHubRepoBranchList = (token, githubToken, githubRepoFullname) =>
	callApi(`${URLMap.GIT_GITHUB_REPO_BRANCH_LIST}?githubToken=${githubToken}&repoFullname=${githubRepoFullname}`, {
		token
	});

export const serviceAppDefCreate = ({ token, data }) =>
	callApi(`${URLMap.SERVICE_APP_BASE}def/create`, {
		token,
		data
	});

export const serviceAppCatalogListOfDef = (token, listType, catalogId, attribs) => {
	// console.log(
	//   'listType,\n' + '  catalogId,\n' + '  attribs,',
	//   listType,
	//   catalogId,
	//   attribs,
	// );
	let url = `${URLMap.SERVICE_APP_DATA_BASE}list/${listType}?catalogId=${catalogId}`;

	if (attribs) {
		url += `&attribs=${btoa(JSON.stringify(attribs))}`;
	}

	console.log('url', url);

	return callApi(url, { token });
};

export const serviceAppDefListOfSpec = (token, listType, appDefId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}list/${listType}?appDefId=${appDefId}`, { token });

export const serviceAppDefChannelBuild = (token, appDefId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}def/channel/build?appDefId=${appDefId}`, { token });

export const serviceAppDefChannelReleases = (
	token,
	appDefId,
	assetType,
	attribs = null,
	assetId = null,
	assetEq = null
) => {
	let url = URLMap.ASSET_LIST;

	url += `?assetType=${assetType}`;

	if (assetId) {
		url += `&assetId=${assetId}`;
	}

	if (assetEq) {
		url += `&assetEq=${assetEq}`;
	}

	if (attribs) {
		url += `&attribs=${btoa(JSON.stringify(attribs))}`;
	}

	return callApi(url, { token });
};

export const serviceAppDefSpecList = (token, listType) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}list/${listType}`, { token });

export const serviceAppDefDetail = (token, appDefId) => {
	const url = `${URLMap.SERVICE_APP_DATA_BASE}def/detail/${appDefId}`;

	return callApi(url, { token });
};

export const serviceAppDefServiceDetail = (token, appDefId, serviceId) => {
	let url = `${URLMap.SERVICE_APP_DATA_BASE}def/detail/${appDefId}`;

	if (serviceId) {
		url += `?serviceId=${serviceId}`;
	}

	return callApi(url, { token });
};

export const serviceAppDef = (token, appDefId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}def/detail/${appDefId}`, { token });

export const dataAppDefDetail = (token, appDefId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}def/${appDefId}`, { token });

export const dataAppDefSettingsData = (token, appDefId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}def/settings/data?appDefId=${appDefId}`, {
		token
	});

export const dataAppDefReleaseChannels = (token, appDefId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}def/release/channels?appDefId=${appDefId}`, {
		token
	});

export const dataAppDefServicesContainerDetail = (token, appDefId, serviceId) =>
	callApi(
		`${URLMap.SERVICE_APP_DATA_BASE}def/services/container/detail?appDefId=${appDefId}&serviceId=${serviceId}`,
		{
			token
		}
	);

export const dataAppDefServicesDetail = (token, appDefId, serviceId) => {
	let url = `${URLMap.SERVICE_APP_DATA_BASE}def/services/detail?appDefId=${appDefId}`;

	if (serviceId) {
		url += `&serviceId=${serviceId}`;
	}

	return callApi(url, {
		token
	});
};

export const dataAppDefServicesSnapshot = (token, appSpecId, appSpecUrl) => {
	const url = `${URLMap.SERVICE_APP_DATA_BASE}def/services/snapshot?appSpecId=${appSpecId}&url=${appSpecUrl}`;

	return callApi(url, {
		token
	});
};

export const dataAppDefServicesReleaseChannels = (token, appDefId, serviceId) =>
	callApi(
		`${URLMap.SERVICE_APP_DATA_BASE}def/services/release/channels?appDefId=${appDefId}&serviceId=${serviceId}`,
		{
			token
		}
	);

export const dataAppDefServicesReleaseSoftware = (token, appDefId, serviceId) =>
	callApi(
		`${URLMap.SERVICE_APP_DATA_BASE}def/services/release/software?appDefId=${appDefId}&serviceId=${serviceId}`,
		{
			token
		}
	);

export const dataAppDefServicesReleaseTimeline = (token, appDefId, serviceId) =>
	callApi(
		`${URLMap.SERVICE_APP_DATA_BASE}def/services/release/timeline?appDefId=${appDefId}&serviceId=${serviceId}`,
		{
			token
		}
	);

export const dataAppDefServicesSettingsData = (token, appDefId, serviceId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}def/services/settings/data?appDefId=${appDefId}&serviceId=${serviceId}`, {
		token
	});

export const dataAppDefSoftwareBuildList = (token, appDefId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}def/software/build/list?appDefId=${appDefId}`, {
		token
	});

export const dataAppDefSoftwareBuildDetail = (token, buildId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}def/software/build/detail?buildId=${buildId}`, {
		token
	});

export const dataAppDefReleaseTimeline = (token, appDefId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}def/release/timeline?appDefId=${appDefId}`, {
		token
	});

export const dataAppDefReleaseSoftware = (token, appDefId) =>
	callApi(`${URLMap.SERVICE_APP_DATA_BASE}def/release/software?appDefId=${appDefId}`, {
		token
	});

export const serviceAppSpecCreate = ({ token, data }) =>
	callApi(`${URLMap.SERVICE_APP_BASE}spec/create`, {
		token,
		data
	});

export const serviceAppSpecDeploy = ({ token, data }) =>
	callApi(`${URLMap.SERVICE_APP_BASE}spec/deploy`, {
		token,
		data
	});

export const serviceAccessGet = (token, resourceType, resource, entityType, entity) => {
	let url = `${URLMap.ACCESS_LIMITS}/get?resource=${resource}&resourceType=${resourceType}`;

	if (entity) {
		url += `&entity=${entity}`;
	}

	if (entityType) {
		url += `&entity_type=${entityType}`;
	}

	return callApi(url, { token });
};

export const serviceAccessSet = ({ token, data }) =>
	callApi(`${URLMap.ACCESS_LIMITS}/set`, {
		token,
		data
	});

export const serviceAccessRevoke = ({ token, data }) =>
	callApi(`${URLMap.ACCESS_LIMITS}/revoke`, {
		token,
		data
	});

export const serviceCatalogOwnerUpdate = ({ token, data }) =>
	callApi(`${URLMap.SERVICE_CATALOG_BASE}/owner`, {
		token,
		data
	});

export const serviceMaintenance = ({ token, data }) => {
	const url = `${URLMap.APP_BASE}/maintenance`;

	return callApi(url, {
		token,
		data
	});
};

export const infraImagePromote = ({ token, data }) => {
	const url = `${URLMap.CHANNEL_IMAGE_PROMOTE}`;

	return callApi(url, {
		token,
		data
	});
};

export const infraImageContainerDetail = (token, image, provider, image_tag) => {
	let url = `${URLMap.CHANNEL_IMAGE_BASE}container/detail?image=${image}&provider=${provider}`;

	if (image_tag) {
		url += `&imageTag=${image_tag}`;
	}

	return callApi(url, {
		token
	});
};
