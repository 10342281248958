// import { authRoles } from 'app/auth';
import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';

const StartOnBoardingAgent = lazy(() => import('../shared-components/AgentCreate/index'));
const StartOnBoardingChatbot = lazy(() => import('../shared-components/ChatbotCreate/index'));
const WelcomeOnboarding = lazy(() => import('./WelcomeOnboarding/index'));

const ConfirmOnboarding = lazy(() => import('./ConfirmOnboarding/index'));

const onBoardingConfig = {
	path: URLPaths.ON_BOARDING,
	children: [
		{
			path: '',
			element: <WelcomeOnboarding />
		},
		{
			path: 'start/',
			exact: true,
			element: <StartOnBoardingChatbot />
		},
		{
			path: 'start/agent/',
			exact: true,
			element: <StartOnBoardingAgent />
		},
		{
			path: 'start/chatbot/',
			exact: true,
			element: <StartOnBoardingChatbot />
		},
		{
			path: 'conformation/',
			exact: true,
			element: <ConfirmOnboarding />
		}
	],
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	}
};

export default onBoardingConfig;
