import { lazy } from 'react';
import { URLPaths } from 'src/app/constants';
import authRoles from '../../../auth/authRoles';

const Login = lazy(() => import('./index'));

const loginConfig = {
	path: URLPaths.LOGIN_PATH,
	element: <Login />,
	settings: {
		layout: {
			config: {
				navbar: {
					display: false
				},
				toolbar: {
					display: false
				},
				footer: {
					display: false
				},
				leftSidePanel: {
					display: false
				},
				rightSidePanel: {
					display: false
				}
			}
		}
	},
	auth: [...authRoles.onlyGuest, '', ...authRoles.user] // guest only, and forward if logged in
};

export default loginConfig;
