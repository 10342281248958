const noRedirect = [
	'/test/component/',
	'/integrations/',
	'/integrations/github/',
	'/register',
	'/register/',
	'/terms/',
	'/pricing',
	'/pricing/',
	'/pages/verify/incoming/',
	'/pages/confirmation-required/',
	'/pages/verify/pass/code',
	'/pages/forgot-password/',
	'/pages/errors',
	'/pages/errors/404',
	'/pages/errors/500'
];

export { noRedirect };
