import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useLocalStorage } from 'react-storage-complete';
import { useAuthState } from 'src/app/context/useAuth';
import { Base64 } from 'js-base64';
import { useSettings } from 'src/app/context/useSettings';
import { URLPaths } from 'src/app/constants';

import FuseSplashScreen from 'src/lib/@fuse/core/FuseSplashScreen';

import { noRedirect } from './constants';

const AuthCheck = (props) => {
	const { isAuthenticated, companySettings } = useAuthState();
	const { userPref } = useSettings();
	const history = useNavigate();
	const location = useLocation();

	const [storedSettings, setStoredSettings] = useLocalStorage(
		'settings',
		{},
		{
			prefix: 'config',
			shouldInitialize: true,
			emitterDisabled: false,
			encode: (t) => Base64.encode(JSON.stringify(t)),
			decode: (t) => JSON.parse(Base64.decode(t))
		}
	);

	const [waitAuthCheck, setWaitAuthCheck] = useState(true);

	useEffect(() => {
		console.log('isAuthenticated', isAuthenticated);
		// // console.log('authState.isAuthenticated', isAuthenticated);

		if (
			((!isAuthenticated && !noRedirect.includes(location.pathname)) ||
				(!isAuthenticated && location.pathname === '/')) &&
			isAuthenticated !== undefined
		) {
			console.log('No logged in');
			history('/login');
			setWaitAuthCheck(false);
		} else {
			console.log('Finished waiting for AuthCheck.');
			setWaitAuthCheck(false);
		}
	}, [history, isAuthenticated, location.pathname]);

	useEffect(() => {
		if (isAuthenticated && !storedSettings?.twoFactorVerified && userPref.enableTwoFactor) {
			if (companySettings?.twoFactor?.enableTimeForce && companySettings?.twoFactor?.enableTime === 'every') {
				// If we enforce two factor for each login, we will make the user to the check.
				history(URLPaths.PAGES_VERIFY_PASS_CODE);
			} else if (storedSettings?.rememberCode30days) {
				if (
					storedSettings?.rememberCode30daysEnd <= Math.floor(Date.now() / 1000) &&
					storedSettings?.rememberCode30daysCheckNextLogin
				) {
					setStoredSettings({
						...storedSettings,
						rememberCode30daysCheckNextLogin: false
					});
					// If we have a 30 day remember set, we will wait till that expires before we ask for the code again.
					history(URLPaths.PAGES_VERIFY_PASS_CODE);
				} else {
					setStoredSettings({
						...storedSettings,
						rememberCode30daysCheckNextLogin: true
					});
				}
			} else {
				// If we have a 30 day remember set, we will wait till that expires before we ask for the code again.
				history(URLPaths.PAGES_VERIFY_PASS_CODE);
			}
		}
	}, [isAuthenticated, storedSettings?.twoFactorVerified, userPref.enableTwoFactor, location.pathname]);

	return waitAuthCheck ? <FuseSplashScreen /> : <>{props.children}</>;
};

export default AuthCheck;
